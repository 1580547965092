import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import logo from 'assets/imgs/logo-happ-heart.svg';
import { Link } from 'react-router-dom';

//components
import { ExamsIcon, ProgramsIcon, MobileHappHeartIcon, TipsIcon, NotificationsIcon, ProfileIcon } from 'happ-icons/icons';
import Notifications from 'happ-components/Notifications/Notifications';
import { ProfileIconOn } from 'happ-icons/icons';
import { ExamsIconOn } from 'happ-icons/icons';
import { MobileHappHeartIconOff } from 'happ-icons/icons';


const NavBar = () => {
  const navigate = useNavigate()
  const [active, setActive] = useState({
    indicators: false,
    programs: false,
    exams: false,
    tips: false,
    profile: false,
    currentPath: ''
  });
  const actionFocus = option => e => {
    e.preventDefault();
    navigate(option[1]);
    Object.keys(active).forEach(key => {
      active[key] = false;
    });
    active[option[0]] = true;
    setActive({
      ...active,
      active
    });
  }
  useEffect(() => {
    const currentPath = window.location.pathname;
    setActive({
      ...active,
      currentPath: currentPath
    });
  }, []);
  return (
    <Navbar bg="white" className="navbar-container" sticky="top">
      <Container >
        {/* <Navbar.Toggle aria-controls="navbarScroll" /> */}

        <Nav
          className="me-auto my-2 my-lg-0"
          style={{ maxHeight: '100px' }}
        // navbarScroll
        >
          <Link to="/">
            <img src={logo} alt="description" />
          </Link>
        </Nav>
        <Nav className="me-auto my-2 my-lg-0 nav-span">
          {/* <Link 
                  to="/" 
                  className={`${active.indicators ? 'active-icon' : ''} heart-icon`} 
                  onClick={actionFocus(['indicators','/'])}
                >
                  <MobileHappHeartIcon />
                  <span className="primary-color">Indicadores</span>
                </Link> */}
          <Link
            to="/mi-viaje"
            className={`${active.programs ? 'active-icon' : ''} program-icon`}
            onClick={actionFocus(['programs', '/'])}
          // style={{paddingRight: '40px', paddingLeft: '40px'}}
          >
            {/* <ProgramsIcon /> */}
            {active.currentPath === '/' ?
              <MobileHappHeartIcon /> :
              <MobileHappHeartIconOff />
            }
            <span className="primary-color">Mi viaje</span>
          </Link>
          <Link
            to="/mis-examenes"
            className={`${active.exams ? 'active-icon' : ''} icon`}
            onClick={actionFocus(['exams', '/mis-examenes'])}
          // style={{paddingRight: '40px', paddingLeft: '40px'}}
          >
            {active.currentPath === '/mis-examenes' ?
              <ExamsIconOn /> :
              <ExamsIcon />
            }
            <span className="primary-color">Exámenes</span>
          </Link>
          {/* <Link 
                  to="/mis-consejos" 
                  className={`${active.tips ? 'active-icon' : ''} icon`} 
                  onClick={actionFocus(['tips', '/mis-consejos'])}
                >
                  <TipsIcon />
                  <span className="primary-color">Consejos</span>
                </Link> */}
          <Link
            to="/mi-perfil"
            className={`${active.profile ? 'active-icon' : ''} icon`}
            onClick={actionFocus(['profile', '/mi-perfil'])}
          // style={{paddingRight: '40px', paddingLeft: '40px'}}
          >
            {active.currentPath === '/mi-perfil' ?
              <ProfileIconOn /> :
              <ProfileIcon />
            }
            <span className="primary-color">Mi Perfil</span>
          </Link>
        </Nav>
        <Nav className='notifications-content-nav'>
          <div>
            <NotificationsIcon
              notifications={3}
            />
          </div>
          <NavDropdown title="" id="collasible-nav-dropdown">
            <Notifications />
          </NavDropdown>
        </Nav>

      </Container>
    </Navbar>
  );
}

export default NavBar;