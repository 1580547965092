import {
    INIT_MEDICAL_GOALS,
    MEDICAL_GOALS,
    ERROR_MEDICAL_GOALS,
    LOGOUT,
    INIT_ADD_MEDICAL_GOAL_RECORD,
    ADD_MEDICAL_GOAL_RECORD,
    ERROR_ADD_MEDICAL_GOAL_RECORD
} from 'happ-redux/happ-types';
import { getMedicalGoals, addMedicalGoalRecord } from 'happ-redux/happ-services/medical-goals/medical-goals';

export function getMedicalGoalsAction (navigate) {
    return async (dispatch) => {
        dispatch(initMedicalGoals());
        try {
            const token = localStorage.getItem('token');
            if (token === null){
                dispatch(logout());
                navigate('/login');
            }
            const response = await getMedicalGoals(token);
            if (response.status === 401){
                dispatch(logout());
                navigate('/login');
            }
            if (response.status === 200) {
                dispatch(medicalGoals(response.data));
            } else {
                dispatch(errorMedicalGoals(response.data.msg));
            }
        } catch (error) {
            dispatch(errorMedicalGoals(error.data.msg));
        }
    }
}

export function addMedicalGoalRecordAction (goal, newParam, navigate) {
    return async (dispatch) => {
        dispatch(initAddMedicalGoalRecord());
        try {
            const token = localStorage.getItem('token');
            if (token === null){
                dispatch(logout());
                navigate('/login');
            }
            const response = await addMedicalGoalRecord(token, goal, newParam);
            if (response.status === 401){
                dispatch(logout());
                navigate('/login');
            }
            if (response.status === 200) {
                dispatch(sendMedicalGoalRecord(response.data));
            } else {
                dispatch(errorMedicalGoalRecord(response.data.msg));
            }

        } catch (error) {
            dispatch(errorMedicalGoalRecord(error.data.msg));
        }
    }
}

const initMedicalGoals = () => ({
    type: INIT_MEDICAL_GOALS
});

const logout = () => ({
    type: LOGOUT
});

const medicalGoals = response => ({
    type: MEDICAL_GOALS,
    payload: response
});

const errorMedicalGoals = error => ({
    type: ERROR_MEDICAL_GOALS,
    payload: error
});

const initAddMedicalGoalRecord = () => ({
    type: INIT_ADD_MEDICAL_GOAL_RECORD
});

const sendMedicalGoalRecord = response => ({
    type: ADD_MEDICAL_GOAL_RECORD,
    payload: response
});

const errorMedicalGoalRecord = error => ({
    type: ERROR_ADD_MEDICAL_GOAL_RECORD,
    payload: error
});