import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
// components
import { HighRisk } from 'happ-icons/icons';
import { ModerateRisk } from 'happ-icons/icons';
import { LowRisk } from 'happ-icons/icons';
import { HappContentIndicator } from 'happ-components/HappStyledComponents/HappContentIndicator/HappContentIndicator';
import { OverweightIcon, PressureIndicator, ColesterolIndicator, DiabetesIndicator } from 'happ-icons/icons';
import { useModal } from 'happ-custom-hooks/useModal/useModal';
import { DiabetesIndicatorBoxExam } from 'happ-icons/icons';
import { DislipidemiaIcon } from 'happ-icons/icons';
const MedicalStageDiagnosisDeliveryComplete = ({ medicalStageResults }) => {
    console.log(medicalStageResults);
    const {
        openDiagnosisCompleteDelivery,
        ShowModalDiagnosisCompleteDelivery
    } = useModal();
    const { rcvPatient } = useSelector(state => state.auth);
    return (
        <>
            <div className="indicators-content">
                <Row className='px-2'>
                    <>
                        {medicalStageResults && medicalStageResults.map(item => (
                            <Col key={item._id} md={6} xs={6} lg={6} className="px-0 pt-4  col-indicator-mobile">
                                <HappContentIndicator
                                    className={`box-content indicator-bg-${item.color}`}
                                    onClick={openDiagnosisCompleteDelivery(item.code, item)}
                                    role="button"
                                >
                                    <Row>
                                        {item.code === 'hipertension' ?
                                            (
                                                <>
                                                    <div className='my-2'>
                                                        <PressureIndicator
                                                            fill='white'
                                                            responsive='scale-mobile'
                                                        />
                                                    </div>
                                                </>
                                            ) : item.code === 'obesidad' ?
                                                (
                                                    <>
                                                        <div className='my-2'>
                                                            <OverweightIcon
                                                                fill='white'
                                                                responsive='scale-mobile'
                                                            />
                                                        </div>
                                                    </>
                                                ) : item.code === 'diabetes' || item.code === 'pre-diabetes' ?
                                                    (
                                                        <>
                                                            <div className='my-2'>
                                                                <DiabetesIndicatorBoxExam
                                                                    style={{ height: '53px' }}
                                                                    fill='white'
                                                                    responsive='scale-mobile'
                                                                />
                                                            </div>
                                                        </>
                                                    ) : item.code === 'dislipidemia' ?
                                                        (
                                                            <>
                                                                <div className='my-2'>
                                                                    <DislipidemiaIcon
                                                                     style={{ height: '53px' }}
                                                                        fill='white'
                                                                        responsive='scale-mobile'
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : null}
                                    </Row>
                                    <Row className={`dark-opacity border-bottom-container`}>
                                        <Col >
                                            {item.code === 'hipertension' ?
                                                (
                                                    <>
                                                        <p>Presión Arterial</p>
                                                    </>
                                                ) : item.code === 'obesidad' ?
                                                    (
                                                        <>
                                                            <p> Chequeo <strong>IMC</strong> </p>
                                                        </>
                                                    ) : item.code === 'diabetes' || item.code === 'pre-diabetes' ?
                                                        (
                                                            <>
                                                                <p>Azúcar</p>
                                                            </>
                                                        ) : item.code === 'dislipidemia' ? (
                                                            <>
                                                                <p>Colesterol</p>
                                                            </>
                                                        ) : null}
                                        </Col>
                                    </Row>
                                </HappContentIndicator>
                            </Col>
                        ))}
                        <ShowModalDiagnosisCompleteDelivery />
                    </>
                </Row>
                {/* {rcvPatient !== null ? (
                    <div className='indicators-content d-flex justify-content-center w-100 mt-5' >
                        {rcvPatient.rcv === 3 ? (
                            <HighRisk />
                        ) : null}
                        {rcvPatient.rcv === 2 ? (
                            <ModerateRisk />
                        ) : null}
                        {rcvPatient.rcv === 1 ? (
                            <LowRisk />
                        ) : null}
                    </div>
                ) : null} */}
            </div>
        </>
    );
}

export default MedicalStageDiagnosisDeliveryComplete;