import React from 'react';
import { Form, Col, Button, Row } from 'react-bootstrap';

const LungCancerSurveyPartOne = ({state, handlerChange, next}) => {
    const { question1 } = state;
    return ( 
        <Row className='justify-content-md-center'>
            <Col md={9} sm={12} lg={9} className="col-question">
                <h6>1.- ¿Fuma en promedio más de 1 cigarrillo al día?</h6>
                <Form.Check
                    className='select-question'
                    label="Si"
                    checked={String(question1) === "Si"}
                    onChange={handlerChange}
                    value={'Si'}
                    name="question1"
                    type="radio"
                />
                <Form.Check
                    className='select-question'
                    label="No"
                    checked={String(question1) === "No"}
                    onChange={handlerChange}
                    value={'No'}
                    name="question1"
                    type="radio"
                />
            </Col>
            <Row className='row-error'>
                {/* {surveyError ? (
                    <Col md={12} sm={12} lg={5} className='content-error-survey'>
                            <p>Debe seleccionar una opción para continuar</p>
                    </Col>
                ) : null} */}
            </Row>
            <Col className='d-flex col-btns' md={5} sm={12} lg={5}>
                <Button 
                    className='shadow btn-grey-survey'
                >
                    <div className='d-flex justify-content-center mt-1'>
                        <p>
                            Atrás
                        </p>
                    </div>
                </Button>
                <Button 
                    className='shadow btn-blue-survey'
                    onClick={next}
                >
                    <div className='d-flex justify-content-center mt-1'>
                        <p>
                            Siguiente
                        </p>
                    </div>
                </Button>
            </Col>
        </Row>
    );
}
 
export default LungCancerSurveyPartOne;