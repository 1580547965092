import { FETCH_OAUTH_CLIENT_DATA, SET_OAUTH_LOADING } from 'happ-redux/happ-types';

const initialState = {
  client: null,
  tos: null,
  loading: true,
  info: [
    { title: 'Nombre y apellido', icon: '<IdcardOutlined style={{ fontSize: 24 }} /> ' },
    { title: 'Género', icon: '<UserOutlined style={{ fontSize: 24 }} /> ' },
    { title: 'Correo electronico', icon: '<MailOutlined style={{ fontSize: 24 }} />' },
    { title: 'Fecha de nacimiento', icon: '<CalendarOutlined style={{ fontSize: 24 }} />' }
  ],
  logo: null
}
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {

    case FETCH_OAUTH_CLIENT_DATA:
      return{
        client: action.payload.client_name,
        info:[...state.info],
        loading: state.loading,
        tos: action.payload.client_tos,
        logo: action.payload.client_logo
      }

    case SET_OAUTH_LOADING:
      return {
        client: state.client,
        info: state.info,
        loading: action.payload,
        tos: state.tos,
        logo: state.logo,
      }

    default:
    return state
  }
}