import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { DiabetesIndicator } from 'happ-icons/icons'
import { Loading } from 'happ-icons/icons'
import { OverweightIcon } from 'happ-icons/icons'
import { PressureIndicator } from 'happ-icons/icons'
import BarInfoContentLC from 'happ-components/BarInfoContent/BarInfoContentLC';

const SubContentDiagnosisModalLC = ({ diagnosisContent }) => {
    console.log(diagnosisContent);
    return (
        <>
            {diagnosisContent.code === 'colesterol' && !diagnosisContent.results ? (
                <>
                    <Row className='my-2'>
                        <Col className='d-flex justify-content-around align-items-center pt-1'>
                            <Loading
                                fill='white'
                                responsive='scale-mobile'
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className='my-2 mx-2'>
                            <p className='p-modal'>Para completar tu chequeo es necesario que te realices un Perfil Lipídico.</p>
                        </Col>
                    </Row>
                </>
            ) : null}

            {diagnosisContent.code === 'diabetes' || diagnosisContent.code === 'pre-diabetes' ? (
                <>
                    <Row className='my-2'>
                        <Col className='d-flex justify-content-around align-items-center' >
                            <DiabetesIndicator
                                fill='white'
                                responsive='scale-mobile'
                            />
                            <div className='modal-content-tape-result'>
                                <p className='modal-tape-result'>{diagnosisContent.results[0].glicemia} <div className='modal-unit-of-measurement'> mg/dl</div></p>
                            </div>
                            <BarInfoContentLC diagnosisContent={diagnosisContent} />
                        </Col>
                    </Row>
                    <Row>
                        {diagnosisContent.color !== 'success' ? (
                            <Col className='my-2 mx-2'>
                                <p className='p-modal'><strong>¡Alerta!</strong> Descubrimos que <strong>tus niveles de azúcar están sobre los parámetros normales</strong> y es necesario analizarlos más en profundidad en la <strong>siguiente etapa</strong>.</p>
                            </Col>
                        ) : (
                            diagnosisContent.results.fast === true ? (
                                <Col className='my-2 mx-2'>
                                    <p className='p-modal'><strong>¡Felicidades!</strong> Tu <strong>glicemia es normal.</strong> Esto quiere decir que tu sangre tiene regulados los niveles de azúcar, lo que disminuye el riesgo de padecer diabetes. ¡Sigue así! .</p>
                                </Col>
                            ) : (
                                <Col className='my-2 mx-2'>
                                <p className='p-modal'>
                                    Tus niveles de <strong>glicemia sin ayuno son normales</strong>, indicando una buena regulación del azúcar en sangre.
                                </p>
                            </Col>
                            )
                        )}
                    </Row>
                </>
            ) : null}

            {diagnosisContent.code === 'obesidad' ? (
                <>
                    <Row className='my-2'>
                        <Col className='d-flex justify-content-around align-items-center' >
                            <OverweightIcon
                                fill='white'
                                responsive='scale-mobile'
                            />
                            <div className='modal-content-tape-result'>
                                <div className='modal-tape-result'> {diagnosisContent.results[0].imc}</div>
                            </div>
                            <BarInfoContentLC diagnosisContent={diagnosisContent} />
                        </Col>
                    </Row>
                    <Row className='my-2'>
                        <Col className='d-flex justify-content-around fw-bold'>
                            <div>
                                Peso: {diagnosisContent.results[0].weight}kg
                            </div>
                            <div>
                                Altura: {diagnosisContent.results[0].height}cm
                            </div>
                            <div>
                                Cintura: {diagnosisContent.results[0].circumference}cm
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        {diagnosisContent.status === 'Saludable' ? (
                            <Col className='my-2 mx-2'>
                                <p className='p-modal '> <strong>¡Felicitaciones!</strong> Tu <strong>índice de masa corporal es normal.</strong> Esto significa que tienes menos riesgo de sufrir un infarto cardíaco. ¡Sigue así!</p>
                            </Col>
                        ) : diagnosisContent.status === 'Sobrepeso' ? (
                            <Col className='my-2 mx-2'>
                                <p className='p-modal '> <strong>¡Atención!</strong> Los resultados de tu índice de masa corporal muestran valores que corresponden a <strong>sobrepeso</strong>. Es importante que cambies tus hábitos para revertir esta situación.</p>
                            </Col>
                        ) : diagnosisContent.status === 'Obesidad I' || diagnosisContent.status === 'Obesidad II' || diagnosisContent.status === 'Obesidad III' ? (
                            <Col className='my-2 mx-2'>
                                <p className='p-modal '> <strong>¡Atención!</strong> Los resultados de tu índice de masa corporal muestran valores que corresponden a <strong>obesidad</strong>, una enfermedad inflamatoria sistémica que  afecta distintos órganos del cuerpo.  Debes revertir esta condición. </p>
                            </Col>
                        ) : diagnosisContent.status === 'Bajo peso' ? (
                            <Col className='my-2 mx-2'>
                                <p className='p-modal '> <strong>¡Alerta!</strong> Los resultados de tu índice de masa corporal muestran valores <strong>bajo los niveles normales</strong>. Te recomendamos mejorar tus habitos alimenticios para revertir esta situacion.</p>
                            </Col>
                        ) : null}
                    </Row>
                </>
            ) : null}

            {diagnosisContent.code === 'hipertension' ? (
                <>
                    <Row>
                        <Col className='d-flex justify-content-around align-items-center'>
                            <PressureIndicator
                                fill='white'
                                responsive='scale-mobile'
                            />
                            <div className='modal-content-tape-result' >
                                <p className='modal-tape-result'>{diagnosisContent.results[0].sistolica}/{diagnosisContent.results[0].diastolica}  <div className='modal-unit-of-measurement'> mm/Hg</div></p>
                            </div>
                            <BarInfoContentLC diagnosisContent={diagnosisContent} />
                        </Col>
                    </Row>
                    <Row>
                        {diagnosisContent.color === 'danger' ? (
                            <Col className='my-2 mx-2'>
                                <p className='p-modal'><strong>¡Precaución!</strong> Los resultados de tu presión arterial muestran <strong>valores sobre el rango normal.</strong> Existe un aumento de presión en la sangre sobre las paredes de las arterias. Esto puede afectar diferentes órganos, especialmente al <strong>riñón</strong>. </p>
                            </Col>
                        ) : (
                            <Col className='my-2 mx-2'>
                                <p className='p-modal'><strong>¡Felicidades!</strong> Tu <strong>presión arterial es normal</strong>. Esto quiere decir que la circulación de tu sangre no está afectada por la tensión de las paredes de las arterias, lo que disminuye el riesgo de que se dañen tus órganos, especialmente el riñón.  </p>
                            </Col>
                        )}
                    </Row>
                </>
            ) : null}

        </>
    );
}

export default SubContentDiagnosisModalLC;