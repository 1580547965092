import { BrowserRouter } from "react-router-dom";
//Routes
import RoutesMap from 'happ-routes/RoutesMap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/css/global.css';

//redux
import { Provider } from 'react-redux';
import store from 'happ-store';

function App() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <RoutesMap />
      </Provider>
    </BrowserRouter>
  );
}

export default App;
