import React from 'react';
import Tabs from './NursingTeleMedicalStageDelivery/Tabs';
const NursingTeleMedicalStageDelivery = ({
        medicalStageResults,
        lastPrescription,
        lastConsultation,
        otherExams,
        isMetaExams
    }) => {
    return ( 
        <div className={`indicators-content ${isMetaExams ? 'meta-indicators-content-width' : ''}`}>
            <Tabs 
                medicalStageResults={medicalStageResults}
                lastPrescription={lastPrescription}
                lastConsultation={lastConsultation}
                otherExams={otherExams}
                isMetaExams={isMetaExams}
            />
        </div>
    );
}
 
export default NursingTeleMedicalStageDelivery;