import React from 'react';
import { useSelector } from 'react-redux';
import { HappContentIndicator } from 'happ-components/HappStyledComponents/HappContentIndicator/HappContentIndicator';
import { Row, Col } from 'react-bootstrap';
import { NutritionalPlan, SportsPlan, Goals, ImproveYourLifestyle } from 'happ-icons/icons';

const MyImprovementPlan = () => {
    const { sportPlan, nutritionPlan } = useSelector(state => state.auth);
    const handleClick = (file) => {
        if (file !== null && file !== '') {
            window.open(file);
        }
    };
    return (
        <>
            <Row className="pt-3">
                <div className="d-flex justify-content-between">
                    <HappContentIndicator
                        className={'box-content-flex box-indicator bg-white mb-4'}
                        onClick={() => handleClick(nutritionPlan)}
                        role="button"
                    >
                        <Col>
                            <Row className='mt-3' >
                                <NutritionalPlan
                                    fill='white'
                                    responsive='scale-mobile'
                                />
                            </Row>
                            <div style={{ fontSize: '11px', lineHeight: '17px' }} className='primary-color-text'>Plan nutricional</div>
                            <Row className={nutritionPlan === null ? 'dark-opacity border-bottom-container' : 'border-bottom-container-exam blue-border'}>
                                <Col >
                                    <p style={{ fontWeight: '400' }}> ver más</p>
                                </Col>
                            </Row>
                        </Col>
                    </HappContentIndicator>
                    <HappContentIndicator
                        className={'box-content-flex box-indicator bg-white  mb-4'}
                        onClick={() => handleClick(sportPlan)}
                        role="button"
                    >
                        <Col>
                            <Row className='mt-3' >
                                <SportsPlan
                                    fill={sportPlan === null ? '#7C7C7C' : '#04477A'}
                                    responsive='scale-mobile'
                                />
                            </Row>
                            <div style={{ fontSize: '11px', lineHeight: '17px' }} className='primary-color-text'>Plan deportivo</div>
                            <Row className={sportPlan === null ? 'dark-opacity border-bottom-container' : 'border-bottom-container-exam blue-border'}>
                                <Col >
                                    <p style={{ fontWeight: '400' }}> ver más</p>
                                </Col>
                            </Row>
                        </Col>
                    </HappContentIndicator>
                    <HappContentIndicator
                        className={'box-content-flex box-indicator bg-white mb-4'}
                        // onClick={() => handlePreviewClick(aus, 'mp3')}
                        role="button"
                    >
                        <Col>
                            <Row className='mt-3' >
                                <Goals
                                    responsive='scale-mobile'
                                // style={{ width: '48px', height: '48px' }}
                                />
                                <div style={{ fontSize: '11px', lineHeight: '17px' }} className='primary-color-text'>Metas</div>
                            </Row>

                            <Row className={`dark-opacity border-bottom-container`}>
                                <Col >
                                    <p style={{ fontWeight: '400' }}> ver más</p>
                                </Col>
                            </Row>
                        </Col>
                    </HappContentIndicator>
                </div>
            </Row>
            <div className='d-flex justify-content-center px-1 treatment-icon-content'>
                <ImproveYourLifestyle />
            </div>
        </>
    );
}

export default MyImprovementPlan;