import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, ButtonGroup, Card } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import bajo from 'assets/imgs/15.png';
import medio from 'assets/imgs/50.png';
import alto from 'assets/imgs/75.png';
// components
import { HappContentIndicator, SubContentIndicator } from 'happ-components/HappStyledComponents/HappContentIndicator/HappContentIndicator';
import { Message } from 'happ-components/HappStyledComponents/HappElementsStyled/HappElementsStyled';
import { Dyslipidemiaicon, OverweightIcon, HypertensionIcon, DiabetesIcon, ScoreIcon, SmallScoreIcon, PressureIndicator, ColesterolIndicator, DiabetesIndicator } from 'happ-icons/icons';
import Params from 'happ-components/Indicators/Params';
import { Loader } from 'happ-components/Loader/Loader';
//actions
import { getHealthIndicatorsAction } from 'happ-redux/happ-actions/healthIndicatos/healthIndicatorsActions';
import IndicatorModal from './IndicatorModal';
import { indicadoresModal } from 'happ-data/data2'
import { useModal } from 'happ-custom-hooks/useModal/useModal';
import { useModal2 } from 'happ-custom-hooks/useModal/useModal2';
import { agendaModal } from 'happ-data/data2';
import { HighRisk } from 'happ-icons/icons';
import { ModerateRisk } from 'happ-icons/icons';
import { LowRisk } from 'happ-icons/icons';
import HomeMessage from 'happ-components/HomeMessage/HomeMessage';
import ModalColesterol from 'happ-components/Modal/ModalColesterol';
import ModalPeso from 'happ-components/Modal/ModalPeso';
import ModalPresion from 'happ-components/Modal/ModalPresion';
import ModalAzucar from 'happ-components/Modal/ModalAzucar';
import HomeMessage2 from 'happ-components/HomeMessage/HomeMessage2';
import ModalColesterol1 from 'happ-components/Modal/ModalChequeo1/ModalColesterol1';
import ModalPeso1 from 'happ-components/Modal/ModalChequeo1/ModalPeso1';
import ModalPresion1 from 'happ-components/Modal/ModalChequeo1/ModalPresion1';
import ModalAzucar1 from 'happ-components/Modal/ModalChequeo1/ModalAzucar1';
import HomeMessageExamenesFisicos from 'happ-components/HomeMessage/HomeMessageExamenesFisicos';
import ModalColesterol2 from 'happ-components/Modal/ModalChequeo2/ModalColesterol2';
import ModalPeso2 from 'happ-components/Modal/ModalChequeo2/ModalPeso2';
import ModalPresion2 from 'happ-components/Modal/ModalChequeo2/ModalPresion2';
import ModalAzucar2 from 'happ-components/Modal/ModalChequeo2/ModalAzucar2';
import { DiabetesIndicatorBoxExam } from 'happ-icons/icons';
import { Ecg } from 'happ-icons/icons';
import { Edems } from 'happ-icons/icons';
import { Auscultation } from 'happ-icons/icons';
import ModalEcg from 'happ-components/Modal/ModalExamPhysical/ModalEcg';
import ModalEdms from 'happ-components/Modal/ModalExamPhysical/ModalEdms';
import ModalAuscultation from 'happ-components/Modal/ModalExamPhysical/ModalAuscultation';


const arrayOpen = [];
const IndicatorExamenesFisicos = ({ loading, medicalStage }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getHealthIndicatorsAction(navigate));
    }, []);

    const { healthIndicators, rcvPatient, errorServer, errorMsg } = useSelector(state => state.indicators);
    const [show, handleShow, handleClose] = useModal2(false);
    const [show2, handleShow2, handleClose2] = useModal2(false);
    const [show3, handleShow3, handleClose3] = useModal2(false);
    const [show4, handleShow4, handleClose4] = useModal2(false);

    const status = 'examenesfisicos'

    return (
        loading ? (
            <Loader />
        ) : (
            <>
                <HomeMessageExamenesFisicos medicalStage={medicalStage} status={status} />
                <div className="indicators-content">
                    {/* <Row  className=''> */}
                    <Row className="cajas-responsive">
                        <div className="d-flex justify-content-between">
                            {/* <Card className="w-100 boxcajita">
                                <Card.Body>Caja 1</Card.Body>
                            </Card> */}

                            <HappContentIndicator
                                // bgColor={ `indicator-${item.determination.code}`}
                                className={status === 'examenesfisicos' ? 'box-content boxcajita  mb-4' : null}
                                onClick={handleShow}
                                role="button"
                            >
                                <Col>
                                <Row className='mt-4'>
                                    <div>
                                        <Ecg
                                            fill='white'
                                            responsive='scale-mobile'
                                        />
                                    </div>
                                    <div className='primary-color title-physical-exam'>Electrocardiograma</div>
                                </Row>
                        
                                <Row className={status === 'examenesfisicos' ? 'border-bottom-container-exam blue-border' : `dark-opacity border-bottom-container`}>
                                    <Col >
                                        <p style={{fontSize: '17px'}}> ver más</p>
                                    </Col>
                                </Row>
                                </Col>
                            </HappContentIndicator>
                            <HappContentIndicator
                                // bgColor={ `indicator-${item.determination.code}`}
                                className={status === 'examenesfisicos' ? 'box-content boxcajita  mb-4' : null}
                                onClick={handleShow2}
                                role="button"
                            >
                                <Col>
                                <Row className='mt-4' >
                                    <div className=''>
                                        <Edems
                                            fill='white'
                                            responsive='scale-mobile'
                                        />
                                    </div>
                                    <div className='primary-color title-physical-exam'>Valoración de edema</div>
                                </Row>
                        
                                <Row className={status === 'examenesfisicos' ? 'border-bottom-container-exam blue-border' : `dark-opacity border-bottom-container`}>
                                    <Col >
                                        <p style={{fontSize: '17px'}}> ver más</p>
                                    </Col>
                                </Row>
                                </Col>
                            </HappContentIndicator>
                            <HappContentIndicator
                                // bgColor={ `indicator-${item.determination.code}`}
                                className={status === 'examenesfisicos' ? 'box-content boxcajita mb-4' : null}
                                onClick={handleShow3}
                                role="button"
                            >
                                <Col>
                                <Row className='mt-3' >
                                    <div className=''>
                                        <Auscultation
                                            // fill='black'
                                            responsive='scale-mobile'
                                            // style={{backgroundColor: 'blue', color: 'red'}}
                                        />
                                    </div>
                                    <div className='primary-color title-physical-exam'>Auscultación</div>
                                </Row>
                        
                                <Row className={status === 'examenesfisicos' ? 'border-bottom-container-exam blue-border' : `dark-opacity border-bottom-container`}>
                                    <Col >
                                        <p style={{fontSize: '17px'}}> ver más</p>
                                    </Col>
                                </Row>
                                </Col>
                            </HappContentIndicator>
                        </div>
                    </Row>
                    {/* <HappContentIndicator
                                    // bgColor={ `indicator-${item.determination.status}`}
                                    className={status === 'examenesfisicos' ? 'box-content indicator-danger mb-4' : null} // en estatus podria enviarme el success el danger el normal para cambiar el color(agregar mas status)
                                    // onClick={openModal(test)} // una vez editada la api le envio item.modalinfo(que debiese ser un objeto) y el indice
                                    onClick={handleShow}
                                    role="button"
                                > */}
                    {/* <Row> */}
                    {/* <Col className='d-flex justify-content-center' style={{border: '1px solid red'}}> */}
                    {/* <div className='my-2'>
                                            <ColesterolIndicator
                                                fill='white'
                                                responsive='scale-mobile'
                                            />
                                        </div> */}
                    {/* </Row> */}
                    {/* <Row className={status === 'examenesfisicos' ? `dark-opacity border-bottom-container` : 'border-bottom-container blue-border'}>
                                        <Col >
                                            <p> Colesterol</p>
                                        </Col>
                                    </Row> */}
                    {/* </HappContentIndicator> */}


                    {/* <ModalColesterol2 show={show} onHide={handleClose} />
                            <ModalPeso2 show={show2} onHide={handleClose2} />
                            <ModalPresion2 show={show3} onHide={handleClose3} />
                            <ModalAzucar2 show={show4} onHide={handleClose4} /> */}
                    <ModalEcg show={show} onHide={handleClose} />
                    <ModalEdms show={show2} onHide={handleClose2} />
                    <ModalAuscultation show={show3} onHide={handleClose3} />
                    {/* <ModalAzucar2 show={show4} onHide={handleClose4} /> */}
                    {/* <ShowModal3Test/> */}
                    {/* <ShowModal /> */}
                    {/* <ShowModal2 /> para stage2 */}
                    {/* <ShowModal3 /> para examenesfisicos  */}
                    {/* </Row> */}
                    {/* {status === 'examenesfisicos' ? ( */}
                    <>
                        {/* <h4 className='primary-color-text'>Tu riesgo cardiovascular</h4> */}
                        {/* {rcvPatient !== null ? ( */}
                        <div className='indicators-content d-flex justify-content-center w-100 mt-3' >
                            <ModerateRisk />
                            {/* {rcvPatient === 'Alto' ? (
                                                <HighRisk  />
                                                ) : null} */}
                            {/* {rcvPatient === 'Moderado' ? (
                                                <ModerateRisk  />
                                                ) : null} */}
                            {/* {rcvPatient === 'Bajo' ? (
                                                <LowRisk  />
                                            ) : null} */}
                        </div>
                        {/* ) : null} */}
                    </>
                    {/* ) : null} */}

                </div>
            </>

        )
    );
}

export default IndicatorExamenesFisicos;