import styled from 'styled-components';

export const HappContainerNotificationIcon = styled.div.attrs(props => ({}))`
    width:74px;
    height: 74px;
    background-color: ${props => props.color ? props.color : "#044064"};
    border-radius: 50%;
    text-align: center;
        svg{
            margin-top: 16px;
        }
`; 