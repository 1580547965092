import {
    INIT_GET_PHQ9_CALCULATION,
    GET_PHQ9_CALCULATION,
    INIT_GET_LAST_PRESCRIPTION,
    GET_LAST_PRESCRIPTION,   
    INIT_SEND_MENTAL_HEALTH_SURVEY,
    SEND_MENTAL_HEALTH_SURVEY,
    ERROR_SEND_MENTAL_HEALTH_SURVEY,
    CLEAN_SURVEY
} from 'happ-redux/happ-types'


const initialState = {
    loading: false,
    phq9Calculation: null,
    mentalPrescription: null,
    saveMentalHealthSurvey: null,
    loadingSave: false,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case INIT_GET_PHQ9_CALCULATION:
        case INIT_GET_LAST_PRESCRIPTION:
            return {
                ...state,
                loading: true,
            }
        case INIT_SEND_MENTAL_HEALTH_SURVEY:
            return {
                ...state,
                loadingSave: true,
            }        
        case GET_PHQ9_CALCULATION:
            return {
                ...state,
                loading: false,
                phq9Calculation: action.payload,
            }   
        case GET_LAST_PRESCRIPTION:
            return {
                ...state,
                loading: false,
                mentalPrescription: action.payload,
            }  
        case SEND_MENTAL_HEALTH_SURVEY:     
            return {
                ...state,
                loadingSave: false,
                saveMentalHealthSurvey: true,
            }
        case ERROR_SEND_MENTAL_HEALTH_SURVEY:     
            return {
                ...state,
                loadingSave: false,
                saveMentalHealthSurvey: false,
            }
        case CLEAN_SURVEY:
            return {
                ...state,
                saveMentalHealthSurvey: null,
            }    
        default:
            return state;      
    }
}