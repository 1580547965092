import { DiagnosticModalIcon } from 'happ-icons/icons'
import { CloseModalBlue } from 'happ-icons/icons'
import React from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const ModalDelete2 = (props) => {
    const status = 'stage3'
  return (
    <Modal
    {...props}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
>
    {/* este es para el colesterol plomo */}
    <div className='modal-content-white'>
        <Row className=''>
            <Col className='mt-1'>
                <div onClick={props.onHide} className='modal-close-btn primary-color-text'>
                    <CloseModalBlue
                    />
                </div>
                {status === 'stage3' ? (
                    <div className='text-center'>

                        <DiagnosticModalIcon fill="#044064" className="my-2 ms-3" />
                        {/* <h6 className='primary-color-text fw-bold' style={{letterSpacing: '0.08em'}}>Chequeo y diagnóstico</h6> */}
                        {/* <h6 className='primary-color-text fw-bold'>Titulo</h6> */}

                    </div>
                ) : null}

            </Col>
        </Row>
        <Modal.Body style={{ padding: 0 }}>
            <Row className='text-center'>
                <Col>
                    <h6 className='primary-color-text fw-bold'>¿Estás seguro que deseas eliminar tu hora?</h6>
                    <div className='d-flex justify-content-center py-3'>
                        <Link to="/examenesdeconfirmacion"  >
                            <Button className='primary-bg-color btn-delete'>
                                <p>Sí</p>
                            </Button>
                        </Link>
                        <div onClick={props.onHide} className='ms-2' >
                            <Button className='shadow danger-bg-color btn-delete'>
                                <p>No</p>
                            </Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </Modal.Body>
    </div>
</Modal>
  )
}

export default ModalDelete2