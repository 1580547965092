import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {Row, Col, Nav} from 'react-bootstrap';
//components
import {HappContainer} from 'happ-components/HappStyledComponents/HappContainer/HappContainer';
import {SubNav} from 'happ-components/HappStyledComponents/SubNavOptions/SubNavOptions';
import { Message } from 'happ-components/HappStyledComponents/HappElementsStyled/HappElementsStyled';
import DeterminationsList from 'happ-components/DeterminationsList/DeterminationsList';
import PatientProgram from 'happ-components/PatientProgram/PatientProgram';
import { Loader } from 'happ-components/Loader/Loader';
//actions
import { getMedicalScheduleAction } from 'happ-redux/happ-actions/medicalSchedule/medicalScheduleActions';


const MedicalAgendaOld = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const {medicalSchedule, determinations, allGoodStatus, errorServer, errorMsg, loading, objLength} = useSelector(state => state.schedule);
    useEffect(() => {
        dispatch(getMedicalScheduleAction(navigate));
    }, []);
    return ( 
        <Row className="mt-content">
            <HappContainer>
                <Col sm={12} lg={12} md={12} className="sub-options">
                    <Nav className="">
                        <Nav.Item>
                            <SubNav
                                bgClass='primary-bg-color'
                                brRadius="40px 0px 0px 40px"
                            >
                                Mi viaje
                            </SubNav>
                        </Nav.Item>
                        <Nav.Item className="comming-soon">
                            <SubNav
                                bgClass='grey-bg-color'
                                textColor='#666666'
                                brRadius='0px 40px 40px 0px;'
                            >
                                Mi pastillero <small>próximamente habilitado</small>
                            </SubNav>
                        </Nav.Item>
                    </Nav> 
                </Col>
                {/* {loading ? (
                    <Loader />
                ) : (
                    allGoodStatus ? (
                        <Col sm={12} lg={12} md={12} className="mb-5 mt-5">
                            <Message>
                                <p>
                                 ¡Felicidades! luego de tu examen podemos decir que no tienes enfermedades cardiovasculares, continúa con tus 
                                hábitos saludables que llevas e informate de otros que te podrían interesar con nuestra app para mantenerte así, no te descuides. 
                                </p>
                            </Message>
                        </Col>
                    ) : (
                        medicalSchedule === null ? (
                            <Col sm={12} lg={12} md={12} className="mb-5 mt-5">
                                <Message>
                                    <p>
                                        Su agenda medica esta en proceso de ser creada
                                    </p>
                                </Message>
                            </Col>
                        ) : (
                            <>
                                <Col sm={12} lg={12} md={12} className="mb-3 mt-2">
                                    <DeterminationsList 
                                        determinations={determinations}
                                    />
                                </Col>
                                <Col sm={12} lg={12} md={12} className="mt-2 mb-3">
                                    <PatientProgram 
                                        medicalSchedule={medicalSchedule}
                                        objLength={objLength}
                                    />
                                </Col>
                            </>
                        )
                    )
                )} */}
            </HappContainer>
        </Row>            
    );
}
 
export default MedicalAgendaOld;