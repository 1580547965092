import React from 'react';
import MedicalStageStaticDelivery from 
    'happ-components/MedicalStageDelivery/MedicalStageStaticDelivery';
import MedicalStageDiagnosisDelivery from 
    'happ-components/MedicalStageDelivery/MedicalStageDiagnosisDelivery';
import MedicalStageDiagnosisDeliveryComplete from 
    'happ-components/MedicalStageDelivery/MedicalStageDiagnosisDeliveryComplete';
import NursingTeleMedicalStageDelivery from 
    'happ-components/MedicalStageDelivery/NursingTeleMedicalStageDelivery';   
import MedicalStageDiagnosisDeliveryMB from 'happ-components/MedicalStageDelivery/MedicalStageDiagnosisDeliveryMB';    

const Indicators = ({ 
        medicalStage, 
        medicalStageResults, 
        isDislipidemia, 
        lastPrescription, 
        lastConsultation, 
        otherExams,
        ecgDocument,
        isMetaExams,
        allHealthy,
        companyName,
        isLabConditional,
        medicalOrder
    }) => {
    return (
        <>
            {medicalStage && medicalStage === 'welcome' ? (
                <>
                    <MedicalStageStaticDelivery />
                </>
            ) : null}
            {medicalStage && medicalStage === 'diagnostic-complementary-one' ? (
                <>
                    <MedicalStageDiagnosisDelivery 
                        medicalStageResults={medicalStageResults}
                        isDislipidemia={isDislipidemia}
                        allHealthy={allHealthy}
                        companyName={companyName}
                        medicalOrder={medicalOrder}
                    />
                </>
            ) : null}
            {
                medicalStage && 
                (
                    medicalStage === 'confirmatory-diagnostic-complementary-one' ||
                    medicalStage === 'medical-teleconsultation'
                ) ? (
                <>
                    <MedicalStageDiagnosisDeliveryComplete 
                        medicalStageResults={medicalStageResults}
                    /> 
                </>
            ) :null}
            {
                medicalStage && (
                    medicalStage === 'nursing-teleconsultation-one' || 
                    medicalStage === 'meta-exams-one' ||
                    medicalStage === 'meta-exams-two' ||
                    medicalStage === 'meta-exams-three' ||
                    medicalStage === 'meta-exams-two-one' ||
                    medicalStage === 'meta-sos' ||
                    medicalStage === 'sos'
                ) ? (
                <>
                    <NursingTeleMedicalStageDelivery 
                        lastPrescription={lastPrescription}
                        lastConsultation={lastConsultation}
                        otherExams={otherExams}
                        medicalStageResults={medicalStageResults}
                        isMetaExams={isMetaExams}
                    /> 
                </>
            ) :null}
            {
                medicalStage &&
                medicalStage === 'diagnostic-complementary-two' ? (
                    <>
                        {
                            isLabConditional !== null ? (
                                <>
                                    {isLabConditional ? (
                                        <>
                                            <MedicalStageDiagnosisDeliveryMB 
                                                medicalStageResults={medicalStageResults}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <MedicalStageDiagnosisDeliveryComplete 
                                                medicalStageResults={medicalStageResults}
                                            /> 
                                        </>
                                    )}
                                </>
                            ) : null
                        }
                    </>
                ) : null
            }
        </>
    )
}

export default Indicators;