import React from 'react';
import { Col } from 'react-bootstrap';
import { MentalHealthOkicon } from 'happ-icons/icons';

const MentalHealthOk = () => {
    return ( 
        <div className=''>
            <Col md={9} sm={12} lg={9} className='pt-2 result-content'>
                <div className='d-flex justify-content-center'>
                    <div className='text-center px-5 py-2 text-white primary-bg-color  rounded-1 indicators-content'>
                        <strong className='title-stages' >Resultados Encuesta de Salud</strong>
                    </div>
                </div>
            </Col>
            <Col md={9} sm={12} lg={9} className='mt-4 text-center result-content'>
                <p>Los resultados de tu encuesta de salud muestran que estás en un estado de equilibrio mental.</p>
            </Col>
            <Col md={9} sm={12} lg={9} className='mt-4 text-center result-content result-icon'>
                <MentalHealthOkicon />
            </Col>
            <Col md={9} sm={12} lg={9} className='mt-5 text-center result-content'>
                <p>
                    Recuerda que mantener una buena salud mental es un proceso continuo, 
                    y es importante seguir practicando el autocuidado, la autoexploración y buscar apoyo si es necesario. 
                    ¡Sigue adelante con esa actitud positiva y cuidado personal!
                </p>
            </Col>
        </div>
    );
}
 
export default MentalHealthOk;