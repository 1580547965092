import { DiagnosticModalIcon } from 'happ-icons/icons'
import { DiagnosticCalendarIcon } from 'happ-icons/icons'
import { CloseModalBlue } from 'happ-icons/icons'
import React from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const ModalToAgendarExamF1 = (props) => {

    const status = 'stage3'

  return (
    <Modal
    {...props}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
>
    {/* este es para el colesterol plomo */}
    <div className='modal-content-white'>
        <Row className=''>
            <Col className='mt-1'>
                <div onClick={props.onHide} className='modal-close-btn primary-color-text'>
                    <CloseModalBlue
                    />
                </div>
                {status === 'stage3' ? (
                    <div className='text-center'>

                        <DiagnosticModalIcon fill="#044064" className="my-2 ms-3" />
                        {/* <h6 className='primary-color-text fw-bold' style={{letterSpacing: '0.08em'}}>Chequeo y diagnóstico</h6> */}
                        <h6 className='primary-color-text fw-bold'>Exámenes Físicos</h6>

                    </div>
                ) : null}

            </Col>
        </Row>
        <Modal.Body style={{ padding: 0 }}>
            <div>
                <Row>
                    <Col>
                        <ul className='primary-color-text mx-5'>
                            <li className=' my-2'> En esta etapa realizaremos exámenes <strong>no invasivos</strong></li>
                            <li className=' my-2'> El objetivo es <strong>descartar</strong> otras <strong>enfermedades</strong>.</li>
                            <li className=' my-2'> Realizaremos un <strong>Electrocardiograma</strong>. Te <strong>auscultaremos</strong> y evaluaremos si cuentas con <strong>edemas</strong>.</li>
                        </ul>
                        <div className='py-3'>
                                    <Link to="/agendarhora3" className='d-flex justify-content-center link'>
                                        <Button className='shadow btn-blue'>
                                            <div className='d-flex justify-content-center mt-1'>
                                                <DiagnosticCalendarIcon />
                                                <p className='ms-3'>Agendar</p> 
                                            </div>
                                        </Button>
                                    </Link>
                                </div>
                    </Col>
                </Row>
            </div>
        </Modal.Body>
    </div>
</Modal>
  )
}

export default ModalToAgendarExamF1