import axiosClient from 'happ-config/axios';

const getScheduleByPatient = async (id) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            // 'authtoken': token
        };
        const response = await axiosClient.get(`/patient-scheduling/get-schedules-by-patient/${id}`, { headers });
        return response;
    } catch (error) {
        const { response } = error;
        return response;
    }
}

const getCurrentScheduleByPatient = async (id) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            // 'authtoken': token
        };
        const response = await axiosClient.get(`/patient-scheduling/get-current-schedule-by-patient/${id}`, { headers });
        return response;
    } catch (error) {
        const { response } = error;
        return response;
    }
}

const createScheduleByPatient = async (id, phone, schedule, day, hour, hourId) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            // 'authtoken': token
        };
        const body = {
            patient: id, phone, schedule, day, hour, hourId
        }
        const response = await axiosClient.post('/patient-scheduling', body, { headers });
        return response;
    } catch (error) {
        const { response } = error;
        return response;
    }
}
const deleteScheduleByPatient = async (scheduleId) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            // 'authtoken': token
        };
        const response = await axiosClient.delete(`/patient-scheduling/${scheduleId}`, { headers });
        return response;
    } catch (error) {
        const { response } = error;
        return response;
    }
}

const updateScheduleByPatient = async (id, newSchedule, newDay, newHour, newHourId, phone) => {

    try {
        const headers = {
            'Content-Type': 'application/json',
            // 'authtoken': token
        };
        const body = {
            newSchedule,
            newDay: String(newDay),
            newHour,
            newHourId,
            phone,
        }
        const response = await axiosClient.patch(`/patient-scheduling/${id}`, body, headers);
        return response;
    } catch (error) {
        const { response } = error;
        return response;
    }
}


export {
    getScheduleByPatient,
    createScheduleByPatient,
    deleteScheduleByPatient,
    updateScheduleByPatient,
    getCurrentScheduleByPatient,
}