import {
    INIT_EXAM_HISTORY,
    EXAM_HISTORY,
    ERROR_EXAM_HISTORY,
    LOGOUT
} from 'happ-redux/happ-types';

const initialState = {
    loading: false,
    examHistory: null,
    errorServer: false,
    errorMsg: null,
    lastRecords: null
}

export default function(state = initialState, action){
    switch(action.type){
        case INIT_EXAM_HISTORY:
            return {
                ...state,
                loading: true,
            }
        case EXAM_HISTORY:
            return {
                loading: false,
                examHistory: action.payload,
                errorServer: false,
                errorMsg: null,
                // lastRecords: action.payload.lastRecords
            }
        case ERROR_EXAM_HISTORY:
            return {
                loading: false,
                examHistory: null,
                errorServer: true,
                errorMsg: action.payload,
                lastRecords: null
            }        
        case LOGOUT:
            localStorage.clear();
            return {
                loading: false,
                examHistory: null,
                errorServer: false,
                errorMsg: null,
                lastRecords: null
            }  
        default:
            return state
    }
}