import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { CloseModalBlue } from 'happ-icons/icons'
import { SugarIcon } from 'happ-icons/icons'
import { IMCIcon } from 'happ-icons/icons';
import { HipertensionIcon } from 'happ-icons/icons';
import { DislipidemiaIcon } from 'happ-icons/icons';
import AccordionModal from './AccordionModal/AccordionModal';
import ButtonDownloadModalExam from 'happ-components/ButtonDownloadModalExam/ButtonDownloadModalExam';
import { ColesterolIndicator } from 'happ-icons/icons';
import { useSelector } from 'react-redux';
const DiagnosisDeliveryCompleteModal = (props) => {
    const { diagnosisContent } = props;
    const { isMetaExams, determinationsWithMeta } = useSelector(state => state.auth);
    const checkDeterminationMeta = code => {
      let check = null;
      if (determinationsWithMeta !== null) {
        check = determinationsWithMeta[code] || null;
      }
      return check;
    }

    console.log(diagnosisContent)
    return ( 
        <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        contentClassName="modal3"
        centered
        animation={false}
      >
        <div className={'modal-content-white'}>
          <Row>
            <Col>
              <div style={{ zIndex: 10, position: 'sticky' }}>
                <>
                  <div style={{ backgroundColor: 'white', position: 'relative', borderRadius: 10 }} className='py-2'>
  
                    <h6 className='text-center primary-color-text pb-2'>
                    {
                        isMetaExams && (diagnosisContent.code === 'diabetes' || diagnosisContent.code === 'pre-diabetes' ||
                        diagnosisContent.code === 'dislipidemia' ||
                        diagnosisContent.code === 'hipertension') ? (
                          <>
                            {diagnosisContent.status === 'Saludable' && checkDeterminationMeta(diagnosisContent.code) === true ? (
                              <>
                                Diagnóstico: <strong>{diagnosisContent.name} Compensada</strong>
                              </>
                            ) : (
                              <>
                              Diagnóstico <strong>{diagnosisContent.status}</strong> 
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            Diagnóstico <strong>{diagnosisContent.status}</strong>  
                          </>
                        )
                      }
                    </h6>
                    <div onClick={props.onHide} className='modal-close-btn primary-color-text'>
                      <CloseModalBlue
                      />
                    </div>
  
                    <div className='modal-info primary-bg-color'>
                      <Row className='pt-3'>
                        <Col className='d-flex justify-content-around' >
                            {diagnosisContent.code === 'diabetes' || diagnosisContent.code === 'pre-diabetes' ? (
                                <>
                                    <SugarIcon style={{height: '70px'}}/>
                                    <div className='width-info-modal align-self-end mt-3'>
                                        <p className='description-modal-exam' >
                                            La <strong>{diagnosisContent.name}</strong> es una <strong>enfermedad crónica</strong> provocada por <strong>niveles elevados</strong> de glucosa (azúcar) en la sangre 
                                        </p>
                                    </div>
                                </>
                            ) : null}
                            {diagnosisContent.code === 'dislipidemia' ? (
                                <>
                                    <DislipidemiaIcon style={{height: '70px'}} />
                                    <div className='width-info-modal align-self-end mt-3'>
                                        <p className='description-modal-exam' >
                                            La <strong>{diagnosisContent.name}</strong> es una <strong>enfermedad</strong> causada por altos niveles de grasas en la sangre
                                        </p>
                                    </div>
                                </>
                            ) : null}
                            {diagnosisContent.code === 'obesidad' ? (
                                <>
                                    <IMCIcon style={{height: '70px'}}/>
                                    <div className='width-info-modal align-self-end mt-3'>
                                        <p className='description-modal-exam'>
                                            La <strong>{diagnosisContent.name}</strong>, es una <strong>enfermedad inflamatoría sistémica</strong>, que afecta a los <strong>diferentes órganos del cuerpo</strong>.
                                        </p>
                                    </div>    
                                </>
                            ) : null}
                            {diagnosisContent.code === 'hipertension' ? (
                                <>
                                    <HipertensionIcon style={{height: '70px'}}/>
                                    <div className='width-info-modal align-self-end mt-3'>
                                        <p className='description-modal-exam'>
                                            La <strong>{diagnosisContent.name}</strong> es una enfermedad, donde existe un exceso de <strong>presión de la sangre sobre las paredes de las arterias</strong>.
                                        </p>
                                    </div>
                                </>
                            ) : null}
                        </Col>
                      </Row>
                      <div className={`status-bar-${diagnosisContent.color}`} />
                    </div>
                  </div>
                </>
  
              </div>
            </Col>
          </Row>
          <Modal.Body style={{ padding: 0 }}   >
            <Row>
              <Col>
                <div className='text-center pt-3 '>
                  <Row className=' modal-scroll-mobile' >
                    <div className='accordion-modal'>
                      <Col className='d-flex justify-content-between' >
                        <h6 className='fw-bold align-self-end'> Mis exámenes</h6>
                        <ButtonDownloadModalExam />
                      </Col>
                    </div>
                    <Col>
                      <AccordionModal
                        results={diagnosisContent.results}
                        color={diagnosisContent.color}
                        diagnosisContent={diagnosisContent}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Modal.Body>
        </div>
  
      </Modal>
     );
}
 
export default DiagnosisDeliveryCompleteModal;