import styled from 'styled-components';

export const ColMenu = styled.div.attrs(props => ({}))`
    width: 33%;
    text-align: center;
    padding: 5px 7px;
    & a{
        text-decoration: none;
        color: #666666;
    }
    & p{
        font-size: 10px;
        margin: 3px 0 0 0;
    }
`;