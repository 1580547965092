import React from 'react';
import { Row, Col, Container, Form, Button, InputGroup, Spinner } from 'react-bootstrap';
import { DiagnosticModalIcon } from 'happ-icons/icons';
import moment from 'moment';
import 'moment/min/locales';

//components
import { HappContainer } from 'happ-components/HappStyledComponents/HappContainer/HappContainer';
import useMedicalSchedule from 'happ-custom-hooks/useMedicalSchedule/useMedicalSchedule';
import SuccessSchedule from './SuccessSchedule';

const initState = {
    schedule: '',
    phone: '',
    origin: '',
    laboratory: '',
    day: '',
    hour: '',
    hourId: '',
    patient: '',
}

const MedicalSchedule = () => {
    moment.locale('es');
    const {
        state,
        origin,
        schedule,
        currentMedicalScheduleStatus,
        currentMedicalSchedule,
        currentMedicalScheduleStatusLoader,
        option,
        days,
        hours,
        activeForm,
        loadingSchedule,
        medicalStagePrint,
        showSpinner,
        labFilter,
        loading,
        errorPhone,
        errorLab,
        errorDays,
        errorHours,
        arrayLabs,
        onlyLabs,
        onChengeOption,
        onChangeLabOption,
        handleChangePhone,
        handlerScheduling,
        handleChangeHour,
        handleChangeDay,
        activeScheduleForm,
        handleDeleteSchedule,
    } = useMedicalSchedule(initState);
    const {
        phone,
        laboratory,
        day,
        hour,
    } = state;
    console.log(hour);
    return ( 
        <Row className="mt-content">
            <HappContainer>
                {currentMedicalScheduleStatusLoader ? (
                    <Container className='text-center'>
                        <Row className='py-3 mt-2 ms-2'>
                            <Col>
                                <div className='text-center mt-5 mb-2'>
                                    <Spinner 
                                        animation="border"
                                        className='text-center'
                                    />
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <p>
                                        Cargando...
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                ) : (
                    <>
                        {currentMedicalScheduleStatus ? (
                            <Container className='text-center'>
                                <SuccessSchedule 
                                    activeScheduleForm={activeScheduleForm}
                                    handleDeleteSchedule={handleDeleteSchedule}
                                    currentMedicalSchedule={currentMedicalSchedule}
                                    medicalStagePrint={medicalStagePrint}
                                />
                            </Container>
                        ) : (
                            <>
                                {(schedule === true || activeForm === true) ? (
                                    <>
                                        <Container className='text-center'>
                                            <Row className='py-3 mt-2 ms-2'>
                                                <Col>
                                                    <div >
                                                        <DiagnosticModalIcon fill="#044064" />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <h3 className='primary-color-text' >Agenda tus exámenes</h3>
                                                    {/* <h2 className='primary-color-text fw-bold ' style={{ lineHeight: '16px' }}>Chequeo Diagnóstico</h2> */}
                                                </Col>
                                            </Row>
                                            {/* {errorMsg !== null && errorMsg ? (
                                                <Row className='pt-2'>
                                                    <Col className='d-flex justify-content-center'>
                                                        <div className='text-center py-2 text-white danger-bg-color rounded-1 indicators-content canvas-message'>
                                                            {errorMsg}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            ) : null} */}
                                            <Row className='d-flex justify-content-center mt-4'>
                                                <div className='selectors' >
                                                    <p style={{ fontSize: '14px', lineHeight: '1px' }}>Ingresa tu número móvil</p>
                                                    <InputGroup className="mb-4">
        
                                                        <InputGroup.Text 
                                                            id="basic-addon1"
                                                        >
                                                            +56
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            aria-label="Username"
                                                            onChange={handleChangePhone}
                                                            aria-describedby="basic-addon1"
                                                            className={errorPhone ? 'border-require' : ''}
                                                            value={phone ? phone : ''}
                                                        />
                                                    </InputGroup>
                                                    {origin && origin === 'both' ? (
                                                        <>
                                                            <Col className='mt-3 mb-4'>
                                                                <p style={{ fontSize: '14px', lineHeight: '1px' }}>Dirígete a</p>
                                                                <div>
                                                                    <Button 
                                                                        className='btn-options'
                                                                        variant={option === 'laboratory' ? 'primary' : 'light'}
                                                                        onClick={onChengeOption('laboratory')}
                                                                        style={{ border: '1px solid #0b5ed7' }}
                                                                    >
                                                                        A un laboratorio</Button>
                                                                    <Button
                                                                        className='btn-options'
                                                                        variant={option === 'branch' ? 'primary' : 'light'}
                                                                        onClick={onChengeOption('branch')}
                                                                        style={{ border: '1px solid #0b5ed7' }}
                                                                    >
                                                                        A tu lugar de trabajo
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            {showSpinner !== null ? (
                                                                <>
                                                                <div className='text-center mt-5 mb-5'>
                                                                    <Spinner 
                                                                        animation="border"
                                                                        className='text-center'
                                                                    />
                                                                </div>
                                                                </>
                                                            ) : null}
                                                        </>
                                                    ) : null}
                                                    {option !== '' ? (
                                                        <>
                                                        {(option && (option === 'laboratory')) ? (
                                                            <>
                                                                    {onlyLabs.length > 1 ? (
                                                                        <>
                                                                            <Col>
                                                                                <p style={{ fontSize: '14px', lineHeight: '1px' }}>Selecciona un Laboratorio</p>
                                                                            </Col>
                                                                            <Col className="my-1  mb-3">
                                                                                <Form.Select
                                                                                    name="laboratory"
                                                                                    className={`text-end ${errorLab ? 'border-require' : ''}`}
                                                                                    defaultValue={laboratory}
                                                                                    onChange={onChangeLabOption}
                                                                                >
                                                                                    <option value=""> Selecciona un Laboratorio </option>
                                                                                    {onlyLabs?.map(item => {
                                                                                        return (
                                                                                            <option key={item.idOrigin} value={item.idOrigin}>{item.name.name}</option>
                                                                                        )
                                                                                    })}
                                                                                </Form.Select>
                                                                            </Col>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <h4 className='mt-4 mb-5'>Ir a: { onlyLabs[0].name.name }</h4>
                                                                        </>
                                                                    )}
                                                                {labFilter ? (
                                                                    <>
                                                                        <Col>
                                                                            <p style={{ fontSize: '14px', lineHeight: '1px' }}>Selecciona la fecha y hora de atención</p>
                                                                        </Col>
                                                                        <Col className="my-1">
                                                                            <Form.Select
                                                                                name="date"
                                                                                className={`text-end ${errorDays ? 'border-require' : ''}`}
                                                                                defaultValue={day}
                                                                                onChange={handleChangeDay}
                                                                            >
                                                                                <option value=""> Seleciona un día </option>
                                                                                {days?.map(item => {
                                                                                    return (
                                                                                        <option key={item._id} value={JSON.stringify(item)}> {moment.utc(item.day).format('dddd, D MMMM YYYY')}</option>
                                                                                    )
                                                                                })}
                                                                            </Form.Select>
                                                                        </Col>
                                                                        {hours.length > 0 ? (
                        
                                                                            <Col className="my-1">
                        
                                                                                <Form.Select
                                                                                    name="hr"
                                                                                    className={`text-end ${errorHours ? 'border-require' : ''}`}
                                                                                    defaultValue={hour}
                                                                                    onChange={handleChangeHour}
                                                                                >
                                                                                    <option value="">Seleciona una hora</option>
                                                                                    {hours?.map((hour) => (
                                                                                        <option key={hour.hourId} disabled={hour.status === true ? false : true} value={JSON.stringify(hour)}>
                                                                                            {hour.hour}
                                                                                        </option>
                                                                                    ))}
                                                                                </Form.Select>
                                                                            </Col>
                                                                        ) : null}
                                                                    </>
                                                                ) : null}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <Col>
                                                                    <p style={{ fontSize: '14px', lineHeight: '1px' }}>Selecciona la fecha y hora de atención</p>
                                                                </Col>
                                                                <Col className="my-1">
                                                                    <Form.Select
                                                                        name="date"
                                                                        className={`text-end ${errorDays ? 'border-require' : ''}`}
                                                                        defaultValue={day ? day : ''}
                                                                        onChange={handleChangeDay}
                                                                    >
                                                                        <option value=""> Selecciona un día </option>
                                                                        {days?.map(item => {
                                                                            return (
                                                                                <option key={item._id} value={JSON.stringify(item)}> {moment.utc(item.day).format('dddd, D MMMM YYYY')}</option>
                                                                            )
                                                                        })}
                                                                    </Form.Select>
                                                                </Col>
                                                                {hours.length > 0 ? (
                
                                                                    <Col className="my-1">
                
                                                                        <Form.Select
                                                                            name="hr"
                                                                            className={`text-end ${errorHours ? 'border-require' : ''}`}
                                                                            defaultValue={hour}
                                                                            onChange={handleChangeHour}
                                                                        >
                                                                            <option value="">Seleciona una hora</option>
                                                                            {hours?.map((hour) => (
                                                                                <option key={hour.hourId} disabled={hour.status === true ? false : true} value={JSON.stringify(hour)}>
                                                                                    {hour.hour}
                                                                                </option>
                                                                            ))}
                                                                        </Form.Select>
                                                                    </Col>
                                                                ) : null}
                                                            </>
                                                        )}
                                                            <Button 
                                                                disabled={hour === '' ? true : false} 
                                                                onClick={handlerScheduling} 
                                                                className={hour === '' ? 'shadow btn-gray-schedule' : 'shadow btn-blue-schedule'}   
                                                            >
                                                                <div className='d-flex justify-content-center mt-1'>
                                                                    <p>
                                                                        Agendar hora
                                                                    </p>
                                                                </div>
                                                            </Button>
                                                        </>
                                                    ) : null}
                                                </div>
                                            </Row>
                                        </Container>
                                    </>
                                ) : (
                                    <Container className='text-center'>
                                        <Row className='py-3 mt-2 ms-2'>
                                            <Col>
                                                <div className='text-center mt-5 mb-2'>
                                                    <Spinner 
                                                        animation="border"
                                                        className='text-center'
                                                    />
                                                </div>
                                                <div className='d-flex justify-content-center'>
                                                    <p>
                                                        Cargando...
                                                    </p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>        
                                )}
                                {!schedule && !loading ? (
                                    <Container className='text-center'>
                                        <Row className='py-3 mt-2 ms-2'>
                                            <Col>
                                                <div className='d-flex justify-content-center'>
                                                    <h3>
                                                        No hay agendas disponibles
                                                    </h3>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                ) : null}
                            </>
                        )}
                    </>
                )}
            </HappContainer>
        </Row>
    );
}
 
export default MedicalSchedule;