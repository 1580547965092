import React from 'react'
import { Form, Col, Button, Row } from 'react-bootstrap';

const SurveyQuestionFive = ({ questionFive, next, preview, handlerChange, surveyError }) => {
    return ( 
        <Row className='justify-content-md-center'>
            <Col md={9} sm={12} lg={9} className="col-question">
                <h6>5.- Tener poco apetito o comer en exceso.</h6>
                <Form.Check
                    className='select-question'
                    label="Nunca"
                    checked={String(questionFive) === "Nunca"}
                    onChange={handlerChange}
                    value={'Nunca'}
                    name="questionFive"
                    type="radio"
                />
                <Form.Check
                    className='select-question'
                    label="Varios días"
                    checked={String(questionFive) === "Varios días"}
                    onChange={handlerChange}
                    value={'Varios días'}
                    name="questionFive"
                    type="radio"
                />
                <Form.Check
                    className='select-question'
                    label="Mas de la mitad de los días"
                    checked={String(questionFive) === "Mas de la mitad de los días"}
                    onChange={handlerChange}
                    value={'Mas de la mitad de los días'}
                    name="questionFive"
                    type="radio"
                />
                <Form.Check
                    className='select-question'
                    label="Casi todos los días"
                    checked={String(questionFive) === "Casi todos los días"}
                    onChange={handlerChange}
                    value={'Casi todos los días'}
                    name="questionFive"
                    type="radio"
                />
            </Col>
            <Row className='row-error'>
                {surveyError ? (
                    <Col md={12} sm={12} lg={5} className='content-error-survey'>
                            <p>Debe seleccionar una opción para continuar</p>
                    </Col>
                ) : null}
            </Row>
            <Col className='d-flex col-btns' md={5} sm={12} lg={5}>
                <Button 
                    className='shadow btn-grey-survey'
                    onClick={preview}
                >
                    <div className='d-flex justify-content-center mt-1'>
                        <p>
                            Atrás
                        </p>
                    </div>
                </Button>
                <Button 
                    className='shadow btn-blue-survey'
                    onClick={next}
                >
                    <div className='d-flex justify-content-center mt-1'>
                        <p>
                            Siguente
                        </p>
                    </div>
                </Button>
            </Col>
        </Row>
     );
}
 
export default SurveyQuestionFive;