import { useState } from "react";

export const useModal2 = (initialState = false) => {
    const [show, setShow] = useState(initialState);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);


    return [
        show,
        handleShow,
        handleClose,
    ];
}
