import styled from 'styled-components';

export const SubNav = styled.li.attrs(props => ({
    className: props.bgClass
}))`
    color: ${props => props.textColor ? props.textColor : "#fff"};
    text-align: center;
    border-radius: 8px 8px 0px 0px;
    padding: 10px 20px;
    margin-right: 15px;
    cursor: pointer;
    font-weight: 400;
    font-size: 18px;
    @media (max-width: 700px) {
        width: 100%;
        font-size:12px;
        padding: 10px;
        border-radius: ${props => props.brRadius ? props.brRadius : "8px 8px 0px 0px"};
        align-items: center;
        justify-content: center;
    }
`;