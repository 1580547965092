import axiosClient from 'happ-config/axios';


const getMedicalSchedule = async token => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'x-auth-token': token
        };
        const response = await axiosClient.get('/patients/getMedicalAgenda', {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const getMedicalSchedulesByBranchPatient = async (branch, medicalStage, holding, token) => {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const response = await axiosClient.get(`/patient-scheduling-medical-events/get-schedules-by-branch-patient/${branch}/${medicalStage}/${holding}` , {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }    
}

const getCurrentMedicalSchedulesByBranchPatient = async (patient, branch, medicalStage, holding, token) => {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const response = await axiosClient.get(`/patient-scheduling-medical-events/get-current-schedule-by-patient/${patient}/${branch}/${medicalStage}/${holding}` , {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }    
}

const createMedicalSchedule = async (body) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await axiosClient.post('/patient-scheduling-medical-events', body, { headers });
        return response;
    } catch (error) {
        const { response } = error;
        return response;
    }
}

const updateMedicalSchedule = async (id, body) => {

    try {
        const headers = {
            'Content-Type': 'application/json',
            // 'authtoken': token
        };
        const response = await axiosClient.patch(`/patient-scheduling-medical-events/${id}`, body, headers);
        return response;
    } catch (error) {
        const { response } = error;
        return response;
    }
}

const deleteMedicalSchedule = async (scheduleId) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            // 'authtoken': token
        };
        const response = await axiosClient.delete(`/patient-scheduling-medical-events/${scheduleId}`, { headers });
        return response;
    } catch (error) {
        const { response } = error;
        return response;
    }
}

export {
    getMedicalSchedule,
    getMedicalSchedulesByBranchPatient,
    createMedicalSchedule,
    getCurrentMedicalSchedulesByBranchPatient,
    updateMedicalSchedule,
    deleteMedicalSchedule
}