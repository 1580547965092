import { CloseModal } from 'happ-icons/icons'
import { Loading } from 'happ-icons/icons'
import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'

const ModalColesterol1 = (props) => {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered

        >
            {/* este es para el colesterol plomo */}
            <div className='modal-content-gris'>
                <Row className=''>
                    <Col className='mt-2'>
                        {/* <div onClick={onHide} className='modal-close-btn primary-color-text'> */}
                        <div onClick={props.onHide} className='modal-close-btn primary-color-text'>
                            <CloseModal
                            />
                        </div>
                        <div className='text-center text-white'>
                            {/* <DiagnosticModalIcon fill="#044064" className="my-2 ms-3" /> */}
                            <h6>Chequeo <strong>Colesterol</strong></h6>
                        </div>
                    </Col>
                </Row>

                <Modal.Body style={{ padding: 0 }}>
                    <div className='text-center text-white'>
                        <Row className='my-2'>
                            <Col className='d-flex justify-content-around align-items-center pt-1'>
                                <Loading
                                    fill='white'
                                    responsive='scale-mobile'
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col className='my-2 mx-2'>
                                <p className='p-modal'>Estamos a la espera de los resultados de tus exámenes. Te avisaremos tan pronto estén disponibles</p>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    )
}

export default ModalColesterol1