import axiosClient from 'happ-config/axios';

const getMedicalGoals = async token => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'x-auth-token': token
        };
        const response = await axiosClient.get('/medicalgoals/getMedicalGoals', {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const addMedicalGoalRecord = async (token, goal, newParam) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'x-auth-token': token
        };
        const body = {
            goal,
            newParam
        }
        const response = await axiosClient.post('/medicalgoals/addMedicalGoalParam', body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

export {
    getMedicalGoals,
    addMedicalGoalRecord
}