import { PressureIndicator } from 'happ-icons/icons'
import { TwoPressionBar } from 'happ-icons/icons'
import { CloseModal } from 'happ-icons/icons'
import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'

const ModalPresion1 = (props) => {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered

        >
            {/* este es para el colesterol plomo */}
            <div className='modal-content-blue'>
                <Row className=''>
                    <Col className='mt-2'>
                        {/* <div onClick={onHide} className='modal-close-btn primary-color-text'> */}
                        <div onClick={props.onHide} className='modal-close-btn primary-color-text'>
                            <CloseModal
                            />
                        </div>
                        <div className='text-center text-white'>
                            {/* <DiagnosticModalIcon fill="#044064" className="my-2 ms-3" /> */}
                            <h6>Tu Presión <strong>Arterial</strong></h6>
                        </div>
                    </Col>
                </Row>

                <Modal.Body style={{ padding: 0 }}>
                    <div className='text-center text-white'>
                        <Row>
                            <Col className='d-flex justify-content-around align-items-center'>
                                <PressureIndicator
                                    fill='white'
                                    responsive='scale-mobile'
                                />
                                <div className='modal-content-tape-result' >
                                    <p className='modal-tape-result'>145/85  <div className='modal-unit-of-measurement'> mm/Hg</div></p>
                                </div>
                                <TwoPressionBar
                                    fill="white"
                                    responsive="scale-mobile"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className='my-2 mx-2'>
                                <p className='p-modal'><strong>¡Atención!</strong> Detectamos que tus niveles de <strong>presión arterial sobrepasan los rangos normales </strong> 
                                y deben ser analizados más en profundidad en la <strong>siguiente etapa</strong></p>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    )
}

export default ModalPresion1