import {
    INIT_SEND_CANCER_SURVEY,
    SEND_CANCER_SURVEY,
    ERROR_SEND_CANCER_SURVEY,
    CLEAN_CANCER_SURVEY,
    INIT_GET_LAST_CANCER_PRESCRIPTION,
    GET_LAST_CANCER_PRESCRIPTION,
    ERROR_GET_LAST_CANCER_PRESCRIPTION,
    INIT_GET_CANCER_SURVEY_CALCULATION,
    GET_CANCER_SURVEY_CALCULATION,
} from 'happ-redux/happ-types';

import {
    sendCancerSurvey,
    lastCancerPrescription,
    getCancerCalculation
} from 'happ-redux/happ-services/cancer-journey/cancer-journey';

export function sendCancerSurveyAction(body) {
    return async dispatch => {
        dispatch(initSendCancerSurvey())
        try {
            const response = await sendCancerSurvey(body);
            if (response.data.status === 201) {
                dispatch(sendCancerSurveyData(response.data))
            } else {
                dispatch(errorSendCancerSurveyData(true))
            }
        } catch (error) {
            dispatch(errorSendCancerSurveyData(true))
        }
    } 
}

export function getLastCancerPrescriptionAction(type, patient){
    return async dispatch => {
        dispatch(initgetLastPrescription());
        try {
            const response = await getCancerCalculation(type, patient);
            console.log(response)
            if (response.data.status === 200) {
                dispatch(lastPrescription(response.data));
            } else {
                dispatch(errorLastPrescription(true));
            }
        } catch (error) {
            dispatch(errorLastPrescription(true));
        }
    }
};

const initSendCancerSurvey = () => ({
    type: INIT_SEND_CANCER_SURVEY,
});
const sendCancerSurveyData = response => ({
    type: SEND_CANCER_SURVEY,
    payload: response
});
const errorSendCancerSurveyData = () => ({
    type: ERROR_SEND_CANCER_SURVEY,
});
const initgetLastPrescription = () => ({
    type: INIT_GET_LAST_CANCER_PRESCRIPTION
})
const lastPrescription = response => ({
    type: GET_LAST_CANCER_PRESCRIPTION,
    payload: response,
})
const errorLastPrescription = error => ({
    type: ERROR_GET_LAST_CANCER_PRESCRIPTION,
    payload: error,
})