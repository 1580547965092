import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    createMedicalScheduleAction,
    getCurrentMedicalSchedulesByBranchPatientAction,
    getMedicalSchedulesByBranchPatientAction,
    activeScheduleFormAction,
    updateMedicalScheduleAction,
    deleteMedicalScheduleAction
} from 'happ-redux/happ-actions/medicalSchedule/medicalScheduleActions';
import moment from 'moment';
const useMedicalSchedule = (initialState = null) => {
    const [state, setState] = useState(initialState);
    const [loadingSchedule, setLoadingSchedule] = useState(false);
    const [labFilter, setLabFilter] = useState(false);
    const [showSpinner, setShowSpinner] = useState(null);
    const [errorPhone, setErrorPhone] = useState(false);
    const [errorLab, setErrorLab] = useState(false);
    const [errorDays, setErrorDays] = useState(false);
    const [errorHours, setErrorHours] = useState(false);
    const [onlyLabs, setOnlyLab] = useState([]);
    const [option, setOption] = useState('');
    const [days, setDays] = useState([]);
    const [hours, setHours] = useState([]);
    const [updateSchedule, setUpdateSchedule] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { profile, medicalStage, medicalStagePrint } = useSelector(state => state.auth);

    useEffect(() => {
        if (profile !== null) {
            const { _id, branchOfficeId, company } = profile;
            dispatch(
                getCurrentMedicalSchedulesByBranchPatientAction(
                    _id, 
                    branchOfficeId._id, 
                    medicalStage?.medicalStage?._id, 
                    company.holdingId,
                    navigate
                )
            );
        }
    }, [profile]);

    useEffect(() => {
        if (profile !== null) {
            const phoneSplitLength = profile?.phone.split(' ').length;
            const phone = phoneSplitLength > 1 ?
                    profile?.phone.split(' ')[1] === '987654321' ? '' : profile?.phone.split(' ')[1] : 
                    profile?.phone === '987654321' ? '' : profile?.phone;   
            if (profile?.phone === '987654321') {
                setState({
                    ...state,
                    phone,
                });
            } else {
                setState({
                    ...state,
                    phone,
                });
            }
        }
    }, [profile])
    

    const { 
        origin,
        arrayDays,
        arrayHours,
        arrayLabs,
        loading,
        updateStatus,
        schedule,
        activeForm,
        currentMedicalScheduleStatus,
        currentMedicalScheduleStatusLoader,
        currentMedicalSchedule,
        currentMedicalScheduleId
    } = useSelector(state => state.medicalSchedule);

    useEffect(() => {
        if (profile !== null) {
            if (profile?.phone === '987654321') {
                setState({
                    ...state,
                    phone: '',
                });
            } else {
                setState({
                    ...state,
                    phone: profile?.phone,
                });
            }
        }
    }, [activeForm])

    
    useEffect(() => {
        if (updateStatus === true) {
            console.log('entre a actualizar ');
            setState({
                ...state,
                hour: '',
                day: '',
                hourId: '',
            })
            if (origin && (origin === 'branch' || origin === 'holding')) {
                setOption(origin);
                const selectDays = arrayDays?.filter(day => day.origin === origin);
                setDays(selectDays);
            }
            if (origin && origin === 'laboratory') {
                setOption('laboratory');
                const onlyDifferentsLabs = arrayLabs.reduce(
                    (acc, x) =>
                        acc.concat(
                        acc.find((y) => String(y.idOrigin) === String(x.idOrigin))
                            ? []
                            : [x],
                        ),
                    [],
                );
                setOnlyLab(onlyDifferentsLabs);
                if (onlyDifferentsLabs.length === 1) {
                    setLabFilter(false);
                    const onlyLabId = onlyDifferentsLabs[0].idOrigin;
                    const selectDays = arrayDays?.filter(day => day.idOrigin === onlyLabId);
                    setState({
                        ...state,
                        laboratory: onlyLabId,
                    });
                    setDays(selectDays);
                    setLabFilter(true);
                }
            }
        }
    }, [updateStatus])
    
    useEffect(() => {
        if (schedule === true || activeForm === true) {
            if (origin && origin === 'laboratory') {
                setOption('laboratory');
                const onlyDifferentsLabs = arrayLabs.reduce(
                    (acc, x) =>
                        acc.concat(
                        acc.find((y) => String(y.idOrigin) === String(x.idOrigin))
                            ? []
                            : [x],
                        ),
                    [],
                );
                setOnlyLab(onlyDifferentsLabs);
                if (onlyDifferentsLabs.length === 1) {
                    setLabFilter(false);
                    const onlyLabId = onlyDifferentsLabs[0].idOrigin;
                    const selectDays = arrayDays?.filter(day => day.idOrigin === onlyLabId);
                    setState({
                        ...state,
                        laboratory: onlyLabId,
                    });
                    setDays(selectDays);
                    setLabFilter(true);
                }
            }
            if (origin && (origin === 'branch' || origin === 'holding')) {
                setOption(origin);
                const selectDays = arrayDays?.filter(day => day.origin === origin);
                setDays(selectDays);
            }
        }
    }, [schedule, activeForm, updateSchedule]);
    
    const handleChangePhone = e => {
        const newValue = e.target.value.replace(/[^0-9]/g, "");
        if (newValue.length <= 9) {
            setState({
                ...state,
                phone: newValue,
            });
        }
    }

    const activeScheduleForm = () => {
        const { branchOfficeId, company } = profile;
        dispatch(activeScheduleFormAction(
            branchOfficeId._id, 
            medicalStage?.medicalStage?._id, 
            company.holdingId,
            navigate
        ));
    }

    const onChengeOption = (value) => e => {
        e.preventDefault();
        setDays([]);
        setHours([]);
        setLabFilter(false);
        setOption('')
        setShowSpinner(true);
        setTimeout(() => {
            setShowSpinner(null)
            setOption(value);
        }, 400);
        if (value === 'branch') {
            const selectDays = arrayDays?.filter(day => day.origin === 'branch');
            setDays(selectDays);
        }
    }

    const validateInputsform = () => {
        const { phone, laboratory, day, hour, hourId } = state;
        let errorCounter = 0;
        if (phone === '' || phone.length < 9) {
            setErrorPhone(true);
            setTimeout(() => {
                setErrorPhone(false)
            }, 450);
            errorCounter ++;
        }
        if ((origin === 'laboratory' || origin === 'both')) {
            if (laboratory === '') {
                setErrorLab(true);
                setTimeout(() => {
                    setErrorLab(false)
                }, 450);
                errorCounter ++;
            }
        }
        if (day === '') {
            setErrorDays(true);
            setTimeout(() => {
                setErrorDays(false)
            }, 450);
            errorCounter ++;
        }
        if (hour === '' || hourId === '') {
            setErrorHours(true);
            setTimeout(() => {
                setErrorHours(false)
            }, 450);
            errorCounter ++;
        }
        return errorCounter;
    }

    const handleDeleteSchedule = e => {
        e.preventDefault();
        dispatch(deleteMedicalScheduleAction(currentMedicalSchedule._id, navigate))
    }

    const handlerScheduling = e => {
        e.preventDefault();
        const counter = validateInputsform();
        if (counter > 0) {
            return;
        }
        if (currentMedicalScheduleId !== null) {
            const body = {
                newSchedule: state.schedule,
                newDay: state.day,
                newHour: state.hour,
                origin: state.origin,
                newHourId: state.hourId,
            }
            dispatch(updateMedicalScheduleAction(currentMedicalScheduleId, body));
        } else {
            const body = {
                patient: profile?._id,
                branch: profile?.branchOfficeId._id,
                phone: `56 ${state.phone}`,
                origin: state.origin,
                schedule: state.schedule,
                day: state.day,
                hour: state.hour,
                hourId: state.hourId,
            }
            dispatch(createMedicalScheduleAction(body));
        }
        setDays([]);
        setHours([]);
        setLabFilter(false);
        setOption('');
        setLoadingSchedule(true);
        setTimeout(() => {
            setLoadingSchedule(false);
        }, 500);
    }

    const handleChangeHour = (event) => {
        if (event.target.value !== '') {
            const selectedHour = event.target.value
            if (selectedHour) {
                const parsedHr = JSON.parse(selectedHour);
                setState({
                    ...state,
                    hour: parsedHr.hour,
                    hourId: parsedHr.hourId,
                    origin: option,
                });
            }
        } else {
            setState({
                ...state,
                hour: '',
                hourId: '',
                origin: '',
            });    
        }
    }

    const handleChangeDay = (e) => {
        if (e.target.value !== '') {
            const parsedValue = JSON.parse(e.target.value);
            setState({
                ...state,
                day: moment.utc(parsedValue.day).format('YYYY-MM-DD'),
                schedule: parsedValue._id,
                patient: profile?._id,
            });
            const selectedHours = arrayHours?.filter(hour => hour._id === parsedValue._id);
            setHours(selectedHours[0].hours);
        } else {
            setHours([]);
        }
    }

    const onChangeLabOption = e => {
        setState({
            ...state,
            laboratory: e.target.value,
        });
        if (e.target.value !== '') {
            const selectDays = arrayDays?.filter(day => day.idOrigin === e.target.value);
            setDays(selectDays);
            setLabFilter(true);
        } else {
            setLabFilter(false);
        }
    }

    return {
        state,
        schedule,
        origin,
        days,
        option,
        currentMedicalScheduleStatus,
        currentMedicalScheduleStatusLoader,
        currentMedicalSchedule,
        showSpinner,
        labFilter,
        arrayDays,
        arrayHours,
        arrayLabs,
        hours,
        errorPhone,
        errorLab,
        errorDays,
        errorHours,
        loading,
        loadingSchedule,
        activeForm,
        medicalStagePrint,
        onlyLabs,
        onChengeOption,
        onChangeLabOption,
        handleChangePhone,
        handleChangeHour,
        handleChangeDay,
        handlerScheduling,
        activeScheduleForm,
        handleDeleteSchedule,
    }
}
 
export default useMedicalSchedule;