import styled from 'styled-components';

export const HappContentIndicator = styled.div.attrs(props =>


({
    // className: props.border
    // ESTE VA ABAJITO... background-color: ${props => props.bgColor ? props.bgColor : '#04477A'};

}))`
    /* width: ${props => props.wid ? props.wid : '50%'}; */
    color: #fff;
    /* float: left; */
    border-radius: 8px;
    padding: 0px 0px;
    margin: 0 5px;
    @media(max-width: 700px) {
        padding: 0px;
    }
`;

export const SubContentIndicator = styled(HappContentIndicator)`
    background-color: #F6F6F6;
    color: #000000;
    font-weight: 400;
    font-size: 16px;
    border-right: none;
    border-radius: 0px 0px 8px 8px;
    @media(max-width: 700px) {
        padding: 6px;*90
    }
`;