import { OverweightIcon } from 'happ-icons/icons'
import { GreenWeightBar } from 'happ-icons/icons'
import { CloseModal } from 'happ-icons/icons'
import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'

const ModalPeso1 = (props) => {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered

        >
            {/* este es para el colesterol plomo */}
            <div className='modal-content-blue'>
                <Row className=''>
                    <Col className='mt-2'>
                        {/* <div onClick={onHide} className='modal-close-btn primary-color-text'> */}
                        <div onClick={props.onHide} className='modal-close-btn primary-color-text'>
                            <CloseModal
                            />
                        </div>
                        <div className='text-center text-white'>
                            {/* <DiagnosticModalIcon fill="#044064" className="my-2 ms-3" /> */}
                            <h6>Tu <strong>IMC</strong></h6>
                        </div>
                    </Col>
                </Row>

                <Modal.Body style={{ padding: 0 }}>
                    <div className='text-center text-white'>
                        <Row className='my-2'>
                            <Col className='d-flex justify-content-around align-items-center' >
                                <OverweightIcon
                                    fill='white'
                                    responsive='scale-mobile'
                                />
                                <div className='modal-content-tape-result'>
                                    <div className='modal-tape-result'> 24.8</div>
                                </div>
                                <GreenWeightBar
                                    fill="white"
                                    responsive="scale-mobile"
                                />
                            </Col>
                        </Row>
                        <Row className='my-2'>
                            <Col className='d-flex justify-content-around fw-bold'>
                                <div>
                                    Peso: 71kg
                                </div>
                                <div>
                                    Altura: 1.70m
                                </div>
                                <div>
                                    Cintura: 77cm
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='my-2 mx-2'>
                                <p className='p-modal '> <strong>¡Felicitaciones!</strong> Tienes un IMC bueno. ¡Sigue así! Mantener un <strong>peso saludable</strong> te ayudará a prevenir <strong>muchas enfermedades</strong>, <strong>incluso cardiacas</strong>.</p>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    )
}

export default ModalPeso1