import TableModal from 'happ-components/TableModal/TableModal'
import React from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'

const AccordionModalExamPresion = () => {
    return (
        <Accordion defaultActiveKey="0" className="accordion-modal my-3">
            <Accordion.Item eventKey="0" className='mt-2'>
                <Accordion.Header>
                    <Row>
                        <Col>
                            <h6 className='fw-bold'>Presión Arterial </h6>
                            <div className='accordion-description'>Los resultados de tu <strong>Presión Arterial</strong> son compatibles con el diagnóstico de <strong>Hipertensión</strong></div>
                        </Col>
                    </Row>
                </Accordion.Header>

                <Accordion.Body>
                    <Row>
                        <TableModal title="Presión Arterial" />
                    </Row>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1" className='mt-2'>
                <Accordion.Header>
                    <Row>
                        <Col>
                            <h6 className='fw-bold'>Orina Completa </h6>
                            <div className='accordion-description'>Hemos solicitado este <strong>exámen Complementario</strong> para descartar otras enfermedades relacionadas</div>
                        </Col>
                    </Row>
                </Accordion.Header>

                <Accordion.Body>
                    <Row>
                        <TableModal title="Orina Completa" />
                    </Row>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2" className='mt-2'>
                <Accordion.Header>
                    <Row>
                        <Col>
                            <h6 className='fw-bold'>Electrolitos Plasmáticos </h6>
                            <div className='accordion-description'>Hemos solicitado este <strong>exámen Complementario</strong> para descartar otras enfermedades relacionadas</div>
                        </Col>
                    </Row>
                </Accordion.Header>

                <Accordion.Body>
                    <Row>
                        <TableModal title="Electrolitos Plasmáticos" />
                    </Row>
                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3" className='mt-2'>
                <Accordion.Header>
                    <Row>
                        <Col>
                            <h6 className='fw-bold'>Función Renal </h6>
                            <div className='accordion-description'>Hemos solicitado este <strong>exámen Complementario</strong> para descartar otras enfermedades relacionadas</div>
                        </Col>
                    </Row>
                </Accordion.Header>

                <Accordion.Body>
                    <Row>
                        <TableModal title="Función Renal" />
                    </Row>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export default AccordionModalExamPresion