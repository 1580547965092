import styled from 'styled-components';

export const HappContainer = styled.div`
    background: #fff;
    width: 75%;
    padding: 0;
    margin: 0 auto;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 0px 8px 8px 8px;
    position: relative;
    margin-bottom: 30px;
    @media (max-width: 700px) {
        width: 100%;
        box-shadow: none;
        margin-bottom: 4rem;
    }
`;