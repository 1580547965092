import { useState } from 'react';
import WelcomeDeliveryModal from 'happ-components/Modal/ModalDelivery/WelcomeDeliveryModal';
import ModalSchedulePatient from 'happ-components/Modal/ModalDelivery/ModalSchedulePatient';
import DiagnosisDeliveryModal from 'happ-components/Modal/ModalDelivery/DiagnosisDeliveryModal';
import DiagnosisDeliveryModalLC from 'happ-components/Modal/ModalDelivery/DiagnosisDeliveryModalLC';
import DiagnosisDeliveryCompleteModal from 'happ-components/Modal/ModalDelivery/DiagnosisDeliveryCompleteModal';
import DeleteMedismartModal from 'happ-components/Modal/ModalDelivery/DeleteMedismartModal';
import MetaControlModal from 'happ-components/Modal/ModalDelivery/MetaControlModal';

export const useModal = () => {
    const [show, setShow] = useState(false);
    const [cssFix, setCssFix] = useState({
        css: '',
    });
    const [contentStatic, setContentStatic] = useState({
        code: ''
    })
    const [diagnosisContent, setDiagnosisContent] = useState({
        results: null,
        status: '',
        color: '',
        code: ''
    });
    const [diagnosisCompleteContent, setDiagnosisCompleteContent] = useState({
        results: null,
        status: '',
        color: '',
        code: '',
        name: '',
    });
    const [metaControlContent, setMetaControlContent] = useState({
        results: null,
        status: '',
        color: '',
        determinationWithControl: null,
        msgPathology: null,
        code: '',
        name: '',
    });
    const openModalStaticDeliver = (code) => e => {
        e.preventDefault();
        setContentStatic({
            ...contentStatic,
            code
        });
        setShow(true);
    };
    const openDiagnosisDeliveryLC = (code, pathology = null) => e => {
        e.preventDefault();
        setDiagnosisContent({
            ...diagnosisContent,
            results: !pathology ? null : pathology.results,
            status: !pathology ? '' : pathology.status,
            color: !pathology ? '' : pathology.color,
            code
        });
        setShow(true);
    }
    const openDiagnosisDelivery = (code, pathology = null) => e => {
        e.preventDefault();
        setDiagnosisContent({
            ...diagnosisContent,
            results: !pathology ? null : pathology.results,
            status: !pathology ? '' : pathology.status,
            color: !pathology ? '' : pathology.color,
            code
        });
        setShow(true);
    }
    const openDiagnosisCompleteDelivery = (code, pathology = null) => e => {
        e.preventDefault();
        setDiagnosisCompleteContent({
            ...diagnosisCompleteContent,
            results: !pathology ? null : pathology.results,
            status: !pathology ? '' : pathology.status,
            color: !pathology ? '' : pathology.color,
            code,
            name: !pathology ? '' : pathology.name,
        });
        setShow(true);
    }
    const openMetaControl = (code, pathology = null) => e => {
        setCssFix({
            ...cssFix,
            css: 'ws-content-modal-open',
        });
        setMetaControlContent({
            ...metaControlContent,
            results: !pathology ? null : pathology.result,
            status: !pathology ? '' : pathology.status,
            color: !pathology ? '' : pathology.color,
            determinationWithControl: !pathology ? '' : pathology.determinationWithControl,
            msgPathology: !pathology ? null : pathology.msgPathology,
            code,
            name: !pathology ? '' : pathology.name,
        });
        setShow(true);
    }
    const openModal = () => setShow(true);
    const closeModal = () => setShow(false);

    const ShowModalSchedulePatient = () => {
        return (
            <>
                {show ? (
                    <ModalSchedulePatient
                        show={show}
                        onHide={closeModal}
                    />
                ) : null}
            </>
        )
    }

    const DeleteMedismart = () => {
        return (
            <>
                {show ? (
                    <DeleteMedismartModal
                        show={show}
                        onHide={closeModal}
                    />
                ) : null}
            </>
        )
    }

    const ShowModalStaticDelivey = () => {
        return (
            <>
                {show && contentStatic.code !== '' ? (
                    <WelcomeDeliveryModal
                        show={show}
                        code={contentStatic.code}
                        onHide={closeModal}
                    />
                ) : null}
            </>
        )
    }

    const ShowModalDiagnosisDelivery = () => {
        return (
            <>
                {show && diagnosisContent.code !== '' ? (
                    <DiagnosisDeliveryModal
                        show={show}
                        diagnosisContent={diagnosisContent}
                        onHide={closeModal}
                    />
                ) : null}
            </>
        )
    }

    const ShowModalDiagnosisDeliveryLC = () => {
        return (
            <>
                {show && diagnosisContent.code !== '' ? (
                    <DiagnosisDeliveryModalLC
                        show={show}
                        diagnosisContent={diagnosisContent}
                        onHide={closeModal}
                    />
                ) : null}
            </>
        )
    }

    const ShowModalDiagnosisCompleteDelivery = () => {
        return (
            <>
                {show && diagnosisCompleteContent.code !== '' ? (
                    <DiagnosisDeliveryCompleteModal
                        show={show}
                        diagnosisContent={diagnosisCompleteContent}
                        onHide={closeModal}
                    />
                ) : null}
            </>
        )
    }

    const ShowModalMetaControl = () => {
        return (
            <>
                {show && metaControlContent.code !== '' ? (
                    <MetaControlModal
                        show={show}
                        diagnosisContent={metaControlContent}
                        onHide={closeModal}
                    />
                ) : null}
            </>
        )
    }

    return {
        cssFix,
        ShowModalStaticDelivey,
        ShowModalSchedulePatient,
        ShowModalDiagnosisDelivery,
        ShowModalDiagnosisDeliveryLC,
        ShowModalDiagnosisCompleteDelivery,
        ShowModalMetaControl,
        DeleteMedismart,
        openModalStaticDeliver,
        openDiagnosisDeliveryLC,
        openDiagnosisDelivery,
        openDiagnosisCompleteDelivery,
        openModal,
        openMetaControl,
    }
}
