import React from 'react';

const Dyslipidemia = ({determinationWithControl, results, msgPathology}) => {
    return (
        <>
            <tr>
                <td>LDL</td>
                <td>{results.happ.LDL}</td>
                {determinationWithControl ? (
                    <td>{results.meta.LDL}</td>
                ) : null}
                <td>Hasta 130 mg/dl</td>
                <td>{msgPathology ? msgPathology.ldl : ''}</td>
            </tr>
            <tr>
                <td>Triglicéridos</td>
                <td>{results.happ.Trigliceridos}</td>
                {determinationWithControl ? (
                    <td>{results.meta.Trigliceridos}</td>
                ) : null}
                <td>Menor a 150 mg/dl</td>
                <td>{msgPathology ? msgPathology.trigliceridos : ''}</td>
            </tr>
            <tr>
                <td>Colesterol</td>
                <td>{results.happ.Colesterol}</td>
                {determinationWithControl ? (
                    <td>{results.meta.Colesterol}</td>
                ) : null}
                <td>Menor a 200 mg/dl</td>
                <td>{msgPathology ? msgPathology.colesterol : ''}</td>
            </tr>

            <tr>
                <td>HDL</td>
                <td>{results.happ.HDL}</td>
                {determinationWithControl ? (
                    <td>{results.meta.HDL}</td>
                ) : null}
                <td>Mayor 50 Femenino; Mayor 40 Masculino</td>
                <td>{msgPathology ? msgPathology.hdl : ''}</td>
            </tr>
        </>
    );
}
 
export default Dyslipidemia;