import React, {useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Button } from 'react-bootstrap';
import { MentalHealthPsychologisticon } from 'happ-icons/icons';
import { getMentalHelathPrescriptionAction } from 'happ-redux/happ-actions/mentalHealth/mentalHealthAction';

const MentalHealthPsychologist = ({phq9Calculation, profile}) => {
    const dispatch = useDispatch();
    useEffect(() => {
        const getLink = () => {
            if (phq9Calculation && phq9Calculation.type.name === 'tipo-2') {
                dispatch(getMentalHelathPrescriptionAction(profile?._id));
            }
        }
        getLink()
    }, [phq9Calculation]);
    const { mentalPrescription } = useSelector(state => state.mentalHealth);
    const handleClick = (file) => {
        if (file !== null && file !== '') {
            window.open(file);
        }
    };
    return ( 
        <div className='mb-5'>
            <Col md={9} sm={12} lg={9} className='pt-2 result-content'>
                <div className='d-flex justify-content-center'>
                    <div className='text-center px-5 py-2 text-white primary-bg-color  rounded-1 indicators-content'>
                        <strong className='title-stages' >Resultados Encuesta de Salud</strong>
                    </div>
                </div>
            </Col>
            <Col md={9} sm={12} lg={9} className='mt-4 text-center result-content '>
                <p className='result-text'>Gracias por tomarte el tiempo de completar nuestra encuesta, tu bienestar físico y mental son nuestra prioridad.</p>
            </Col>
            <Col md={9} sm={12} lg={9} className='mt-4 text-center result-content'>
                <p className='result-text'>Los resultados  indican que podrías estar atravesando algunas situaciones emocionales que podrían requerir un mayor apoyo y acompañamiento.</p>
            </Col>
            <Col md={9} sm={12} lg={9} className='mt-4 text-center result-content result-icon'>
                <MentalHealthPsychologisticon />
            </Col>
            <Col md={9} sm={12} lg={9} className='mt-5 text-center result-content '>
                <p className='result-text'>
                    Por ello, te invitamos a visitar a un psicólogo clínico, el cual te entregará herramientas que te ayudarán a manejar 
                    mejor las situaciones que hoy son complejas
                </p>
            </Col>
            <Col md={12} sm={12} lg={12} className='mt-5 text-center result-content'>
                {mentalPrescription ? (
                    <Button
                        className='shadow-lg primary-bg-color'
                        onClick={() => handleClick(mentalPrescription.prescription)}
                    >
                        Descargar orden medica
                    </Button>
                ) : null}
            </Col>
        </div>
    );
}
 
export default MentalHealthPsychologist;