import AccordionModalExamColesterol from 'happ-components/AccordionModalExam/AccordionModalExamColesterol'
import ButtonDownloadModalExam from 'happ-components/ButtonDownloadModalExam/ButtonDownloadModalExam'
// import { Prueba1 } from 'happ-icons/icons'
import { ColesterolIndicatorExam } from 'happ-icons/icons'
import { CloseModalBlue } from 'happ-icons/icons'
import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'

const ModalColesterol2 = (props) => {

    const status = 'stage3'

        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                contentClassName="modal3"
                centered
                animation={false}
            // dialogClassName="modal-100w"
            // fullscreen
            // className="modal-footer"
            >
                {/* este es para el colesterol plomo */}
                <div className={status === 'stage3' ? 'modal-content-white' : null}>
                    <Row>
                        <Col>
                            <div style={{ zIndex: 10, position: 'sticky' }}>
                                <>
                                    <div style={{ backgroundColor: 'white', position: 'relative', borderRadius: 10 }} className='py-2'>

                                        <h6 className='text-center primary-color-text pb-2'>Diagnóstico <strong>Dislipidemia</strong> </h6>
                                        <div onClick={props.onHide} className='modal-close-btn primary-color-text'>
                                            <CloseModalBlue
                                            />
                                        </div>

                                        <div className='modal-info primary-bg-color'>
                                            <Row className='pt-3 pb-2'>
                                                <Col className='d-flex justify-content-around'>
                                                    {/* <ColesterolIndicatorExam
                                                        fill='white'
                                                        responsive='scale-mobile'
                                                    />} */}
                                                    {/* <Prueba1/> */}
                                                    <div className='width-info-modal align-self-end mt-3'>
                                                        <p className='description-modal-exam' >La <strong>dislipidemia</strong> es una <strong>enfermedad</strong> causada por altos 
                                                        niveles de grasas en la sangre</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* className={`status-bar-${status}`} */}

                                            <div className='status-bar-danger' />
                                        </div>
                                    </div>
                                </>

                            </div>
                        </Col>
                    </Row>


                    {/* en celu le coloco u paddingTop: 180px */}
                    <Modal.Body style={{ padding: 0 }}   >
                        <Row>
                            <Col>
                                <div className='text-center pt-3 '>
                                    <Row className=' modal-scroll-mobile' >
                                        <div className='accordion-modal'>
                                            <Col className='d-flex justify-content-between' >
                                                <h6 className='fw-bold align-self-end'> Mis exámenes</h6>
                                                <ButtonDownloadModalExam />
                                            </Col>
                                        </div>
                                        <Col>
                                            <AccordionModalExamColesterol title='Chequeo Colesterol' />
                                        </Col>
                                    </Row>
                                </div>
                                {/* {description[0]?.length > 1 && title !== 'Exámenes Físicos' ? (
                                <div className='d-flex justify-content-center py-2'>
                                    <Button className='shadow btn-blue'>
                                        <div className='d-flex justify-content-center mt-1'>
                                            <DiagnosticCalendarIcon />
                                            <p className='ms-3'>
                                                Agendar chequeo inicial
                                            </p>
                                        </div>
                                    </Button>
                                </div>
                            ) : description[0]?.length > 1 && title === 'Exámenes Físicos' ?
                                <div className='d-flex justify-content-center py-2'>
                                    <Button className='shadow btn-blue'>
                                        <div className='d-flex justify-content-center mt-1'>

                                            <DiagnosticCalendarIcon />
                                            <Link to="/agendarhora" className='ms-3' style={{ textDecoration: 'none', backgroundColor: 'transparent', color: 'inherit', border: 'none' }}  >
                                                Agendar exámenes físicos
                                            </Link>

                                        </div>
                                    </Button>
                                </div>
                                : null
                            } */}
                            </Col>
                        </Row>
                    </Modal.Body>
                </div>

            </Modal>
        )
    }

    export default ModalColesterol2