import axiosClient from 'happ-config/axios';

const getMedicalAdvices = async token => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'x-auth-token': token
        };
        const response = await axiosClient.get('/medicaladvice/getMedicalAdvice', {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

export {
    getMedicalAdvices
}