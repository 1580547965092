import React from 'react';
import {useSelector} from 'react-redux';
import {Container, Row, Col, Card, Form} from 'react-bootstrap';
import { Link } from 'react-router-dom';
//components
import {HappContainer} from 'happ-components/HappStyledComponents/HappContainer/HappContainer';
import logo from 'assets/imgs/logo-happ-heart.svg';
import LoginLoader from 'happ-components/Loader/LoginLoader';

const EmailPassword = () => {

    const {emailPatient} = useSelector(state => state.auth)

    return ( 
        <Container>
            <LoginLoader/> 
                <Row className="vh-100 d-flex justify-content-center align-items-center">
                    <Col md={8} lg={6} xs={12}>
                        <HappContainer>
                            <Card className="shadow-box border-box">
                                <Card.Body className='card-login'>
                                    <div className="mb-3 mt-md-4">
                                        <div className="content-logo">
                                            <img src={logo} alt="description"/>
                                        </div>
                                    </div>
                                    <Form 
                                        className="form-action"
                                    >
                                        <div className="text-center">
                                            Te hemos enviado un correo con un link a tu Email:
                                        </div>
                                        <br></br>
                                        <div className="text-center">
                                            {emailPatient ? (
                                                <h6><strong>{emailPatient}</strong></h6>
                                            ) : null}
                                        </div>
                                        <br></br>
                                        <div className="text-center">
                                            El cual te llevará al cambio de contraseña.
                                        </div>
                                        <br></br>
                                        <div className="d-grid">
                                            <Link to="/login" className="btn primary-bg-color emailLinkReset">
                                                Volver a iniciar sesión
                                            </Link>
                                        </div>
                                    </Form>    
                            </Card.Body>
                        </Card>
                    </HappContainer>
                </Col>
            </Row>
        </Container>
    );
}
 
export default EmailPassword;