import { DiabetesIndicator } from 'happ-icons/icons'
import { MediumSugarBar } from 'happ-icons/icons'
import { CloseModal } from 'happ-icons/icons'
import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'

const ModalAzucar1 = (props) => {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className='modal-content-blue'>
                <Row className=''>
                    <Col className='mt-2'>
                        <div onClick={props.onHide} className='modal-close-btn primary-color-text'>
                            <CloseModal
                            />
                        </div>
                        <div className='text-center text-white'>
                            <h6>Tu Chequeo de <strong>Azúcar</strong></h6>
                        </div>
                    </Col>
                </Row>

                <Modal.Body style={{ padding: 0 }}>
                    <div className='text-center text-white'>
                        <Row className='my-2'>
                            <Col className='d-flex justify-content-around align-items-center' >
                                <DiabetesIndicator
                                    fill='white'
                                    responsive='scale-mobile'
                                />
                                <div className='modal-content-tape-result'>
                                    <p className='modal-tape-result'>118 <div className='modal-unit-of-measurement'> mg/dl</div></p>
                                </div>
                                <MediumSugarBar
                                    fill="white"
                                    responsive="scale-mobile"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col className='my-2 mx-2'>
                                <p className='p-modal'><strong>¡Precaución!</strong> Descubrimos que tus niveles de azúcar están <strong>sobre los parámetros normales</strong> y es necesario analizarlos más en profundidad en la <strong>siguiente etapa</strong>.</p>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    )
}

export default ModalAzucar1