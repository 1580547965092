import React from 'react';

const Hypertension = ({determinationWithControl, results, msgPathology}) => {
    return (
        <>
            <tr>
                <td>Sistolica</td>
                <td>{results.happ.sistolica}</td>
                {determinationWithControl ? (
                    <td>{results.meta.sistolica}</td>
                ) : null}
                <td>Menor 130</td>
                <td>{msgPathology ? msgPathology.hipertension : ''}</td>
            </tr>
            <tr>
                <td>Diastolica</td>
                <td>{results.happ.diastolica}</td>
                {determinationWithControl ? (
                    <td>{results.meta.diastolica}</td>
                ) : null}
                <td>Menor 80</td>
                <td>{msgPathology ? msgPathology.hipertension : ''}</td>
            </tr>
        </>
    );
}
 
export default Hypertension;