import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import { Row, Col, Container } from 'react-bootstrap';
import moment from "moment";
//components
import { DownIcon, CalendarIcon, GlycemiaIcon, BloodPressureIconHistory, ColesterolIconHistory, OverweightIcon } from 'happ-icons/icons';
import { Message } from 'happ-components/HappStyledComponents/HappElementsStyled/HappElementsStyled';
import Results from 'happ-components/ExamsList/Results';
import { Loader } from 'happ-components/Loader/Loader';
//actions
import { getExamHistoryAction } from 'happ-redux/happ-actions/examHistory/examHistoryActions';
import NoExamsResults from './NoExamsResults';
import { useModal } from 'happ-custom-hooks/useModal/useModal';
import { HappContentIndicator } from 'happ-components/HappStyledComponents/HappContentIndicator/HappContentIndicator';
import { PressureIndicator } from 'happ-icons/icons';
import { DiabetesIndicatorBoxExam } from 'happ-icons/icons';
import { DislipidemiaIcon } from 'happ-icons/icons';
import IndicatorsBoxesDiagnosisComplete from './IndicatorsBoxes/IndicatorsBoxesDiagnosisComplete';
import IndicatorsBoxesGray from './IndicatorsBoxes/IndicatorsBoxesGray';
import IndicatorsBoxesDiagnosis from './IndicatorsBoxes/IndicatorsBoxesDiagnosis';
import TeleConsultationDelivery from 'happ-components/MedicalStageDelivery/TeleConsultationDelivery';
import { getMyExamHistoryAction } from 'happ-redux/happ-actions/examHistory/examHistoryActions';
import useAuth from 'happ-custom-hooks/useAuth/useAuth';
import ButtonExamDownload from './ButtonExamDownload/ButtonExamDownload';

const ExamsList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { examHistory, errorServer, errorMsg, loading, lastRecords } = useSelector(state => state.exams);
    const { medicalStageResults, isDislipidemia, medicalStagePrint } = useSelector(state => state.auth);
    const { id } = useAuth();
    const {
        openDiagnosisCompleteDelivery,
        ShowModalDiagnosisCompleteDelivery
    } = useModal();

    useEffect(() => {
        dispatch(getMyExamHistoryAction(navigate, id));
    }, [])

    return (
        <div>
            {loading ? (
                <Loader />
            ) :
                <>
                    <Row className='mt-4'>
                        <Col className='text-center' >
                            <ButtonExamDownload examHistory={examHistory} />
                            {/* <p>aqui iran las descarga de examenes =) </p>   */}
                        </Col>
                    </Row>
                </>
            }
        </div>

    );
}

export default ExamsList;