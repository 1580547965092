import axiosClient from 'happ-config/axios';


const getMedismartSchedules = async (dni, token) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.get(`/medismart-scheduling/medismart-schedule-availability/${dni}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const checkMedismartSchedules = async (dni, token) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.get(`/medismart-scheduling/check-schedulings/${dni}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const getCurrentMedismartSchedule = async (_id, token) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.get(`/medismart-scheduling/info-schedule-confirm/${_id}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const confirmMedismartSchedule = async (params, token) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const { idMh, idBd, dni } = params;
        const response = await axiosClient.get(`/medismart-scheduling/confirm-schedule/${dni}/${idMh}/${idBd}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const filterMedismartSchedules = async (params, token) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const { idMh, dni, specialistDate } = params;
        const response = await axiosClient.get(`/medismart-scheduling/get-schedules-by-specialist-params/${idMh}/${dni}/${specialistDate}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const deleteMedismartSchedule = async (attention, token) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.get(`/medismart-scheduling/delete-schedule/${attention}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

export {
    getMedismartSchedules,
    confirmMedismartSchedule,
    filterMedismartSchedules,
    checkMedismartSchedules,
    getCurrentMedismartSchedule,
    deleteMedismartSchedule,
}