import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { HappContentIndicator } from 'happ-components/HappStyledComponents/HappContentIndicator/HappContentIndicator';
import { OverweightIcon, PressureIndicator, ControlAlertIcon } from 'happ-icons/icons';
import { DiabetesIndicatorBoxExam } from 'happ-icons/icons';
import { DislipidemiaIcon } from 'happ-icons/icons';
import { useModal } from 'happ-custom-hooks/useModal/useModal';
import { useSelector } from 'react-redux';


const MyControl = ({isMetaExams}) => {
    const {metaExams} = useSelector(state => state.auth);
    const {
        ShowModalMetaControl,
        openMetaControl
    } = useModal();
    return ( 
        <Row className='me-1 ms-1'>
                <div className="justify-content-center rcv-diagnosis px-1 mt-3"  >
                    <h5 className='mb-3 fw-bold primary-color-text title-message'>Resultados exámenes de control</h5>
                    <p className='primary-color-text'>Podrás comparar tus resultados con respecto a los anteriores  y ver tu evolución</p>
                </div>
                <div className="justify-content-center mb-3 mt-3">
                    {isMetaExams && metaExams && metaExams.map(item => (
                        <HappContentIndicator
                            key={item._id}
                            className={`box-content-flex box-indicator-meta indicator-bg-${item.color}`}
                            onClick={openMetaControl(item.code, item)}
                            role="button"
                            style={{position: 'relative', aspectRatio: 1/1}}
                        >
                            {item.determinationWithControl ? (
                                // <img 
                                //     src={meta}
                                //     className='alert-meta' 
                                // />
                                <ControlAlertIcon 
                                    className='alert-meta'
                                />
                            ) : null}
                            <Col>
                                <Row>
                                    {item.code === 'hipertension' ?
                                        (
                                            <>
                                                <div className='mb-3'>
                                                    <PressureIndicator
                                                        fill='white'
                                                        responsive='scale-mobile'
                                                    />
                                                </div>
                                            </>
                                        ) : item.code === 'obesidad' ?
                                            (
                                                <>
                                                    <div className='mb-3'>
                                                        <OverweightIcon
                                                            fill='white'
                                                            responsive='scale-mobile'
                                                            style={{width: '45px', height: '45px'}}
                                                        />
                                                    </div>
                                                </>
                                            ) : item.code === 'diabetes' || item.code === 'pre-diabetes' ?
                                                (
                                                    <>
                                                        <div className='mb-3'>
                                                            <DiabetesIndicatorBoxExam
                                                                fill='white'
                                                                responsive='scale-mobile'
                                                                style={{width: '45px', height: '45px'}}
                                                            />
                                                        </div>
                                                    </>
                                                ) : item.code === 'dislipidemia' ?
                                                    (
                                                        <>
                                                            <div className='mb-3'>
                                                                <DislipidemiaIcon
                                                                    style={{width: '45px', height: '45px'}}
                                                                    fill='white'
                                                                    responsive='scale-mobile'
                                                                />
                                                            </div>
                                                        </>
                                                    ) : null}
                                </Row>
                                <Row style={{position: 'absolute'}} className={`dark-opacity border-bottom-container-diagnosis`}>
                                    <Col className='d-flex justify-content-center'>
                                        {item.code === 'hipertension' ?
                                            (
                                                <>
                                                    <p style={{ fontSize: '11px', lineHeight: '25px' }}>Hipertensión</p>
                                                </>
                                            ) : item.code === 'obesidad' ?
                                                (
                                                    <>
                                                        <p style={{ fontSize: '11px', lineHeight: '25px' }}>
                                                            {item.status === 'Saludable' ? (
                                                                'Peso Normal'
                                                            ) : (
                                                            <>
                                                                {item.status}
                                                            </>
                                                            )}
                                                        </p>
                                                    </>
                                                ) : item.code === 'diabetes' || item.code === 'pre-diabetes' ?
                                                    (
                                                        <>
                                                            <p style={{ fontSize: '11px', lineHeight: '25px' }}>Diabetes</p>
                                                        </>
                                                    ) : item.code === 'dislipidemia' ? (
                                                        <>
                                                            <p style={{ fontSize: '11px', lineHeight: '25px' }}>Dislipidemia</p>
                                                        </>
                                                    ) : null}
                                    </Col>
                                </Row>
                            </Col>
                        </HappContentIndicator>
                    ))}
                </div>
                <ShowModalMetaControl />
        </Row>            
    );
}
 
export default MyControl;