import React from 'react';

const Imc = ({determinationWithControl, results, msgPathology}) => {
    return ( 
        <>
            <tr>
                <td>Peso</td>
                <td>{results.happ.weight}</td>
                {determinationWithControl ? (
                    <td>{results.meta.weight}</td>
                ) : null}
                <td></td>
                <td>{msgPathology ? msgPathology.obesidad : ''}</td>
            </tr>
            <tr>
                <td>IMC</td>
                <td>{results.happ.imc}</td>
                {determinationWithControl ? (
                    <td>{results.meta.imc}</td>
                ) : null}
                <td>Menor a 30 en Imc</td>
                <td>{msgPathology ? msgPathology.obesidad : ''}</td>
            </tr>
            <tr>
                <td>Circunferencia abdominal</td>
                <td>{results.happ.circumference}</td>
                {determinationWithControl ? (
                    <td>{results.meta.circumference}</td>
                ) : null}
                <td></td>
                <td>{msgPathology ? msgPathology.obesidad : ''}</td>
            </tr>
        </>
    );
}
 
export default Imc;