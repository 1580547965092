import {
    INIT_EXAM_HISTORY,
    EXAM_HISTORY,
    ERROR_EXAM_HISTORY,
    LOGOUT
} from 'happ-redux/happ-types';
import { getExamHistory, getMyExamHistory } from 'happ-redux/happ-services/exam-history/exam-history';

export function getExamHistoryAction(navigate){
    return async (dispatch) => {
        dispatch(initExamHistory());
        try {
            const token = localStorage.getItem('token');
            if (token === null) {
                dispatch(logout());
                navigate('/login');
            }
            const response = await getExamHistory(token);

            if (response.status === 401){
                dispatch(logout());
                navigate('/login');
            }
            if (response.status === 200) {
                dispatch(examHistory(response.data));
            } else {
                dispatch(errorExamHistory(response.data.msg))
            }   
        } catch (error) {
            dispatch(errorExamHistory(error.data.msg))
        }
    }
}

export function getMyExamHistoryAction(navigate, id){
    return async (dispatch) => {
        dispatch(initExamHistory());
        try {
            const token = localStorage.getItem('token');
            if (token === null) {
                dispatch(logout());
                navigate('/login');
            }
            const response = await getMyExamHistory(token, id);
            if (response.status === 401){
                dispatch(logout());
                navigate('/login');
            }
            if (response.status === 200) {
                console.log(response.data, 'es 200 llevo la info =)')
                dispatch(examHistory(response.data));
            } else {
                dispatch(errorExamHistory(response.data.msg))
            }   
        } catch (error) {
            dispatch(errorExamHistory(error.data.msg))
        }
    }
}

const initExamHistory = () => ({
    type: INIT_EXAM_HISTORY
});

const logout = () => ({
    type: LOGOUT
});

const examHistory = response => ({
    type: EXAM_HISTORY,
    payload: response
});

const errorExamHistory = error => ({
    type: ERROR_EXAM_HISTORY,
    payload: error
})

