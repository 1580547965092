import React from 'react';
import {Form, Button} from 'react-bootstrap';
import { SelfRegistrationComplete } from 'happ-components/HappStyledComponents/HappElementsStyled/HappElementsStyled';

const InputsRecord = ({healthBenefitCode, goal, record, getNewMedicalGoalRecord, handlerChangeRecordInput, ...rest}) => {
    const {glicemia, peso, sistolica, diastolica, colesterol} = record;
    const {errorPa, errorPl, errorGl, errorImc, submit, goalStatus} = rest;
    return (
        !goalStatus ? (
        <>
            <SelfRegistrationComplete>
                ¡Meta cumplida!
            </SelfRegistrationComplete>
        </>
        ) : (
            <>
                <Form>
                    {healthBenefitCode === "GL001" ? (
                        <>
                            <Form.Group className="input-self-registration" controlId="formglicemia">
                                <Form.Control 
                                    type="text"
                                    name="glicemia"
                                    value={glicemia} 
                                    onChange={handlerChangeRecordInput}
                                    placeholder="Ej: 100"
                                />
                            </Form.Group>
                            {errorGl ? (
                                <p className='msg-error-input'>Solo números</p>
                            ) : null}
                        </>
                    ) : null}
                    {healthBenefitCode === "IMC001" ? (
                        <>
                            <Form.Group className="input-self-registration" controlId="formpeso">
                                <Form.Control 
                                    type="text"
                                    name="peso"
                                    value={peso}
                                    onChange={handlerChangeRecordInput} 
                                    placeholder="Ej: 75"
                                />
                            </Form.Group>
                            {errorImc ? (
                                <p className='msg-error-input'>Solo números</p>
                            ) : null}
                        </>
                    ) : null}
                    {healthBenefitCode === "PA001" ? (
                        <>
                            <div className='inputs-pl'>
                                <Form.Group className="input-self-registration" controlId="formsistolica">
                                    <p>Sys</p>
                                    <Form.Control 
                                        type="text"
                                        name="sistolica" 
                                        value={sistolica}
                                        onChange={handlerChangeRecordInput}
                                        placeholder="120"
                                    />
                                </Form.Group>
                                <Form.Group className="input-self-registration" controlId="formdiastolica">
                                    <p>Dia</p>
                                    <Form.Control 
                                        type="text"
                                        name="diastolica"
                                        value={diastolica}
                                        onChange={handlerChangeRecordInput} 
                                        placeholder="90"
                                    />
                                </Form.Group>
                            </div>
                            {errorPa ? (
                                <p className='msg-error-input'>Solo números</p>
                            ) : null}
                        </>
                        
                    ) : null}
                    {healthBenefitCode === "PL001" ? (
                        <>
                            <Form.Group className="input-self-registration" controlId="formcolesterol">
                                <Form.Control 
                                    type="text"
                                    name="colesterol" 
                                    value={colesterol}
                                    onChange={handlerChangeRecordInput}
                                    placeholder="Ej: 200"
                                />
                            </Form.Group>
                            {errorPl ? (
                                <p className='msg-error-input'>Solo números</p>
                            ) : null}
                        </>
                    ) : null}
                    <Button
                        type="button"
                        onClick={getNewMedicalGoalRecord(goal, healthBenefitCode)}
                        disabled={submit}
                        className="btn info-bg-color text-white mt-2 btn-add-self-registration"
                    >Ingresar</Button>
                </Form>
            </>
        )

    );
}
 
export default InputsRecord;