import ModalDelete from 'happ-components/Modal/ModalDelete'
import ModalDelete3 from 'happ-components/Modal/ModalDelete3'
import { useModal2 } from 'happ-custom-hooks/useModal/useModal2'
import { SuccessCalendarhr } from 'happ-icons/icons'
import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'

const TimeSuccessCalendar3 = () => {

    const fecha = 'Viernes 27 de enero a las 11:00horas.'

    // const dispatch = useDispatch();
    // const handleClick = () => {
    //     dispatch(hooraagendada)
    // }

    const [show, handleShow, handleClose] = useModal2(false);
    const [show3, handleShow3, handleClose3] = useModal2(false);

    return (
        <Container className='mt-5 text-center' >
            <Row className='mt-5'  >
                <Col>
                    <h3 className='text-center pb-5 primary-color-text'> <b>¡Tu hora ya <br /> está agendada!</b> </h3>
                    <div className='d-flex justify-content-center' >
                        <div className='text-center btn-success-calendarhr'>
                            <SuccessCalendarhr />
                        </div>
                    </div>
                    <div className='text-center mt-4 primary-color-text'>
                        {/* <p className='p-success-schedule' ><strong>¡Tu hora está agendada!</strong></p>  */}
                        <p className='p-success-schedule'> Recuerda llegar el día </p>
                        <p className='p-success-schedule'><strong>{fecha}</strong></p>
                    </div>



                </Col>
            </Row>
            <Row className='py-3' style={{ width: '32%', margin: '0 auto' }}>
                <Col className='d-flex justify-content-center'>
                    <div className='me-1'>
                        <Link to="/agendarhora3" className='d-flex justify-content-center link'>
                            <Button className='shadow-lg primary-bg-color btn-time-success-calendar'>
                                Modificar hora
                            </Button>
                        </Link>
                    </div>
                    <div className='ms-1' onClick={handleShow3}>
                        <Button className='shadow-lg danger-bg-color btn-time-success-calendar'>
                            Eliminar hora
                        </Button>
                    </div>
                </Col>
                <div className='pt-5 d-flex justify-content-center'>
                    <Link to="/horaagendada3">
                        <Button className='shadow-lg btn-blue-back-travel'>
                            Regresar a mi viaje
                        </Button>
                    </Link>
                </div>
            </Row>
            <ModalDelete show={show} onHide={handleClose} />
             <ModalDelete3 show={show3} onHide={handleClose3} />
            {/* <Row className='my-5'>
                <Col className='d-flex justify-content-center'>
                    <Link to="/">
                        <Button className='shadow '>
                            Regresar a mi viaje
                        </Button>
                    </Link>
                </Col>
            </Row> */}
        </Container>
    )
}

export default TimeSuccessCalendar3