import React from 'react';
import { Col, Button } from 'react-bootstrap';
import { CancerProstata } from 'happ-icons/icons';

const FlowFinishView = ({isMedicalOrder, healthForecastLink, healthForecast, cancerPrescription, cancerCalculation}) => {
    const handleClick = (file) => {
        if (file !== null && file !== '') {
            window.open(file);
        }
    };
    return ( 
        cancerCalculation !== null ? ( 
        <div className=''>
            <Col md={9} sm={12} lg={9} className='pt-2 result-content'>
                <div className='d-flex justify-content-center'>
                    <div className='text-center px-5 py-2 text-white primary-bg-color  rounded-1 indicators-content'>
                        <strong className='title-stages' >Resultados encuesta Cáncer de Próstata</strong>
                    </div>
                </div>
            </Col>
            <Col md={9} sm={12} lg={9} className='mt-4 text-center result-content'>
                <p className='result-text'>Gracias por tomarte el tiempo de completar nuestra encuesta, tu bienestar físico y mental son nuestra prioridad.</p>
            </Col>
            <Col md={9} sm={12} lg={9} className='mt-4 mb-3 text-center result-content'>
                <p className='result-text'>A partir de la información recopilada, hemos identificado que te encuentras dentro del grupo de riesgo para el cáncer de próstata.</p>
            </Col>
            <Col md={9} sm={12} lg={9} className='mt-4 text-center result-content result-icon'>
                <CancerProstata />
            </Col>
            {cancerPrescription !== null ? (
                <>
                {/* <Col md={9} sm={12} lg={9} className='mt-5 mb-4 text-center result-content'>
                    <p className='result-text'>
                    Al estar afiliado a <strong>{healthForecast}</strong> esta cuenta con beneficios de exámenes a costo cero.
                     Podrás encontrar el laboratorio más cercano en el siguiente enlace
                    </p>
                    <Button
                        className='primary-bg-color-white-href'
                        onClick={() => handleClick(healthForecastLink)}
                        >
                            Ver más información
                    </Button>
                </Col> */}
                <Col md={9} sm={12} lg={9} className='mt-5 mb-4 text-center result-content'>
                    <p className='result-text'>
                    Dado que <strong>No</strong> te has realizado un exámen de antigeno prostático dentro del último año, es muy importante que te lo realices
                    </p>
                    <Button
                        className='shadow-lg primary-bg-color'
                        onClick={() => handleClick(cancerPrescription)}
                        >
                            Descargar orden médica
                    </Button>
                </Col>
                </>
            ) : null}                
            {cancerPrescription === null ? (
                <Col md={9} sm={12} lg={9} className='mb-4 mt-5 text-center result-content'>
                    <p className='result-text'>
                    Como que te has realizado un exámen de antígeno prostático dentro del último año, es importante que tu médico lo analice en detalle
                    </p>
                </Col>
            ) : null}
        </div>
        ) : null
    );
}
 
export default FlowFinishView;