import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// cancer-mama
import BreastCancerSurveyQuestionOne from 'happ-components/BreastCancerSurveyParts/BreastCancerSurveyQuestionOne';
import BreastCancerSurveyQuestionTwo from 'happ-components/BreastCancerSurveyParts/BreastCancerSurveyQuestionTwo';
// cancer-cervix
import CervixCancerSurveyPartOne from 'happ-components/CervixCancerSurveyParts/CervixCancerSurveyPartOne';
// cancer-colon
import ColonCancerSurveyPartOne from 'happ-components/ColonCancerSurveyParts/ColonCancerSurveyPartOne';
import ColonCancerSurveyPartTwo from 'happ-components/ColonCancerSurveyParts/ColonCancerSurveyPartTwo';
// cancer-pulmon
import LungCancerSurveyPartOne from 'happ-components/LungCancerSurveyParts/LungCancerSurveyPartOne';
import LungCancerSurveyPartTwo from 'happ-components/LungCancerSurveyParts/LungCancerSurveyPartTwo';
import LungCancerSurveyPartThree from 'happ-components/LungCancerSurveyParts/LungCancerSurveyPartThree';
import LungCancerSurveyPartFour from 'happ-components/LungCancerSurveyParts/LungCancerSurveyPartFour';
import LungCancerSurveyPartFive from 'happ-components/LungCancerSurveyParts/LungCancerSurveyPartFive';
import LungCancerSurveyPartSix from 'happ-components/LungCancerSurveyParts/LungCancerSurveyPartSix';
import LungCancerSurveyPartSeven from 'happ-components/LungCancerSurveyParts/LungCancerSurveyPartSeven';
// cancer-prostata
import ProstateCancerSurveyPartOne from 'happ-components/ProstateCancerSurveyParts/ProstateCancerSurveyPartOne';
import ProstateCancerSurveyPartTwo from 'happ-components/ProstateCancerSurveyParts/ProstateCancerSurveyPartTwo';
import ProstateCancerSurveyPartThree from 'happ-components/ProstateCancerSurveyParts/ProstateCancerSurveyPartThree';
import ProstateCancerSurveyPartFour from 'happ-components/ProstateCancerSurveyParts/ProstateCancerSurveyPartFour';
import ProstateCancerSurveyPartFive from 'happ-components/ProstateCancerSurveyParts/ProstateCancerSurveyPartFive';
import ProstateCancerSurveyPartSix from 'happ-components/ProstateCancerSurveyParts/ProstateCancerSurveyPartSix';
import ProstateCancerSurveyPartSeven from 'happ-components/ProstateCancerSurveyParts/ProstateCancerSurveyPartSeven';
import ProstateCancerSurveyPartEight from 'happ-components/ProstateCancerSurveyParts/ProstateCancerSurveyPartEight';
import { SURVEYQUESTIONS, healthForecastLinks } from './questionsSurveysStructure';

import { sendCancerSurveyAction, getLastCancerPrescriptionAction } from 'happ-redux/happ-actions/cancerJourney/cancerJourneyActions';

const useSurvey = (type, age, healthForecast, smoke, gender, initialState, patient) => {
    const [state, setState] = useState(initialState);
    const [finishFlow, setFinishFlow] = useState(null);
    const [isMedicalOrder, setIsMedicalOrder] = useState(null);
    const [flowCut, setFlowCut] = useState(null);
    const dispatch = useDispatch();
    const {
        cancerPrescription,
        saveCancerSurvey,
        loadingSave,
        riskGroup,
        cancerCalculation,
    } = useSelector(state => state.cancer);

    const ageRangeByTypeOfcancer = {
        'cancer-mama': age >= 40 && age <= 65 ? true : false,
        'cancer-colon': age >= 40 ? true : false,
        'cancer-cervix': age >= 25 && age <= 65 ? true : false,
        'cancer-prostata': age >= 50 ? true : false,
    }

    useEffect(() => {
      const getDocument = () => {
        if ((type !== null && type !== undefined) && (patient !== null && patient !== undefined)) {
            dispatch(getLastCancerPrescriptionAction(type, patient));
            if (cancerPrescription !== null) {
                setIsMedicalOrder(true);
            } else {
                setIsMedicalOrder(false);
            }
        }
      }
      getDocument()
    }, [type, patient]);
    
    const checkAnswers = (initQuestion, endQuestion, totalNot) => {
        let answerNot = 0;
        while(initQuestion <= endQuestion) {
            if (state[`question${initQuestion}`] === 'No') {
                answerNot ++;
            }
            initQuestion ++;
        }
        console.log(answerNot);
        console.log(totalNot);
        if (answerNot === totalNot) {
            return true;
        } 
        return false;
    }
    
    const cancerFlow = (type, step, answerStep) => {
        const range = ageRangeByTypeOfcancer[type];
        const flow = {
            'cancer-mama': step === 1 && (answerStep === 'Si' || range) ? true : false,
            'cancer-colon': step === 1 && answerStep === 'Si' ? true : false, 
            'cancer-pulmon': 
                ((step === 1 || step === 2) && answerStep === 'No') ||
                (step === 6 && checkAnswers(3, 6, 4)) ? false : true,
            'cancer-prostata': 
                step === 7 && (checkAnswers(1, 7, 7) && range === false) ? false : true, 
        }
        return flow[type];
    }

    const handlerChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        });
    }

    const cutSurvey = (type, step) => {
        let index = 1;
        const survey = [];
        const questions = SURVEYQUESTIONS[type];
        while (index <= step) {
            survey.push({
                item: questions['question' + index],
                value: state['question' + index],
            });
            index ++;
        }
        const body = {
            survey,
            patient,
            type,
            name: questions.title,
            calculation: {
                type,
                calculation: 'no-grupo-riesgo',
                medicalOrder: false,
                dailyControlExam: false,
            }
        }
        dispatch(sendCancerSurveyAction(body));
    }

    const next = () => {
        const { step, total } = state;
        const currentQuestion = `${state[`question${step}`]}`;
        if (Number(currentQuestion) === -1) {
            return;
        }
        const flowCut = cancerFlow(type, step, currentQuestion);
        if (flowCut !== undefined && flowCut === false) {
            setFlowCut(true);
            cutSurvey(type, step);
            return;
        }
        const newStep = step + 1;
        const newProgress = Math.round((newStep * 100) / total);
        setState({
            ...state,
            step: newStep,
            progress: newProgress,
        });
    }
    
    const preview = () => {
        const { step, total } = state;
        const newStep = step - 1;
        const newProgress = Math.round((newStep * 100) / total);
        setState({
            ...state,
            step: newStep,
            progress: newProgress,
        });
    }

    const submit = () => {
        const { step, total } = state;
        const currentQuestion = `${state[`question${step}`]}`;
        if (Number(currentQuestion) === -1) {
            return;
        }
        const questions = SURVEYQUESTIONS[type];
        const survey = [];
        let totalaux = 1;
        while (totalaux <= total) {
            survey.push({
                item: questions['question' + totalaux],
                value: state['question' + totalaux],
            });
            totalaux ++;
        }
        setIsMedicalOrder(currentQuestion === 'No' ? true : false);
        const body = {
            survey,
            patient,
            type,
            name: questions.title,
            calculation: {
                type,
                calculation: 'grupo-riesgo',
                medicalOrder: currentQuestion === 'No' ? true : false,
                dailyControlExam: currentQuestion === 'Si' ? true : false,
            }
        }
        setFinishFlow(true);
        dispatch(sendCancerSurveyAction(body));
    }

    const cancerMamaStructure = step => {
        switch(step) {
            case 1:
                return (
                    <BreastCancerSurveyQuestionOne 
                        state={state}
                        handlerChange={handlerChange}
                        next={next}
                    />
                );
            case 2:
                return (
                    <BreastCancerSurveyQuestionTwo 
                        state={state}
                        handlerChange={handlerChange}
                        submit={submit}
                        preview={preview}
                    />
                );    
            default: 
                return null;
        }
    }

    const cancerCervixStructure = step => {
        switch(step) {
            case 1: 
                return (
                    <CervixCancerSurveyPartOne 
                        state={state}
                        handlerChange={handlerChange}
                        submit={submit}
                    />
                );
            default:
                return null;
        }
    }

    const cancerColonStructure = step => {
        switch(step) {
            case 1:
                return (
                    <ColonCancerSurveyPartOne 
                        state={state}
                        handlerChange={handlerChange}
                        next={next}
                    />
                );
            case 2:
                return (
                    <ColonCancerSurveyPartTwo 
                        state={state}
                        handlerChange={handlerChange}
                        submit={submit}
                        preview={preview}
                    />
                );    
            default: 
                return null;
        }
    }

    const cancerPulmonStructure = step => {
        switch(step) {
            case 1:
                return (
                    <LungCancerSurveyPartOne 
                        state={state}
                        handlerChange={handlerChange}
                        next={next}
                    />
                );
            case 2:
                return (
                    <LungCancerSurveyPartTwo 
                        state={state}
                        handlerChange={handlerChange}
                        next={next}
                        preview={preview}
                    />
                );   
            case 3:
                return (
                    <LungCancerSurveyPartThree 
                        state={state}
                        handlerChange={handlerChange}
                        next={next}
                        preview={preview}
                    />
                ); 
            case 4:
                return (
                    <LungCancerSurveyPartFour 
                        state={state}
                        handlerChange={handlerChange}
                        next={next}
                        preview={preview}
                    />
                );   
            case 5:
                return (
                    <LungCancerSurveyPartFive 
                        state={state}
                        handlerChange={handlerChange}
                        next={next}
                        preview={preview}
                    />
                );   
            case 6:
                return (
                    <LungCancerSurveyPartSix 
                        state={state}
                        handlerChange={handlerChange}
                        next={next}
                        preview={preview}
                    />
                );   
            case 7:
                return (
                    <LungCancerSurveyPartSeven 
                        state={state}
                        handlerChange={handlerChange}
                        submit={submit}
                        preview={preview}
                    />
                );                           
            default: 
                return null;
        }
    }

    const cancerProstataStructure = step => {
        switch(step) {
            case 1:
                return (
                    <ProstateCancerSurveyPartOne 
                        state={state}
                        handlerChange={handlerChange}
                        next={next}
                    />
                );
            case 2:
                return (
                    <ProstateCancerSurveyPartTwo 
                        state={state}
                        handlerChange={handlerChange}
                        next={next}
                        preview={preview}
                    />
                );   
            case 3:
                return (
                    <ProstateCancerSurveyPartThree 
                        state={state}
                        handlerChange={handlerChange}
                        next={next}
                        preview={preview}
                    />
                ); 
            case 4:
                return (
                    <ProstateCancerSurveyPartFour 
                        state={state}
                        handlerChange={handlerChange}
                        next={next}
                        preview={preview}
                    />
                );   
            case 5:
                return (
                    <ProstateCancerSurveyPartFive 
                        state={state}
                        handlerChange={handlerChange}
                        next={next}
                        preview={preview}
                    />
                );   
            case 6:
                return (
                    <ProstateCancerSurveyPartSix 
                        state={state}
                        handlerChange={handlerChange}
                        next={next}
                        preview={preview}
                    />
                );   
            case 7:
                return (
                    <ProstateCancerSurveyPartSeven 
                        state={state}
                        handlerChange={handlerChange}
                        next={next}
                        preview={preview}
                    />
                );
            case 8:
                return (
                    <ProstateCancerSurveyPartEight 
                        state={state}
                        handlerChange={handlerChange}
                        submit={submit}
                        preview={preview}
                    />
                );                
            default:
                return null;
        }
    }
    
    return {
        state,
        isAgeRange: ageRangeByTypeOfcancer[type],
        healthForecastLink: healthForecastLinks[healthForecast] || null,
        flowCut,
        finishFlow,
        isMedicalOrder,
        saveCancerSurvey,
        cancerPrescription,
        riskGroup,
        cancerCalculation,
        cancerMamaStructure,
        cancerCervixStructure,
        cancerColonStructure,
        cancerProstataStructure,
        cancerPulmonStructure,
    }
}

export default useSurvey;
