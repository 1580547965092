import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Container, Row, Col, Card, Form, Button} from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
//components
import {HappContainer} from 'happ-components/HappStyledComponents/HappContainer/HappContainer';
import {FormError} from 'happ-components/HappStyledComponents/HappFormStyles/HappFormStyles';
import logo from 'assets/imgs/logo-happ-heart.svg';
import LoginLoader from 'happ-components/Loader/LoginLoader';
//custom-hooks
import useValidatorFrom from 'happ-custom-hooks/useValidatorForm/useValidatorForms';
//actions
import {emailForgotPasswordAction} from 'happ-redux/happ-actions/auth/authActions';

const initState = {
    dni: ''
}
const ForgotPassword = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        state,
        errors,
        handlerSubmit,
        handlerChange,
        handlerChangeDni
    } = useValidatorFrom(initState, emailForgotPassword);
    const {dni} = state;
    const {forgetPasswordErrorMsg, forgetPasswordErrorServer, emailPatient, emailErrorPatient} = useSelector(state => state.auth);
    function emailForgotPassword(){
        dispatch(emailForgotPasswordAction(dni, navigate));
    }       
    console.log(emailPatient);
    console.log(emailErrorPatient);
    return ( 
        <Container>
            <LoginLoader/> 
                <Row className="vh-100 d-flex justify-content-center align-items-center">
                    <Col md={8} lg={6} xs={12}>
                        <HappContainer>
                            <Card className="shadow-box border-box">
                                <Card.Body className='card-login'>
                                    <div className="mb-3 mt-md-4">
                                        <div className="content-logo">
                                            <img src={logo} alt="description"/>
                                        </div>
                                    </div>
                                    <Form 
                                        className="form-action"
                                        onSubmit={handlerSubmit}
                                    >
                                        <div className="text-center">
                                            Recupera o cambia tu contraseña
                                            <br></br><br></br>
                                        </div>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <div className="text-center">
                                                Ingresa tu RUT
                                                <br></br><br></br>
                                            </div>
                                            <Form.Control 
                                                type="text" 
                                                onChange={handlerChangeDni}
                                                value={dni}
                                                placeholder="11111111-1" 
                                                name="dni" 
                                            />
                                            {errors.dni ? (
                                                <FormError>{errors.dni}</FormError>
                                            ) : null}
                                            {forgetPasswordErrorServer && forgetPasswordErrorMsg.dni ? (
                                                <FormError>{forgetPasswordErrorMsg.dni}</FormError>
                                            ) : null}
                                        </Form.Group>

                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicCheckbox"
                                        >
                                        <div className="text-center">
                                            <Link className="primary-color" to="/login">Iniciar sesión</Link>
                                        </div>    
                                        </Form.Group>

                                        <div className="d-grid">
                                            <br></br>
                                            <Button 
                                                className="primary-bg-color" 
                                                type="submit"
                                            >
                                                Continuar
                                            </Button>
                                        </div>
                                    </Form>    
                            </Card.Body>
                        </Card>
                    </HappContainer>
                </Col>
            </Row>
        </Container>
    );
}
 
export default ForgotPassword;