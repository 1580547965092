import {
    INIT_MEDICAL_SCHEDULE,
    MEDICAL_SCHEDULE,
    ERROR_MEDICAL_SCHEDULE,
    LOGOUT,
    INIT_GET_MEDICAL_SCHEDULES_BY_BRANCH_PATIENT,
    GET_MEDICAL_SCHEDULES_BY_BRANCH_PATIENT,
    ERROR_GET_MEDICAL_SCHEDULES_BY_BRANCH_PATIENT,
    INIT_CREATE_MEDICAL_SCHEDULE,
    CREATE_MEDICAL_SCHEDULE,
    ERROR_CREATE_MEDICAL_SCHEDULE,
    INIT_GET_CURRENT_MEDICAL_SCHEDULE,
    GET_CURRENT_MEDICAL_SCHEDULE,
    ERROR_GET_CURRENT_MEDICAL_SCHEDULE,
    ACTIVE_SCHEDULE_FORM,
    INIT_UPDATE_MEDICAL_SCHEDULE,
    UPDATE_MEDICAL_SCHEDULE,
    ERROR_UPDATE_MEDICAL_SCHEDULE,
    ERROR_ACTIVE_SCHEDULE_FORM,
    INIT_DELETE_MEDICAL_SCHEDULE,
    DELETE_MEDICAL_SCHEDULE,
    INIT_ACTIVE_SCHEDULE_FORM,
    ERROR_DELETE_MEDICAL_SCHEDULE
} from 'happ-redux/happ-types';

const initialState = {
    loading: false,
    medicalSchedule: null,
    determinations: null,
    allGoodStatus: false,
    errorServer: false,
    errorMsg: null,
    objLength: {},
    //New Schedule
    origin: null,
    arrayDays: null,
    arrayHours: null,
    arrayLabs: null,
    schedule: false,
    currentMedicalScheduleStatus: false,
    currentMedicalScheduleStatusLoader: false,
    currentMedicalSchedule: null,
    currentMedicalScheduleId: null,
    deleteErrorMsg: null,
    activeForm: null,
    updateStatus: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case INIT_MEDICAL_SCHEDULE:   
        case INIT_GET_MEDICAL_SCHEDULES_BY_BRANCH_PATIENT:  
        case INIT_GET_CURRENT_MEDICAL_SCHEDULE:      
        case INIT_DELETE_MEDICAL_SCHEDULE:
            return {
                ...state,
                loading: true,
            }
        case INIT_ACTIVE_SCHEDULE_FORM:
            return {
                ...state,
                updateStatus: null,
            }    
        case INIT_CREATE_MEDICAL_SCHEDULE:   
        case INIT_UPDATE_MEDICAL_SCHEDULE:   
            return {
                ...state,
                currentMedicalScheduleStatusLoader: true,
            }
        case MEDICAL_SCHEDULE:
            return {
                ...state,
                loading: false,
                errorServer: false,
                errorMsg: null,
                allGoodStatus: action.payload.allGoodStatus,
                medicalSchedule: action.payload.medicalAgenda,
                determinations: action.payload.determinationsResults,
                objLength: action.payload.objLength
            }
        case ERROR_MEDICAL_SCHEDULE:
            return {
                ...state,
                loading: false,
                allGoodStatus: false,
                objLength: {},
                determinations: null,
                medicalSchedule: null,
                errorServer: true,
                errorMsg: action.payload
            }        
        case LOGOUT:
            localStorage.clear();
            return {
                loading: false,
                objLength: {},
                medicalSchedule: null,
                determinations: null,
                allGoodStatus: false,
                errorServer: false,
                errorMsg: null,
            }  
        case ERROR_GET_MEDICAL_SCHEDULES_BY_BRANCH_PATIENT:
            return {
                ...state,
                loading: false,
                origin: null,
                arrayDays: null,
                arrayHours: null,
                arrayLabs: null,
                schedule: null,
            }  
        case UPDATE_MEDICAL_SCHEDULE:  
        case CREATE_MEDICAL_SCHEDULE:
            return{
                ...state,
                loading: false,
                currentMedicalScheduleStatus: true,
                currentMedicalSchedule: action.payload,
                currentMedicalScheduleStatusLoader: false,
            } 
        case ERROR_UPDATE_MEDICAL_SCHEDULE:    
        case ERROR_CREATE_MEDICAL_SCHEDULE:
            return {
                ...state,
                loading: false,
                currentMedicalScheduleStatus: false,
                currentMedicalSchedule: null,
                currentMedicalScheduleStatusLoader: false,
            }   
        case GET_MEDICAL_SCHEDULES_BY_BRANCH_PATIENT:
            return {
                ...state,
                loading: false,
                arrayDays: action.payload.arrayDays,
                arrayHours: action.payload.arrayHours,
                arrayLabs: action.payload.arrayLabs,
                origin: action.payload.origin,
                schedule: action.payload.schedule,
            }        
        case GET_CURRENT_MEDICAL_SCHEDULE:
            return {
                ...state,
                loading: false,
                currentMedicalScheduleStatus: action.payload.currentMedicalScheduleStatus,
                currentMedicalSchedule: action.payload.schedule,
                arrayDays: action.payload.schedulesActives.arrayDays,
                arrayHours: action.payload.schedulesActives.arrayHours,
                arrayLabs: action.payload.schedulesActives.arrayLabs,
                origin: action.payload.schedulesActives.origin,
                schedule: action.payload.schedulesActives.schedule,
            }
        case ERROR_GET_CURRENT_MEDICAL_SCHEDULE:
            return {
                ...state,
                loading: false,
                arrayDays: action.payload.schedulesActives.arrayDays,
                arrayHours: action.payload.schedulesActives.arrayHours,
                arrayLabs: action.payload.schedulesActives.arrayLabs,
                origin: action.payload.schedulesActives.origin,
                schedule: action.payload.schedulesActives.schedule,
            }  
        case ACTIVE_SCHEDULE_FORM:
            return {
                ...state,
                currentMedicalScheduleStatus: false,
                currentMedicalScheduleId: state.currentMedicalSchedule._id,
                arrayDays: action.payload.arrayDays,
                arrayHours: action.payload.arrayHours,
                arrayLabs: action.payload.arrayLabs,
                origin: action.payload.origin,
                schedule: action.payload.schedule,
                activeForm: true,
                updateStatus: true,
            }  
        case ERROR_ACTIVE_SCHEDULE_FORM:
            return {
                ...state,
                currentMedicalScheduleStatus: false,
                currentMedicalScheduleId: null,
                arrayDays: null,
                arrayHours: null,
                arrayLabs: null,
                origin: null,
                schedule: false,
                activeForm: false,
                updateStatus: false,
            }        
        case DELETE_MEDICAL_SCHEDULE:
            return {
                ...state,
                loading: false,
                currentMedicalScheduleStatus: false,
                currentMedicalSchedule: null,
                currentMedicalScheduleId: null,
            }
        case ERROR_DELETE_MEDICAL_SCHEDULE:
            return {
                ...state,
                loading: false,
                deleteErrorMsg: 'No se pudo eliminar la cita',
            }            
        default:
            return state;
    }
}