import React from 'react';
import MyDiagnoses from './TabsContent/MyDiagnoses';
import MyTreatment from './TabsContent/MyTreatment';
import MyImprovementPlan from './TabsContent/MyImprovementPlan';
import MyControl from './TabsContent/MyControl';
import {Nav, Tab} from 'react-bootstrap';

const Tabs = ({
        medicalStageResults,
        lastPrescription,
        lastConsultation,
        otherExams,
        metaExams,
        isMetaExams
    }) => {
    return (
        <Tab.Container defaultActiveKey={isMetaExams ? 'cuatro' : 'uno'} className="" >
            {!isMetaExams ? (
                <div style={{ borderRadius: 20, backgroundColor: '#c7dff0' }}>
                    <Nav variant="pills" className="justify-content-between nav-title-tabs"> {/*de 436px abajo achico el font */}
                        <Nav.Item>
                            <Nav.Link style={{ borderRadius: 20 }} eventKey="uno">Mis diagnósticos</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link style={{ borderRadius: 20 }} eventKey="dos">Mi tratamiento</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link style={{ borderRadius: 20 }} eventKey="tres">Mi plan de mejora</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
            ) : null}
            {isMetaExams ? (
                <div style={{ borderRadius: 20, backgroundColor: '#c7dff0', width: '60%', margin: '0 auto' }}>
                    <Nav variant="pills" className="justify-content-between nav-title-tabs"> {/*de 436px abajo achico el font */}
                        <Nav.Item>
                            <Nav.Link style={{ borderRadius: 20 }} eventKey="cuatro">Mis resultados</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link style={{ borderRadius: 20 }} eventKey="dos">Mi tratamiento</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
            ) : null}

            <Tab.Content className="my-2 text-center " >
                <Tab.Pane eventKey="uno" className="tab-pane-custom" style={{ backgroundColor: '#c7dff0', borderRadius: 5 }}>
                    <MyDiagnoses 
                        medicalStageResults={medicalStageResults}
                    />
                </Tab.Pane>
                <Tab.Pane eventKey="dos" className="tab-pane-custom" style={{ backgroundColor: '#c7dff0', borderRadius: 5 }}>
                    <MyTreatment 
                        lastPrescription={lastPrescription}
                        lastConsultation={lastConsultation}
                        otherExams={otherExams}
                    />
                </Tab.Pane>
                <Tab.Pane eventKey="tres" className="tab-pane-custom" style={{ backgroundColor: '#c7dff0', borderRadius: 5 }}>
                    <MyImprovementPlan />
                </Tab.Pane>
                <Tab.Pane eventKey="cuatro" className="tab-pane-custom" style={{ backgroundColor: 'rgb(199 223 240 / 28%)', borderRadius: 5 }}>
                    <MyControl
                        isMetaExams={isMetaExams}
                    />
                </Tab.Pane>
            </Tab.Content>
        </Tab.Container>
    );
}

export default Tabs;