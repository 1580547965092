import React, { useState } from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import { HappContainer } from 'happ-components/HappStyledComponents/HappContainer/HappContainer';
import useAuth from 'happ-custom-hooks/useAuth/useAuth';
import useSurvey from 'happ-custom-hooks/useSurvey/useSurvey';
import FlowCutView from 'happ-components/LungCancerSurveyParts/FlowCutView';
import FlowFinishView from 'happ-components/LungCancerSurveyParts/FlowFinishView';

const initState = {
    step: 1,
    progress: Math.round((1 * 100) / 7),
    question1: -1,
    question2: -1,
    question3: -1,
    question4: -1,
    question5: -1,
    question6: -1,
    question7: -1,
    total: 7,
}

const LungCancerSurvey = () => {
    const { 
        gender, 
        patientAge, 
        profile, 
        healthForecast 
    } = useAuth();
    const { 
        state,
        flowCut,
        finishFlow,
        isMedicalOrder,
        cancerPrescription,
        riskGroup,
        cancerCalculation,
        healthForecastLink,
        cancerPulmonStructure,
    } = useSurvey('cancer-pulmon', patientAge, healthForecast, false, gender, initState, profile?._id);
    const { step, progress } = state;
    return ( 
        <Row className="mt-content">
            <HappContainer>
                
                {(finishFlow !== null && finishFlow === true) || (riskGroup !== null && riskGroup.calculation === 'grupo-riesgo') ? (
                    <FlowFinishView 
                        isMedicalOrder={isMedicalOrder}
                        healthForecastLink={healthForecastLink}
                        healthForecast={healthForecast}
                        cancerPrescription={cancerPrescription}
                        cancerCalculation={cancerCalculation}
                    />
                ) : (flowCut !== null && flowCut === true) || (riskGroup !== null && riskGroup.calculation === 'no-grupo-riesgo') ? (
                    <FlowCutView />
                ) : (
                    <>
                        <Col className='pt-2'>
                            <div className='d-flex justify-content-center'>
                                <div className='text-center px-5 py-2 text-white primary-bg-color  rounded-1 indicators-content'>
                                    <strong className='title-stages' >Encuesta Cáncer de Pulmón</strong>
                                </div>
                            </div>
                        </Col>
                        <Col md={9} sm={12} lg={9} className='progress-bar-content'>
                            <ProgressBar now={progress} />
                        </Col>
                        {cancerPulmonStructure(step)}
                    </>
                )} 
            </HappContainer>
        </Row>
    );
}
 
export default LungCancerSurvey;