import React from 'react';
import { HappContentIndicator } from 'happ-components/HappStyledComponents/HappContentIndicator/HappContentIndicator'
import { Row, Col } from 'react-bootstrap';
import { Recipe, Consultation, OtherIndications, MyTreatmentMessage } from 'happ-icons/icons';

const MyTreatment = ({
    lastPrescription,
    lastConsultation,
    otherExams
}) => {
    console.log(lastPrescription);
    const handleClick = (file) => {
        if (file) {
            window.open(file, '_blank'); 
        }
    };
    return ( 
        <>
            <Row className="pt-3">
                <div className="d-flex justify-content-between">
                    <HappContentIndicator
                        className={'box-content-flex box-indicator bg-white mb-4'}
                        onClick={() => handleClick(lastPrescription)}
                        role="button"
                    >
                        <Col>
                            <Row className='mt-3' >
                                <Recipe
                                    fill={lastPrescription === null ? '#7C7C7C' : '#04477A'}
                                    responsive='scale-mobile'
                                />
                            </Row>
                            <div style={{ fontSize: '10px', lineHeight: '17px' }} className='primary-color-text'>Medicamentos</div>
                            <Row className={lastPrescription === null ? 'dark-opacity border-bottom-container' : 'border-bottom-container-exam blue-border'}>
                                <Col >
                                    <p style={{ fontWeight: '400' }}> ver más</p>
                                </Col>
                            </Row>
                        </Col>
                    </HappContentIndicator>
                    <HappContentIndicator
                        className={'box-content-flex box-indicator bg-white mb-4'}
                        onClick={() => handleClick(lastConsultation)}
                        role="button"
                    >
                        <Col>
                            <Row className='mt-3' >

                                <Consultation
                                    fill={lastConsultation === null ? '#7C7C7C' : '#04477A'}
                                    responsive='scale-mobile'
                                />

                            </Row>
                            <div style={{ fontSize: '10px', lineHeight: '17px' }} className='primary-color-text'>Interconsultas</div>

                            <Row className={lastConsultation === null ? 'dark-opacity border-bottom-container' : 'border-bottom-container-exam blue-border'}>
                                <Col >
                                    <p style={{ fontWeight: '400' }}> ver más</p>
                                </Col>
                            </Row>
                        </Col>
                    </HappContentIndicator>
                    <HappContentIndicator
                        className={'box-content-flex box-indicator bg-white mb-4'}
                        onClick={() => handleClick(otherExams)}
                        role="button"
                    >
                        <Col>
                            <Row className='mt-3' >
                                <OtherIndications
                                    fill={otherExams === null ? '#7C7C7C' : '#04477A'}
                                    // #7C7C7C
                                    responsive='scale-mobile'
                                />
                            </Row>
                            <div style={{ fontSize: '10px', lineHeight: '17px' }} className='primary-color-text'>Otras indicaciones</div>
                            <Row className={otherExams === null ? 'dark-opacity border-bottom-container' : 'border-bottom-container-exam blue-border'}>
                                <Col >
                                    <p style={{ fontWeight: '400' }}> ver más</p>
                                </Col>
                            </Row>
                        </Col>
                    </HappContentIndicator>
                </div>
            </Row>
            <div className='d-flex justify-content-center px-1 treatment-icon-content'>
                <MyTreatmentMessage />
            </div>
        </>
    );
}
 
export default MyTreatment;