import {
    INIT_GET_PHQ9_CALCULATION,
    GET_PHQ9_CALCULATION,
    INIT_GET_LAST_PRESCRIPTION,
    GET_LAST_PRESCRIPTION,
    INIT_SEND_MENTAL_HEALTH_SURVEY,
    SEND_MENTAL_HEALTH_SURVEY,
    ERROR_SEND_MENTAL_HEALTH_SURVEY,
    CLEAN_SURVEY
} from 'happ-redux/happ-types';
import { 
    getPHQ9Calculation, 
    getMentalHealthPrescription, 
    mentalHealthSurvey 
} from 'happ-redux/happ-services/mental-health/mental-health';

function surveyTargets(question, value) {
    const surveyQuestions = {
        questionOne: "Tener poco interés o placer en hacer las cosas.",
        questionTwo: "Sentirse desanimado, deprimido o sin esperanza.",
        questionThree: "Con problemas en dormirse o en mantenerse dormido, o en dormir temprano.",
        questionFour: "Sentirse cansado o tener poca energía",
        questionFive: "Tener poco apetito o comer en exceso",
        questionSix: "Sentir falta de amor propio o que sea un fracaso, que decepcionara a si mismo o a su familia",
        questionSeven: "Tener dificultad para concentrarse en cosas tales como leer el periódico o ver televisión",
        questionEight: "Se mueve o habla tan lentamente que otras personas podrían darse cuenta, o de lo contrario, esta tan agitado que se mueve mucho mas de lo acostumbrado",
        questionNine: "Se le han ocurrido pensamientos de que seria mejor estar muerto o de que se haría daño de alguna manera.",
    } 
    const setValues = {
        ['Nunca']: 0,
        ['Varios días']: 1,
        ['Mas de la mitad de los días']: 2,
        ['Casi todos los días']: 3,
    }
    return {
        question: surveyQuestions[question] || null,
        value: setValues[value] === 0 ? 0 : setValues[value] || null,
    };
}

function surveyStructure(survey) {
    const structure = [];
    Object.keys(survey).map(item => {
        if (item !== 'step' && item !== 'progress') {
            const question = surveyTargets(item, survey[item]);
            if (question.question || question.value) {
                structure.push({
                    item: question.question,
                    value: survey[item],
                    number: question.value
                });
            }
        }
    });
    return structure;
}

export function getPHQ9CalculationAction(patient) {
    return async dispatch => {
        dispatch(initGetPHQ9());
        try {
            const response = await getPHQ9Calculation(patient);
            // console.log(response.data.phq9);
            if (response.data.status === 200) {
                dispatch(getPHQ9(response.data.phq9));
            } else {
                dispatch(errorGetPHQ9(null));
            }
        } catch (error) {
            dispatch(errorGetPHQ9(null));
        }
    }
}

export function getMentalHelathPrescriptionAction(patient) {
    return async dispatch => {
        dispatch(initMentalHealthPrescription());
        try {
            const response = await getMentalHealthPrescription(patient, 'psychologist');
            if (response.data.status === 200) {
                dispatch(mentalHealthPrescription(response.data));
            } else {
                dispatch(errorMentalHealthPrescription(null));
            }
        } catch (error) {
            dispatch(errorMentalHealthPrescription(null));
        }
    }
}

export function sendMentalHealthSurveyAction(survey, patient, type, name) {
    return async dispatch => {
        dispatch(initSendSurvey());
        const structure = surveyStructure(survey);
        if (structure.length === 0) {
            dispatch(errorSendSurvey());
        }
        try {
            const response = await mentalHealthSurvey(structure, patient, type, name);
            if (response.status === 201) {
                dispatch(sendSurvey());
                // setTimeout(() => {
                //     dispatch(cleanSurvey());
                // }, 2000);
            } else {
                dispatch(errorSendSurvey());
                // setTimeout(() => {
                //     dispatch(cleanSurvey());
                // }, 2000);
            }
        } catch (error) {
            dispatch(errorSendSurvey());
            // setTimeout(() => {
            //     dispatch(cleanSurvey());
            // }, 2000);
        }
    }
}

const initGetPHQ9 = () => ({
    type: INIT_GET_PHQ9_CALCULATION,
});
const getPHQ9 = response => ({
    type: GET_PHQ9_CALCULATION,
    payload: response,
});
const errorGetPHQ9 = response => ({
    type: GET_PHQ9_CALCULATION,
    payload: response,
});
const initMentalHealthPrescription = () => ({
    type: INIT_GET_LAST_PRESCRIPTION,
});
const mentalHealthPrescription = response => ({
    type: GET_LAST_PRESCRIPTION,
    payload: response,
});
const errorMentalHealthPrescription = response => ({
    type: GET_LAST_PRESCRIPTION,
    payload: response,
});
const initSendSurvey = () => ({
    type: INIT_SEND_MENTAL_HEALTH_SURVEY,
});
const sendSurvey = () => ({
    type: SEND_MENTAL_HEALTH_SURVEY,
});
const errorSendSurvey = () => ({
    type: ERROR_SEND_MENTAL_HEALTH_SURVEY,
});
const cleanSurvey = () => ({
    type: CLEAN_SURVEY
});