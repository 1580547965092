import React from 'react';
import Diabetes from './Diabetes';
import Dyslipidemia from './Dyslipidemia';
import Hypertension from './Hypertension';
import Imc from './Imc';

const ResultsTableControl = ({
    results,
    determinationWithControl, 
    msgPathology, 
    code, 
}) => {
    return (
        <>
        {code === 'hipertension' ? (
            <Hypertension 
                determinationWithControl={determinationWithControl}
                results={results}
                msgPathology={msgPathology}

            />
        ) : null}
        {code === 'diabetes' || code === 'pre-diabetes' ? (
            <Diabetes 
                determinationWithControl={determinationWithControl}
                results={results}
                msgPathology={msgPathology}

            />
        ) : null}
        {code === 'dislipidemia' ? (
            <Dyslipidemia 
                determinationWithControl={determinationWithControl}
                results={results}
                msgPathology={msgPathology}

            />
        ) : null}
        {code === 'obesidad' ? (
            <Imc 
                determinationWithControl={determinationWithControl}
                results={results}
                msgPathology={msgPathology}

            />
        ) : null}
        </>
     );
}
 
export default ResultsTableControl;