import React, {useState, useEffect} from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useModal } from 'happ-custom-hooks/useModal/useModal'
import moment from 'moment';
import 'moment/min/locales';
import useMedicalSchedule from 'happ-custom-hooks/useMedicalSchedule/useMedicalSchedule';

const HomeScheduleDateInfo = ({medicalStagePrint}) => {
    moment.locale('es');
    const [infoSchedule, setInfoSchedule] = useState(null);
    const {
        currentMedicalScheduleStatus,
        currentMedicalSchedule,
        schedule,
    } = useMedicalSchedule(null);
    const {
        ShowModalSchedulePatient,
        openModal,
    } = useModal();
    useEffect(() => {
        if (medicalStagePrint !== null) {
            const info = getScheduleInfo(medicalStagePrint);
            setInfoSchedule(info);
        }
      }, [medicalStagePrint])
    const getScheduleInfo = medicalStage => {
        const options = {
            'welcome': {
                title: 'Comienza tu viaje',
                description: 'Agenda tu chequeo médico inicial',
            },
            'diagnostic-complementary-one': {
                title: 'Agenda para tu siguiente paso',
                description: '',
            },
            'confirmatory-diagnostic-complementary-one': {
                title: 'Exámenes de confirmación',
                description: 'Tu diagnostico requiere un nuevo examen',
            },
            'medical-teleconsultation': {
                title: 'Telemedicina',
                description: 'Nuestro médico te espera para comenzar tu tratamiento',
            },
            'meta-exams-one': {
                title: 'Exámenes de control',
                description: 'Agenda tus exámenes de control',
            },
        };
        return options[medicalStage] || null;
    }
    return ( 
        <>
        {currentMedicalScheduleStatus && currentMedicalSchedule ? (
            <>
                <Row>
                    <Col className=' text-center primary-color-text'>
                        <h1 className='title-message' ><strong>¡Tu hora está agendada!</strong></h1>
                        {/* <p className='p-success-schedule'> Asiste con ropa cómoda</p> */}
                        <p className='p-success-schedule'> <strong>{moment.utc(currentMedicalSchedule.day).format('dddd, D MMMM YYYY')} a las  {currentMedicalSchedule.hour} Hrs.</strong></p>
                        {medicalStagePrint !== 'medical-teleconsultation' ? (
                            <p>En <strong>{currentMedicalSchedule?.address[0].alias}, {currentMedicalSchedule?.address[0].street}</strong></p>
                        ) : null}
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <Col className=' text-center'>
                        <Link to="/agendar-hora" className='d-flex justify-content-center link'   >
                            <Button className='btn-home-message'>
                                Modificar/Eliminar cita
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </>
        ) : null}
        {schedule && (!currentMedicalScheduleStatus || !currentMedicalSchedule) ? (
            <Row className='mb-5'>
                <Col className=' text-center'>
                        {infoSchedule !== null ? (
                            <>
                                <h1 className='primary-color-text title-message'>{infoSchedule.title}</h1>
                                <p className='primary-color-text subtitle-message'>
                                    {infoSchedule.description}
                                </p>
                            </>
                        ) : null}
                    <Button 
                        onClick={openModal} 
                        className='btn-home-message'
                    >
                        Agendar Hora
                    </Button>
                </Col>
            </Row>
        ) : null}
        <ShowModalSchedulePatient />
        </>
    );
}
 
export default HomeScheduleDateInfo;