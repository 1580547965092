import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Nav, Navbar, NavDropdown } from 'react-bootstrap';

// components
import { NotificationsIcon } from 'happ-icons/icons';
import Notifications from 'happ-components/Notifications/Notifications';
// custom Hooks
import { ProfileImg } from 'happ-icons/icons';
import { BellIcon } from 'happ-icons/icons';
import { SuraLogoDemo } from 'happ-icons/icons';
import useAuth from 'happ-custom-hooks/useAuth/useAuth';

import logoa from '../../assets/imgs/logos-empresas/logo-araucana.png';


const MobileNavBar = () => {
    const [dni, setDni] = useState('');
    const { profile } = useSelector(state => state.auth);
    const { companyDni } = useAuth();

    useEffect(() => {
        if (companyDni !== undefined) {
            setDni(companyDni)
        }
    }, [profile])

    const empresas = {
        '70005730-5': '700057305.png', // alianza
        '79936340-2': '799363402.png', //manager
        '96665000-1': '966650001.png', //eagon
        '99017000-2': 'suraseguros1.png', //sura
        '76263414-7': 'suraseguros1.png', // sura
        '77332926-5': '773329265.png', //happ
        '96976920-4': '96976920-4.png', //enjoy
        '88888001-1': 'Nestle.png', //nestle 
        '77111222-2': 'logo-araucana.png', //nestle 
        '': 'Nestle.png', //nestle 
    };

    const LogoDeEmpresa = (dni) => {
        const logoUrl = `../../assets/imgs/logos-empresas/${empresas[dni]}`;
        return <img src={logoa} style={{ width: '80px,', height: '45px' }} alt={`Logo de empresa con RUT ${dni}`} />;
    }


    return (
        <Navbar bg="white" expand="lg" className="navbar-container-mobile" sticky="top">
            {/* <div className="navbar-content"> */}
            <div>
                <Nav
                    className="navbar-item-mobile justify-content-center align-items-start"

                >
                    {profile ? (
                        <>

                            <div className='d-flex' style={{ width: '100%' }}>
                                <Col className='d-flex' >
                                    <div className='d-flex justify-content-center align-items-center' style={{ width: '65px', height: '65px', marginRight: '20px', borderRadius: '100px' }}>
                                        <ProfileImg />
                                    </div>
                                    <p className='align-self-center'> Hola, <strong>{profile.firstName}</strong></p>
                                </Col>
                                <Col className='d-flex justify-content-end align-self-center w-100'>
                                    {dni === '77111222-2' ? (
                                        <img src={logoa} style={{ width: '80px,', height: '35px' }} alt={`Logo de empresa con RUT ${dni}`} />
                                    ) : null}
                                </Col>
                            </div>

                        </>
                    ) : (
                        <p>Cargando...</p>
                    )}
                </Nav>
                <Nav className="navbar-item-mobile justify-content-center align-items-end">
                    <div>
                        <NotificationsIcon
                            notifications={3}
                        />
                    </div>
                    <NavDropdown title="" id="collasible-nav-dropdown">
                        <Notifications />
                    </NavDropdown>
                </Nav>
            </div>
        </Navbar>
    );
}

export default MobileNavBar;