import { HappContainer } from 'happ-components/HappStyledComponents/HappContainer/HappContainer';
import { Arrows } from 'happ-icons/icons';
import { HappHeartIconList } from 'happ-icons/icons';
import { ShieldHappLogo } from 'happ-icons/icons';
import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData, redirectUri, isAuth } from 'happ-redux/happ-actions/oauth/oauthActions';
import OauthLoader from 'happ-components/Loader/OauthLoader';


const Consent = () => {
  const client_data = useSelector((state) => state.oauth)
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(window.location.search);
  const getAuthParams = () => {
    const client_id = searchParams.get('client_id');
    const client_secret = searchParams.get('client_secret');
    const redirect_uri = searchParams.get('redirect_uri');
    const response_type = searchParams.get('response_type');
    const scope = searchParams.get('scope');
    const authParams = {
      'response_type': response_type,
      'client_id': client_id,
      'client_secret': client_secret,
      'redirect_uri': redirect_uri,
      'scope': scope,
    }
    return (authParams)
  }
  const isLoading = useSelector((state)=> state.oauth.loading);
   useState(() => {
    dispatch(isAuth(getAuthParams()))
    const client_id = searchParams.get('client_id');
    dispatch(fetchData(client_id))
  }, [])

  const onConsent = () => {
    dispatch(redirectUri(getAuthParams()))

  }

  const data = [
    { title: 'Nombre y apellido', icon: '<IdcardOutlined style={{ fontSize: 24 }} /> ' },
    { title: 'Género', icon: '<UserOutlined style={{ fontSize: 24 }} /> ' },
    { title: 'Correo electronico', icon: '<MailOutlined style={{ fontSize: 24 }} />' },
    { title: 'Fecha de nacimiento', icon: '<CalendarOutlined style={{ fontSize: 24 }} />' }
  ]

  return (
    <>
      {isLoading && <OauthLoader />}
      {client_data.client && <Row className="mt-content">
        <HappContainer>
          <Row>
            <Col className='pt-2'>
              <div className='d-flex justify-content-center'>
                <div className='text-center pt-2 text-white primary-bg-color rounded-1 indicators-content'>
                  <strong className='title-stages'>Plan Nutricional y Deportivo</strong>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='pt-5'>
            <div className='d-flex justify-content-center'>
              <ShieldHappLogo /> <Arrows style={{ marginTop: '10px', padding: '5px' }} /> <img className='oauth-client-logo' src={client_data.logo} alt={client_data.client} />
            </div>
          </Row>
          <Row className='pt-4'>
            <div className='d-flex justify-content-center'>
              <h1 className='primary-color-text'>{client_data.client} quiere acceder a:</h1>
            </div>
          </Row>
          <Row className='pt-3'>
            <div className='d-flex justify-content-center'>
              <ul className="custom-list">
                {data.map((data, index) => (
                  <li key={index}>
                    <HappHeartIconList />
                    {data.title}
                  </li>
                ))}
              </ul>
            </div>
          </Row>
          <Row className='pt-5'>
            <Col className='d-flex justify-content-center'>
            <div className='me-4'>
                <Link to="/" className='d-flex justify-content-center link'  >
                  <Button className='shadow-lg danger-bg-color btn-time-success-calendar'>
                    Rechazar
                  </Button>
                </Link>
              </div>
              <div className='ms-4'>
                <Link to="" className='d-flex justify-content-center link'  >
                  <Button onClick={onConsent} className='shadow-lg primary-bg-color btn-time-success-calendar'>
                    Permitir
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <div className='d-flex justify-content-center py-3'>
              <p className='text-start lh-sm pt-2 indicators-content' style={{ fontSize: '12px' }}>Al presionar Permitir, das acceso a la app de Hanu Fit para usar tu información de usuario Happ de acuerdo a sus respectivos términos de servicio y políticas de privacidad. Puedes revocar esta decision en cualquier momento.</p>
            </div>
          </Row>
        </HappContainer>
      </Row>}
    </>
  )
}

export default Consent