import React from 'react';
import SurveyQuestionOne from 'happ-components/SurveyParts/SurveyQuestionOne';
import SurveyQuestionTwo from 'happ-components/SurveyParts/SurveyQuestionTwo';
import SurveyQuestionThree from 'happ-components/SurveyParts/SurveyQuestionThree';
import SurveyQuestionFour from 'happ-components/SurveyParts/SurveyQuestionFour';
import SurveyQuestionFive from 'happ-components/SurveyParts/SurveyQuestionFive';
import SurveyQuestionSix from 'happ-components/SurveyParts/SurveyQuestionSix';
import SurveyQuestionSeven from 'happ-components/SurveyParts/SurveyQuestionSeven';
import SurveyQuestionEight from 'happ-components/SurveyParts/SurveyQuestionEight';
import SurveyQuestionNine from 'happ-components/SurveyParts/SurveyQuestionNine';

const MentalHealthStructure = ({...rest}) => {
    const { survey, surveyError, preview, next, handlerChange, submitSurvey } = rest;
    const {
        step,
        questionOne,
        questionTwo,
        questionThree,
        questionFour,
        questionFive,
        questionSix,
        questionSeven,
        questionEight,
        questionNine,
    } = survey;
    // eslint-disable-next-line default-case
    switch(step) {
        case 1:
            return (
                <SurveyQuestionOne 
                    questionOne={questionOne}
                    next={next}
                    handlerChange={handlerChange}
                    surveyError={surveyError}
                />
            );
        case 2:
            return (
                <SurveyQuestionTwo 
                    questionTwo={questionTwo}
                    next={next}
                    preview={preview}
                    handlerChange={handlerChange}
                    surveyError={surveyError}
                />
            );  
        case 3:
            return (
                <SurveyQuestionThree 
                    questionThree={questionThree}
                    next={next}
                    preview={preview}
                    handlerChange={handlerChange}
                    surveyError={surveyError}
                />
            );    
        case 4:
            return (
                <SurveyQuestionFour 
                    questionFour={questionFour}
                    next={next}
                    preview={preview}
                    handlerChange={handlerChange}
                    surveyError={surveyError}
                />
            ); 
        case 5:
            return (
                <SurveyQuestionFive 
                    questionFive={questionFive}
                    next={next}
                    preview={preview}
                    handlerChange={handlerChange}
                    surveyError={surveyError}
                />
            ); 
        case 6:
            return (
                <SurveyQuestionSix 
                    questionSix={questionSix}
                    next={next}
                    preview={preview}
                    handlerChange={handlerChange}
                    surveyError={surveyError}
                />
            );    
        case 7:
            return (
                <SurveyQuestionSeven 
                    questionSeven={questionSeven}
                    next={next}
                    preview={preview}
                    handlerChange={handlerChange}
                    surveyError={surveyError}
                />
            ); 
        case 8:
            return (
                <SurveyQuestionEight 
                    questionEight={questionEight}
                    next={next}
                    preview={preview}
                    handlerChange={handlerChange}
                    surveyError={surveyError}
                />
            ); 
        case 9:
            return (
                <SurveyQuestionNine 
                    questionNine={questionNine}
                    submitSurvey={submitSurvey}
                    preview={preview}
                    handlerChange={handlerChange}
                    surveyError={surveyError}
                />
            );                                                                                                                                    
    }
}
 
export default MentalHealthStructure;