import React from 'react';
import { Col, Button } from 'react-bootstrap';
import { MentalHealthPsychiatristicon } from 'happ-icons/icons';

const MentalHealthPsychiatrist = () => {
    return ( 
        <div className='mb-5'>
            <Col md={9} sm={12} lg={9} className='pt-2 result-content'>
                <div className='d-flex justify-content-center'>
                    <div className='text-center px-5 py-2 text-white primary-bg-color  rounded-1 indicators-content'>
                        <strong className='title-stages' >Resultados Encuesta de Salud</strong>
                    </div>
                </div>
            </Col>
            <Col md={9} sm={12} lg={9} className='mt-4 text-center result-content '>
                <p className='result-text'>Gracias por tomarte el tiempo de completar nuestra encuesta, tu bienestar físico y mental son nuestra prioridad.</p>
            </Col>
            <Col md={9} sm={12} lg={9} className='mt-4 text-center result-content'>
                <p className='result-text'>Los resultados  indican que podrías estar atravesando algunas situaciones emocionales que podrían requerir un mayor apoyo y acompañamiento.</p>
            </Col>
            <Col md={9} sm={12} lg={9} className='mt-4 text-center result-content result-icon'>
                <MentalHealthPsychiatristicon />
            </Col>
            <Col md={9} sm={12} lg={9} className='mt-5 text-center result-content '>
                <p className='result-text'>
                    Por ello, te invitamos a agendar una teleconsulta con un médico de nuestro equipo Happ, 
                    el cual te guiará en este proceso
                </p>
            </Col>
            <Col md={12} sm={12} lg={12} className='mt-5 text-center result-content'>
                <Button
                    className='shadow-lg primary-bg-color'
                >
                    Agendar Telemedicina
                </Button>
            </Col>
        </div>
    );
}
 
export default MentalHealthPsychiatrist;