import React from 'react';
import { DiagnosticModalIcon } from 'happ-icons/icons';
import { CloseModalBlue } from 'happ-icons/icons';
import { deleteScheduledTime } from 'happ-redux/happ-actions/schedule/scheduleAction';
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom';
import { removeScheduleAction } from 'happ-redux/happ-actions/medismartSchedule/medismartScheduleAction';
const DeleteMedismartModal = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { attention } = useSelector(state => state.medismart);
    const handleDelete = () => {
        if (attention) {
            dispatch(removeScheduleAction(attention, navigate))
        }
    }
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className='modal-content-white'>
                <Row className=''>
                    <Col className='mt-1'>
                        <div onClick={props.onHide} className='modal-close-btn primary-color-text'>
                            <CloseModalBlue
                            />
                        </div>
                        <div className='text-center'>
                            <DiagnosticModalIcon fill="#044064" className="my-2 ms-3" />
                        </div>
                    </Col>
                </Row>
                <Modal.Body style={{ padding: 0 }}>
                    <Row className='text-center'>
                        <Col>
                            <h6 className='primary-color-text fw-bold'>¿Estás seguro que deseas eliminar tu hora?</h6>
                            <div className='d-flex justify-content-center py-3'>
                        
                                    <Button onClick={handleDelete} className='primary-bg-color btn-delete'>
                                        <p>Sí</p>
                                    </Button>
                            
                                <div onClick={props.closeModal} className='ms-2' >
                                    <Button className='shadow danger-bg-color btn-delete'>
                                        <p>No</p>
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </div>
        </Modal>
    )
}
 
export default DeleteMedismartModal;