import {
    INIT_GET_SCHEDULE_AVAILABILITY,
    GET_SCHEDULE_AVAILABILITY,
    ERROR_GET_SCHEDULE_AVAILABILITY,
    INIT_GET_FILTER_SCHEDULES,
    GET_FILTER_SCHEDULES,
    ERROR_GET_FILTER_SCHEDULES,
    INIT_CONFIRM_SCHEDULE,
    CONFIRM_SCHEDULE,
    ERROR_CONFIRM_SCHEDULE,
    INIT_CHECK_SCHEDULES,
    CHECK_SCHEDULES,
    ERROR_CHECK_SCHEDULES,
    INIT_CURRENT_SCHEDULES,
    CURRENT_SCHEDULES,
    ERROR_CURRENT_SCHEDULES,
    INIT_DELETE_SCHEDULE_M,
    DELETE_SCHEDULE_M,
    ERROR_DELETE_SCHEDULE_M
} from 'happ-redux/happ-types';
import { 
    getMedismartSchedules, 
    confirmMedismartSchedule,
    checkMedismartSchedules,
    getCurrentMedismartSchedule, 
    deleteMedismartSchedule,
    filterMedismartSchedules,
} from 'happ-redux/happ-services/medismart/medismart-services';

export function getMedismartSchedulesAction(dni, navigate) {
    return async dispatch => {
        dispatch(initMedismartSchedules());
        try {
            const token = localStorage.getItem('token');
            if (token === null){
                navigate('/login');
            }
            const response = await getMedismartSchedules(dni, token);
            if (response.data.status === 200) {
                dispatch(medismartSchedules(response.data));
            } else {
                dispatch(errorMedismartSchedules());
            }
        } catch (error) {
            dispatch(errorMedismartSchedules());
        }
    }
}

export function filterSchedulesAction(params, navigate) {
    return async dispatch => {
        dispatch(initFilter());
        try {
            const token = localStorage.getItem('token');
            if (token === null){
                navigate('/login');
            }
            const response = await filterMedismartSchedules(params, token);
            if (response.data.status === 200) {
                dispatch(filterSchedule(response.data));
            } else {
                dispatch(errorFilter());
            }
        } catch (error) {
            dispatch(errorFilter());
        }
    }
}

export function confirmMedismartScheduleAction(params, navigate) {
    return async dispatch => {
        dispatch(initConfirm())
        try {
            const token = localStorage.getItem('token');
            if (token === null){
                navigate('/login');
            }
            const response = await confirmMedismartSchedule(params, token);
            console.log(response);
            if (response.data.status === 200) {
                dispatch(confirmSchedule(response.data));
            } else {
                dispatch(errorConfirmSchedule())
            }
        } catch (error) {
            
        }
    }
}

export function checkMedismartSchedulesAction(dni, navigate) {
    return async dispatch => {
        dispatch(initCheckMedismartSchedules());
        try {
            const token = localStorage.getItem('token');
            if (token === null){
                navigate('/login');
            }
            const response = await checkMedismartSchedules(dni, token);
            if (response.data.status === 200) {
                dispatch(checkSchedules());
            } else {
                dispatch(errorCheckMedismartSchedules());
            }
        } catch (error) {
            dispatch(errorCheckMedismartSchedules());
        }
    }
}

export function getCurrentMedismartScheduleAction(_id, navigate) {
    return async dispatch => {
        dispatch(initCurrentMedismartSchedules());
        try {
            const token = localStorage.getItem('token');
            if (token === null){
                navigate('/login');
            }
            const response = await getCurrentMedismartSchedule(_id, token);
            if (response.data.status === 200) {
                dispatch(currentSchedules(response.data));
            } else {
                dispatch(errorCurrentMedismartSchedules());
            }
        } catch (error) {
            dispatch(errorCurrentMedismartSchedules());
        }
    }
}

export function removeScheduleAction(attention, navigate) {
    return async dispatch => {
        dispatch(initRemove());
        try {
            const token = localStorage.getItem('token');
            if (token === null){
                navigate('/login');
            }
            const response = await deleteMedismartSchedule(attention, token);
            if (response.data.status === 200) {
                dispatch(remove());
                navigate('/');
            } else {
                dispatch(errorRemove());
            }    
        } catch (error) {
            dispatch(errorRemove());
        }
    } 
}


const initFilter = () => ({
    type: INIT_GET_FILTER_SCHEDULES,
});

const filterSchedule = response => ({
    type: GET_FILTER_SCHEDULES,
    payload: response,
});

const errorFilter = error => ({
    type: ERROR_GET_FILTER_SCHEDULES,
    payload: error,
});

const initCurrentMedismartSchedules = () => ({
    type: INIT_CURRENT_SCHEDULES,
});

const currentSchedules = response => ({
    type: CURRENT_SCHEDULES,
    payload: response,
});

const errorCurrentMedismartSchedules = () => ({
    type: ERROR_CURRENT_SCHEDULES,
});

const initCheckMedismartSchedules = () => ({
    type: INIT_CHECK_SCHEDULES,
});

const checkSchedules = () => ({
    type: CHECK_SCHEDULES,
});

const errorCheckMedismartSchedules = () => ({
    type: ERROR_CHECK_SCHEDULES,
});

const initMedismartSchedules = () => ({
    type: INIT_GET_SCHEDULE_AVAILABILITY,
});

const medismartSchedules = response => ({
    type: GET_SCHEDULE_AVAILABILITY,
    payload: response,
});

const errorMedismartSchedules = () => ({
    type: ERROR_GET_SCHEDULE_AVAILABILITY,
});

const initConfirm = () => ({
    type: INIT_CONFIRM_SCHEDULE,
});

const confirmSchedule = response => ({
    type: CONFIRM_SCHEDULE,
    payload: response
});

const errorConfirmSchedule = () => ({
    type: ERROR_CONFIRM_SCHEDULE,
});

const initRemove = () => ({
    type: INIT_DELETE_SCHEDULE_M,
});

const remove = () => ({
    type: DELETE_SCHEDULE_M,
});

const errorRemove = () => ({
    type: ERROR_DELETE_SCHEDULE_M,
});