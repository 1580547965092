import React from 'react';
import {Row, Col} from 'react-bootstrap';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
// components
import { Message } from 'happ-components/HappStyledComponents/HappElementsStyled/HappElementsStyled';
import { LeftArrowIcon } from 'happ-icons/icons';

const Advice = ({advices, emptyAdvice, hideAdviceView}) => {
    const advicesArray = advices?.map(item => {
        return {
            description: item.medicalAdviceText,
            imgPath: item.multimedia,
            label: item._id,
            medicalAdviceBullets: item.medicalAdviceBullets
        }
    }); 
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = advicesArray.length;
    const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    return (
        <>
        <div className="icon-back-content">
            <div 
                className="cursor-back"
                onClick={hideAdviceView}
            >
                <LeftArrowIcon />
                <span>Volver a mis consejos</span>
            </div>
        </div>
        {advices !== null ? (
            <Box className="carousel-box" sx={{ maxWidth: 800, flexGrow: 1, width: '100%' }}>
                <Box 
                    component="img"
                    sx={{
                        height: 'auto',
                        display: 'block',
                        maxWidth: 800,
                        overflow: 'hidden',
                        width: '100%',
                    }}
                    src={advicesArray[activeStep].imgPath}
                    alt={advicesArray[activeStep].label}
                >
                </Box>
                <Paper
                    className="carousel-paper"
                    square
                    elevation={0}
                    sx={{
                    display: 'flex',
                    alignItems: 'center',
                    height: 50,
                    pl: 2,
                    bgcolor: 'background.default',
                    }}
                >
                    <Row className="description-row">
                        <Col sm={12} lg={12} md={12} className="description-content">
                            <p>
                                {advicesArray[activeStep].description}
                            </p>                 
                        </Col>
                        <Col sm={12} lg={12} md={12} className="description-content">
                            {advicesArray[activeStep].medicalAdviceBullets.length > 0 ? (
                                <ListGroup>
                                    <h6>{advicesArray[activeStep].medicalAdviceBullets[0].bulletTitle}</h6>
                                    {advicesArray[activeStep].medicalAdviceBullets[0].bullets.map(item => {
                                        return (
                                            <ListGroup.Item key={item._id}>{item.item}</ListGroup.Item>
                                    )})}
                                </ListGroup>
                            ) : null}
                        </Col>
                    </Row>
                </Paper>
                <MobileStepper
                    className=""
                    steps={maxSteps}
                    position="static"
                    activeStep={activeStep}
                    nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={activeStep === maxSteps - 1}
                    >
                        
                        {theme.direction === 'rtl' ? (
                        <KeyboardArrowLeft />
                        ) : (
                        <KeyboardArrowRight />
                        )}
                    </Button>
                    }
                    backButton={
                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                        <KeyboardArrowRight />
                        ) : (
                        <KeyboardArrowLeft />
                        )}
                        
                    </Button>
                    }
                />
            </Box>
        ) : (
            <Message>
                <p>No hay Consejos cargados en este momento</p>
            </Message>
        )}
        </>
    );
}

export default Advice;