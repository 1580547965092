import React from 'react';
import {ListGroup, ListGroupItem, Row, Col} from 'react-bootstrap';
import {LaboratoryExamIcon, ProgramsIcon, MedicineIcon} from 'happ-icons/icons';

//components
import {HappContainerNotificationIcon} from 'happ-components/HappStyledComponents/HappContainerNotificationIcon/HappContainerNotificationIcon';
const Notifications = () => {
    return ( 
        <ListGroup className="justify-content-center align-items-center">
            <ListGroupItem href="#">
                <Row className="justify-content-center align-items-center">
                    <Col md={3} xs={3} lg={3}>
                        <HappContainerNotificationIcon>
                            <LaboratoryExamIcon 
                                fill="white"
                            />
                        </HappContainerNotificationIcon>
                    </Col>
                    <Col md={9} xs={9} lg={9} className="msg-info">
                        <span>El resultado de tu examen de Glicemia está listo</span>
                        <small>Ayer a las 11:50pm</small>
                    </Col>
                </Row>
            </ListGroupItem>
            <ListGroupItem href="#">
                <Row className="justify-content-center align-items-center">
                    <Col md={3} xs={3} lg={3}>
                        <HappContainerNotificationIcon>
                            <ProgramsIcon 
                                fill="white"
                            />
                        </HappContainerNotificationIcon>
                    </Col>
                    <Col md={9} xs={9} lg={9} className="msg-info">
                        <span>Recordatorio: el día jueves 28 de julio tienes cita para telemedicina</span>
                        <small>Martes 26 de julio a las 8:30 pm</small>
                    </Col>
                </Row>
            </ListGroupItem>
            <ListGroupItem href="#">
                <Row className="justify-content-center align-items-center">
                    <Col md={3} xs={3} lg={3}>
                        <HappContainerNotificationIcon
                            color="#A2A2A2"
                        >
                            <MedicineIcon 
                                fill="white"
                            />
                        </HappContainerNotificationIcon>
                    </Col>
                    <Col md={9} xs={9} lg={9} className="msg-info">
                        <span>Recordatorio: Toma 5mg de ENLAPRIL</span>
                        <small>Martes 26 de julio a las 05:00 pm</small>
                    </Col>
                </Row>
            </ListGroupItem>
            
        </ListGroup>
    );
}
 
export default Notifications;