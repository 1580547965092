import { width } from '@mui/system'
import IndicatorModal from 'happ-components/Indicators/IndicatorModal'
// import ModalToAgendar1 from 'happ-components/Modal/ModalChequeo1/ModalToAgendar1'
import { useModal } from 'happ-custom-hooks/useModal/useModal'
import { useModal2 } from 'happ-custom-hooks/useModal/useModal2'
import useSchedule from 'happ-custom-hooks/useSchedule/useSchedule'
import { agendaModal } from 'happ-data/data2'
import React, { useEffect } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import moment from 'moment';
import 'moment/min/locales';


const arrayOpen = [];
const test = [
    {
        title: 'Chequeo Diagnóstico',
        description:
            [
                { result: 'En esta etapa detectaremos si tienes sospecha de alguna enfermedad cardiovascular.' },
                { result: 'Se realizarán mediciones y exámenes no invasivos.' },
                { result: 'Si detectamos una sospecha, te realizaremos exámenes de sangre para confirmar.' }
            ]
    }
]
const test2 = [
    {
        title: 'Exámenes Físicos',
        description:
            [
                { result: 'En esta etapa realizaremos exámenes no invasivos' },
                { result: 'El objetivo es descartar otras enfermedades asociadas a las que ya hemos diagnosticado' },
                { result: 'Realizaremos un Electrocardiograma, te auscultaremos y evaluaremos si cuentas con edemas' }
            ]

    }
]

const HomeMessageExamenesFisicos = ({ medicalStage, status }) => {
    moment.locale('es');
    const { openModal, ShowModal } = useModal(arrayOpen, 'homeButton');
    const [show, handleShow, handleClose] = useModal2(false);
    const { hour, statusCodeSchedule, schedule, statusScheduleActives } = useSchedule();
    const fecha = schedule?.day;
    const fechaAgendada = moment.utc(fecha).format('dddd, D MMMM YYYY');

    return (
        <>
            <div className='blue-stripe'>
                {/* {!statusCodeSchedule && statusScheduleActives ? (
                    <>
                        <Row>
                            <Col className=' text-center'>
                                <h1 className='primary-color-text title-message'>Comienza tu viaje</h1>
                                <p className='primary-color-text subtitle-message'>
                                    Programa tu próxima etapa <strong>chequeo y diagnóstico</strong>
                                </p>
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col className=' text-center'>
                                <Button onClick={handleShow} className='btn-home-message'>
                                    Iniciar
                                </Button>
                            </Col>
                        </Row>
                        <Row className='pt-2'>
                            <Col className='d-flex justify-content-center'>
                                <div className='py-2 primary-bg-color rounded-1 indicators-content canvas-message'>
                                    Conoce la <strong>importancia</strong> de cada examen
                                </div>
                            </Col>
                        </Row>
                    </>
                ) : null}
                <ShowModal /> */}
                {/* { !statusCodeSchedule  && !statusScheduleActives ? (
                    <>
                        <Row>
                            <Col className=' text-center'>
                                <h1 className='primary-color-text title-message'>Comienza tu viaje</h1>
                                <p className='primary-color-text subtitle-message'>
                                   Te notificaremos cuando puedas agendar para tu <strong>chequeo y diagnóstico</strong>
                                </p>
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col className=' text-center'>
                                <Button disabled className='btn-home-message btn-homegray-message'>
                                    Iniciar
                                </Button>
                            </Col>
                        </Row>
                        <Row className='pt-2'>
                            <Col className='d-flex justify-content-center'>
                                <div className='py-2 primary-bg-color rounded-1 indicators-content canvas-message'>
                                    Conoce la <strong>importancia</strong> de cada examen
                                </div>
                            </Col>
                        </Row>
                    </>
                ) : null}
                <ShowModal /> */}

                {/* {statusCodeSchedule  ? (
                    <>
                        <Row>
                            <Col className=' text-center primary-color-text'>
                                <h1 className='title-message' ><strong>¡Tu hora está agendada!</strong></h1>
                                <p className='p-success-schedule'> Asiste en ayunas a tu consulta el día</p>
                                <p className='p-success-schedule'> <strong>{fechaAgendada} a las  {hour}.</strong></p>
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col className=' text-center'>
                                <Link to="/agendar-hora" className='d-flex justify-content-center link'   >
                                    <Button className='btn-home-message'>
                                        Modificar/Eliminar cita 
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                        <Row className='pt-2'>
                            <Col className='d-flex justify-content-center'>
                                <div className='py-2 primary-bg-color rounded-1 indicators-content canvas-message'>
                                    Conoce la <strong>importancia</strong> de cada examen
                                </div>
                            </Col>
                        </Row>
                    </>
                ) : null} */}
                {/* {medicalStage === 'stage2' ? (
                    <>
                        <Row>
                            <Col className=' text-center my-4'>
                                <h1 className='fw-bold primary-color-text title-message'>¡Revisa tus resultados!</h1>
                                <p className='primary-color-text lh-1 subtitle-message'>
                                    ¡Lo sentimos! Tus resultados de laboratorio todavía están siendo procesados. Por favor, espera.
                                </p>
                            </Col>
                        </Row>
                        <Row className='pt-2'>
                            <Col className='d-flex justify-content-center'>
                                <div className='text-center py-2 text-white primary-bg-color rounded-1 indicators-content canvas-message'>
                                    Serás notificado cuando estén disponibles
                                </div>
                            </Col>
                        </Row>
                    </>
                ) : null} */}
                {status === 'examenesfisicos' ? (
                    <>
                        <Row>
                            <Col className=' text-center'>
                                <h1 className='primary-color-text title-message'>Selecciona tu hora</h1>
                                <p className='primary-color-text subtitle-message'>
                                    Programa tu próxima etapa: <strong>Telemedicina</strong>
                                </p>
                            </Col>
                        </Row>
                        <Row className='mb-3'>
                            <Col className=' text-center'>
                                <Button onClick={handleShow} className='btn-home-message'>
                                    Agenda aquí
                                </Button>
                            </Col>
                        </Row>
                        <Row className='pt-2'>
                            <Col className='d-flex justify-content-center'>
                                <div className='py-2 primary-bg-color rounded-1 indicators-content canvas-message'>
                                     <strong>Revisa tu bitácora de exámenes</strong>
                                </div>
                            </Col>
                        </Row>
                    </>
                ) : null}
            </div>
            {/* <ModalToAgendar1 show={show} onHide={handleClose} /> */}
            {/* <ModalToAgendarExam1 show={show2} onHide={handleClose2} /> */}
        </>
    )
}

export default HomeMessageExamenesFisicos;