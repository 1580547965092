import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {Row, Col, Button} from 'react-bootstrap';
//components
import {HappContainer} from 'happ-components/HappStyledComponents/HappContainer/HappContainer';
import {ContainerTip} from 'happ-components/HappStyledComponents/HappContainerTip/HappContainerTip';
import {SportsTipsIcon, NutritionalTipsIcon} from 'happ-icons/icons';
import Advice from 'happ-components/Advice/Advice';
//actions
import { getMedicalAdviceAction } from 'happ-redux/happ-actions/medicalAdvice/medicalAdviceActions';
const MedicalAdvice = () => {
    const [hideAdviceOptions, setHideAdviceOptions] = useState(true);
    const [showSportsAdvice, setShowSportsAdvice] = useState(false);
    const [showNutritionalsAdvice, setShowNutritionalsAdvice] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getMedicalAdviceAction(navigate));
    }, []);
    const { medicalAdvice } = useSelector(state => state.advices);
    const showAdviceView = category => {
        setHideAdviceOptions(false);
        if ( category === 'sports' ) {
            setShowSportsAdvice(true);
        } else {
            setShowNutritionalsAdvice(true);
        }
    }
    const hideAdviceView = () => {
        setHideAdviceOptions(true);
        setShowSportsAdvice(false);
        setShowNutritionalsAdvice(false);
    }
    return ( 
        <Row className="mt-content">
            <HappContainer>
                {hideAdviceOptions ? (
                    <>
                        <Col sm={12} lg={12} md={12} className="mb-3 mt-5">
                            <ContainerTip>
                                <div className="content-tip">
                                    <SportsTipsIcon />
                                    <p>Consejos <strong>Deportivos</strong></p>
                                    <p>Mejora tu calidad de vida con estos consejos enfocados al ejercicio y el deporte</p>
                                    <Button
                                        className="btn primary-bg-color text-white"
                                        onClick={() => showAdviceView('sports')}
                                    >
                                        Ver más
                                    </Button>
                                </div>
                            </ContainerTip>
                        </Col>
                        <Col sm={12} lg={12} md={12} className="mb-5 mt-5">
                            <ContainerTip>
                                <div className="content-tip">
                                    <NutritionalTipsIcon />
                                    <p>Consejos <strong>Nutricionales</strong></p>
                                    <p>Mejora tu calidad de vida con estos consejos nutricionales</p>
                                    <Button
                                        className="btn primary-bg-color text-white"
                                        onClick={() => showAdviceView('nutritional')}
                                    >
                                        Ver más
                                    </Button>
                                </div>
                            </ContainerTip>
                        </Col>
                    </>
                ) : (
                    <>
                    {showSportsAdvice === true ? (
                        <Col sm={12} lg={12} md={12} className="mb-5 mt-5">
                            {medicalAdvice !== null && medicalAdvice.sports !== null ? (
                                <Advice 
                                    advices={medicalAdvice.sports}
                                    emptyAdvice={false}
                                    hideAdviceView={hideAdviceView}
                                />
                            ) : (
                                <Advice 
                                    advices={null}
                                    emptyAdvice={true}
                                    hideAdviceView={hideAdviceView}
                                />
                            )}
                        </Col>
                    ) : null}
                    {showNutritionalsAdvice ? (
                        <Col sm={12} lg={12} md={12} className="mb-5 mt-5">
                            {medicalAdvice !== null && medicalAdvice.nutritional !== null ? (
                                <Advice 
                                    advices={medicalAdvice.nutritional}
                                    emptyAdvice={false}
                                    hideAdviceView={hideAdviceView}
                                />
                            ) : (
                                <Advice 
                                    advices={null}
                                    emptyAdvice={true}
                                    hideAdviceView={hideAdviceView}
                                />
                            )}
                        </Col>
                    ) : null}
                    </>
                )}
            </HappContainer>
        </Row>            
    );
}
 
export default MedicalAdvice;