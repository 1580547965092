import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { SuccessCalendarhr } from 'happ-icons/icons';
import { Link } from 'react-router-dom';
import { useModal2 } from 'happ-custom-hooks/useModal/useModal2';
import ModalDelete from './ModalDelete';
import moment from 'moment';
import 'moment/min/locales';


const SuccessSchedule = ({
    activeScheduleForm, 
    handleDeleteSchedule, 
    currentMedicalSchedule,
    medicalStagePrint
}) => {
    moment.locale('es');
    const [show, handleShow, handleClose] = useModal2(false);
    return ( 
        <>
            <Row className='mt-3'>
            <Col>
                <h3 className='text-center mb-4 primary-color-text'>
                        <b>¡Tu hora ya <br /> está agendada con éxito!</b>
                </h3>

                <div className='d-flex justify-content-center' >
                    <div className='text-center btn-success-calendarhr'>
                        <SuccessCalendarhr />
                    </div>
                </div>
                <div className='text-center mt-4 primary-color-text'>
                        {currentMedicalSchedule ? (
                            <>
                                <p style={{ marginBottom: '1px' }}>
                                    Asistir el día <strong>{moment.utc(currentMedicalSchedule.day).format('dddd, D MMMM YYYY')}</strong>, a las <strong>{currentMedicalSchedule.hour} Hrs</strong> 
                                </p>
                                {medicalStagePrint !== 'medical-teleconsultation' ? (
                                    <p>
                                        En <strong>{currentMedicalSchedule?.address[0].alias}, {currentMedicalSchedule?.address[0].street}</strong>
                                    </p>
                                ) : null}
                            </>
                        ) :
                            null
                        }
                    </div>
            </Col>
            </Row>
            <Row className='py-3 mb-3' style={{ width: '32%', margin: '0 auto' }}>
                <div className='mb-3 d-flex justify-content-center'>
                    <Link to="/">
                        <Button className='shadow-lg btn-blue-back-travel'>
                            Regresar a mi viaje
                        </Button>
                    </Link>
                </div>
                <Col className='d-flex justify-content-center'>
                    <div className='me-1'>
                        {/* <Link to="/n-agenda" className='d-flex justify-content-center link'  > */}
                            <Button 
                                onClick={() => activeScheduleForm()} 
                                className='shadow-lg primary-bg-color btn-time-success-calendar'
                            >
                                Modificar hora
                            </Button>
                        {/* </Link> */}
                    </div>
                    <div className='ms-1'>
                        <Button 
                            onClick={handleShow} 
                            className='shadow-lg danger-bg-color btn-time-success-calendar'
                        >
                            Eliminar hora
                        </Button>
                    </div>
                </Col>
            </Row>
            <ModalDelete 
                show={show} 
                onHide={handleClose} 
                handleDeleteSchedule={handleDeleteSchedule} 
            />
        </>
    );
}
 
export default SuccessSchedule;