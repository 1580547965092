import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import {Row, Col} from 'react-bootstrap';
import moment from "moment";
//components
import {CalendarIcon, BigUpIcon, BigDownIcon} from 'happ-icons/icons';

const Records = ({records}) => {
    return ( 
        <ListGroup>
            {records ? (
                records.map(item => (
                    <ListGroup.Item key={item._id}>
                        <Row className="row-list-self-registration">
                            <Col xs={8} lg={8} md={8} className="mobile-col">
                                <CalendarIcon 
                                    className="calendar-icon"
                                />
                                <p>{moment.utc(item.date).format('YYYY-MM-DD')}</p>
                            </Col>
                            <Col xs={4} lg={4} md={4} className="text-center mobile-col">
                                <p>
                                    {item.value}
                                    <br></br>
                                    {item.item === "peso" ? "Kg" : ""}
                                    {item.item === "glicemia" ? "mg/dl" : ""}
                                    {item.item === "colesterol" ? "mg/dl" : ""}
                                    {item.item === "presion" ? "mm/hg" : ""}
                                </p>
                            </Col>
                        </Row>
                    </ListGroup.Item>
                ))
            ) : null}
        </ListGroup>
    );
}
 
export default Records;