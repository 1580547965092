import AccordionModalAuscultation from 'happ-components/AccordionModalExam/AccordionModalAuscultation'
import AccordionModalExamColesterol from 'happ-components/AccordionModalExam/AccordionModalExamColesterol'
import ButtonDownloadModalExam from 'happ-components/ButtonDownloadModalExam/ButtonDownloadModalExam'
import { AuscultationWhite } from 'happ-icons/icons'
import { Prueba1 } from 'happ-icons/icons'
import { ColesterolIndicatorExam } from 'happ-icons/icons'
import { CloseModalBlue } from 'happ-icons/icons'
import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'

const ModalAuscultation = (props) => {

    const status = 'stage3'

        return (
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                contentClassName="modal3"
                centered
                animation={false}
            // dialogClassName="modal-100w"
            // fullscreen
            // className="modal-footer"
            >
                {/* este es para el colesterol plomo */}
                <div className={status === 'stage3' ? 'modal-content-white' : null}>
                    <Row>
                        <Col>
                            <div style={{ zIndex: 10, position: 'sticky' }}>
                                <>
                                    <div style={{ backgroundColor: 'white', position: 'relative', borderRadius: 10 }} className='py-2'>

                                        <h6 className='text-center primary-color-text pb-2'><strong>Auscultación</strong> </h6>
                                        <div onClick={props.onHide} className='modal-close-btn primary-color-text'>
                                            <CloseModalBlue
                                            />
                                        </div>

                                        <div className='modal-info primary-bg-color'>
                                            <Row className='pt-3 pb-2'>
                                                <Col className='d-flex justify-content-around'>
                                                    {/* <ColesterolIndicatorExam
                                                        fill='white'
                                                        responsive='scale-mobile'
                                                    />} */}
                                                    <AuscultationWhite/>
                                                    <div className='width-info-modal align-self-end'>
                                                        <p className='description-modal-exam' >  <strong>Técnica médica</strong> para escuchar los
                                                         <strong>sonidos corporales</strong> con un <strong>estetoscopio</strong> y evaluar la <strong>función de los órganos internos</strong> </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {/* className={`status-bar-${status}`} */}

                                            <div className='status-bar-normal' />
                                        </div>
                                    </div>
                                </>

                            </div>
                        </Col>
                    </Row>


                    {/* en celu le coloco u paddingTop: 180px */}
                    <Modal.Body style={{ padding: 0 }}   >
                        <Row>
                            <Col>
                                <div className='text-center pt-3 '>
                                    <Row className=' modal-scroll-mobile' >
                                        <div className='accordion-modal'>
                                            <Col className='d-flex justify-content-between' >
                                                <h6 className='fw-bold align-self-end'> Mis exámenes</h6>
                                                <ButtonDownloadModalExam />
                                            </Col>
                                        </div>
                                        <Col>
                                            <AccordionModalAuscultation title='Auscultacion' />
                                        </Col>
                                    </Row>
                                </div>
                                {/* {description[0]?.length > 1 && title !== 'Exámenes Físicos' ? (
                                <div className='d-flex justify-content-center py-2'>
                                    <Button className='shadow btn-blue'>
                                        <div className='d-flex justify-content-center mt-1'>
                                            <DiagnosticCalendarIcon />
                                            <p className='ms-3'>
                                                Agendar chequeo inicial
                                            </p>
                                        </div>
                                    </Button>
                                </div>
                            ) : description[0]?.length > 1 && title === 'Exámenes Físicos' ?
                                <div className='d-flex justify-content-center py-2'>
                                    <Button className='shadow btn-blue'>
                                        <div className='d-flex justify-content-center mt-1'>

                                            <DiagnosticCalendarIcon />
                                            <Link to="/agendarhora" className='ms-3' style={{ textDecoration: 'none', backgroundColor: 'transparent', color: 'inherit', border: 'none' }}  >
                                                Agendar exámenes físicos
                                            </Link>

                                        </div>
                                    </Button>
                                </div>
                                : null
                            } */}
                            </Col>
                        </Row>
                    </Modal.Body>
                </div>

            </Modal>
        )
    }

    export default ModalAuscultation