import {
    INIT_MEDICAL_ADVICE,
    MEDICAL_ADVICE,
    ERROR_MEDICAL_ADVICE,
    LOGOUT
} from 'happ-redux/happ-types';
import { getMedicalAdvices } from 'happ-redux/happ-services/medical-advice/medical-advice';

export function getMedicalAdviceAction (navigate) {
    return async (dispatch) => {
        dispatch(initMedicalAdvice());
        try {
            const token = localStorage.getItem('token');
            if (token === null){
                dispatch(logout());
                navigate('/login');
            }
            const response = await getMedicalAdvices(token);
            if (response.status === 401){
                dispatch(logout());
                navigate('/login');
            }
            if (response.status === 200) {
                dispatch(medicalAdvice(response.data));
            } else {
                dispatch(errorMedicalAdvice(response.data.msg));
            }    
        } catch (error) {
            dispatch(errorMedicalAdvice(error.data.msg))
        }
    }
}

const initMedicalAdvice = () => ({
    type: INIT_MEDICAL_ADVICE
});

const medicalAdvice = response => ({
    type: MEDICAL_ADVICE,
    payload: response
});

const errorMedicalAdvice = error => ({
    type: ERROR_MEDICAL_ADVICE,
    payload: error
});

const logout = () => ({
    type: LOGOUT
});