import { DiabetesIndicatorBoxExam } from 'happ-icons/icons'
import { PressureHeart } from 'happ-icons/icons'
import { DownloadExam } from 'happ-icons/icons'
import React from 'react'

const ButtonExamDownload = ({ examHistory }) => {

    const clickExam = (event, exam) => {
        event.preventDefault();
        if (exam !== null) {
            window.open(exam)
        }
        return
    }
    return (
        examHistory ? (
            examHistory.map((item) => (
                <div
                    onClick={(e) => clickExam(e, item.url)}
                    style={{ position: 'relative' }}
                    className={item.url === null ? 'pt-2 mt-2 mb-5 text-white rounded-2 box-download-exam indicators-content dark-grey-bg-color' : 'pt-2 mt-2 mb-5 text-white primary-bg-color rounded-2 box-download-exam indicators-content'}
                >
                    <div className='d-flex justify-content-between'>
                        <div className='d-flex'>
                            <div className='ms-3 mt-1' >
                                {item.name === 'ecg' ?
                                    <PressureHeart
                                        style={{
                                            width: '45px',
                                            height: '45px'
                                        }}
                                    /> :
                                    <DiabetesIndicatorBoxExam
                                        style={{
                                            width: '45px',
                                            height: '45px'
                                        }}
                                    />
                                }
                            </div>
                            <div className='text-start ps-3 pe-3'>
                                {item.name === 'diagnostico-complementario' ? (
                                    <>
                                        <h6 className='fw-bold' style={{ letterSpacing: 0.5 }}>Exámenes de laboratorio</h6>
                                        <p style={{ fontSize: '13px', fontWeight: '', lineHeight: '1px' }}>No disponible</p>
                                        <p style={{ fontSize: '13px', fontWeight: '', lineHeight: '1px' }}>Etapa: Chequeo y diagnostico</p>
                                    </>
                                ) :
                                    item.name === 'glicemia 2' ? (
                                        <>
                                            <h6 className='fw-bold' style={{ letterSpacing: 0.5 }}>Glicemia 2</h6>
                                            <p style={{ fontSize: '13px', fontWeight: '', lineHeight: '1px' }}>No disponible</p>
                                            <p style={{ fontSize: '13px', fontWeight: '', lineHeight: '1px' }}>Etapa: Exámenes confirmatorios</p>
                                        </>
                                    ) :
                                        <>
                                            <h6 className='fw-bold' style={{ letterSpacing: 0.5 }}>Electrocardiograma</h6>
                                            <p style={{ fontSize: '13px', fontWeight: '', lineHeight: '1px' }}>14/09/2023</p>
                                            <p style={{ fontSize: '13px', fontWeight: '', lineHeight: '1px' }}>Etapa: Electrocardiograma</p>
                                        </>
                                }
                            </div>
                        </div>

                        <div className='me-3 mt-1'>
                            <DownloadExam />
                        </div>

                    </div>
                    <div style={{ position: 'absoulute' }} className={item.url === null ? 'd-flex flex-column border-bottom-container-diagnosis grey-border' : 'd-flex flex-column border-bottom-container-diagnosis blue-border'} />
                </div>
            ))
        ) : null
    )
}

export default ButtonExamDownload