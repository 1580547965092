import React from 'react';
import 'assets/css/spinnerView.scss';
import { Image } from 'react-bootstrap';
import ReactLogo from 'assets/imgs/icono-indicadores.svg'

const LoaderView = ({show}) => {
    return (
        <div className={`preloader-view bg-soft flex-column justify-content-center align-items-center ${show ? "" : "show-view"}`}>
            <Image className="loader-element-view animate_animated animate_jackInTheBox" src={ReactLogo} height={40} />
        </div>
    )
}
 
export default LoaderView;