import { useSelector } from 'react-redux'

const useAuth = () => {
    const { errorServer, errorMsg, session, loading, medicalStage, profile } = useSelector(state => state.auth);

    const id = profile ? profile._id : null;
    const companyDni = profile?.company?.dni;

    const calculateAge = (birthday) => {
        const ageDifMs = Date.now() - new Date(birthday).getTime();
        const ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    const patientAge = profile ? calculateAge(profile.birthDay) : null;

    return {
        errorServer,
        errorMsg,
        session,
        loading,
        medicalStageId: medicalStage?.medicalStage?._id,
        medicalStage: medicalStage?.medicalStage?.code,
        profile,
        id,
        companyDni,
        patientAge,
        healthForecast: profile?.healthForecast,
        gender: profile?.gender,
    }
}

export default useAuth;