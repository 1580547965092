import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Row, Col, Accordion} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
//icons
import {ImcIcon, GlicemiaIconColor, BloodPressureIconColor} from 'happ-icons/icons';
//actions
import { getMedicalGoalsAction, addMedicalGoalRecordAction } from 'happ-redux/happ-actions/medicalGoals/medicalGoalsActions';
//component
import Records from './Records';
import InputsRecord from './InputsRecord';
import { SelfRegistrationParagraph } from 'happ-components/HappStyledComponents/HappElementsStyled/HappElementsStyled';
import { Message } from 'happ-components/HappStyledComponents/HappElementsStyled/HappElementsStyled';
import ProgressBar from 'react-bootstrap/ProgressBar';

const MedicalGoals = () => {
    const [errorPa, setErrorPa] = useState(false);
    const [errorPl, setErrorPl] = useState(false);
    const [errorImc, setErrorImc] = useState(false);
    const [errorGl, setErrorGl] = useState(false);
    const [statusSubmit, setStatusSubmit] = useState(false);
    const [record, setRecord] = useState({
        glicemia: '',
        peso: '',
        sistolica: '',
        diastolica: '',
        colesterol: ''
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(getMedicalGoalsAction(navigate));
    }, []);
    const { loading, medicalGoals, errorServer, errorMsg } = useSelector(state => state.goals);
    const handlerChangeRecordInput = e => {
        setRecord({
            ...record,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        });
    }
    const getNewMedicalGoalRecord = (goal, healthBenefitCode) => e => {
        e.preventDefault();
        let newParam = {}
        if (healthBenefitCode === "PL001") {
            if (record.colesterol === "") {
                setErrorPl(true);
                return;
            }
            setStatusSubmit(true);
            setErrorPl(false);
            newParam.item = 'colesterol';
            newParam.value = Number(record.colesterol);
            console.log(goal, newParam);
            dispatch(addMedicalGoalRecordAction(goal, newParam, navigate));
            record.colesterol = "";
            setTimeout(() => {
                setStatusSubmit(false);
            }, 1000);
        }
        if (healthBenefitCode === "IMC001") {
            if (record.peso === "") {
                setErrorImc(true);
                return;
            }
            setStatusSubmit(true);
            setErrorImc(false);
            newParam.item = 'peso';
            newParam.value = Number(record.peso);
            console.log(goal, newParam);
            dispatch(addMedicalGoalRecordAction(goal, newParam, navigate));
            record.peso = "";
            setTimeout(() => {
                setStatusSubmit(false);
            }, 1000);
        }
        if (healthBenefitCode === "PA001") {
            if (record.sistolica === "" || record.diastolica === "") {
                setErrorPa(true);
                return;
            }
            setStatusSubmit(true);
            setErrorPa(false);
            newParam.item = 'presion'
            newParam.value = `${record.sistolica}/${record.diastolica}`;
            console.log(goal, newParam);
            dispatch(addMedicalGoalRecordAction(goal, newParam, navigate));
            record.sistolica = "";
            record.diastolica = "";
            setTimeout(() => {
                setStatusSubmit(false);
            }, 1000);
        }
        if (healthBenefitCode === "GL001") {
            if (record.glicemia === "") {
                setErrorGl(true);
                return;
            }
            setStatusSubmit(true);
            setErrorGl(false);
            newParam.item = 'glicemia';
            newParam.value = Number(record.glicemia)
            console.log(goal, newParam);
            dispatch(addMedicalGoalRecordAction(goal, newParam, navigate));
            record.glicemia = "";
            setTimeout(() => {
                setStatusSubmit(false);
            }, 1000);
        }
    }
    return (
        <Row>
            {errorServer ? (
                <Col sm={12} lg={12} md={12} className="mb-5 mt-5">
                    <Message>
                        <p>
                            {errorMsg && errorMsg}
                        </p>
                    </Message>
                </Col>
            ) : (
                medicalGoals ? (
                    medicalGoals.map(item => (
                        <Col key={item._id} sm={12} lg={12} md={12} className="mb-3 mt-5 col-self-registration">
                            <Row className='header-self-registration'>
                                <Col sm={4} lg={12} md={12} xs={3} className="icon-self-registration">
                                    {item.healthBenefitCode === 'GL001' ? (
                                        <GlicemiaIconColor />
                                    ) : null}
                                    {item.healthBenefitCode === 'PL001' ? (
                                        <ImcIcon />
                                    ) : null}
                                    {item.healthBenefitCode === 'PA001' ? (
                                        <BloodPressureIconColor />
                                    ) : null}
                                    {item.healthBenefitCode === 'IMC001' ? (
                                        <ImcIcon />
                                    ) : null}
                                </Col>
                                <Col sm={6} lg={12} md={12} xs={9} className="header-self-registration-info mt-3">
                                    <SelfRegistrationParagraph>
                                        <p>Tu meta de {item.healthBenefit} es de {item.globalGoalReference}</p>
                                        <p className='frequency-param'>Frecuencia de autoregistro: {item.frequency}</p>
                                    </SelfRegistrationParagraph>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={9} lg={9} md={9} xs={8} className="mb-3 mt-3">
                                    <SelfRegistrationParagraph>
                                        <p className='goal-reference'>Este mes debes llegar a {item.goalReference}</p>
                                    </SelfRegistrationParagraph>
                                    <ProgressBar now={item.progress} variant={item.progressColor} />
                                    <Accordion className="accordion-self-registration">
                                        <Accordion.Item eventKey="0">
                                        <Accordion.Header>Ver registros</Accordion.Header>
                                        <Accordion.Body>
                                            <Row>
                                                <Records 
                                                    records={item.params}
                                                />
                                            </Row>
                                        </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Col>
                                <Col sm={3} lg={3} md={3} xs={4} className="mb-3 mt-3">
                                    <InputsRecord 
                                        healthBenefitCode={item.healthBenefitCode}
                                        goal={item._id}
                                        goalStatus={item.status}
                                        submit={statusSubmit}
                                        record={record}
                                        errorPa={errorPa}
                                        errorPl={errorPl}
                                        errorGl={errorGl}
                                        errorImc={errorImc}
                                        handlerChangeRecordInput={handlerChangeRecordInput}
                                        getNewMedicalGoalRecord={getNewMedicalGoalRecord}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    ))
                ) : null
            )}
        </Row> 
    );
}
 
export default MedicalGoals;