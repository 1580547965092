import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Nav, Container, Form, Button, InputGroup } from 'react-bootstrap';
//components
import { HappContainer } from 'happ-components/HappStyledComponents/HappContainer/HappContainer';
import { SubNav } from 'happ-components/HappStyledComponents/SubNavOptions/SubNavOptions';
import { Message } from 'happ-components/HappStyledComponents/HappElementsStyled/HappElementsStyled';
import DeterminationsList from 'happ-components/DeterminationsList/DeterminationsList';
import PatientProgram from 'happ-components/PatientProgram/PatientProgram';
import { Loader } from 'happ-components/Loader/Loader';
//actions
import { getMedicalScheduleAction } from 'happ-redux/happ-actions/medicalSchedule/medicalScheduleActions';
import { DiagnosticCalendarIcon } from 'happ-icons/icons';
import { DiagnosticModalIcon } from 'happ-icons/icons';
import TimeSuccessCalendar from 'happ-components/TimeSuccessCalendar/TimeSuccessCalendar';
import TimeSuccessCalendar2 from 'happ-components/TimeSuccessCalendar/TimeSuccessCalendar2';
import TimeSuccessCalendar3 from 'happ-components/TimeSuccessCalendar/TimeSuccessCalendar3';


const MedicalAgenda3 = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const { medicalSchedule, determinations, allGoodStatus, errorServer, errorMsg, loading, objLength } = useSelector(state => state.schedule);

    // console.log(allGoodStatus)
    // const [gender, setGender] = useState('')

    const [agendar, setAgendar] = useState(false) // esto ponerlo en el estado global de la app para consultar el estado de agendado

    const loading = false;

    useEffect(() => {
        dispatch(getMedicalScheduleAction(navigate));
    }, []);
    return (
        <Row className="mt-content">
            <HappContainer>
                {/* <Col sm={12} lg={12} md={12} className="sub-options">
                    <Nav className="">
                        <Nav.Item>
                            <SubNav
                                bgClass='primary-bg-color'
                                brRadius="40px 0px 0px 40px"
                            >
                                Mi viaje
                            </SubNav>
                        </Nav.Item>
                        <Nav.Item className="comming-soon">
                            <SubNav
                                bgClass='grey-bg-color'
                                textColor='#666666'
                                brRadius='0px 40px 40px 0px;'
                            >
                                Mi pastillero <small>próximamente habilitado</small>
                            </SubNav>
                        </Nav.Item>
                    </Nav>
                </Col> */}
                {loading ? (
                    <Loader />
                ) : !agendar ? (
                    <>
                        <Container className='text-center'>
                            <Row className='py-3 mt-2 ms-2'>
                                <Col>
                                    <div >
                                        <DiagnosticModalIcon fill="#044064" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h3 className='primary-color-text'  >Agendar</h3>
                                    <h2 className='primary-color-text fw-bold ' style={{ lineHeight: '16px' }}>Exámenes Físicos</h2>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col>
                                    <h5 className='second-color fw-bold'>Exámenes físicos en tu empresa</h5>
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center mt-4'>
                                <div className='selectors' >
                                    <Col className="">
                                        <p style={{ fontSize: '14px', lineHeight: '1px' }}>Selecciona la fecha y hora de atención</p>
                                    </Col>
                                    <Col className="my-1">
                                        <Form.Group controlId="date">
                                            <Form.Select
                                                name="date"
                                                className='text-end'
                                            >
                                                <option value="">Seleciona un día</option>
                                                <option value="">Jueves 5 de enero de 2023</option>
                                                <option value="">Viernes 6 de enero de 2023</option>
                                                <option value="">Lunes 9 de enero de 2023</option>
                                                <option value="">Martes 10 de enero de 2023</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col className="my-1">
                                        <Form.Group controlId="hr">
                                            <Form.Select
                                                name="hr"
                                                className='text-end'
                                            >
                                                <option value="">Seleciona una hora</option>
                                                <option value="">10:30</option>
                                                <option value="">11:00</option>
                                                <option value="">11:30</option>
                                                <option value="">12:00</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Button onClick={() => setAgendar(true)} className='shadow btn-blue-schedule'>
                                        <div className='d-flex justify-content-center mt-1'>
                                            <p>
                                                Agendar hora
                                            </p>
                                        </div>
                                    </Button>
                                </div>
                            </Row>
                        </Container>
                    </>
                ) : (
                    <TimeSuccessCalendar3 />
                )}
            </HappContainer>
        </Row>
    );
}

export default MedicalAgenda3;