import axiosClient from 'happ-config/axios';
import { regiones, comunas } from 'happ-data/data';


const userAuth = async userParams => {
    try {
        const headers = {'Content-Type': 'application/json'};
        const response = await axiosClient.post('/auth/login/patient', userParams, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const userSuperAuth = async userParams => {
    try {
        const headers = {'Content-Type': 'application/json'};
        const response = await axiosClient.post('/auth/login/super-admin-patient', userParams, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const validateSession = async token => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.get('/auth/verify-token', {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const profileUser = async token => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'x-auth-token': token
        };
        const response = await axiosClient.get(`/patients/profile`, {
            headers: headers
        });
        return response;;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const getMedicalStageDelivery = async (token, patient, medicalStage) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'authtoken': token
        };
        const response = await axiosClient.get(`/patients/get-medical-stage-delivery/${patient}/${medicalStage}`, {
            headers: headers
        });
        return response;;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const updateProfileSession = async (id, profile, token) => {

    try {
        const headers = {
            'Content-Type': 'application/json',
            // 'x-auth-token': token
        };
        const response = await axiosClient.patch(`/patients/${id}`, profile, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}


const sendDniPatient = async dni => {
    try {
        const headers = {'Content-Type': 'application/json'};
        const response = await axiosClient.get(`/auth/forgot-password-patient/${dni}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const sendEmailPatient = async email => {
    try {
        const headers = {'Content-Type': 'application/json'};
        const response = await axiosClient.get('/auths/email-password', email, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const resetPasswordPatient = async (resetParams) => {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const response = await axiosClient.post('/auth/reset-password-patient', resetParams, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const communesByReg = region => {
    const id = getIdRegBayName(region);
    if ( id !== null ) {
        return comunas.filter(comuna => comuna.reg === id);
    } 
    return null;   
}

const getIdRegBayName = region => {
    if ( region ) {
        let id = 0;
        regiones.some(reg => {
            if (reg.name === region) {
                id = reg.id;
            }
        })
        return id;
    }
    return null;
}

export {
    regiones,
    userAuth,
    userSuperAuth,
    validateSession,
    profileUser,
    updateProfileSession,
    communesByReg,
    sendDniPatient,
    sendEmailPatient,
    resetPasswordPatient,
    getMedicalStageDelivery
}