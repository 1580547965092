import React from 'react';
import { Col } from 'react-bootstrap';
import { CancerIcon } from 'happ-icons/icons';


const FlowCutView = () => {
    return ( 
        <div className=''>
            <Col md={9} sm={12} lg={9} className='pt-2 result-content'>
                <div className='d-flex justify-content-center'>
                    <div className='text-center px-5 py-2 text-white primary-bg-color  rounded-1 indicators-content'>
                        <strong className='title-stages' >Resultados encuesta Cáncer de Mama</strong>
                    </div>
                </div>
            </Col>
            <Col md={9} sm={12} lg={9} className='mt-4 text-center result-content'>
                <p className='result-text'>Los resultados de tu encuesta indican que no estás dentro del grupo de riesgo.</p>
            </Col>
            <Col md={9} sm={12} lg={9} className='mt-4 text-center result-content result-icon'>
                <CancerIcon />
            </Col>
            <Col md={9} sm={12} lg={9} className='text-center result-content'>
                <p className='result-text'>
                Recuerda que mantener una buena salud es un proceso continuo, y es 
                importante seguir practicando el autocuidado y llevar una vida sana
                </p>
            </Col>
        </div>
    );
}
 
export default FlowCutView;