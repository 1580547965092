import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Row, Container, Col, Button, Form, Spinner } from 'react-bootstrap';
import { HappContainer } from 'happ-components/HappStyledComponents/HappContainer/HappContainer';
import { DiagnosticModalIcon } from 'happ-icons/icons';
import ConfirmScheduleMedismart from 'happ-components/ConfirmScheduleMedismart/ConfirmScheduleMedismart';
import { 
    getMedismartSchedulesAction, 
    confirmMedismartScheduleAction, 
    filterSchedulesAction,
} from 'happ-redux/happ-actions/medismartSchedule/medismartScheduleAction';

const MedismartSchedule = () => {
    const [stateDays, setStateDays] = useState(null);
    const [stateHours, setStateHours] = useState(null);
    const [confirm, setConfirm] = useState(false);
    const [errors, setErrors] = useState(false);
    const [schedule, setSchedule] = useState({
        dni: '',
        idMh: '',
        idBd: '',
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { profile, loadingFilter } = useSelector(state => state.auth);
    const { 
        loading, 
        specialist,
        days,
        hours,
        currentSchedule,
    } = useSelector(state => state.medismart);
    useEffect(() => {
        if (days && days.length) {
            setStateDays(days);
        }
    }, [days])
    
    useEffect(() => {
        if (profile) {
            dispatch(getMedismartSchedulesAction(profile.dni, navigate));
        }
        setStateDays(null);
        setStateHours(null)
    }, []);
    useEffect(() => {
        if (currentSchedule === true) {
            setConfirm(true);
        }
    }, [currentSchedule]);
    const filterDays = e => {
        e.preventDefault();
        const targetDni = e.target.value;
        const splitTarget = targetDni.split('/');
        const idMh = splitTarget[1];
        const date = splitTarget[0];
        const params = {
            idMh, 
            dni: profile?.dni, 
            specialistDate: date,
        }
        dispatch(filterSchedulesAction(params, navigate));
        setStateDays(null);
    } 
    const filterHours = e => {
        e.preventDefault();
        const targetDay = e.target.value;
        const splitDay = targetDay.split('/');
        const selectIdMh = splitDay[0];
        const selectDay = splitDay[1];
        console.log(selectIdMh, selectDay);
        console.log(hours);
        const newHours = hours?.filter(hour => hour.day === selectDay);
        console.log(newHours);
        setStateHours(newHours);
    }
    const getScheduleParams = e => {
        e.preventDefault();
        const targetSchedule = e.target.value;
        const splitparams = targetSchedule.split('/');
        const idMh = splitparams[0];
        const idBd = splitparams[1];
        setSchedule({
            ...schedule,
            idMh,
            idBd,
            dni: profile?.dni,
        });
    }
    const validateEmptyField = inputs => {
        let errors = false;
        if (inputs.idMh === '') {
            errors = true;
        }
        if (inputs.idBd === '') {
            errors = true;
        }
        if (inputs.dni === '') {
            errors = true;
        }
        return errors;
    }
    const sendSchedule = e => {
        e.preventDefault();
        const empty = validateEmptyField(schedule);
        if (empty) {
            setErrors(true);
            return;
        }
        setErrors(false);
        dispatch(confirmMedismartScheduleAction(schedule, navigate));
        setStateDays(null);
        setStateHours(null);
    }
    return ( 
        <Row className="mt-content">
            <HappContainer>
                {loading ? (
                    <div className='text-center mt-5 mb-5'>
                        <Spinner 
                            animation="border"
                            className='text-center'
                        />
                    </div>
                ) : (
                    confirm === false ? (
                        <Container className='text-center'>
                            <Row className='py-3 mt-2 ms-2'>
                                <Col>
                                    <div >
                                        <DiagnosticModalIcon fill="#044064" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <h3 className='primary-color-text' >Agendar</h3>
                                    <h2 className='primary-color-text fw-bold ' style={{ lineHeight: '16px' }}>Telemedicina</h2>
                                </Col>
                            </Row>
                            <Row className='d-flex justify-content-center mt-4'>
                                <div className='selectors' >
                                    <Col>
                                        <p style={{ fontSize: '14px', lineHeight: '1px' }}>Selecciona la fecha y hora de atención</p>
                                    </Col>
                                    <Col className="my-1">
                                        <Form.Select
                                            name="date"
                                            className='text-end'
                                            onChange={filterDays}
                                        >
                                            <>
                                                <option value=""> Selecciona un doctor </option>
                                                {specialist ? specialist.map(item => (
                                                    <option 
                                                        key={item.dni} 
                                                        value={`${item.date}/${item.idMh}`}
                                                    >
                                                        {item.name}
                                                    </option>
                                                )) : null}
                                            </>
                                        </Form.Select>

                                    </Col>
                                    {stateDays  ? (
                                        stateDays.length && !loadingFilter ? (
                                            <Col className="my-1">
                                                <Form.Select
                                                    name="date"
                                                    className='text-end'
                                                    onChange={filterHours}
                                                >
                                                    <option value=""> Selecciona un día </option>
                                                    {stateDays.map(day => (
                                                        <option key={day.day} value={`${day.idMh}/${day.day}`}>{day.day}</option>
                                                    ))}
                                                </Form.Select>
                                            </Col>
                                        ) : (
                                            <div className='text-center mt-5 mb-5'>
                                                <Spinner 
                                                    animation="border"
                                                    className='text-center'
                                                />
                                            </div>
                                        )
                                    ) : null}
                                    {stateHours && stateHours.length ? (
                                        <Col className="my-1">
                                            <Form.Select
                                                name="hr"
                                                className={`text-end ${errors ? 'border-require' : ''}`}
                                                onChange={getScheduleParams}
                                            >
                                                <option value="">Selecciona una hora</option>
                                                {stateHours.map(hour => (
                                                    <option key={hour.idMh} value={`${hour.idMh}/${hour.idBd}`}>{hour.hour}</option>
                                                ))}
                                            </Form.Select>
                                        </Col>
                                    ) : null}
                                    {errors ? (
                                        <Col className="my-1 mt-5">
                                            <p className='error-p-medismart'>No se puede agendar la hora seleccionada</p>
                                        </Col>
                                    ) : null}
                                    <Button 
                                        disabled={stateHours === null ? true : false} 
                                        onClick={sendSchedule} 
                                        className={stateHours === null ? 'shadow btn-gray-schedule' : 'shadow btn-blue-schedule'} 
                                    >
                                        <div className='d-flex justify-content-center mt-1'>
                                            <p>
                                                Agendar hora
                                            </p>
                                        </div>
                                    </Button>
                                </div>
                            </Row>
                        </Container>
                    ) : (
                        <ConfirmScheduleMedismart />
                    )
                )}
            </HappContainer>
        </Row>        
    );
}
 
export default MedismartSchedule;