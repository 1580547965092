import React from 'react';
import 'assets/css/spinner.scss';
import { Image } from 'react-bootstrap';
import ReactLogo from 'assets/imgs/icono-indicadores.svg'

const LoginLoader = ({show}) => {
    return (
        <div className={`preloader bg-soft flex-column justify-content-center align-items-center ${show ? "" : "show"}`}>
            <Image className="loader-element animate_animated animate_jackInTheBox" src={ReactLogo} height={40} />
        </div>
    )
}

export default LoginLoader;