import axiosClient from 'happ-config/axios';

const getPHQ9Calculation = async patient => {
    try {
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await axiosClient.get(`/mental-health-journey/get-phq9-calculation/${patient}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const getMentalHealthPrescription = async (patient, type) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await axiosClient.get(`/teledoc/get-last-consultation-by-patient/${patient}/${type}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const mentalHealthSurvey = async (survey, patient, type, name) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
        };
        const body = {
            type,
            patient,
            results: survey,
            name
        }
        const response = await axiosClient.post('/survey', body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

export {
    getPHQ9Calculation,
    getMentalHealthPrescription,
    mentalHealthSurvey
}