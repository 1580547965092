import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Row, Col } from 'react-bootstrap';

//components
import { HappContainer } from 'happ-components/HappStyledComponents/HappContainer/HappContainer';
import ExamsList from 'happ-components/ExamsList/ExamsList';

const ExamHistory = () => {
    return (
        <Row className="mt-content">
            <HappContainer>
                <Col className='pt-2'>
                    <div className='d-flex justify-content-center'>
                        <div className='text-center py-2 text-white primary-bg-color  rounded-1 indicators-content'>
                            <strong className='title-stages' >Tus exámenes históricos</strong>
                        </div>
                    </div>
                </Col>
                <Col sm={12} lg={12} md={12}>
                    <ExamsList />
                </Col>
            </HappContainer>
        </Row>
    );
}

export default ExamHistory;