import {
    INIT_SEND_CANCER_SURVEY,
    SEND_CANCER_SURVEY,
    ERROR_SEND_CANCER_SURVEY,
    CLEAN_CANCER_SURVEY,
    INIT_GET_LAST_CANCER_PRESCRIPTION,
    GET_LAST_CANCER_PRESCRIPTION,
    ERROR_GET_LAST_CANCER_PRESCRIPTION,
    INIT_GET_CANCER_SURVEY_CALCULATION,
    GET_CANCER_SURVEY_CALCULATION,
} from 'happ-redux/happ-types';

const initialState = {
    loading: false,
    cancerPrescription: null,
    saveCancerSurvey: null,
    riskGroup: null,
    loadingSave: false,
    cancerCalculation: null,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action){
    switch(action.type){
        case INIT_GET_LAST_CANCER_PRESCRIPTION:  
        case INIT_GET_CANCER_SURVEY_CALCULATION:
            return {
                ...state,
                loading: true,
            }

        case INIT_SEND_CANCER_SURVEY:
            return {
                ...state,
                loadingSave: true,
            }   

        case GET_LAST_CANCER_PRESCRIPTION:
            return {
                ...state,
                loading: false,
                cancerPrescription: action.payload.pdf.cancerPrescription ? action.payload.pdf.cancerPrescription.prescriptionLink : null,
                cancerCalculation: action.payload.calculation,
                riskGroup: action.payload.calculation.calculation,
            }   
        case ERROR_GET_LAST_CANCER_PRESCRIPTION:
            return {
                ...state,
                loading: false,
                riskGroup: null,
                cancerPrescription: null,
            }      
        case SEND_CANCER_SURVEY:     
            return {
                ...state,
                loadingSave: false,
                saveCancerSurvey: true,
                cancerCalculation: action.payload.calculationCancer,
                riskGroup: action.payload.calculationCancer.calculation,
                cancerPrescription: action.payload.cancerPrescription.pdf || null,
            }

        case ERROR_SEND_CANCER_SURVEY: 
            return {
                ...state,
                loadingSave: false,
                saveCancerSurvey: false,
                riskGroup: null,
                cancerCalculation: null,
                cancerPrescription: null,
            }  

        case CLEAN_CANCER_SURVEY:
            return {
                ...state,
                saveCancerSurvey: null,
            }   
            
        default:
            return state;   
    }
}