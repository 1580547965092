import TableModal from 'happ-components/TableModal/TableModal'
import React, { useState } from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'

const AccordionModalExamColesterol = ({title}) => {
  const [activeKey, setActiveKey] = useState("0");

  return (
    <Accordion activeKey={activeKey} className="accordion-modal my-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header onClick={() => setActiveKey("0")}>
          <Row>
            <Col>
            <h6 className='fw-bold'>Perfil Lipídico</h6>
            <div className='accordion-description'>Los resultados de tu <strong>Perfil Lipídico</strong> son compatibles con el diagnóstico de <strong>Dislipidemia</strong></div>
            </Col>
          </Row>
        </Accordion.Header>

        <Accordion.Body>
          <Row>
          <TableModal title={title} />
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default AccordionModalExamColesterol
