import React from 'react'
import { useSelector } from 'react-redux'
import { DownloadIcon } from 'happ-icons/icons'
import { Button } from 'react-bootstrap'

const ButtonDownloadModalExam = () => {
    const { diagExamFile } = useSelector(state => state.auth);
    const handleClick = () => {
        if (diagExamFile) {
            window.open(diagExamFile, '_blank'); 
        }
    };
    return (
        <>
            {diagExamFile ? (
                <Button 
                    variant="outline-primary"
                    onClick={handleClick}
                >
                    <DownloadIcon
                        className=''
                    />
                </Button>
            ) : null}
        </>
    )
}

export default ButtonDownloadModalExam