export const SURVEYQUESTIONS = {
    'cancer-mama': {
        question1: '¿Su madre, hermana o hija ha tenido o tiene de Cáncer de mamas?',
        question2: '¿Usted se ha realizado una mamografía dentro del ultimo año?',
        type: 'cancer-mama-survey',
        title: 'Encuesta de cancer de mama'
    },
    'cancer-cervix': {
        question1: '¿Usted se ha realizado un PAP dentro de los ultimos 3 años?',
        type: 'cancer-cervix-survey',
        title: 'Encuesta de cancer de cervix'
    },
    'cancer-colon': {
        question1: '¿Sus padres, hermanos o hijos han tenido o tienen de Cáncer de colon antes de sus 60 años?',
        question2: '¿Se ha realizado una colonoscopia los ultimos 5 años?',
        type: 'cancer-colon-survey',
        title: 'Encuesta de cancer de colon'
    },
    'cancer-pulmon': {
        question1: '¿Fuma en promedio mas de 1 cigarro al día?',
        question2: '¿Usted ha fumado cerca de 1 cajetilla diaria por 20 años o mas?',
        question3: '¿En su trabajo tiene exposición ambiental a asbesto o arsénico?',
        question4: '¿Pérdida de peso con apetito disminuido y tos con sangre actual?',
        question5: '¿Sus padres, hermanos o hijos han tenido o tienen de Cáncer de pulmon?',
        question6: '¿tiene o ha tenido fibrosis pulmonar o efisema pulmonar?',
        question7: '¿Se ha realizado un TAC de torax el ultimo año?',
        type: 'cancer-pulmon-survey',
        title: 'Encuesta de cancer de pulmon',
    },
    'cancer-prostata': {
        question1:'¿Su padre, hermano o hijos han tenido o tienen de Cáncer de próstata?',
        question2:'¿Usted presenta dificultad para empezar a orinar?',
        question3:'¿Usted presenta flujo de orina débil o interrumpido?',
        question4:'¿Usted orina con frecuencia, especialmente en la noche?',
        question5:'¿Usted presenta sangramiento en orina o semen?',
        question6:'¿Usted presenta dolor al eyacular?',
        question7:'¿Usted presenta dificultad para vaciar la vejiga por completo?',
        question8:'¿Usted se ha realizado un prueba PSA el ultimo año?',
        type: 'cancer-prostata-survey',
        title: 'Encuesta de cancer de prostata',
    }
}

export const healthForecastLinks = {
    'Fonasa': 'https://www.supersalud.gob.cl/difusion/665/w3-article-6317.html',
    'Isapre Colmena': 'https://www.colmena.cl/convenio-bono-costo-cero/',
    'Isapre Cruz Blanca': 'https://cruzblanca.cl/convenios',
    'Isapre Banmédica': 'https://www.banmedica.cl/red-ppi/',
    'Isapre Consalud': 'https://www.consalud.cl/beneficios/bonos-costo-cero.html',
    'Isapre Vida tres': 'https://www.vidatres.cl/red-de-prestadores-ppi/',
    'Isapre Esencial': 'https://www.somosesencial.cl/prevencion/chequeate-alemana-esencial',
    'Isapre Nueva masvida': 'https://www.nuevamasvida.cl/bonos-costo-0/',
}
