import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Row, Col, Nav, InputGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import SweetAlert2 from 'react-sweetalert2';
//components
import { HappContainer } from 'happ-components/HappStyledComponents/HappContainer/HappContainer';
import { SubNav } from 'happ-components/HappStyledComponents/SubNavOptions/SubNavOptions';
//actions
import { signOff, updateProfileAction } from 'happ-redux/happ-actions/auth/authActions';
//custom-hooks
import useAuthProfile from 'happ-custom-hooks/useAuthProfile/useAuthProfile';
import useValidatorForm from 'happ-custom-hooks/useValidatorForm/useValidatorForms';
import { StreamTwoTone } from '@mui/icons-material';
import TermsAndConditionsModalText from 'happ-views/Login/TermsAndConditionsModalText'
import useAuth from 'happ-custom-hooks/useAuth/useAuth';
import moment from 'moment';
import 'moment/min/locales';

let initState = {
    dni: '',
    firstName: '',
    lastName: '',
    lastName2: '',
    email: '',
    phone: '',
    gender: '',
    healthForecast: '',
    birthDay: new Date(),
    commune: "",
    region: "",
    street: "",
    company: '',
    branchOffice: '',
};

const Profile = () => {
    moment.locale('es');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [patientId, setPatientId] = useState('')
    const [fecha, setFecha] = useState('');
    const endSession = e => {
        e.preventDefault();
        dispatch(signOff(navigate));
    }
    // useAuthProfile();
    const { updateProfileInfo } = useSelector(state => state.auth);
    const { id } = useAuth();
    const [updateProfileMessage, setUpdateProfileMessage] = useState({
        show: true,
        text: 'Perfil actualizado con exito',
        type: 'success',
        icon: 'success',
    });

    const {
        state,
        errors,
        comunes,
        stateReg,
        handlerSubmit,
        handlerChangeReg,
        handleChangePhone,
        handlerChangeDate,
        handlerChange
    } = useValidatorForm(initState, updateProfile);

    useEffect(() => {
        if (id !== undefined) {
            setPatientId(id)
        }
        console.log(patientId)
    }, [id])

    const {
        dni,
        firstName,
        lastName,
        lastName2,
        email,
        phone,
        gender,
        healthForecast,
        birthDay,
        commune,
        region,
        street,
        company,
        branchOffice,
    } = state;

    // const apellidos = lastName.split(' ');

    function updateProfile() {
        console.log('errors', errors);
        console.log(birthDay);
        const body = {
            firstName,
            lastName: `${lastName} ${lastName2}`,
            fullName: `${firstName} ${lastName} ${lastName2}`,
            email,
            phone,
            gender,
            healthForecast,
            birthDay,
            address: [{
                commune,
                region,
                street,
                alias: 'Dirección'
            }],
        }
        // console.log(body)
        dispatch(updateProfileAction(patientId, body, navigate));
    }

    return (
        <Row className="mt-content">
            <HappContainer>
                <Col className='pt-2'>
                    <div className='d-flex justify-content-center'>
                        <div className='text-center px-5 py-2 text-white primary-bg-color  rounded-1 indicators-content'>
                            <strong className='title-stages' >Mi perfil</strong>
                        </div>
                    </div>
                </Col>
                {/* <Col sm={12} lg={12} md={12} className="mt-5 mb-5 text-center">
                    <Link
                        to="/forgot-password"
                        className="btn primary-bg-color text-white"
                    >Cambiar Contraseña</Link>
                </Col> */}
                <Col sm={12} lg={12} md={12} className="mt-5 mb-5">
                    <div className="content-profile-form">
                        {updateProfileInfo && updateProfileInfo ? (
                            <SweetAlert2
                                {...updateProfileMessage}
                            />
                        ) : null}
                        <Form
                            onSubmit={handlerSubmit}
                        >
                            <Row className="mb-3">
                                <Col sm={4} md={4} className="my-1">
                                    <Form.Group controlId="firstName">
                                        <Form.Label>Nombres</Form.Label>
                                        <Form.Control
                                            className={errors && errors.firstName ? 'border-require' : ''}
                                            type="text"
                                            name="firstName"
                                            onChange={handlerChange}
                                            value={firstName ? firstName : ''}
                                            placeholder="Nombres"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={4} md={4} className="my-1">
                                    <Form.Group controlId="lastName">
                                        <Form.Label>Apellido Paterno</Form.Label>
                                        <Form.Control
                                            className={errors && errors.lastName ? 'border-require' : ''}
                                            type="text"
                                            name="lastName"
                                            placeholder="Apellido Paterno"
                                            onChange={handlerChange}
                                            value={lastName ? lastName : ''}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={4} md={4} className="my-1">
                                    <Form.Group controlId="lastName2">
                                        <Form.Label>Apellido Materno</Form.Label>
                                        <Form.Control
                                            className={errors && errors.lastName2 ? 'border-require' : ''}
                                            type="text"
                                            name="lastName2"
                                            placeholder="Apellido Materno"
                                            onChange={handlerChange}
                                            value={lastName2 ? lastName2 : ''}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={4} md={4} className="my-1">
                                    <Form.Group className="mb-3" controlId="formGridAddress1">
                                        <Form.Label>Teléfono</Form.Label>
                                        <div className='d-flex'>
                                            {/* <InputGroup.Text id="basic-addon1">+56</InputGroup.Text> */}
                                            <Form.Control
                                                className={errors && errors.phone ? 'border-require' : ''}
                                                type="text"
                                                placeholder="Teléfono"
                                                name="phone"
                                                onChange={handleChangePhone}
                                                value={phone ? phone : ''}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col sm={4} className="my-1">
                                    <Form.Group controlId="gender">
                                        <Form.Label>Genero</Form.Label>
                                        <Form.Select
                                            className={errors && errors.gender ? 'border-require' : ''}
                                            name="gender"
                                            value={gender ? gender : ''}
                                            onChange={handlerChange}

                                        >
                                            <option value="">Selecione un genero</option>
                                            <option value="M">Masculino</option>
                                            <option value="F">Femenino</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col sm={4} className="my-1">
                                    <Form.Group className="mb-3" controlId="birthDay">
                                        <Form.Label>Fecha de nacimiento:</Form.Label>
                                        <Form.Control
                                            className={errors && errors.birthDay ? 'border-require' : ''}
                                            type="date"
                                            name="birthDay"
                                            placeholder="1989-02-13"
                                            onChange={handlerChangeDate}
                                            value={birthDay ? birthDay : ''}
                                        />
                                        {/* <input type="date" id="fecha" name="fecha" value={birthDay ? birthDay : ''} onChange={handlerChange} /> */}

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col sm={6} className="my-1">
                                    <Form.Group className="mb-3" controlId="email">
                                        <Form.Label>Correo Electronico</Form.Label>
                                        <Form.Control
                                            className={errors && errors.email ? 'border-require' : ''}
                                            type="text"
                                            name="email"
                                            placeholder="Correo Electronico"
                                            onChange={handlerChange}
                                            value={email ? email : ''}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6} className="my-1">
                                    <Form.Group className="mb-3" controlId="street">
                                        <Form.Label>Dirección</Form.Label>
                                        <Form.Control
                                            className={errors && errors.street ? 'border-require' : ''}
                                            type="text"
                                            name="street"
                                            placeholder="Dirección"
                                            onChange={handlerChange}
                                            value={street ? street : ''}
                                        />
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row className="mb-3">

                                <Col sm={3} className="my-1">
                                    <Form.Group controlId="region">
                                        <Form.Label>Región</Form.Label>
                                        <Form.Select
                                            name="region"
                                            className={errors && errors.region ? 'border-require' : ''}
                                            onChange={handlerChangeReg}
                                            value={region ? region : ''}
                                        >
                                            <option value="">Selecione una región</option>
                                            {stateReg && Object.keys(stateReg).length > 0 ? (
                                                stateReg.map(reg => (
                                                    <option key={reg.id} value={reg.name}>{reg.name}</option>
                                                ))
                                            ) : null}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                {/* {comunes && Object.keys(comunes).length > 0 ? (
                                    <> */}
                                <Col sm={3} className="my-1">
                                    <Form.Group controlId="commune">
                                        <Form.Label>Comuna</Form.Label>
                                        <Form.Select
                                            className={errors && errors.commune ? 'border-require' : ''}
                                            name="commune"
                                            value={commune ? commune : ''}
                                            onChange={handlerChange}
                                        >
                                            <option value="">Selecione una comuna</option>
                                            {comunes && comunes.map(comuna => (
                                                <option key={comuna.id} value={comuna.name}>{comuna.name}</option>

                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col sm={6} className="my-1">
                                    <Form.Group controlId="healthForecast">
                                        <Form.Label>Previsión de Salud</Form.Label>
                                        <Form.Select
                                            name="healthForecast"
                                            className={errors && errors.healthForecast ? 'border-require' : ''}
                                            value={healthForecast ? healthForecast : ''}
                                            onChange={handlerChange}
                                        >
                                            <option value="">Selecione una previsión de salud</option>
                                            <option value="Fonasa">Fonasa</option>
                                            <option value="Isapre Colmena">Isapre Colmena</option>
                                            <option value="Isapre Cruz Blanca">Isapre Cruz Blanca</option>
                                            <option value="Isapre Banmédica">Isapre Banmédica</option>
                                            <option value="Isapre Consalud">Isapre Consalud</option>
                                            <option value="Isapre Vida tres">Isapre Vida tres</option>
                                            <option value="Isapre Esencial">Isapre Esencial</option>
                                            <option value="Isapre Nueva masvida">Isapre Nueva masvida</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                {/* </>
                                ) : null} */}
                            </Row>
                            <Row className="mb-3">
                                <Col sm={6} className="my-1">
                                    <Form.Group controlId="company">
                                        <Form.Label>Empresa</Form.Label>
                                        <Form.Control
                                            name="company"
                                            type="text"
                                            placeholder="Empresa"
                                            onChange={handlerChange}
                                            disabled
                                            value={company.name ? company.name : ''}
                                        />
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row className="align-items-center mt-5">
                                <Col sx={12} lg={12} md={12} className="my-1 text-center">
                                    <Button
                                        type="submit"
                                        className="btn primary-bg-color text-white"
                                    >
                                        Actualizar
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
                <Col sm={12} lg={12} md={12} className="mt-5 mb-5 text-center terms-col">
                    <TermsAndConditionsModalText />
                </Col>

                <Col sm={12} lg={12} md={12} className="mt-5 mb-5 text-center">
                    <Button
                        onClick={endSession}
                        type="button"
                        className="btn danger-bg-color text-white"
                    >
                        Cerrar sesión
                    </Button>
                </Col>
            </HappContainer>
        </Row>
    );
}

export default Profile;