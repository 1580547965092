import {
    INIT_HEALTH_SCORE,
    HEALTH_SCORE,
    ERROR_HEALTH_SCORE,
    LOGOUT
} from 'happ-redux/happ-types';

const initialState = {
    loading: false,
    healthIndicators: null,
    hypertensionParams: null,
    errorServer: false,
    errorMsg: null,
    rcvPatient: null
}

export default function(state = initialState, action){
    switch(action.type){
        case INIT_HEALTH_SCORE:
            return {
                ...state,
                loading: true,
            }
        case HEALTH_SCORE:
            return {
                ...state,
                loading: false,
                errorServer: false,
                errorMsg: null,
                healthIndicators: action.payload.resObj,
                hypertensionParams: action.payload.hypertesionParams,
                rcvPatient: action.payload.rcvpatient
            }
        case ERROR_HEALTH_SCORE:
            return {
                ...state,
                loading: false,
                errorMsg: action.payload,
                rcvPatient: null,
                healthIndicators: null,
                hypertensionParams: null,
                errorServer: true
            }                
        case LOGOUT:
            localStorage.clear();
            return {
                loading: false,
                healthIndicators: null,
                errorServer: false,
                hypertensionParams: null,
                errorMsg: null,
                rcvPatient: null
            }  
        default:
            return state;
    }
}