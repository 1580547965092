import React from 'react'
import { useSelector } from 'react-redux';

const useSchedule = () => {
    const {loading, arrayDays, arrayHours, day, hour, errorMsg, scheduleId, statusDelete, statusCodeSchedule, schedule, statusScheduleActives} = useSelector(state => state.schedule);
 
    return{
        loading,
        arrayDays,
        arrayHours,
        day,
        hour,
        errorMsg,
        scheduleId,
        statusDelete,
        statusCodeSchedule, // 200(true) o 404(false) 
        schedule, //objeto no usadi
        statusScheduleActives // true o false dentro de scheduleActives
    }
}

export default useSchedule