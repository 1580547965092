import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import {ProgramsIcon, SmallTipsIcon, SmallExamsIcon} from 'happ-icons/icons';

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: '#044064',
    zIndex: 1,
    color: '#fff',
    width: 46,
    height: 46,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ['@media (max-width: 700px)'] : {
      width: 32,
      height: 32
    }
  }));

  const ColorlibStepIconRootSelect = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: '#249CF3',
    zIndex: 1,
    color: '#fff',
    width: 76,
    height: 76,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ['@media (max-width: 700px)'] : {
      width: 60,
      height: 60
    }
  }));

  
  const  ColorlibStepIcon = props => {
    const { active, completed, className } = props;
  
    const icons = {
      2: <SmallTipsIcon />,
      4: <SmallExamsIcon />,
    };
  
    return (
      <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  const ColorlibStepIconSelect = props => {
    const { active, completed, className } = props;
  
    const icons = {
      3: <ProgramsIcon fill='#ffffff' />,
    };
  
    return (
      <ColorlibStepIconRootSelect ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </ColorlibStepIconRootSelect>
    );
  }

const TimeLineIndicators = () => {
    return (
        <div className="content-timeline-indicators mt-5">
            <Box sx={{ width: '100%' }}>
                <Stepper activeStep={1} alternativeLabel>
                    <Step>
                        <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
                    </Step>
                    <Step>
                        <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
                    </Step>
                    <Step>
                        <StepLabel className="step-select" StepIconComponent={ColorlibStepIconSelect}></StepLabel>
                    </Step>
                    <Step>
                        <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
                    </Step>
                    <Step>
                        <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
                    </Step>
                </Stepper>
            </Box>
        </div>
    );
}
 
export default TimeLineIndicators;