import axios from 'axios';

const getBaseURL = () => {
    return process.env.REACT_APP_URL_OAUTH_SERVER || 'https://dev.oauth.happlabs.cl/oauth/'
}
const getAPIkey = () => {
    return process.env.REACT_APP_KEY_OAUTH_SERVER || 'anotherpassword'
}
const getToken = () => { return localStorage.getItem('token'); }


const fetchClient = async (client_id) => {
    try {
        const response = await axios({
            method: 'get',
            baseURL: getBaseURL(),
            url: `client/${client_id}`,
            headers: {
                'Authorization': getAPIkey()
            }
        })
        return response.data
    } catch (e) {
        console.log(e)
        return e
    }
}

const getRedirect = async (clientCredentials) => {
    const data = clientCredentials
    try {
        const response = await axios({
            method: 'get',
            baseURL: getBaseURL(),
            url: 'authorize',
            headers: { 'Authorization': `Bearer ${getToken()}` },
            params: {
                'response_type': data.response_type,
                'client_id': data.client_id,
                'client_secret': data.client_secret,
                'redirect_uri': data.redirect_uri,
                'scope': data.scope,
            }
        })
        return response.data
    } catch (e) {
        console.log(e)
        return (e)
    }
}

const validateAuth = async (client_credentials) => {
    const data = client_credentials
    try {
        const response = await axios({
            method: 'get',
            baseURL: getBaseURL(),
            url: 'isAuth',
            headers: { 'Authorization': `Bearer ${getToken()}` },
            params: {
                'response_type': data.response_type,
                'client_id': data.client_id,
                'client_secret': data.client_secret,
                'redirect_uri': data.redirect_uri,
                'scope': data.scope,
            }
        })
        return response.data
    } catch (e) {
        console.log(e)
        return (e)
    }
}

export {
    fetchClient,
    getRedirect,
    validateAuth,
    //se exportan las funciones
}