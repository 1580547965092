import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
// components
import { HighRisk } from 'happ-icons/icons';
import { ModerateRisk } from 'happ-icons/icons';
import { LowRisk } from 'happ-icons/icons';
import { HappContentIndicator } from 'happ-components/HappStyledComponents/HappContentIndicator/HappContentIndicator';
import { OverweightIcon, PressureIndicator } from 'happ-icons/icons';
import { useModal } from 'happ-custom-hooks/useModal/useModal';
import { DiabetesIndicatorBoxExam } from 'happ-icons/icons';
import { DislipidemiaIcon, TobaccoWarning } from 'happ-icons/icons';


const MyDiagnoses = ({ medicalStageResults }) => {
    const {
        openDiagnosisCompleteDelivery,
        ShowModalDiagnosisCompleteDelivery
    } = useModal();
    const { rcvPatient } = useSelector(state => state.auth);
    return ( 
        <>
            <div className="d-flex justify-content-center rcv-diagnosis px-1"  >
                {rcvPatient !== null ? (
                    <div className='indicators-content d-flex justify-content-center w-100 mt-2' >
                        {rcvPatient.rcv === 3 ? (
                            <HighRisk />
                        ) : null}
                        {rcvPatient.rcv === 2 ? (
                            <ModerateRisk />
                        ) : null}
                        {rcvPatient.rcv === 1 ? (
                            <LowRisk />
                        ) : null}
                    </div>
                ) : null}
            </div>
            <Row className='me-1 ms-1'>
                <div className="d-flex justify-content-center mb-3 mt-3">
                    {medicalStageResults && medicalStageResults.map(item => (
                        <HappContentIndicator
                            key={item._id}
                            className={`box-content-flex box-indicator-diagnosis indicator-bg-${item.color}`}
                            onClick={openDiagnosisCompleteDelivery(item.code, item)}
                            role="button"
                            style={{position: 'relative', aspectRatio: 1/1}}
                        >
                            <Col>
                                <Row>
                                    {item.code === 'hipertension' ?
                                        (
                                            <>
                                                <div className='mb-3'>
                                                    <PressureIndicator
                                                        fill='white'
                                                        responsive='scale-mobile'
                                                    />
                                                </div>
                                            </>
                                        ) : item.code === 'obesidad' ?
                                            (
                                                <>
                                                    <div className='mb-3'>
                                                        <OverweightIcon
                                                            fill='white'
                                                            responsive='scale-mobile'
                                                            style={{width: '45px', height: '45px'}}
                                                        />
                                                    </div>
                                                </>
                                            ) : item.code === 'diabetes' || item.code === 'pre-diabetes' ?
                                                (
                                                    <>
                                                        <div className='mb-3'>
                                                            <DiabetesIndicatorBoxExam
                                                                fill='white'
                                                                responsive='scale-mobile'
                                                                style={{width: '45px', height: '45px'}}
                                                            />
                                                        </div>
                                                    </>
                                                ) : item.code === 'dislipidemia' ?
                                                    (
                                                        <>
                                                            <div className='mb-3'>
                                                                <DislipidemiaIcon
                                                                    style={{width: '45px', height: '45px'}}
                                                                    fill='white'
                                                                    responsive='scale-mobile'
                                                                />
                                                            </div>
                                                        </>
                                                    ) : null}
                                </Row>
                                <Row style={{position: 'absolute'}} className={`dark-opacity border-bottom-container-diagnosis`}>
                                    <Col className='d-flex justify-content-center padding-0'>
                                        {item.code === 'hipertension' ?
                                            (
                                                <>
                                                    <p style={{ fontSize: '10px', lineHeight: '25px' }}>Hipertensión</p>
                                                </>
                                            ) : item.code === 'obesidad' ?
                                                (
                                                    <>
                                                        <p style={{ fontSize: '10px', lineHeight: '25px' }}>
                                                            {item.status === 'Saludable' ? (
                                                                'Peso Normal'
                                                            ) : (
                                                            <>
                                                                {item.status}
                                                            </>
                                                            )}
                                                        </p>
                                                    </>
                                                ) : item.code === 'diabetes' || item.code === 'pre-diabetes' ?
                                                    (
                                                        <>
                                                            <p style={{ fontSize: '10px', lineHeight: '25px' }}>Diabetes</p>
                                                        </>
                                                    ) : item.code === 'dislipidemia' ? (
                                                        <>
                                                            <p style={{ fontSize: '10px', lineHeight: '25px' }}>Dislipidemia</p>
                                                        </>
                                                    ) : null}
                                    </Col>
                                </Row>
                            </Col>
                        </HappContentIndicator>
                    ))}
                </div>
                <ShowModalDiagnosisCompleteDelivery />
            </Row>
            <Row className='d-flex justify-content-center px-1 treatment-icon-content'>
                <TobaccoWarning />
            </Row>
        </>
    );
}
 
export default MyDiagnoses;