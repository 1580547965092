import { CleanHands } from "@mui/icons-material";
import { checkRut } from "react-rut-formatter";

export default function validateInputTypes(inputs){
    
    let errors = {};

    if( ('email' in inputs) && (inputs.email === '' || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputs.email)) ){
        errors.email = 'El Email es requerido o ingreso un mail no valido';
    } 
    if( ('password' in inputs) && ((inputs.password === '') || (inputs.password.length < 6))  ) {
        errors.password = 'La contraseña debe ser con un minimo de 6 digitos';
    }
    if( ('copyPassword' in inputs) && ((inputs.copyPassword === '') || (inputs.copyPassword.length < 6))  ) {
        errors.copyPassword = 'La contraseña debe ser con un minimo de 6 digitos';
    }
    if ( ('first_name' in inputs) && inputs.first_name === '' ){
        errors.first_name = 'El Nombre es requerido'
    }
    if ( ('last_name' in inputs) && inputs.last_name === '' ){
        errors.last_name = 'El Apellido es requerido'
    }
    if ( ('dni' in inputs) && inputs.dni === ''  ){
        errors.dni = 'El Rut es requerido'
    }
    if ( ('dni' in inputs) && !checkRut(inputs.dni) ){
        errors.dni = 'El Rut no es valido'
    }
    if( ('gender' in inputs) && inputs.gender === '' ) {
        errors.gender = 'El genero es requerido';
    }
    if( ('phone' in inputs) && inputs.phone === '' ) {
        errors.phone = 'EL número de teléfono es requerido';
    }
    if( ('region' in inputs) && inputs.region === '' ) {
        errors.region = 'Seleccione una Región valida';
    }    
    if( ('commune' in inputs) && inputs.commune === '' ) {
        errors.commune = 'Seleccione una Comuna valida';
    }
    if( ('street' in inputs) && inputs.street === '' ) {
        errors.street = 'Ingrese una direccion de domicilio';
    }
    if( ('birthDay' in inputs) && (inputs.birthDay === '' || inputs.birthDay === 'Invalid date') ) {
        errors.birthDay = 'Escriba su fecha de nacimiento';
    }
    if( ('healthForecast' in inputs) && inputs.healthForecast === '' ) {
        errors.healthForecast = 'Seleccione su previsión de salud';
    }

    return errors;
}