import React, { useState } from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import { HappContainer } from 'happ-components/HappStyledComponents/HappContainer/HappContainer';
import useAuth from 'happ-custom-hooks/useAuth/useAuth';
import useSurvey from 'happ-custom-hooks/useSurvey/useSurvey';
import FlowCutView from 'happ-components/BreastCancerSurveyParts/FlowCutView';
import FlowFinishView from 'happ-components/BreastCancerSurveyParts/FlowFinishView';

const initState = {
    step: 1,
    progress: Math.round((1 * 100) / 2),
    question1: -1,
    question2: -1,
    total: 2,
}

const BreastCancerSurvey = () => {
    const [okSurvey, setOkSurvey] = useState({
        show: true,
        text: 'Encuesta registrada con exito',
        type: 'success',
        icon: 'success',
    });
    const { 
        gender, 
        patientAge, 
        profile, 
        healthForecast 
    } = useAuth();
    const { 
        state,
        flowCut,
        finishFlow,
        isMedicalOrder,
        healthForecastLink,
        cancerPrescription,
        riskGroup,
        cancerCalculation,
        cancerMamaStructure,
    } = useSurvey('cancer-mama', patientAge, healthForecast, false, gender, initState, profile?._id);
    const { step, progress } = state;
    return ( 
        <Row className="mt-content">
            <HappContainer>
                {(flowCut !== null && flowCut === true) || (riskGroup !== null && riskGroup.calculation === 'no-grupo-riesgo') ? (
                    <>
                        <FlowCutView />
                    </>
                ) : (finishFlow !== null && finishFlow === true) || (riskGroup !== null && riskGroup.calculation === 'grupo-riesgo') ? (
                    <>
                        <FlowFinishView 
                            isMedicalOrder={isMedicalOrder}
                            healthForecastLink={healthForecastLink}
                            healthForecast={healthForecast}
                            cancerPrescription={cancerPrescription}
                            cancerCalculation={cancerCalculation}
                        />
                    </>
                ) : (
                    <>
                        <Col className='pt-2'>
                            <div className='d-flex justify-content-center'>
                                <div className='text-center px-5 py-2 text-white primary-bg-color  rounded-1 indicators-content'>
                                    <strong className='title-stages' >Encuesta Cáncer de Mama</strong>
                                </div>
                            </div>
                        </Col>
                        <Col md={9} sm={12} lg={9} className='progress-bar-content'>
                            <ProgressBar now={progress} />
                        </Col>
                        {cancerMamaStructure(step)}
                    </>
                )}
            </HappContainer>
        </Row>
    );
}
 
export default BreastCancerSurvey;