import {
    INIT_HEALTH_SCORE,
    HEALTH_SCORE,
    ERROR_HEALTH_SCORE,
    LOGOUT
} from 'happ-redux/happ-types';
import { getHealthScore } from 'happ-redux/happ-services/health-indicators/health-indicators';

export function getHealthIndicatorsAction (navigate) {
    return async (dispatch) => {
        dispatch(initHealthIdicators());
        try {
            const token = localStorage.getItem('token');
            if (token === null){
                dispatch(logout());
                navigate('/login');
            }
            const response = await getHealthScore(token);
            if (response.status === 401){
                dispatch(logout());
                navigate('/login');
            }
            if (response.status === 200) {
                dispatch(indicators(response.data));
            } else {
                dispatch(errorIndicators(response.data.msg));
            }
        } catch (error) {
            dispatch(errorIndicators(error.data.msg));
        }
    }
}

const initHealthIdicators = () => ({
    type: INIT_HEALTH_SCORE
});

const indicators = response => ({
    type: HEALTH_SCORE,
    payload: response
});

const errorIndicators = error => ({
    type: ERROR_HEALTH_SCORE,
    payload: error
});

const logout = () => ({
    type: LOGOUT
});
