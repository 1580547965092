import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Container, Row, Col, Card, Form, Button} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
//components
import {HappContainer} from 'happ-components/HappStyledComponents/HappContainer/HappContainer';
import {FormError} from 'happ-components/HappStyledComponents/HappFormStyles/HappFormStyles';
import logo from 'assets/imgs/imag_.png';
import LoginLoader from 'happ-components/Loader/LoginLoader';
//custom-hooks
import useValidatorFrom from 'happ-custom-hooks/useValidatorForm/useValidatorForms';
// actions
import { authSuperProcessAction } from 'happ-redux/happ-actions/auth/authActions';

const initState = {
    dni: '',
    email: '',
    password: '',
}

const SuperLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        state,
        errors,
        handlerSubmit,
        handlerChange,
        handlerChangeDni
    } = useValidatorFrom(initState, authProcess);
    const {dni, password, email} = state;
    const {errorServer, errorMsg, session} = useSelector(state => state.auth);
    function authProcess(){
        const body = {
            email, 
            password, 
            dniPatient: dni,
        }
        dispatch(authSuperProcessAction(body, navigate));
    }    
    return ( 
        <Container>
            <LoginLoader show={session}/> 
                <Row className="vh-100 d-flex justify-content-center align-items-center">
                    <Col md={8} lg={6} xs={12}>
                        <HappContainer>
                            <Card className="shadow-box border-box">
                                <Card.Body className='card-login'>
                                    <div className="mb-3 mt-md-4">
                                        <div className="content-logo logo-login mt-3">
                                            <img src={logo} alt="description"/>
                                        </div>
                                    </div>
                                        <Form 
                                            className="form-action"
                                            onSubmit={handlerSubmit}
                                        >
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label className="text-center">
                                                    Email Admin
                                                </Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    onChange={handlerChange}
                                                    value={email}
                                                    placeholder="admin@admin.cl" 
                                                    name="email" 
                                                />
                                                {errors.email ? (
                                                    <FormError>{errors.email}</FormError>
                                                ) : null}
                                                {errorServer && errorMsg.email ? (
                                                    <FormError>{errorMsg.email}</FormError>
                                                ) : null}
                                            </Form.Group>

                                            <Form.Group
                                                className="mb-3"
                                                controlId="formBasicPassword"
                                            >
                                                <Form.Label>
                                                    Contraseña
                                                </Form.Label>
                                                <Form.Control 
                                                    type="password" 
                                                    onChange={handlerChange}
                                                    value={password}
                                                    placeholder="******" 
                                                    name="password"
                                                />
                                                {errors.password ? (
                                                    <FormError>{errors.password}</FormError>
                                                ) : null}
                                                {errorServer && errorMsg.password ? (
                                                    <FormError>{errorMsg.password}</FormError>
                                                ) : null}
                                            </Form.Group>

                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label className="text-center">
                                                    Rut Paciente
                                                </Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    onChange={handlerChangeDni}
                                                    value={dni}
                                                    placeholder="11111111-1" 
                                                    name="dni" 
                                                />
                                                {errors.dni ? (
                                                    <FormError>{errors.dni}</FormError>
                                                ) : null}
                                                {errorServer && errorMsg.dniPatient ? (
                                                    <FormError>{errorMsg.dniPatient}</FormError>
                                                ) : null}
                                            </Form.Group>

                                            <div className="d-grid">
                                                <Button 
                                                    className="primary-bg-color" 
                                                    type="submit"
                                                >
                                                    Continuar
                                                </Button>
                                            </div>
                                        </Form>    
                                </Card.Body>
                            </Card>
                        </HappContainer>
                    </Col>
                </Row>
            </Container>
    );
}
 
export default SuperLogin;