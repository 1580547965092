import { FETCH_OAUTH_CLIENT_DATA, SET_REDIRECT_URL } from 'happ-redux/happ-types'
import { fetchClient, getRedirect, validateAuth } from 'happ-redux/happ-services/oauth/oauth-services'
import { SET_OAUTH_LOADING } from 'happ-redux/happ-types'

export function fetchData(id) {
  return async (dispatch) => {
    try {
       const res = await fetchClient(id)
      dispatch(fetchOauthClientData(res)) 
    } catch (e) {
      console.log(e)
    }

  }
}

export function redirectUri(client_data) {
  return async (dispatch) => {
    try {
      const res = await getRedirect(client_data)
      dispatch(setRedirectURL(res))
      window.location.href = res.url
    } catch (e) {
      console.log(e)
    }
  }
}

export function isAuth(creds) {
  return async (dispatch) => {
    dispatch(setLoading(true))
    try{
      const res = await validateAuth(creds)
      if(res.statusCode === 200){
        window.location.href = res.url
      }
      else{
        dispatch(setLoading(false))
      }
    } catch (e) {
      console.log(e)
    }
  }
}

const fetchOauthClientData = (payload) => ({
  type: FETCH_OAUTH_CLIENT_DATA,
  payload
})

const setRedirectURL = (payload) => ({
  type: SET_REDIRECT_URL,
  payload
})

const setLoading = (payload) => ({
  type: SET_OAUTH_LOADING,
  payload
})