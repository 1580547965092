import TableModal from 'happ-components/TableModal/TableModal'
import React, { useState } from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'

const AccordionModalExamPeso = ({title}) => {
  const [activeKey, setActiveKey] = useState("0");

  return (
    <Accordion activeKey={activeKey} className="accordion-modal my-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header onClick={() => setActiveKey("0")}>
          <Row>
            <Col>
            <h6 className='fw-bold'>Masa corporal</h6>
            <div className='accordion-description'>Ante las pruebas realizadas cuentas con un índice de <strong>masa corporal IMC</strong> compatible con un diagnóstico de <strong>Peso Normal</strong></div>
            </Col>
          </Row>
        </Accordion.Header>

        <Accordion.Body>
          <Row>
          <TableModal title='Control de Peso' />
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default AccordionModalExamPeso
