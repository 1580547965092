/* eslint-disable import/no-anonymous-default-export */
import {
    INIT_SESSION,
    LOGIN,
    LOGIN_ERROR,
    LOGOUT,
    INIT_VALIDATE_SESSION,
    VALIDATE_SESSION,
    VALIDATE_SESSION_ERROR,
    INIT_ME,
    ME,
    ME_ERROR,
    INIT_UPDTAE_PROFILE,
    UPDTAE_PROFILE,
    SET_UPDTAE_PROFILE_STATUS,
    UPDTAE_PROFILE_ERROR,
    INIT_FORGET_PASSWORD,
    FORGET_PASSWORD,
    FORGET_PASSWORD_ERROR,
    INIT_RESET_PASSWORD,
    RESET_PASSWORD,
    RESET_PASSWORD_ERROR,
    INIT_MEDICAL_STAGE_DELIVERY,
    MEDICAL_STAGE_DELIVERY,
    ERROR_MEDICAL_STAGE_DELIVERY
} from 'happ-redux/happ-types';

const initialState = {
    profile: null,
    companyName: null,
    isLabConditional: null,
    session: false,
    login: false,
    emailPatient: null,
    emailErrorPatient: null,
    updateProfileInfo: false,
    errorServer: false,
    errorMsg: {},
    loading: false,
    medicalStage: {},
    resetPasswordValidated: false,
    termsAndConditionsClicked: false,
    forgetPasswordErrorServer: false,
    forgetPasswordErrorMsg: {},
    medicalStageResults: null,
    isDislipidemia: false,
    medicalStagePrint: null,
    rcvPatient: null,
    diagExamFile: null,
    lastPrescription: null,
    lastConsultation: null,
    otherExams: null,
    ecgDocument: null,
    sportPlan: null,
    nutritionPlan: null,
    metaExams: null,
    isMetaExams: false,
    determinationsWithMeta: null,
    allHealthy: null,
    medicalOrder: null
}

export default function(state = initialState, action){
    switch(action.type){
        case INIT_SESSION: 
            localStorage.removeItem('token');
            return {
                ...state,
                loading: true,
                session: true
            }
        case INIT_ME:
        case INIT_UPDTAE_PROFILE:    
        case INIT_VALIDATE_SESSION:
        case INIT_FORGET_PASSWORD:
        case INIT_RESET_PASSWORD:
        case INIT_MEDICAL_STAGE_DELIVERY:    
            return {
                ...state,
                loading: true,
            }
        case ME:
            return {
                ...state,
                loading: false,
                profile: action.payload,
                companyName: action.payload.company.name,
                errorServer: false,
                errorMsg: {},
            }
        case ME_ERROR:
            return {
                ...state,
                loading: false,
                profile: null,
                companyName: null,
                errorMsg: action.payload,
                errorServer: true
            }
        case UPDTAE_PROFILE:
            return {
                ...state,
                errorServer: false,
                errorMsg: {},
                loading: false,
                updateProfileInfo: true,
                profile: action.payload.newPatient,
                login: true,
            }
        case UPDTAE_PROFILE_ERROR:
            return {
                ...state,
                loading: false,
                errorServer: true,
                updateProfileInfo: false,
            }  
        case SET_UPDTAE_PROFILE_STATUS:
            return {
                ...state,
                updateProfileInfo: false
            }                                      
        case LOGIN:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                loading: false,
                medicalStage: action.payload.medicalStage,
                session: false,
                errorMsg: {},
                errorServer: false,
                login: true,
                profile: action.payload.patient,
                companyName: action.payload.patient.company.name,
                termsAndConditionsClicked: false,
            }
        case VALIDATE_SESSION:
            return {
                ...state,
                login: true,
                medicalStage: action.payload.medicalStage,
                loading: false,
                errorServer: false,
                errorMsg: {},
                profile: action.payload.profile,
                companyName: action.payload.profile.company.name,
                isLabConditional: action.payload.profile.branchOfficeId.isLabConditional,
            }       
        case LOGIN_ERROR:   
            localStorage.clear();
            return {
                ...state,
                login: false,
                loading: false,
                session: false,
                errorServer: true,
                profile: null,
                errorMsg: action.payload
            }   
        case VALIDATE_SESSION_ERROR:    
        case LOGOUT:
            localStorage.clear();
            return {
                profile: null,
                session: false,
                login: false,
                errorServer: false,
                errorMsg: {},
                loading: false
            }  
        case FORGET_PASSWORD:
            return {
                ...state,
                loading: false,
                emailPatient: action.payload.data.emailTarget,
                emailErrorPatient: null,
                forgetPasswordErrorMsg: {},
                forgetPasswordErrorServer: false
            }
        case FORGET_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                emailPatient: null,
                forgetPasswordErrorMsg: action.payload,
                forgetPasswordErrorServer: true
            }
        case RESET_PASSWORD:
            return {
                ...state,
                loading: false,
                errorServer: false,
                errorMsg: {},
                resetPasswordValidated: true,
            }
        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                errorServer: true,
                errorMsg: action.payload,
                resetPasswordValidated: false,
            }
        case MEDICAL_STAGE_DELIVERY:
            return {
                ...state,
                loading: false,
                medicalStageResults: action.payload.delivery,
                isDislipidemia: action.payload.isDislipidemia,
                medicalStagePrint: action.payload.medicalStage,
                rcvPatient: action.payload.rcv,
                diagExamFile: action.payload.diagExamFile,
                lastPrescription: action.payload.lastPrescription,
                lastConsultation: action.payload.lastConsultation,
                otherExams: action.payload.otherExams,
                ecgDocument: action.payload.ecgDocument,
                sportPlan: action.payload.sportPlan,
                nutritionPlan: action.payload.nutritionPlan,
                metaExams: action.payload.metaExams,
                isMetaExams: action.payload.isMetaExams,
                determinationsWithMeta: action.payload.determinationsWithMeta,
                allHealthy: action.payload.allHealthy,
                medicalOrder: action.payload.lastMedicalOrder,   
            }
        case ERROR_MEDICAL_STAGE_DELIVERY:
            return {
                ...state,
                loading: false,
                medicalStageResults: null,
                determinations: null,
                isDislipidemia: false,
                rcvPatient: null,
                diagExamFile: null,
                medicalStagePrint: action.payload.medicalStagePrint,
                lastPrescription: null,
                lastConsultation: null,
                otherExams: null,
                ecgDocument: null,
                sportPlan: null,
                nutritionPlan: null,
                metaExams: null,
                isMetaExams: false,
                determinationsWithMeta: null,
                allHealthy: null,
                isLabConditional: null,
                medicalOrder: null,
            }    
        default:
            return state;
    }
}