import React, { useState } from 'react';
import { Row, Col, Nav, Container, Button } from 'react-bootstrap';
//components
import { HappContainer } from 'happ-components/HappStyledComponents/HappContainer/HappContainer';
import { SubNav } from 'happ-components/HappStyledComponents/SubNavOptions/SubNavOptions';
import TimeLineIndicators from 'happ-components/TimeLines/TimeLineIndicators';

//sub-Views
import MedicalGoals from 'happ-components/MedicalGoals/MedicalGoals';
import emp from 'assets/imgs/program-stage/emp.svg';
import { useModal } from 'happ-custom-hooks/useModal/useModal';
import HomeMessage from 'happ-components/HomeMessage/HomeMessage';
import Indicators from 'happ-components/Indicators/Indicators';
import { Stage1 } from 'happ-icons/icons';
import { Stage1Agendada } from 'happ-icons/icons';
import { Stage2 } from 'happ-icons/icons';
import { Stage3 } from 'happ-icons/icons';
import { Stage4 } from 'happ-icons/icons';
import Indicators3 from 'happ-components/Indicators/Indicators3';
import Indicators3extra from 'happ-components/Indicators/Indicators3extra';


const Home3extra = () => {
    const [indicatorView, setIndicatorView] = useState(true);
    const [medicalGoalsView, setmedicalGoalsView] = useState(false);
    const activeView = subView => e => {
        e.preventDefault();
        if (subView === 'indicators') {
            setmedicalGoalsView(false);
            setIndicatorView(true);
        } else {
            setIndicatorView(false);
            setmedicalGoalsView(true);
        }
    }
    return (
        <Row className="mt-content">
            <HappContainer>
                {/* <Col sm={12} lg={12} md={12} className="sub-options" style={{border: '1px solid red'}}>
                     <Nav className="">
                        <Nav.Item
                            onClick={activeView('indicators')}
                        >
                            <SubNav
                                bgClass={indicatorView ? 'primary-bg-color' : 'grey-bg-color'}
                                textColor={indicatorView ? '#fff' : '#666666'}
                                brRadius="40px 0px 0px 40px"
                            >
                                Indicadores
                            </SubNav>
                        </Nav.Item>
                        <Nav.Item
                            onClick={activeView('medical-goals')}
                        >
                            <SubNav
                                bgClass={medicalGoalsView ? 'primary-bg-color' : 'grey-bg-color'}
                                textColor={medicalGoalsView ? '#fff' : '#666666'}
                                brRadius='0px 40px 40px 0px;'
                            >
                                Metas y autoregistro
                            </SubNav>
                        </Nav.Item>
                    </Nav> 
                </Col> */}
                {indicatorView ? (
                    <>
                        <Col className='pt-2'>
                            <div className='d-flex justify-content-center'>
                                <div className='text-center px-5 py-2 text-white primary-bg-color  rounded-1 indicators-content'>
                                    <strong className='title-stages'>Chequeo y diagnóstico</strong>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} lg={12} md={12} className="mt-3 mb-4 col-img-stage">
                            {/* <TimeLineIndicators /> */}
                            {/* <img src={emp} alt="Emp"/> */}
                            {/* <Stage1
                            className='stages'
                            /> */}
                            {/* <Stage1Agendada
                                className='stages'
                            /> */}
                            <Stage2
                            className='stages'
                            />
                            {/* <Stage3
                            className='stages'
                            /> */}
                            {/* <Stage4
                            className='stages'
                            /> */}
                        </Col>
                        <Col sm={12} lg={12} md={12} className="mb-5">
                            {/* <HomeMessage/> */}
                            <Indicators3extra />
                        </Col>
                    </>
                ) : null}
                {medicalGoalsView ? (
                    <>
                        <Col sm={12} lg={12} md={12} className="mb-5">
                            <MedicalGoals />
                        </Col>
                    </>
                ) : null}
            </HappContainer>
        </Row>
    );
}

export default Home3extra;