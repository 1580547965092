import React from 'react'
import { ColesterolIndicator } from 'happ-icons/icons'
import { CholesterolBar } from 'happ-icons/icons'
import { PressureIndicator } from 'happ-icons/icons'
import { PresionBar } from 'happ-icons/icons'
import { OverweightIcon } from 'happ-icons/icons'
import { WeightBar } from 'happ-icons/icons'
import { CloseModal } from 'happ-icons/icons'
import { Col, Modal, Row } from 'react-bootstrap'
import { DiabetesIndicator } from 'happ-icons/icons'
import { SugarBar } from 'happ-icons/icons'
import { WeightBars } from 'happ-icons/icons'

const WelcomeDeliveryModal = (props) => {
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          
        >
            <div className='modal-content-blue'>
                <Row className=''>
                    <Col className='mt-2'>
                        <div onClick={props.onHide} className='modal-close-btn primary-color-text'>
                            <CloseModal
                            />
                        </div>
                        <div className='text-center'>
                            {props.code === 'colesterol' ? (
                                <h6 className='text-white'>Chequeo <strong>Colesterol</strong></h6>
                            ) : null}
                            {props.code === 'presion' ? (
                                <h6 className='text-white'>Presión <strong>Arterial</strong></h6>
                            ) : null}
                            {props.code === 'imc' ? (
                                <h6 className='text-white'>Control de <strong>Peso</strong></h6>
                            ) : null}
                            {props.code === 'diabetes' ? (
                                <h6 className='text-white'>Chequeo <strong>Azúcar</strong></h6>
                            ) : null}
                        </div>
                    </Col>
                </Row>

                <Modal.Body style={{ padding: 0 }}>
                    <div className=''>
                        {props.code === 'colesterol' ? (
                            <Row className='my-2'>
                                <Col className='d-flex justify-content-around align-items-center pt-1'>
                                    <div>
                                        <ColesterolIndicator
                                            fill='white'
                                            responsive='scale-mobile'
                                        />
                                    </div>
                                    <div>
                                        <CholesterolBar
                                            className='bar-stage-info'
                                            fill="white"
                                            responsive="scale-mobile"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        ) : null}
                        {props.code === 'presion' ? (
                            <Row className='my-2'>
                                <Col className='d-flex justify-content-around align-items-center'>
                                    <PressureIndicator
                                        fill='white'
                                        responsive='scale-mobile'
                                    />
                                    <PresionBar
                                        className='bar-stage-info'
                                        fill="white"
                                        responsive="scale-mobile"
                                    />
                                </Col>
                            </Row>
                        ) : null}
                        {props.code === 'imc' ? (
                             <Row className='my-2'>
                                <Col className='d-flex justify-content-around align-items-center' >
                                    <OverweightIcon
                                        fill='white'
                                        responsive='scale-mobile'
                                    />
                                    <WeightBars
                                        className='bar-stage-info'
                                        fill="white"
                                        responsive="scale-mobile"
                                    />
                                </Col>
                            </Row>
                        ) : null}
                        {props.code === 'diabetes' ? (
                             <Row className='my-2'>
                                <Col className='d-flex justify-content-around align-items-center' >
                                    <DiabetesIndicator
                                        fill='white'
                                        responsive='scale-mobile'
                                    />
                                    <SugarBar
                                        className='bar-stage-info'
                                        fill="white"
                                        responsive="scale-mobile"
                                    />
                                </Col>
                            </Row>
                        ) : null}
                    </div>

                    <div>
                        <Row className='text-center text-white'>
                            <Col>
                                {props.code === 'colesterol' ? (
                                    <p className='text-center text-white p-modal my-3 mx-2'>El <strong>exceso de colesterol</strong> provoca <strong>obstrucción en las arterias</strong> y otras <strong>enfermedades cardiovasculares</strong> como infartos. 
                                    Es importante mantener los niveles en <strong>rangos normales</strong></p>
                                ) : null}
                                {props.code === 'presion' ? (
                                    <p className='text-center text-white p-modal my-3 mx-2'>La <strong>presión arterial alta</strong> (hipertensión) <strong>daña silenciosamente el organismo</strong>. 
                                    Puede pasar <strong>años</strong> antes de que los <strong>síntomas se manifiesten</strong>s</p>
                                ) : null}
                                {props.code === 'imc' ? (
                                    <p className='text-center text-white p-modal my-3 mx-2'>Mantener un <strong>peso saludable</strong> favorece la buena salud. <strong>Las personas con obesidad </strong> 
                                            están más propensas a <strong>sufrir enfermedades graves</strong></p>
                                ) : null}
                                {props.code === 'diabetes' ? (
                                    <p className='text-center text-white p-modal my-3 mx-2'>Tener <strong>niveles altos de azúcar en la sangre</strong> es nocivo para la salud y puede <strong>causar graves enfermedades </strong> 
                                    como infartos, pérdida de visión y problemas en los riñones, entre otros.</p>
                                ) : null}
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    );
}
 
export default WelcomeDeliveryModal;