import {
    INIT_MEDICAL_ADVICE,
    MEDICAL_ADVICE,
    ERROR_MEDICAL_ADVICE,
    LOGOUT
} from 'happ-redux/happ-types';

const initialState = {
    loading: false,
    errorServer: false,
    errorMsg: null,
    medicalAdvice: null
}

export default function(state = initialState, action){
    switch(action.type){
        case INIT_MEDICAL_ADVICE:
            return {
                ...state,
                loading: true
            }
        case MEDICAL_ADVICE:
            return {
                ...state,
                loading: false,
                errorServer: false,
                errorMsg: null,
                medicalAdvice: action.payload.medicalAdvices
            }    
        case ERROR_MEDICAL_ADVICE:
            return {
                ...state,
                loading: false,
                errorServer: true,
                errorMsg: action.payload,
                medicalAdvice: null
            }
        case LOGOUT:
            localStorage.clear();
            return {
                ...state,
                loading: false,
                errorServer: false,
                errorMsg: null,
                medicalAdvice: null
            }        
        default:
            return state;
    }
}