import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
// components
import { HappContentIndicator } from 'happ-components/HappStyledComponents/HappContentIndicator/HappContentIndicator';
import { OverweightIcon,  PressureIndicator, ColesterolIndicator, DiabetesIndicator } from 'happ-icons/icons';
import { useModal } from 'happ-custom-hooks/useModal/useModal';
const MedicalStageDiagnosisDeliveryMB = ({medicalStageResults}) => {
    const {
        ShowModalDiagnosisDeliveryLC,
        openDiagnosisDeliveryLC
    } = useModal();
    console.log(medicalStageResults);
    return ( 
        <>
        <div className="indicators-content">
            <Row className='px-2'>
                    <>
                        {medicalStageResults && medicalStageResults.map(item => (
                            <Col key={item._id} md={12} xs={12} lg={12} className="px-0 pt-4  col-indicator-mobile">
                                <HappContentIndicator
                                    className={`box-content indicator-bg-${item.color}`} 
                                    onClick={openDiagnosisDeliveryLC(item.code, item)}
                                    role="button"
                                >
                                    <Row>
                                        {item.code === 'hipertension' ? 
                                            (
                                                <>
                                                    <div className='my-2'>
                                                        <PressureIndicator
                                                            fill='white'
                                                            responsive='scale-mobile'
                                                        />
                                                    </div>
                                                </>
                                            ) : item.code === 'obesidad' ? 
                                            (
                                                <>
                                                    <div className='my-2'>
                                                        <OverweightIcon
                                                            fill='white'
                                                            responsive='scale-mobile'
                                                        />
                                                    </div>
                                                </>
                                            ) : item.code === 'diabetes' || item.code === 'pre-diabetes' ? 
                                            (
                                                <>
                                                    <div className='my-2'>
                                                        <DiabetesIndicator
                                                            fill='white'
                                                            responsive='scale-mobile'
                                                        />
                                                    </div>
                                                </>
                                        ) : null}
                                    </Row>
                                    <Row className={`dark-opacity border-bottom-container`}>
                                        <Col >
                                            {item.code === 'hipertension' ? 
                                            (
                                                <>
                                                    <p>Presión Arterial</p>
                                                </>
                                            ) : item.code === 'obesidad' ? 
                                            (
                                                <>
                                                    <p> Chequeo <strong>IMC</strong> </p>
                                                </>
                                            ) : item.code === 'diabetes' || item.code === 'pre-diabetes' ? 
                                            (
                                                <>
                                                    <p>Azúcar</p>  
                                                </>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </HappContentIndicator>
                            </Col>   
                        ))} 
                    </>
                <ShowModalDiagnosisDeliveryLC />
            </Row>
        </div>
    </>
     );
}
 
export default MedicalStageDiagnosisDeliveryMB;