import TableModal from 'happ-components/TableModal/TableModal'
import React, { useState } from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'

const AccordionModalExamAzucar = () => {
  const [activeKey, setActiveKey] = useState("0");

  return (
    <Accordion activeKey={activeKey} className="accordion-modal my-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header onClick={() => setActiveKey("0")}>
          <Row>
            <Col>
              <h6 className='fw-bold'>Azúcar en Sangre</h6>
              <div className='accordion-description'>Los resultados de tus <strong>exámenes</strong> de glicemia son compatibles con el diagnóstico de <strong>Prediabetes</strong> </div>
            </Col>
          </Row>
        </Accordion.Header>

        <Accordion.Body>
          <Row>
            <TableModal title="Chequeo Azúcar" />
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default AccordionModalExamAzucar
