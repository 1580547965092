/* eslint-disable import/no-anonymous-default-export */
import { INIT_ADD_SCHEDULE } from 'happ-redux/happ-types';
import { ERROR_ADD_SCHEDULE } from 'happ-redux/happ-types';
import { DELETED_SCHEDULE } from 'happ-redux/happ-types';
import { INIT_GET_SCHEDULING } from 'happ-redux/happ-types';
import { ERROR_SCHEDULING } from 'happ-redux/happ-types';
import { INIT_UPDATE } from 'happ-redux/happ-types';
import { INIT_SCHEDULE_FORM } from 'happ-redux/happ-types';
import { GET_SCHEDULING404 } from 'happ-redux/happ-types';
import { GET_SCHEDULING } from 'happ-redux/happ-types';
import { DELETE_ERROR } from 'happ-redux/happ-types';
import { INIT_DELETE_SCHEDULE } from 'happ-redux/happ-types';
import { ADD_SCHEDULE } from 'happ-redux/happ-types';
import { SCHEDULE_MEDICAL_ERROR } from 'happ-redux/happ-types';
import { CLEAN_ALL_SCHEDULE } from 'happ-redux/happ-types';
import {
    INIT_SCHEDULE,
    SCHEDULE_MEDICAL
} from 'happ-redux/happ-types';


const initialState = {
    loading: false,
    arrayDays: null,
    arrayHours: null,
    day: null,
    hour: null,
    scheduleId: null,
    statusDelete: null,
    statusCodeSchedule: null,
    schedule: null,
    statusScheduleActives: false,
    errorServer: false,
    errorMsg: null,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case INIT_SCHEDULE:
        case INIT_ADD_SCHEDULE:
        case INIT_DELETE_SCHEDULE:
        case INIT_GET_SCHEDULING:
            return {
                ...state,
                loading: true,
            }
        case SCHEDULE_MEDICAL:
            return {
                ...state,
                loading: false,
                arrayDays: action.payload.arrayDays,
                arrayHours: action.payload.arrayHours,
                // errorServer: false,
                // errorMsg: null,
            }
        case SCHEDULE_MEDICAL_ERROR:
            return {
                ...state,
                loading: false,
                errorServer: true,
                errorMsg: action.payload.message
            }
        case GET_SCHEDULING:
            return {
                ...state,
                loading: false,
                statusCodeSchedule: true, // 200
                schedule: action.payload.schedule, // objeto
                scheduleId: action.payload.schedule._id,
            }
        case GET_SCHEDULING404:
            return {
                ...state,
                loading: false,
                statusCodeSchedule: false, // 404
                statusScheduleActives: action.payload.schedulesActives.schedule, //true o false
            }
        case ERROR_SCHEDULING:
            return {
                ...state,
                loading: false,
                errorMsg: action.payload.msg
            }
        case ADD_SCHEDULE:
            return {
                ...state,
                loading: false,
                day: action.payload.day,
                hour: action.payload.hour,
                scheduleId: action.payload._id,
                statusCodeSchedule: true, // 200
                errorMsg: null,
                errorServer: false,
            }
        case ERROR_ADD_SCHEDULE:
            return {
                ...state,
                loading: false,
                errorMsg: action.payload.message,
                errorServer: false,
            }
        case DELETED_SCHEDULE:
            return {
                ...state,
                loading: false,
                statusCodeSchedule: false, // 404
                scheduleId: null,
                schedule: null,
            }
        case DELETE_ERROR:
            return {
                ...state,
                errorMsg: action.payload.message
            }
        case INIT_SCHEDULE_FORM:
        case INIT_UPDATE:
            return {
                ...state,
                loading: true,
                statusCodeSchedule: false // 404
            }
        case CLEAN_ALL_SCHEDULE:
            return {
                loading: false,
                arrayDays: null,
                arrayHours: null,
                day: null,
                hour: null,
                scheduleId: null,
                statusDelete: null,
                statusCodeSchedule: null,
                schedule: null,
                statusScheduleActives: false,
                errorServer: false,
                errorMsg: null,
            }
        default:
            return state;
    }
}