import {
    INIT_MEDICAL_GOALS,
    MEDICAL_GOALS,
    ERROR_MEDICAL_GOALS,
    LOGOUT,
    INIT_ADD_MEDICAL_GOAL_RECORD,
    ADD_MEDICAL_GOAL_RECORD,
    ERROR_ADD_MEDICAL_GOAL_RECORD
} from 'happ-redux/happ-types';

const initialState = {
    loading: false,
    medicalGoals: null,
    errorServer: false,
    goalStatus: true,
    errorMsg: null,
};

export default function(state = initialState, action){
    switch(action.type){
        case INIT_MEDICAL_GOALS:
        case INIT_ADD_MEDICAL_GOAL_RECORD:    
            return {
                ...state,
                loading: true
            }
        case MEDICAL_GOALS:
            return {
                ...state,
                loading: false,
                medicalGoals: action.payload.medicalGoals,
                errorMsg: null,
                errorServer: false
            }
        case ERROR_MEDICAL_GOALS:
            return {
                ...state,
                loading: false,
                medicalGoals: null,
                errorServer: true,
                errorMsg: action.payload
            }    
        case ADD_MEDICAL_GOAL_RECORD:
            return {
                ...state,
                loading: false,
                errorMsg: null,
                errorServer: false,
                medicalGoals: state.medicalGoals.map(goal => goal._id === action.payload.currentMedicalGoal._id ? action.payload.currentMedicalGoal : goal),
            }
        case ERROR_ADD_MEDICAL_GOAL_RECORD:
            return {
                ...state,
                loading: false,
                progress: 0,
                goalStatus: false,
            }        
        case LOGOUT:
            localStorage.clear();
            return {
                ...state,
                loading: false,
                errorServer: false,
                errorMsg: null,
                medicalGoals: null
            }                    
        default:
            return state;
    }
}    