import styled from 'styled-components';

export const FormError = styled.p.attrs(props => ({}))`
    padding: 10px;
    background: #FA0F00;
    width: 100%;
    margin: 5px auto;
    text-align: center;
    color: #fff;
    font-weight: bold;
`;