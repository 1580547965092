import React, {useState} from 'react';
import {Modal} from 'react-bootstrap';
//components
import logo from 'assets/imgs/logo-happ-heart.svg';
import LoginLoader from 'happ-components/Loader/LoginLoader';

const TermsAndConditionsModalText = (props) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
 
    return ( 
        <>
            <LoginLoader/>

            <span className="primary-color" onClick={handleShow}>
                <b><u>Terminos y condiciones</u></b>
            </span>

            <Modal
                show={show} 
                onHide={handleClose}
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                // backdrop="static"
                contentClassName="modal-terms"
                scrollable
                // style={{backgroundColor: 'white'}}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <img src={logo} alt="description"/>
                        <br></br>
                        <div className='termsAndConditionsTitle'>
                            <span className="termsAndConditionsTitle">
                                <h4><strong>Términos y Condiciones de Uso</strong></h4>
                            </span>
                            <span className="termsAndConditionsTitle">
                                <h5>“Happ Salud Digital”</h5>
                            </span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="termsAndConditionsTexts termsAndConditionsTextsSeparation">
                        <h6>POR FAVOR LEA ESTOS TÉRMINOS Y CONDICIONES DETENIDAMENTE ANTES DE ACCEDER O USAR LOS SERVICIOS</h6>
                        <br></br>
                        <p>
                            Happ Labs SpA (en adelante “Happ”) es una empresa chilena de base científica-tecnológica dedicada 
                            a desarrollar una potente infraestructura digital B2C y B2B que permite administrar efectiva e 
                            integralmente todos los requerimientos de información que derivan de los testeos y otras prestaciones 
                            de salud que la empresa realiza en el lugar de trabajo de sus clientes. Happ es una sociedad por 
                            acciones creada bajo las leyes de la República de Chile, con domicilio legal en Alonso de Córdova 5870, 
                            oficina 1714, comuna de Las Condes, ciudad de Santiago, Región Metropolitana.
                        </p>
                        <br></br>
                        <h5>1. Objeto</h5>
                        <p>
                            Los presentes términos y condiciones de uso (en adelante también e indistintamente los “Términos y 
                            Condiciones”) regulan el acceso y uso de la plataforma “Happ Salud Digital” (en adelante también e 
                            indistintamente la “Plataforma”). Favor tener presente que el registro en la Plataforma supone 
                            expresamente que usted conoce y acepta estos Términos y Condiciones. Asimismo, al utilizar y/o acceder 
                            a cualquier Servicio de la Plataforma, se considera que usted acepta y, por lo tanto, se obliga a 
                            cumplir los presentes Términos y Condiciones. Los Términos y Condiciones regirán tanto para la 
                            Plataforma actual, como para las actualizaciones proporcionadas por Happ que reemplacen y/o 
                            complementen la Plataforma, a menos que dicha actualización esté acompañada de unos términos y 
                            condiciones de uso separados, en cuyo caso regirán dichos términos y condiciones de uso. 
                            Si no acepta los presentes Términos y Condiciones, usted no podrá contratar los servicios puestos 
                            a disposición en la Plataforma.
                        </p>
                        <br></br>
                        <h5>2. Definiciones</h5>
                        <ul>
                            <li>
                                <u>Usuario o Usuarios:</u> trabajadores del Cliente que accedan a la Plataforma.
                            </li>
                            <li>
                                <u>Cliente o Clientes:</u> empresas que contraten los Servicios de Happ.
                            </li>
                            <li>
                                <u>Prestador de Salud o Prestadores de Salud:</u> profesional del área de la salud, debidamente 
                            habilitado, que realice los Exámenes Médicos o Consultas Médicas que sean necesarias al Usuario, 
                            así como también los laboratorios que procesen los Exámenes Médicos y cualquier otro prestador 
                            o establecimiento de salud que intervenga en el marco del uso de la Plataforma.
                            </li>
                            <li>
                                <u>Examen médico o Exámenes Médicos:</u> procedimientos médicos que se realicen al Usuario por un 
                            Prestador de Salud, con el fin de detectar, diagnosticar o controlar enfermedades o patologías. 
                            Los principales Exámenes Médicos que podrán ser realizados serán aquellos que se especifican en 
                            la sección 8 de los presentes Términos y Condiciones.
                            </li>
                            <li>
                                <u>Consulta Médica o Consultas Médicas:</u> consulta médica realizada al Usuario por un Prestador 
                            de Salud, con el fin de arribar a un Diagnóstico adecuado.
                            </li>
                            <li>
                                <u>Diagnóstico:</u> resultados médicos que arroje la Plataforma luego de la entrega de cierta información 
                            por parte del Usuario y de la realización de Exámenes Médicos y Consultas Médicas, de ser necesario.
                            </li>
                            <li>
                                <u>Tratamiento:</u> curso de acción recomendado por la Plataforma, según el Diagnóstico que arrojen 
                            los Exámenes Médicos realizados por el Prestador de Salud al Usuario.
                            </li>
                            <li>
                                <u>Datos:</u> cualquier y toda información recolectada durante el uso de la Plataforma, incluyendo, 
                            pero no limitado a información personal, médica y de salud.
                            </li>
                            <li>
                                <u>Servicio o Servicios:</u> servicios actualmente ofrecidos a los Clientes a los cuales tengan acceso 
                            los Usuarios de la Plataforma o de aquí en adelante, en cualquier momento, desarrollado u ofrecido 
                            a los Clientes y a los cuales puedan tener acceso los Usuarios desde la Plataforma.
                            </li>
                        </ul>
                        <br></br>
                        <h5>3. Compatibilidad y requerimiento</h5>
                        <p>
                            El software utilizado en la Plataforma se ofrece principalmente como aplicación móvil, de modo que es 
                            compatible con los siguientes sistemas operativos:
                        </p>
                        <ul className='termsAndConditionsTextsUl'>
                            <li>- Android y iOS. </li>
                        </ul>
                        <p>
                            El uso de la Plataforma requiere el uso de un dispositivo compatible y con acceso a Internet. Usted acepta 
                            ser el único responsable de cumplir estos requisitos y realizar el pago de cualquier honorario, cargo o gasto 
                            asociado con el uso de Internet y/o cualquier otra comunicación de dicho dispositivo, incluyendo, pero sin 
                            limitación, el servicio de mensajes de texto (SMS), correo electrónico y llamada de voz. Happ no será responsable 
                            en caso de que el Usuario no disponga de un dispositivo compatible con la Plataforma.
                        </p>
                        <br></br>
                        <h5>4. Licencia de acceso</h5>
                        <p>
                            Sujeto al cumplimiento de los presentes Términos y Condiciones, Happ le otorga una licencia limitada, 
                            no exclusiva, no comerciable, no sublicenciable, revocable y no transferible para: (i) el acceso y uso 
                            de la Plataforma; y (ii) el acceso y uso de cualquier contenido, información y material relacionado que 
                            pueda ponerse a disposición a través de la Plataforma. Happ se reserva cualquier derecho que no haya 
                            sido expresamente otorgado en los presentes Términos y Condiciones.
                        </p>
                        <br></br>
                        <h5>5. Registro en la plataforma y contraseña</h5>
                        <p>
                            El acceso a la Plataforma estará sujeto al previo registro mediante el diligenciamiento del formulario 
                            de registro de Usuario. Los Datos introducidos en el formulario de registro deberán ser completos, 
                            veraces y actualizados. Los Usuarios garantizan y responden, en cualquier caso, de la veracidad, 
                            exactitud, integridad, vigencia y autenticidad de los Datos ingresados. Toda la información y los 
                            Datos ingresados por el Usuario tienen carácter de declaración jurada.
                        </p>
                        <p>
                            El Usuario deberá entregar los siguientes antecedentes para solicitar el registro en la Plataforma: 
                            nombre completo, cédula de identidad, fecha de nacimiento, correo electrónico, teléfono, domicilio, 
                            previsión y género. Sin perjuicio de ello, el Usuario es el único responsable por la veracidad y 
                            legalidad de la información que provee a Happ, así como por los documentos que exhiba al momento del 
                            registro, de manera que Happ no será responsable por la falsedad de estos documentos.
                        </p>
                        <p>
                            Happ se reserva el derecho de solicitar comprobantes y/o información adicional a efectos de corroborar 
                            o ampliar la información entregada por el Usuario en materia de Datos, así como de suspender temporal o 
                            definitivamente a aquellas cuentas de Usuarios cuyos Datos no hayan podido ser confirmados o que no haya 
                            sido provista la información adicional solicitada a estos por parte de Happ, o que, siendo provista, sea 
                            insuficiente o incompleta, a solo criterio de Happ.
                        </p>
                        <p>
                            Happ no contrata directamente con personas naturales, sino que contrata con empresas, con el objeto de 
                            prestarle sus servicios a través de la Plataforma a los trabajadores de dicha empresa. Por ende, Happ 
                            no acepta el registro de personas naturales en la Plataforma, si no existe un acuerdo previo firmado 
                            con su empleador.
                        </p>
                        <p>
                            El acceso y uso de la Plataforma están sujetos a una contraseña asignada por el Usuario al diligenciar 
                            el formulario de registro, por lo tanto, al ingresar la contraseña se reputará realizado el acceso por 
                            dicho Usuario, quien responderá en todo caso de tal acceso y uso.
                        </p>
                        <p>
                            El Usuario será responsable en todo momento de la custodia de su contraseña, asumiendo, en consecuencia, 
                            los daños y perjuicios que pudieran derivarse de su uso indebido, así como de la cesión o revelación de 
                            esta. En caso de olvido de la contraseña o cualquier otra circunstancia que suponga un riesgo 
                            de acceso y/o utilización por parte de terceros no autorizados, el Usuario lo comunicará inmediatamente a 
                            Happ, al correo electrónico contacto@happ.cl, a fin de que este proceda inmediatamente al bloqueo y 
                            sustitución de esta. En todo caso, las operaciones realizadas antes de dicha comunicación se reputarán 
                            efectuadas por el Usuario, quien será responsable y pagará los gastos y/o daños que se deriven de cualquier 
                            acceso y/o uso no autorizado que se realice con anterioridad a dicha comunicación.
                        </p>
                        <br></br>
                        <h5>6. Uso de la Plataforma</h5>
                        <p>
                            Mediante el acceso y uso de la Plataforma, el Usuario está celebrando un contrato vinculante con Happ, por 
                            lo que este no podrá autorizar a terceros a utilizar su cuenta. El Usuario reconoce y acepta que el acceso 
                            y uso de la Plataforma es libre, conscientemente y bajo su exclusiva responsabilidad. Happ no será en 
                            ningún caso responsable del uso que el Usuario y/o terceros pudieran hacer de la Plataforma ni de los daños 
                            y/o perjuicios que pudieran derivarse del mismo.
                        </p>
                        <p>
                            El Usuario se compromete a hacer un uso adecuado y lícito de la Plataforma, de conformidad con la legislación 
                            aplicable y los presentes Términos y Condiciones. El Usuario deberá abstenerse de: (i) hacer un uso no 
                            autorizado o fraudulento de la Plataforma; (ii) acceder o intentar acceder a recursos restringidos de la 
                            Plataforma; (iii) utilizar la Plataforma con fines o efectos ilícitos, ilegales, contrarios a lo establecido 
                            en los presentes Términos y Condiciones, lesivos de los derechos e intereses de terceros, o que de cualquier 
                            forma puedan dañar, inutilizar, sobrecargar o impedir la normal utilización o disfrute de la Plataforma; 
                            (iv) provocar daños en la Plataforma o en los sistemas de sus proveedores o de terceros; (v) introducir o 
                            difundir virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de 
                            provocar daños en los sistemas de Happ, de sus proveedores o de terceros; (vi) intentar acceder, utilizar 
                            y/o manipular los datos de Happ, terceros proveedores u otros Usuarios; (vii) reproducir, copiar, distribuir, 
                            transformar o modificar la Plataforma; (viii) permitir el acceso a terceros a través de cualquier modalidad 
                            de comunicación pública, a menos de que cuente con la autorización del titular de los correspondientes derechos 
                            o ello esté legalmente permitido; y (ix) obtener o intentar obtener los contenidos empleando para ello medios 
                            o procedimientos distintos de los que, según los casos, se hayan puesto a su disposición para este efecto. 
                            La Plataforma puede mostrar, incluir o poner a disposición contenido, datos, información, aplicaciones o 
                            materiales proporcionados por terceros. Al utilizar la Plataforma, los Usuarios reconocen y aceptan que Happ 
                            no es responsable de examinar o evaluar el contenido, la precisión, la integridad, la actualización, la validez, 
                            el cumplimiento de los derechos de autor, la legalidad, la decencia, la calidad o cualquier otro aspecto de 
                            dichos materiales proporcionados por terceros.
                        </p>
                        <br></br>
                        <h5>7. En qué consiste y cómo opera la Plataforma</h5>
                        <p>7.1 <u>En qué consiste la Plataforma</u></p>
                        <p>
                            La Plataforma consiste en un programa de diagnóstico y prevención cardiovascular que, luego de cierta 
                            información entregada por el Usuario, Exámenes Médicos realizados y Consultas Médicas, entrega un 
                            Diagnóstico, otorgándole al Usuario acciones educativas, orientación médica y un Tratamiento a seguir, 
                            el cual incluye consejería, consulta médica, orientación nutricional y deportiva, entre otros, con el 
                            objeto de dar a conocer y controlar su nivel de riesgo cardiovascular, acompañándolo, además, durante 
                            las distintas etapas de su condición, las cuales incluyen, entre otros, información oral y escrita 
                            relacionada con la enfermedad, la adherencia al Tratamiento y consejos sobre el modo de utilización de 
                            los dispositivos médicos, en caso de corresponder.
                        </p>
                        <p>
                            En este sentido, se deja constancia de que Happ 
                            opera como intermediaria en la contratación de la realización de los Exámenes Médicos y Consultas 
                            Médicas, bajo los términos y condiciones que sean informados por los Prestadores de Salud y aceptados 
                            por los Usuarios.
                        </p>
                        <br></br>
                        <p>7.2. <u>Cómo opera la Plataforma</u></p>
                        <p>
                            Los Servicios que ofrece la Plataforma son contratados por el Cliente, para ser utilizada por el Usuario, y 
                            opera de la siguiente manera:
                        </p>
                        <ol>
                            <li>
                                El Cliente debe enviar una nómina con los Datos de sus trabajadores que podrán acceder y usar la Plataforma.
                            </li>
                            <li>
                                El Usuario deberá firmar un “Consentimiento Informado”, luego de lo cual será registrado en la Plataforma por Happ con una 
                                contraseña que deberá ser modificada por el Usuario.
                            </li>
                            <li>
                                El Usuario deberá entregar ciertos Datos relacionados con su salud y realizarse un chequeo a través de ciertos Exámenes Médicos 
                                iniciales.
                            </li>
                            <li>
                                Una vez el Usuario cuente con los resultados de los Exámenes Médicos iniciales realizados, la Plataforma hace una “Sospecha 
                                Diagnóstica”.
                            </li>
                            <li>
                                Una vez se cuente con la “Sospecha Diagnóstica”, en caso de ser necesario, se realizarán ciertos Exámenes Médicos, principalmente 
                                de sangre o presión.
                            </li>
                            <li>
                                Una vez el Usuario cuente con los resultados de estos últimos Exámenes Médicos, el Usuario podrá iniciar el “Viaje Happ” y seguir 
                                el Tratamiento recomendado por la Plataforma, el cual incluirá indicaciones clínicas, evolución, progreso, metas terapéuticas, 
                                recordatorios y alertas de interés, orientación nutricional y deportiva, entre otros.
                            </li>
                        </ol>
                        <br></br>
                        <h5>8. Exámenes Médicos, Consultas Médicas y otros</h5>
                        <p>
                            Los principales Exámenes Médicos que podrán ser realizados a los Usuarios serán los que se indican a continuación:
                        </p>
                        <ul>
                            <li>0301041 HEMOGLOBINA GLICADA A1C (examen de laboratorio)</li>
                            <li>0302023 CREATININA EN SANGRE (examen de laboratorio)</li>
                            <li>0302032 ELECTROLITOS PLASMATICOS (SODIO, POTASIO, CLORO) C/U (examen de laboratorio)</li>
                            <li>0302034 PERFIL LIPÍDICO (examen de laboratorio)</li>
                            <li>0302047 GLUCOSA EN SANGRE (examen de laboratorio)</li>
                            <li>0303024 TIROESTIMULANTE (TSH), HORMONA (ADULTO, NIÑO O R.N.) (examen de laboratorio)</li>
                            <li>0309010 CREATININA CUANTITATIVA EN ORINA (examen de laboratorio)</li>
                            <li>0309013 MICROALBUMINURIA CUANTITATIVA (examen de laboratorio)</li>
                            <li>0309022 ORINA COMPLETA, (INCLUYE COD. 03-09-023 Y 03-09-024) (examen de laboratorio)</li>
                            <li>1701001 e.c.g. de reposo (examen físico)</li>
                            <li>Toma de muestras con sangre capilar en la empresa</li>
                            <li>Toma de muestra de sangre venosa en la empresa</li>
                            <li>Toma de muestra de orina en la empresa</li>
                            <li>Medición de peso, altura y perímetro de cintura (examen físico)</li>
                            <li>Auscultación cardiaca y pulmonar, y almacenamiento registro auditivo del examen (examen físico)</li>
                            <li>Pesquisa edema por palpación en la piel y almacenamiento de registro fotográfico (examen físico)</li>
                        </ul>
                        <p>
                            Además, también podrán realizarse:
                        </p>
                        <ul>
                            <li>Encuestas de historial clínico</li>
                            <li>Consultas Médicas (ya sea con un/a enfermero/a o con un/a médico general)</li>
                        </ul>
                        <br></br>
                        <h5>9. Pagos</h5>
                        <p>
                            Los pagos deberán efectuarse por parte del Cliente, y se establecerán de forma mensual y por trabajador. 
                            En todo caso, este será definido en el respectivo contrato SaaS que se suscriba entre Happ y el Cliente.
                        </p>
                        <p>
                            Asimismo, los Prestadores de Salud que realicen los Exámenes Médicos de manera particular serán remunerados 
                            por cada Examen Médico realizado.
                        </p>
                        <br></br>
                        <h5>10. Compromisos del Usuario</h5>
                        <ul>
                            <li>El Usuario garantiza que la información facilitada es exacta y veraz.</li>
                            <li>Todos los Usuarios deberán dar observancia a los derechos de terceros.</li>
                            <li>Los Usuarios son los únicos responsables por los datos enviados a Happ.</li>
                            <li>
                                La información de la cuenta debe ser siempre confidencial. El Usuario es el único responsable de mantener en secreto 
                                la contraseña. La persona que crea la cuenta debe asegurarse de que conserva la contraseña y los datos de acceso.
                            </li>
                            <li>
                                Happ nunca solicitará a sus Usuarios datos personales como un nombre de Usuario o una contraseña por correo electrónico. 
                                Si se solicitan datos personales, el Usuario siempre será redireccionado a la plataforma de Happ. 
                            </li>
                            <li>
                                El Usuario no publicará, divulgará, modificará, transmitirá, producirá o distribuirá de ninguna forma, ninguna información 
                                o cualquier otro material o herramienta diseñado para comprometer la seguridad de la Plataforma y/o cualquier servicio de 
                                red o telecomunicación. Tampoco podrá publicar o distribuir ningún programa informático que dañe, interfiera de manera 
                                perjudicial, intercepte subrepticiamente o expropie cualquier sistema, datos o información personal.
                            </li>
                            <li>
                                El Usuario no usará, ni permitirá que se utilice la Plataforma de una manera o para un propósito contrario a lo establecido 
                                en los Términos y Condiciones.
                            </li>
                            <li>
                                El Usuario asume absoluta responsabilidad de todas las actividades realizadas utilizando la Plataforma, incluyendo, pero 
                                sin limitación, el acceso a los materiales en Internet (ya sea un sitio protegido por contraseña o no) y tales actividades son 
                                bajo su propio riesgo.
                            </li>
                            <li>
                                El uso de la cuenta no puede de ninguna manera infringir las leyes aplicables. La cuenta no puede ser utilizada de ninguna 
                                manera para promover actividades ilegales.
                            </li>
                        </ul>
                        <br></br>
                        <h5>11. Cancelación y/o suspensión de la cuenta</h5>
                        <p>
                            Happ podrá, en cualquier oportunidad, terminar o suspender una cuenta sin expresión de causa alguna, lo que implicará el cierre de 
                            la cuenta del Usuario.
                        </p>
                        <p>
                            Asimismo, Happ podrá, en caso de incumplimiento del Usuario a los presentes Términos y Condiciones o a la 
                            legislación aplicable en la materia, suspender o dar por terminada la prestación del servicio de Plataforma sin ningún tipo de aviso 
                            previo, reservándose el derecho de reclamar los daños y perjuicios que tal incumplimiento le haya causado.
                        </p>
                        <br></br>
                        <h5>12. Deber de informar errores</h5>
                        <p>
                            Es ilegal servirse y/o aprovecharse de los errores o vulnerabilidades de la Plataforma. Si el Usuario detecta o se da cuenta de 
                            algún error o vulnerabilidad en la Plataforma: (i) se abstendrá de aprovecharse de ellos en modo alguno; (ii) mantendrá estricta 
                            confidencialidad de ese error o vulnerabilidad; y (iii) deberá notificar a Happ sobre el error encontrado mediante un mensaje al 
                            correo electrónico contacto@happ.cl lo anterior, sin perjuicio de otros recursos disponibles en el marco de la ley o del principio 
                            de equidad.
                        </p>
                        <br></br>
                        <h5>13. Responsabilidad e indemnidad</h5>
                        <p>
                            Se deja expresa constancia de que Happ, al ser una Plataforma que contacta a diversos actores, solamente se encuentra obligada a 
                            responder por el servicio de uso de su Plataforma y no será responsable en ningún caso por los servicios que los Prestadores de 
                            Salud realicen, así como tampoco será responsable por la calidad de dichos servicios.</p>
                        <p>
                            Happ no será responsable por ningún daño o perjuicio, ya sea directo o indirecto, que sufra el Usuario, con ocasión de los servicios 
                            que prestan los Prestadores de Salud.
                        </p>
                        <p>
                            Lo anterior, toda vez que Happ, a través de la Plataforma, solo facilita la comunicación entre el Usuario y los Prestadores de Salud, 
                            de manera tal que es el Usuario quien contrata directamente los servicios a los Prestadores de Salud.
                        </p>
                        <p>
                            El Usuario renuncia y exonera a Happ de cualquier obligación, reclamación, indemnización o daño surgido como consecuencia de la relación 
                            entre el Usuarios y los Prestadores de Salud. Toda responsabilidad u obligación nacida en virtud de la relación entre los Usuarios y 
                            los Prestadores de Salud recae únicamente en ellos, con todas las implicancias que ello conlleva.
                        </p>
                        <p>
                            En especial, el Usuario asume como propia la responsabilidad que pudiere derivarse de sus propios actos u omisiones, eximiendo a Happ 
                            de toda responsabilidad ante cualquier reclamo, daño, perjuicio, pérdida, penalización y costo (incluyendo, sin limitación alguna, 
                            los honorarios de abogados y costas procesales judiciales) y de cualquier gasto derivado de o relacionado con el uso de la Plataforma 
                            debido a un actuar doloso o culposo del Usuario.
                        </p>
                        <p>
                            En la medida de que no esté prohibido por ley, en ningún caso Happ será responsable de lesiones personales, o de 
                            cualquier daño incidental, especial, indirecto o consecuente, incluyendo, pero sin limitación, daños por pérdida de ganancias, pérdida 
                            de datos, interrupciones comerciales, cualquier otro daño o pérdida comercial, derivado o relacionado con su uso o incapacidad de usar 
                            la Plataforma, sin importar la teoría de responsabilidad y aún si Happ ha sido informado de la posibilidad de tales daños. Happ no 
                            garantiza la disponibilidad ni continuidad permanente del funcionamiento y/o acceso a la Plataforma.
                        </p>
                        <p>
                            Happ no garantiza la ausencia de virus ni de otros elementos en la Plataforma introducidos por terceros ajenos a Happ que puedan 
                            producir alteraciones en los sistemas electrónicos y/o lógicos del Usuario y/o en los documentos electrónicos y ficheros almacenados 
                            en sus sistemas.
                        </p>
                        <br></br>
                        <h5>14. Propiedad intelectual</h5>
                        <p>
                            Los derechos de propiedad intelectual de la Plataforma, su código fuente, diseño, estructura de navegación, bases de datos y los 
                            distintos elementos en ella contenidos son titularidad de Happ, a quien corresponde el ejercicio exclusivo de los derechos de explotación 
                            de los mismos en cualquier forma y, en especial, los derechos de reproducción, distribución, comunicación pública y transformación. 
                            La utilización de esta Plataforma no supone en ningún caso la cesión de estos derechos de propiedad intelectual a los Usuarios de la 
                            Plataforma. Cada Usuario debe ser consciente de estos derechos y debe actuar en concordancia. Por lo tanto, queda prohibida la 
                            reproducción, distribución, modificación o transmisión de la Plataforma y sus contenidos sin autorización.
                        </p>
                        <p>
                            Happ es titular de los derechos de propiedad industrial e intelectual referidos a sus productos, servicios, logos, imágenes, vídeos, 
                            textos, etc. Respecto a las citas de productos y servicios de terceros, Happ reconoce a favor de sus titulares los correspondientes 
                            derechos de propiedad industrial e intelectual, no implicando su sola mención o aparición en la Plataforma la existencia de derechos 
                            o responsabilidad alguna de Happ sobre los mismos, como tampoco respaldo, patrocinio, o recomendación por parte de Happ, a no ser 
                            que se manifieste de manera expresa.
                        </p>
                        <p>
                            La Plataforma se proporciona únicamente para su uso personal. El Usuario no podrá modificar, copiar, distribuir, transmitir, exhibir, 
                            reproducir, publicar, licenciar, crear trabajos derivados o vender cualquier información, software, porción de software, productos 
                            y/o hardware proporcionado por Happ.
                        </p>
                        <p>
                            La utilización no autorizada de la información contenida en la Plataforma, su reventa, así como la lesión de los derechos de Propiedad 
                            Intelectual o Industrial de Happ, dará lugar a las responsabilidades legalmente establecidas.
                        </p>
                        <br></br>
                        <h5>15. Datos personales, uso y tratamiento</h5>
                        <p>15.1. <u>Recopilación de la información</u></p>
                        <p>
                            Happ recopila la información que el Usuario proporciona, por ejemplo, cuando se crea o modifica su cuenta, cuando se realiza Exámenes 
                            Médicos o acude a Consultas Médicas, cuando accede a la Plataforma, o cuando se comunica con Happ. Esta información puede incluir: 
                            nombre completo, cédula de identidad, fecha de nacimiento, correo electrónico, teléfono, domicilio, previsión, género, datos de 
                            salud, y toda otra información que sea proporcionada en el marco del uso de la Plataforma.
                        </p>
                        <p>15.2. <u>Autorizaciones del Usuario</u></p>
                        <p>
                            Usted acepta mediante el uso de la Plataforma y proporcionando o enviando cualquier Dato a Happ por cualquier medio de transmisión, 
                            incluyendo, pero no limitado a publicar, cargar, introducir, suministrar o enviar dichos datos a través del uso de la Plataforma, 
                            otorgar permiso a Happ para utilizar dichos datos en relación con el objeto de la Plataforma, incluyendo, pero sin limitación, 
                            los derechos de: tratar, copiar, distribuir, transmitir, reproducir, mostrar, editar, traducir, vender y re-formatear los datos.
                        </p>
                        <p>
                            Usted autoriza que se le realice un control de salud, Exámenes Médicos y Consultas Médicas para establecer su nivel de riesgo 
                            cardiovascular y se le otorgue un Diagnóstico adecuado.
                        </p>
                        <p>
                            Asimismo, usted entiende y acepta que la información recopilada sea entregada a su persona, a Happ, y a los Prestadores de Salud 
                            que correspondan, con el fin de establecer y controlar su nivel de riesgo cardiovascular.
                        </p>
                        <p>
                            Junto con lo anterior, usted autoriza expresamente a Happ para tratar la información materia de lo anterior conforme a lo establecido 
                            por la legislación vigente, en especial la normativa contenida en la Ley N° 19.628 sobre Protección de la Vida Privada, 
                            Ley N° 20.584 que regula los derechos y deberes que tienen las personas en relación con acciones vinculadas a su atención en salud, 
                            y demás normas que regulan la materia.
                        </p>
                        <p>
                            Al registrarse en la Plataforma, usted autoriza expresamente a Happ para acceder, conservar y revelar la información recolectada, 
                            en los siguientes casos:
                        </p>
                        <ol>
                            <li>
                                Para los fines propios de la Plataforma y el giro comercial de la empresa, que corresponden a fines lícitos y legítimos.
                            </li>
                            <li>
                                Si así lo requiere una autoridad competente.
                            </li>
                            <li> 
                                Para proteger los derechos, propiedad o seguridad de Happ o de los Usuarios.
                            </li>
                        </ol>
                        <p>
                            Se deja expresa constancia de que no se pagará ninguna compensación con respecto al uso de los datos, según lo dispuesto en el presente documento.
                        </p>
                        <p>15.3. <u>Recopilación de la información</u></p>
                        <p>
                            Happ se preocupa por la protección de los datos de carácter personal y los datos sensibles de sus Usuarios. Los datos personales o 
                            de carácter personal son aquellos datos relativos a cualquier información concerniente a personas naturales, identificadas o identificables. 
                            Por su parte, los datos sensibles son aquellos datos personales que se refieren a las características físicas o morales de las personas o 
                            a hechos o circunstancias de su vida privada o intimidad.
                        </p>
                        <p>
                            Los datos personales proporcionados por los Usuarios en el proceso de autenticación, registro y/o uso de la Plataforma tienen por 
                            objeto identificar al Usuario que establece una relación con Happ a través de la Plataforma, para así poder acceder a los servicios 
                            que ofrece Happ, además de poder dar a conocer y controlar su nivel de riesgo cardiovascular.
                        </p>
                        <p>
                            El concepto de dato personal no comprende el dato estadístico. El dato estadístico es información que, en su origen o como consecuencia 
                            de su tratamiento, no puede asociarse a persona natural identificada o identificable. Se trata de información recolectada sobre los 
                            Usuarios respecto de la cual se ha retirado la identidad de estos últimos. La información sobre cómo un Usuario usa un servicio puede 
                            ser recolectada y combinada con la información sobre cómo otros usan el mismo servicio, pero ninguna información personal será incluida 
                            en la información resultante. De la misma manera, se puede recolectar la información sobre los productos que los Usuarios compran y 
                            combinarla con la información relativa a productos comprados por otros Usuarios. La información disociada ayuda a entender las tendencias 
                            y las necesidades de los Usuarios, de tal manera de permitir ofrecer nuevos y mejores servicios y productos, junto con adecuar los 
                            actualmente existentes. Esta información puede ser compartida con terceros, pero nunca podrá y será relacionada con una persona natural 
                            identificada o identificable, sin el consentimiento expreso del usuario para realizar dicha asociación.
                        </p>
                        <p>
                            De esta manera, los Usuarios autorizan expresamente a Happ para tratar y utilizar los datos sensibles entregados o recolectados para los 
                            fines de la Plataforma. Además, autorizan expresamente a Happ o a quienes esta faculte para tratar y utilizar los datos estadísticos 
                            libremente, pudiendo registrarlos, almacenarlos, conservarlos, ordenarlos, transferirlos, comunicarlos, reproducirlos y/o comercializarlos 
                            para cualquier fin, a entera voluntad de Happ, quien será el titular de los derechos de autor que emanen de las bases de datos que pudieren 
                            configurar con los datos obtenidos de los Usuarios. Esta autorización podrá ser revocada, sin efecto retroactivo, mediante correo 
                            electrónico enviado a contacto@happ.cl.
                        </p>
                        <p>
                            Happ estará obligada a revelar los datos personales y cualquier otra información y los registros de cualquier Usuario, cuando así se lo 
                            requiera una autoridad competente y/o en cumplimiento de cualquier disposición legal incluida en la legislación local vigente.
                        </p>
                        <p>15.4. <u>Notificaciones y alertas</u></p>
                        <p>
                            Los Usuarios autorizan a Happ a enviarles información, alertas, recordatorios o avisos vía correo electrónico, mensajes de texto o 
                            whatsapp, sobre información de interés clínico o cualquier otra información que sea necesaria para el cumplimiento del objeto de la 
                            Plataforma. Sin perjuicio de lo anterior, los Usuarios podrán solicitar que los excluyan de las listas para el envío de dicha 
                            información, mediante un correo electrónico dirigido a contacto@happ.cl.
                        </p>
                        <p>15.5. <u>Cumplimiento de la normativa aplicable</u></p>
                        <p>
                            Happ cumple con la Ley N° 19.628 sobre Protección de la Vida Privada y otras regulaciones pertinentes que sean aplicables. 
                            Todo lo anterior con el objeto de garantizar que los datos personales de los Usuarios, en todo momento: (i) se procesen con arreglo 
                            a los derechos de los Usuarios de una manera justa y legítima; (ii) se obtengan únicamente para un propósito específico y 
                            legítimo; (iii) sean adecuados, pertinentes y no excesivos para su propósito; (iv) se almacenen de una manera segura; (v) no se 
                            almacenen más tiempo del necesario para su propósito; y (vi) no se transfieran a jurisdicciones no adheridas a las directivas aquí 
                            mencionadas.
                        </p>
                        <p>15.6. <u>Derechos de los Usuarios</u></p>
                        <p>
                            Happ garantiza que los titulares de los datos e información personal tratada puedan en todo momento: (i) conocer, actualizar y 
                            rectificar sus datos personales; (ii) presentar solicitudes respecto de la procedencia, destinatario y uso que se le ha dado a sus 
                            datos personales; (iii) presentar solicitudes respecto del propósito del almacenamiento y la individualización de las personas u 
                            organismos a los cuales sus datos personales son transmitidos regularmente; (iv) revocar la autorización; y (v) solicitar la supresión 
                            de los datos personales cuando en el tratamiento no se respeten los principios, derechos y garantías de lo dispuesto en esta sección, 
                            cuando su almacenamiento carezca de fundamento legal o cuando estuvieren caducos.
                        </p>
                        <p>
                            La solicitud de supresión de la información personal y de revocación de la autorización no procederán cuando el titular tenga un deber 
                            legal o contractual de permanecer en la base de datos o cuando Happ tenga el deber legal o contractual de continuar con el tratamiento.
                        </p>
                        <p>
                            A efectos de la detección y control del fraude, el Usuario tienen derecho a acceder a sus propios datos personales, corregirlos y/o 
                            eliminar los datos incorrectos y/o inapropiados.
                        </p>
                        <p>15.7. <u>Deberes de Happ frente al tratamiento de información y datos personales</u></p>
                        <p>
                            Happ está altamente comprometida en mantener la información de los Usuarios segura, para lo cual utiliza sistemas con altos estándares de 
                            seguridad y protocolos en manejo de información, sistemas que, además, son actualizados constantemente, buscando evitar poner en riesgo la 
                            integridad de la misma información o su divulgación no autorizada, cumpliendo, de este modo, fielmente con la normativa legal vigente en 
                            la materia, y especialmente con lo dispuesto por la Ley N° 19.628 sobre Protección de la Vida Privada, así como otras legislaciones sobre 
                            privacidad de otras jurisdicciones.
                        </p>
                        <p>
                            No obstante lo anterior, Happ, como responsable del tratamiento de información y datos personales, se compromete a: (i) garantizar al 
                            titular, en todo tiempo, el pleno y efectivo ejercicio del derecho de hábeas data, a consultar y hacer reclamos sobre su 
                            información; (ii) solicitar y conservar una copia de la respectiva autorización otorgada por el titular; (iii) informar debidamente al 
                            titular sobre la finalidad de la recolección de información y los derechos que le asisten en virtud de la autorización otorgada; (iv) 
                            conservar la información bajo las condiciones de seguridad necesarias para impedir su adulteración, pérdida, consulta, uso o acceso no 
                            autorizado o fraudulento; (v) actualizar la información, atendiendo de esta forma todas las novedades respecto de los datos del titular. 
                            Adicionalmente, se deberán implementar todas las medidas necesarias para que la información se mantenga actualizada; (vi) rectificar la 
                            información cuando sea incorrecta y comunicar lo pertinente; (vii) respetar las condiciones de seguridad y privacidad de la información 
                            del titular; (viii) tramitar las consultas y reclamos formulados en conformidad con esta sección; y (ix) hacer uso de los datos personales 
                            del titular solo para aquellas finalidades para las cuales se haya autorizado, respetando en todo caso la norma vigente de las distintas 
                            jurisdicciones referente a la protección de datos personales.
                        </p>
                        <br></br>
                        <h5>16. Modificaciones y notificaciones</h5>
                        <p>
                            Happ se reserva el derecho exclusivo de modificar, reemplazar, complementar y/o rectificar los presentes Términos y Condiciones y notificará 
                            a los Usuarios por correo electrónico o a través de un aviso en la Plataforma sobre cualquier decisión adoptada al respecto, quienes deberán 
                            aceptar tales cambios antes de continuar. La continuación en el uso de la Plataforma constituye un acto de aceptación de estas modificaciones.
                        </p>
                        <p>
                            Happ podrá realizar las notificaciones oportunas a través de una notificación general en la Plataforma, a través de la dirección de correo 
                            electrónico facilitada por el Usuario en el formulario de registro, mediante SMS, o a través de correo ordinario a la dirección facilitada por 
                            el Usuario en el formulario de registro.
                        </p>
                        <p>El Usuario deberá modificar en forma inmediata, en su cuenta de Usuario, cualquier cambio relativo a los antecedentes registrados en él y 
                            que sean necesarios para la correcta comunicación entre partes. En ese sentido, Happ no será responsable por los perjuicios que dicha 
                            falta de actualización de la información le pudiera causar.
                        </p>
                        <br></br>
                        <h5>17. Informaciones</h5>
                        <p>
                            La información contenida en estos Términos y Condiciones es completa y suficiente. En caso de cualquier duda o para mayor información, 
                            debe comunicarse a través del siguiente correo electrónico: contacto@happ.cl.
                        </p>
                        <br></br>
                        <h5>18. Régimen legal aplicable</h5>
                        <p>
                            Los presentes Términos y Condiciones, así como la relación entre Happ y el Usuario, se regirán e interpretarán de conformidad con las 
                            leyes de Chile. Las partes acuerdan someterse a la jurisdicción de los Tribunales competentes de Santiago de Chile para la interpretación, 
                            aplicación, ejecución, resolución o cualquier controversia derivada de los presentes Términos y Condiciones.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                {/* <Button variant="secondary" onClick={handleClose}>
                    Cerrar
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Acepto
                </Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
}
 
export default TermsAndConditionsModalText;