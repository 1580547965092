import axiosClient from 'happ-config/axios';

const getHealthScore = async (token) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
            'x-auth-token': token
        };
        const response = await axiosClient.get('/patients/getScore', {headers});
        return response
    } catch (error) {
        const {response} = error;
        return response;
    }
}

export {
    getHealthScore
}