import { width } from '@mui/system'
import IndicatorModal from 'happ-components/Indicators/IndicatorModal'
import ModalToAgendarExamF1 from 'happ-components/Modal/ModalChequeo1/ModalToAgendarExamF1'
import { useModal } from 'happ-custom-hooks/useModal/useModal'
import { useModal2 } from 'happ-custom-hooks/useModal/useModal2'
import { agendaModal } from 'happ-data/data2'
import React, { useEffect } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'


const arrayOpen = [];
const test = [
    {
        title: 'Chequeo Diagnóstico',
        description:
            [
                { result: 'En esta etapa detectaremos si tienes sospecha de alguna enfermedad cardiovascular.' },
                { result: 'Se realizarán mediciones y exámenes no invasivos.' },
                { result: 'Si detectamos una sospecha, te realizaremos exámenes de sangre para confirmar.' }
            ]

    }
]
const test2 = [
    {
        title: 'Exámenes Físicos',
        description:
            [
                { result: 'En esta etapa realizaremos exámenes no invasivos' },
                { result: 'El objetivo es descartar otras enfermedades asociadas a las que ya hemos diagnosticado' },
                { result: 'Realizaremos un Electrocardiograma, te auscultaremos y evaluaremos si cuentas con edemas' }
            ]

    }
]

const HomeMessage3 = ({ healthIndicators }) => {

    // const [isOpen, openModal, setIsOpen] = useModal(false)

    // aqui podria haber un useEffect escuchando el cambio de estado de la agenda y que haga dispatch cuando cambie... 
    // si el estado de mi agenda es true osea ya tome la hora entonces muestro otro mensaje en este componente junto con una barra de color azul abajo. 

    // useSelector

    // test.map((posi, i) => {

    //     console.log(posi.description)

    // })

    const { openModal, ShowModal } = useModal(arrayOpen, 'homeButton');
    const [show, handleShow, handleClose] = useModal2(false);

    useEffect(() => {

    }, [])

    const statusExamenes = false;
    const horaAgendada = true;
    const status = 'stage3'


    return (

        <div className='blue-stripe'>
            {status === 'stage1' && !horaAgendada ? (
                <>
                    <Row>
                        <Col className=' text-center'>
                            <h1 className='primary-color-text title-message'>Comienza tu viaje</h1>
                            <p className='primary-color-text subtitle-message'>
                                Presiona "Iniciar" para agendar tus horas.
                            </p>
                        </Col>
                    </Row>
                    <Row className='py-3'>
                        <Col className=' text-center'>
                            <Button onClick={openModal(test)} className='shadow btn-home-message'>
                                {/* <Button  className='shadow btn-home-message'> */}
                                Iniciar
                            </Button>
                        </Col>
                    </Row>
                    <ShowModal />
                </>
            ) : null}
            {status === 'stage1' && horaAgendada ? (
                <>
                    <Row>
                        <Col className=' text-center'>
                            <h1 className='primary-color-text title-message mb-5'>¡Tu hora ya está agendada!</h1>
                            <p className='primary-color-text subtitle-message px-4'>
                                Asiste en ayunas a tu consula el viernes 6 de enero a las 11:00hrs.
                            </p>

                        </Col>
                    </Row>
                </>
            ) : null}
            {status === 'stage2' ? (
                <>
                    <Row>
                        <Col className=' text-center'>
                            <h1 className='fw-bold primary-color-text title-message'>¡Revisa tus resultados!</h1>
                            <p className='primary-color-text lh-1 subtitle-message'>
                                Los resultados de laboratorio aún están siendo procesados, por favor espera.
                            </p>
                        </Col>
                    </Row>
                    <Row className='primary-bg-color text-white my-4'>
                        <Col className=' text-center'>
                            {/* <p className='mt-2'> Conoce la <b>importancia</b> de cada examen</p> */}
                            <p className='mt-2 '> <b>Serás notificado cuando estén disponibles </b> </p>
                        </Col>
                    </Row>
                </>
            ) : null}
            {status === 'stage3' ? (
                <>
                    <Row>
                        <Col className=' text-center'>
                            <h1 className='fw-bold primary-color-text title-message'>Elige tu hora</h1>
                            <p className='primary-color-text lh-1 subtitle-message'>
                               Programa tu próxima etapa: <strong> exámenes físicos</strong>
                            </p>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col className=' text-center'>
                            <Button onClick={handleShow} className='btn-home-message'>
                                Agendar hora
                            </Button>
                        </Col>
                    </Row>
                    <ShowModal />
                    {/* <ShowModal3 />  */}
                    <ModalToAgendarExamF1 show={show} onHide={handleClose} />
                    {/* este se habre completo de ancho  */}
                </>
            ) : null}

        </div>
    )
}

export default HomeMessage3;