import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// components
import { MobileExamsIcon, MobileProgramsIcon, MobileHappHeartIcon, MobileTipsIcon, MobileProfileIcon } from 'happ-icons/icons';
import { ColMenu } from 'happ-components/HappStyledComponents/HappNavBarFooter/HappNavBarFooter';
import { ExamsIconOn } from 'happ-icons/icons';
import { ExamsIcon } from 'happ-icons/icons';
import { MobileHappHeartIconOff } from 'happ-icons/icons';
import { ProfileIconOn } from 'happ-icons/icons';
import { ProfileIcon } from 'happ-icons/icons';
const NavBarFooter = () => {

    const [active, setActive] = useState('');

    useEffect(() => {
        const currentPath = window.location.pathname;
        setActive(currentPath);
    }, []);

    return (
        <div className="nav-bar-footer-container">
            <Row className="justify-content-center align-items-center">
                {/* <ColMenu>
                    <Link to="/mis-consejos">
                        <MobileTipsIcon />
                        <p>Consejos</p>
                    </Link>
                </ColMenu> */}
                <ColMenu>
                    <Link to="/mis-examenes">
                        {active === '/mis-examenes' ?
                            <ExamsIconOn /> :
                            <ExamsIcon />
                        }
                        <p>Exámenes</p>
                    </Link>
                </ColMenu>
                {/* <ColMenu> 
                    <Link to="/">
                        <MobileHappHeartIcon />
                        <p>Indicadores</p>
                    </Link>
                </ColMenu> */}
                <ColMenu>
                    <Link to="/">
                        {/* <MobileProgramsIcon /> */}
                        {active === '/' ?
                            <MobileHappHeartIcon /> :
                            <MobileHappHeartIconOff />
                        }
                        <p>Mi Viaje</p>
                    </Link>
                </ColMenu>
                <ColMenu>
                    <Link to="/mi-perfil">
                        {active === '/mi-perfil' ?
                            <ProfileIconOn /> :
                            <ProfileIcon />
                        }
                        <p>Perfil</p>
                    </Link>
                </ColMenu>
            </Row>
        </div>
    );
}

export default NavBarFooter;