import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Modal, Row, Table } from 'react-bootstrap';
import { CloseModalBlue } from 'happ-icons/icons'
import { SugarIcon } from 'happ-icons/icons'
import { IMCIcon } from 'happ-icons/icons';
import { HipertensionIcon } from 'happ-icons/icons';
import { DislipidemiaIcon, MsgPathologyIcon } from 'happ-icons/icons';
import AccordionModal from './AccordionModal/AccordionModal';
import MetaResults from './MetaResults/MetaResults';
import ResultsTableControl from './MetaResults/ResultsTableControl';
import ButtonDownloadModalExam from 'happ-components/ButtonDownloadModalExam/ButtonDownloadModalExam';

const MetaControlModal = (props) => {
    const { diagnosisContent } = props;
    const { 
      results,
      status,
      color,
      determinationWithControl,
      msgPathology,
      code,
      name 
    } = diagnosisContent;
    return ( 
        <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        contentClassName="modal3"
        centered
        animation={false}
      >
        <div className={'modal-content-white'}>
          <Modal.Body
            className='table-exams-content'
          >
            <Table striped className='table-exams'>
              <thead>
                <tr>
                  <th>Parametros</th>
                  <th>Examen Diagnóstico</th>
                  {determinationWithControl ? (
                    <th>Examen Control</th>
                  ) : null}    
                  <th>Parametro</th>    
                  <th>Progreso</th>
                </tr>
              </thead>
              <tbody>
                  <ResultsTableControl 
                    results={results}
                    determinationWithControl={determinationWithControl}
                    msgPathology={msgPathology}
                    code={code}
                  />
              </tbody>
            </Table>
          </Modal.Body>
        </div>
  
      </Modal>
     );
}
 
export default MetaControlModal;