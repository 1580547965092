import styled from 'styled-components';

export const ContainerTip = styled.div.attrs((props => {}))`
    background-color: #F6F6F6;
    width: 35%;
    margin: 0 auto;
    border-radius: 8px;
    & p{
        color: #333333;
    }
    & .content-tip{
        text-align: center;
        padding: 15px 55px;
    }
    & .content-tip svg {
        margin-bottom: 10px;
    }
    @media (max-width: 700px) {
        width: 100%;
        & .content-tip{
            padding: 15px 15px;
        }
        & .content-tip svg{
            transform: scale(0.7);
            -ms-transform: scale(0.7);
            -webkit-transform: scale(0.7);
            transform-origin: center center;
            margin-bottom: 15px;
        }
        & button {
            margin-top: 15px;
        }
    }
`;