import React, { useState } from 'react'
import TableModal from 'happ-components/TableModal/TableModal'
import { Accordion, Col, Row } from 'react-bootstrap';

const AccordionModal = ({results, color, diagnosisContent}) => {
    const [activeKey, setActiveKey] = useState(0);

    return (
        <>
            {results && results.map((exam, pos) => (
                <Accordion key={exam.exam} activeKey={activeKey} className="accordion-modal my-3">
                    <Accordion.Item eventKey={pos}>
                    <Accordion.Header onClick={() => setActiveKey(pos)}>
                        <Row>
                            {exam.exam === 'toma-presion-1' ? (
                                <Col>
                                    <h6 className="fw-bold">
                                        Toma de Presión
                                    </h6>
                                    <div className='accordion-description'>Los resultados de tu <strong>Presión Arterial</strong> son compatibles con el diagnóstico de: <strong>{diagnosisContent.status}</strong></div>
                                </Col>
                            ) : null}
                            {exam.exam === 'hemoglucotest' ? (
                                <Col>
                                    <h6 className="fw-bold">
                                        Glicemia Capilar
                                    </h6>
                                    <div className='accordion-description'>Este es el resultado de tu <strong>glicemia capilar</strong>. El <strong>conjunto de tus exámenes</strong> son indicios del diagnóstico de: <strong>{diagnosisContent.status}</strong> </div>
                                </Col>
                            ) : null}
                            {exam.exam === 'imc' ? (
                                <Col>
                                    <h6 className="fw-bold">
                                        Masa corporal
                                    </h6>
                                    <div className='accordion-description'>Ante las pruebas realizadas según tu chequeo, tu <strong>IMC</strong> es compatible con un diagnóstico de: <strong>{diagnosisContent.status}</strong></div>
                                </Col>
                            ) : null}
                            {exam.exam === 'Perfil Lipidico 1' ? (
                                <Col>
                                    <h6 className="fw-bold">
                                        Perfil Lipídico
                                    </h6>
                                    <div className='accordion-description'>Los resultados de tu <strong>Perfil Lipídico</strong> son compatibles con el diagnóstico de: <strong>{diagnosisContent.status}</strong></div>
                                </Col>
                            ) : null}
                            {exam.exam === 'Glicemia en Sangre 1' ? (
                                <Col>
                                    <h6 className="fw-bold">
                                        Glicemia en sangre I
                                    </h6>
                                    <div className='accordion-description'>Este es el resultado de tu <strong>primera glicemia</strong>. El <strong>conjunto de tus exámenes</strong> son compatibles con el diagnóstico de: <strong>{diagnosisContent.status}</strong> </div>
                                </Col>
                            ) : null}
                            {exam.exam === 'Hemoglobina Glicosilada 1' ? (
                                <Col>
                                    <h6 className="fw-bold">
                                        Hemoglobina Glicosilada
                                    </h6>
                                    <div className='accordion-description'>Este es el resultado de tu <strong>hemoglobina glicosilada</strong>. <strong>Tus exámenes</strong> en conjunto, indican el diagnóstico de: <strong>{diagnosisContent.status}</strong></div>
                                </Col>
                            ) : null}
                            {exam.exam === 'Orina Completa 1' ? (
                                <Col>
                                    <h6 className="fw-bold">
                                        Orina Completa
                                    </h6>
                                    <div className='accordion-description'>Hemos solicitado este examen Complementario para descartar otras enfermedades relacionadas</div>
                                </Col>
                            ) : null}
                            {exam.exam === 'ELP 1' ? (
                                <Col>
                                    <h6 className="fw-bold">
                                        Electrolitos Plasmáticos
                                    </h6>
                                    <div className='accordion-description'>Hemos solicitado este examen Complementario para descartar otras enfermedades relacionadas</div>
                                </Col>
                            ) : null}
                            {exam.exam === 'Rac 1' ? (
                                <Col>
                                    <h6 className="fw-bold">
                                        RAC (Relación Albumina Creatinina)
                                    </h6>
                                    <div className='accordion-description'>Hemos solicitado este examen Complementario para descartar otras enfermedades relacionadas</div>
                                </Col>
                            ) : null}
                            {exam.exam === 'Creatinina en Sangre 1' ? (
                                <Col>
                                    <h6 className="fw-bold">
                                        Creatinina en Sangre
                                    </h6>
                                    <div className='accordion-description'>Hemos solicitado este examen Complementario para descartar otras enfermedades relacionadas</div>
                                </Col>
                            ) : null}
                            {exam.exam === 'Toma de presion 2' ? (
                                <Col>
                                    <h6 className="fw-bold">
                                        Toma de Presión
                                    </h6>
                                    <div className='accordion-description'>Los resultados de tu <strong>Presión Arterial</strong> son compatibles con el diagnóstico de: <strong>{diagnosisContent.status}</strong></div>
                                </Col>
                            ) : null}
                            {exam.exam === 'Glicemia en Sangre 2' ? (
                                <Col>
                                    <h6 className="fw-bold">
                                        Glicemia en sangre II
                                    </h6>
                                    <div className='accordion-description'>Este es el resultado de tu <strong>segunda glicemia</strong>. El <strong>conjunto de tus exámenes</strong> confirman el diagnóstico de: <strong>{diagnosisContent.status}</strong> </div>
                                </Col>
                            ) : null}
                        {/* <div className='accordion-description'>Los resultados de tus <strong>exámenes</strong> de glicemia son compatibles con el diagnóstico de <strong>Prediabetes</strong> </div> */}
                        </Row>
                    </Accordion.Header>

                    <Accordion.Body>
                        <Row>
                        <TableModal 
                            exam={exam}
                            color={color} 
                        />
                        </Row>
                    </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            ))}
        </>
        
    );
}
 
export default AccordionModal;