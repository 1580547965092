import React from 'react'
import { CloseModal } from 'happ-icons/icons'
import { Col, Modal, Row } from 'react-bootstrap'
import SubContentDiagnosisModalLC from './SubContentDiagnosisModalLC'

const DiagnosisDeliveryModalLC = (props) => {
    const {diagnosisContent} = props; 
    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <div className={diagnosisContent.code === 'colesterol' && !diagnosisContent.results ? `modal-content-gris` : `modal-content-blue`}>
                <Row className=''>
                    <Col className='mt-2'>
                        <div onClick={props.onHide} className='modal-close-btn primary-color-text'>
                            <CloseModal
                            />
                        </div>
                        <div className='text-center text-white'>
                            {diagnosisContent.code === 'colesterol' ? (
                                <h6 className='text-white'>Chequeo <strong>Colesterol</strong></h6>
                            ) : null}
                            {diagnosisContent.code === 'hipertension' ? (
                                <h6 className='text-white'>Presión <strong>Arterial</strong></h6>
                            ) : null}
                            {diagnosisContent.code === 'obesidad' ? (
                                <h6 className='text-white'>Tu <strong>IMC</strong></h6>
                            ) : null}
                            {diagnosisContent.code === 'diabetes' || diagnosisContent.code === 'pre-diabetes' ? (
                                <h6 className='text-white'>Chequeo <strong>Azúcar</strong></h6>
                            ) : null}
                        </div>
                    </Col>
                </Row>

                <Modal.Body style={{ padding: 0 }}>
                    <div className='text-center text-white'>
                       <SubContentDiagnosisModalLC 
                            diagnosisContent={diagnosisContent}
                       />
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    )
}
 
export default DiagnosisDeliveryModalLC;