import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
// components
import { HappContentIndicator } from 'happ-components/HappStyledComponents/HappContentIndicator/HappContentIndicator';
import { OverweightIcon,  PressureIndicator, ColesterolIndicator, DiabetesIndicator } from 'happ-icons/icons';
import { useModal } from 'happ-custom-hooks/useModal/useModal';
const MedicalStageDiagnosisDelivery = ({ medicalStageResults, isDislipidemia, allHealthy, companyName, medicalOrder }) => {
    const {
        ShowModalDiagnosisDelivery,
        openDiagnosisDelivery,
    } = useModal();
    const handleClick = (file) => {
        if (file) {
            window.open(file, '_blank');
        }
    };
    return (
                <>
                    <div className="indicators-content">
                        {companyName === 'Caja De Compensación La Araucana' ? (
                            <Row className='pt-2'>
                                <Col className='d-flex justify-content-center'>
                                    <div className='py-2 primary-bg-color rounded-1 indicators-content canvas-message' style={{ width: '100%' }}>
                                        <strong>Una vez esten listos tus resultados de laboratorio nos contactaremos contigo.</strong>
                                    </div>
                                </Col>
                            </Row>
                        ) : null}
                        <Row className='px-2'>
                            {!isDislipidemia ? (
                                <>
                                    <Col md={6} xs={6} lg={6} className="px-0 pt-4  col-indicator-mobile">
                                        <HappContentIndicator
                                            className={'box-content indicator-status-grey'} 
                                            onClick={openDiagnosisDelivery('colesterol')}
                                            role="button"
                                        >
                                            <Row>
                                                <div className='my-2'>
                                                    <ColesterolIndicator
                                                        fill='white'
                                                        responsive='scale-mobile'
                                                    />
                                                </div>
                                            </Row>
                                            <Row className={`dark-opacity border-bottom-container`}>
                                                <Col >
                                                    <p> Colesterol</p>
                                                </Col>
                                            </Row>
                                        </HappContentIndicator>
                                    </Col>   
                                    {medicalStageResults && medicalStageResults.map(item => (
                                        <Col key={item._id} md={6} xs={6} lg={6} className="px-0 pt-4  col-indicator-mobile">
                                            <HappContentIndicator
                                                className={`box-content indicator-bg-${item.color}`} 
                                                onClick={openDiagnosisDelivery(item.code, item)}
                                                role="button"
                                            >
                                                <Row>
                                                    {item.code === 'hipertension' ? 
                                                        (
                                                            <>
                                                                <div className='my-2'>
                                                                    <PressureIndicator
                                                                        fill='white'
                                                                        responsive='scale-mobile'
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : item.code === 'obesidad' ? 
                                                        (
                                                            <>
                                                                <div className='my-2'>
                                                                    <OverweightIcon
                                                                        fill='white'
                                                                        responsive='scale-mobile'
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : item.code === 'diabetes' || item.code === 'pre-diabetes' ? 
                                                        (
                                                            <>
                                                                <div className='my-2'>
                                                                    <DiabetesIndicator
                                                                        fill='white'
                                                                        responsive='scale-mobile'
                                                                    />
                                                                </div>
                                                            </>
                                                    ) : null}
                                                </Row>
                                                <Row className={`dark-opacity border-bottom-container`}>
                                                    <Col >
                                                        {item.code === 'hipertension' ? 
                                                        (
                                                            <>
                                                                <p>Presión Arterial</p>
                                                            </>
                                                        ) : item.code === 'obesidad' ? 
                                                        (
                                                            <>
                                                                <p> Chequeo <strong>IMC</strong> </p>
                                                            </>
                                                        ) : item.code === 'diabetes' || item.code === 'pre-diabetes' ? 
                                                        (
                                                            <>
                                                                <p>Azúcar</p>  
                                                            </>
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                            </HappContentIndicator>
                                        </Col>   
                                    ))} 
                                </>
                            ) : (
                                <>
                                    {medicalStageResults && medicalStageResults.map(item => (
                                        <Col key={item._id} md={6} xs={6} lg={6} className="px-0 pt-4  col-indicator-mobile">
                                            <HappContentIndicator
                                                className={`box-content indicator-bg-${item.color}`} 
                                                onClick={openDiagnosisDelivery(item.code, item)}
                                                role="button"
                                            >
                                                <Row>
                                                    {item.code === 'hipertension' ? 
                                                        (
                                                            <>
                                                                <div className='my-2'>
                                                                    <PressureIndicator
                                                                        fill='white'
                                                                        responsive='scale-mobile'
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : item.code === 'obesidad' ? 
                                                        (
                                                            <>
                                                                <div className='my-2'>
                                                                    <OverweightIcon
                                                                        fill='white'
                                                                        responsive='scale-mobile'
                                                                    />
                                                                </div>
                                                            </>
                                                        ) : item.code === 'diabetes' || item.code === 'pre-diabetes' ? 
                                                        (
                                                            <>
                                                                <div className='my-2'>
                                                                    <DiabetesIndicator
                                                                        fill='white'
                                                                        responsive='scale-mobile'
                                                                    />
                                                                </div>
                                                            </>
                                                    ) : null}
                                                </Row>
                                                <Row className={`dark-opacity border-bottom-container`}>
                                                    <Col >
                                                        {item.code === 'hipertension' ? 
                                                        (
                                                            <>
                                                                <p>Presión Arterial</p>
                                                            </>
                                                        ) : item.code === 'obesidad' ? 
                                                        (
                                                            <>
                                                                <p> Chequeo <strong>IMC</strong> </p>
                                                            </>
                                                        ) : item.code === 'diabetes' || item.code === 'pre-diabetes' ? 
                                                        (
                                                            <>
                                                                <p>Azúcar</p>  
                                                            </>
                                                        ) : null}
                                                    </Col>
                                                </Row>
                                            </HappContentIndicator>
                                        </Col>   
                                    ))} 
                                </>
                            )}
                            <ShowModalDiagnosisDelivery />
                        </Row>
                        <Row className='mt-4 text-center'>
                            {medicalOrder ? (
                                <Button 
                                    onClick={() => handleClick(medicalOrder.link)} 
                                    className='btn-home-message'
                                    style={{ margin: '0 auto' }}
                                >
                                    Descargar Orden Médica    
                                </Button>
                            ) : null}
                        </Row>
                    </div>
                </>
            );
}
 
export default MedicalStageDiagnosisDelivery;