import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Container, Row, Col, Card, Form, Button} from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
//components
import {HappContainer} from 'happ-components/HappStyledComponents/HappContainer/HappContainer';
import {FormError} from 'happ-components/HappStyledComponents/HappFormStyles/HappFormStyles';
import logo from 'assets/imgs/logo-happ-heart.svg';
import LoginLoader from 'happ-components/Loader/LoginLoader';
//custom-hooks
import useValidatorFrom from 'happ-custom-hooks/useValidatorForm/useValidatorForms';
//actions
import {resetPasswordAction} from 'happ-redux/happ-actions/auth/authActions';
import SweetAlert2 from 'react-sweetalert2';

const initState = {
    password: '',
    copyPassword: ''
}
const ResetPassword = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [confirmResetPasswordMsg, setConfirmResetPasswordMsg] = useState({
        show: true,
        text: 'Su contraseña ha sido actualizada correctamente, puede iniciar sesión',
        icon: 'success',
    });

    const {
        state,
        errors,
        handlerSubmit,
        handlerChange,
    } = useValidatorFrom(initState, resetPassword);
    const {password, copyPassword} = state;
    const {errorServer, errorMsg, resetPasswordValidated} = useSelector(state => state.auth);
    function resetPassword(){
        let token = location.pathname.split('reset-password/', 2)[1];
        const resetParams = {
            password,
            confirmPassword: copyPassword,
            token
        }
        dispatch(resetPasswordAction(resetParams, navigate));
    }    
    return ( 
        <Container>
            <LoginLoader/> 
                <Row className="vh-100 d-flex justify-content-center align-items-center">

                    {resetPasswordValidated ? (
                        <SweetAlert2 {...confirmResetPasswordMsg} 
                            didClose={() => {
                                navigate('/login');
                            }}
                        />
                    ) : null}

                    <Col md={8} lg={6} xs={12}>
                        <HappContainer>
                            <Card className="shadow-box border-box">
                                <Card.Body className='card-login'>
                                    <div className="mb-3 mt-md-4">
                                        <div className="content-logo">
                                            <img src={logo} alt="description"/>
                                        </div>
                                    </div>
                                    <Form 
                                        className="form-action"
                                        onSubmit={handlerSubmit}
                                    >
                                        <div className="text-center">
                                            <h6>Cambio de contraseña</h6>
                                        </div>
                                        <br></br>
                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicPassword"
                                        >
                                            <Form.Label>
                                                Ingresa nueva contraseña
                                            </Form.Label>
                                            <Form.Control 
                                                type="password" 
                                                onChange={handlerChange}
                                                value={password}
                                                placeholder="******" 
                                                name="password"
                                            />
                                            {errors.password ? (
                                                <FormError>{errors.password}</FormError>
                                            ) : null}
                                        </Form.Group>

                                        <Form.Group
                                            className="mb-3"
                                            controlId="formBasicPasswordConfirm"
                                        >
                                            <Form.Label>
                                                Confirma nueva contraseña
                                            </Form.Label>
                                            <Form.Control 
                                                type="password"
                                                onChange={handlerChange}
                                                value={copyPassword}
                                                placeholder="******" 
                                                name="copyPassword"
                                            />
                                            {errors.copyPassword ? (
                                                <FormError>{errors.copyPassword}</FormError>
                                            ) : null}
                                            {errorServer && errorMsg.comparePassword ? (
                                                <FormError>{errorMsg.comparePassword}</FormError>
                                            ) : null}
                                        </Form.Group>
                                        <div className="d-grid">
                                            <Button 
                                                className="primary-bg-color" 
                                                type="submit"
                                            >
                                                Continuar
                                            </Button>
                                        </div>
                                    </Form>    
                                </Card.Body>
                            </Card>
                        </HappContainer>
                    </Col>
                </Row>
            </Container>
    );
}
 
export default ResetPassword;