import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { formatRut } from "react-rut-formatter";
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import validateInputTypes from 'happ-custom-hooks/form-inputs-validator/inputs';
import { regiones, communesByReg } from 'happ-redux/happ-services/auth/auth-services';


const useValidatorFrom = (initialState, fn) => {
    const [state, setState] = useState(initialState);
    const [errors, setErrors] = useState({});
    const [submit, setSubmit] = useState(false);
    const [comunes, setComunes] = useState(null);
    const [stateReg, setStateReg] = useState(null);
    const {region} = state;
    const { profile } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        const updateData = () => {
            const data =  profile;
            const apellidos = data?.lastName.split(' ');
            let birthDay = moment.utc(data?.birthDay).format('YYYY-MM-DD');
            if (birthDay === '1000-01-01') {
                birthDay = '';
            }
            const phoneSplitLength = data?.phone.split(' ').length;
            const phone = phoneSplitLength > 1 ?
                    data?.phone.split(' ')[1] === '987654321' ? '' : data?.phone.split(' ')[1] : 
                    data?.phone === '987654321' ? '' : data?.phone;      
            if ( data && location.pathname === '/mi-perfil' ) {
                setState({
                    firstName : data?.firstName, 
                    dni : data?.dni,
                    lastName : apellidos[0],
                    lastName2: apellidos[1],
                    email : data?.email,
                    phone,
                    gender : data?.gender,
                    birthDay,
                    street : data?.address[0]?.street === 'sin calle' ? '' : data?.address[0]?.street,
                    commune : data?.address[0]?.commune === 'sin comuna' ? '' : data?.address[0]?.commune,
                    region : data?.address[0]?.region === 'sin region' ? '' : data?.address[0]?.region,
                    healthForecast : data?.healthForecast === 'sin prevision' ? '' : data?.healthForecast,
                    company : data?.company,
                })
            }
            return
        }
        updateData();
    }, [profile, location])
    

    useEffect(() => {
        if (submit) {
            if ( !Object.keys(errors).length ){
                fn();
            }
            setSubmit(false);
        }
    }, [errors, submit]);

    useEffect(() => {
        if (region) {
            const comunes = communesByReg(region);
            setComunes(comunes);
        } else {
            setComunes(null);
        }
    }, [region])

    useEffect(() => {
        setStateReg(regiones);
    }, []);

    const handlerChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        });
    }

    const handleChangePhone = e => {
        // const newValue = e.target.value.replace(/[^0-9]/g, "");
        // if (newValue.length <= 9) {
            setState({
                ...state,
                phone: e.target.value,
            });
        // }
    }


    const handlerChangeDate = (e) => {
        const birthDay = moment(e.target.value);
        const fechaTexto = birthDay.format('YYYY-MM-DD');
        // setFecha(fechaFormateada);
        setState({
            ...state,
            birthDay: fechaTexto
        });
      }

    const handlerChangeDni = e => {
        const dni = e.target.value;
        const formatted = formatRut(dni);
        setState({
            ...state,
            dni: formatted
        });
    }
    

    const handlerChangeReg = e => {
        const comunes = communesByReg(e.target.value);
        setComunes(comunes);
        setState({
            ...state,
            region: e.target.value, 
            commune: ''
        });     
    }

    const handlerSubmit = e => {
        e.preventDefault();
        const arrayErrors = validateInputTypes(state);
        setErrors(arrayErrors);
        setSubmit(true);
    }
    
    return {
        state, 
        errors,
        comunes,
        stateReg,
        handlerChange,
        handlerChangeDate,
        handlerChangeDni, 
        handlerSubmit,
        handleChangePhone,
        handlerChangeReg
    }
}

export default useValidatorFrom;