import { DiagnosticModalIcon } from 'happ-icons/icons'
import { DiagnosticCalendarIcon } from 'happ-icons/icons'
import { CloseModalBlue } from 'happ-icons/icons'
import React from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const ModalToAgendarExam1 = (props) => {

    const status = 'stage2'

    return (
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            {/* este es para el colesterol plomo */}
            <div className='modal-content-white'>
                <Row className=''>
                    <Col className='mt-1'>
                        <div onClick={props.onHide} className='modal-close-btn primary-color-text'>
                            <CloseModalBlue
                            />
                        </div>
                        {status === 'stage2' ? (
                            <div className='text-center'>

                                <DiagnosticModalIcon fill="#044064" className="my-2 ms-3" />
                                {/* <h6 className='primary-color-text fw-bold' style={{letterSpacing: '0.08em'}}>Chequeo y diagnóstico</h6> */}
                                <h6 className='primary-color-text fw-bold'>Exámenes de confirmación</h6>

                            </div>
                        ) : null}

                    </Col>
                </Row>
                <Modal.Body style={{ padding: 0 }}>
                    <div>
                        <Row>
                            <Col>
                                <ul className='primary-color-text mx-5'>
                                    <li className=' my-2'> En esta etapa <strong>revisaremos indicadores específicos</strong> para confirmar <strong>sospechas detectadas</strong> en la fase anterior.</li>
                                    <li className=' my-2'> Te realizaremos mediciones y <strong>exámenes de sangre</strong>.</li>
                                    <li className=' my-2'> Estos exámenes se utilizarán para la <strong>creación de tu eventual tratamiento</strong>.</li>
                                </ul>
                                <div className='py-3'>
                                    <Link to="/agendarhora2" className='d-flex justify-content-center link'>
                                        <Button className='shadow btn-blue'>
                                            <div className='d-flex justify-content-center mt-1'>
                                                <DiagnosticCalendarIcon />
                                                <p className='ms-3'>Agendar</p> 
                                            </div>
                                        </Button>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    )
}

export default ModalToAgendarExam1