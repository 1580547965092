import { HighHemoglobin } from 'happ-icons/icons'
import { LowWeightBarExam } from 'happ-icons/icons'
import { NormalSodio } from 'happ-icons/icons'
import { LowHdlFemale } from 'happ-icons/icons'
import { HighLdlRcvb } from 'happ-icons/icons'
import { HighTriglycerides } from 'happ-icons/icons'
import { HighDiastolic } from 'happ-icons/icons'
import { HighGlycosuria } from 'happ-icons/icons'
import { Normalystolic } from 'happ-icons/icons'
import { NormalCreatinineFemale } from 'happ-icons/icons'
import { NormalProteinuria } from 'happ-icons/icons'
import { NormalWeightBarExam } from 'happ-icons/icons'
import { NormalRac } from 'happ-icons/icons'
import { HighRac } from 'happ-icons/icons'
import { HighProteinuria } from 'happ-icons/icons'
import { NormalErythrocyte } from 'happ-icons/icons'
import { HighErythrocyte } from 'happ-icons/icons'
import { MediumHemoglobin } from 'happ-icons/icons'
import { HighCreatinineMan } from 'happ-icons/icons'
import { HighSugarBarExam } from 'happ-icons/icons'
import { LowHdlMan } from 'happ-icons/icons'
import { HighLdlRcvH } from 'happ-icons/icons'
import { HighLdlRcvM } from 'happ-icons/icons'
import { MediumHemoglucotest } from 'happ-icons/icons'
import { LowWeightBar } from 'happ-icons/icons'
import { LowCreatinineMan } from 'happ-icons/icons'
import { LowPotasio } from 'happ-icons/icons'
import { OverWeightBar } from 'happ-icons/icons'
import { ObesityIandII } from 'happ-icons/icons'
import { LowSodio } from 'happ-icons/icons'
import { LowCreatinineFemale } from 'happ-icons/icons'
import { HighWeighBar } from 'happ-icons/icons'
import { GreenWeightBar } from 'happ-icons/icons'
import { HighHemoglucotest } from 'happ-icons/icons'
import { NormalHemoglucotest } from 'happ-icons/icons'
import { NormalLdlRcvM } from 'happ-icons/icons'
import { NormalLdlRcvH } from 'happ-icons/icons'
import { NormalHdlMan } from 'happ-icons/icons'
import { NormalSugarBarExam } from 'happ-icons/icons'
import { NormalCreatinineMan } from 'happ-icons/icons'
import { HighSystolic } from 'happ-icons/icons'
import { NormalGlycosuria } from 'happ-icons/icons'
import { HighCreatinineFemale } from 'happ-icons/icons'
import { HighWeightBarExam } from 'happ-icons/icons'
import { NormalLdlRcvB } from 'happ-icons/icons'
import { NormalDiastolic } from 'happ-icons/icons'
import { NormalHemoglobin } from 'happ-icons/icons'
import { NormalPotasio } from 'happ-icons/icons'
import { HighPotasio } from 'happ-icons/icons'
import { CholesterolBarExamNormal } from 'happ-icons/icons'
import { WaistNormalBarExam } from 'happ-icons/icons'
import { MediumSugarBarExam } from 'happ-icons/icons'
import { HighSodio } from 'happ-icons/icons'
import { NormalHdlFemale } from 'happ-icons/icons'
import { NormalTriglycerides } from 'happ-icons/icons'
import { CholesterolBarExamHight } from 'happ-icons/icons'
import { WaistLowBarExam } from 'happ-icons/icons'
import React from 'react'
import { Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import mildDiastolic from '../BarInfoContent/MildDiastolic.svg';
import mildSystolic from '../BarInfoContent/MildSystolic.svg';
import normalDiastolic from '../BarInfoContent/NormalDiastolic.svg';
import highDiastolic from '../BarInfoContent/HighDiastolic.svg';
import highSystolic from '../BarInfoContent/HighSystolic.svg';
import normalSystolic from '../BarInfoContent/NormalSystolic.svg';

const TableModal = ({ exam, color }) => {

    // const { medicalStage, medicalStageId, loading, profile, id } = useAuth();
    const { profile, rcvPatient } = useSelector(state => state.auth);

    // const { rcvPatient } = useSelector(state => state.auth);

    console.log(profile?.gender)
    const gender = profile?.gender;

    return (
        <Table>
            <thead>
                <tr>
                    <th></th>
                    <td>Resultados</td>
                    <td>Valores normales</td>

                </tr>
            </thead>

            {exam.exam === 'toma-presion-1' || exam.exam === 'Toma de presion 2' ? (
                <tbody >
                    <tr>
                        <td className='text-start item-table-modal pt-3'>Sistolica</td>
                        <td className='pt-3'>{exam.sistolica}</td>
                        <td className=''>
                            {exam.sistolica <= 129  ? (
                                <img src={normalSystolic} />
                            ) : exam.sistolica >= 130 && exam.sistolica <= 139 ? (
                                <img src={mildSystolic} />
                            ) : exam.sistolica >= 140 ? (
                                <img src={highSystolic} />
                            ) : null}
                        </td>
                    </tr>
                    <tr>
                        <td className='text-start item-table-modal pt-3' >Diastolica</td>
                        <td className='pt-3'>{exam.diastolica}</td>
                        <td>
                            {exam.diastolica <= 79 ? (
                                <img src={normalDiastolic} />
                            ) : exam.diastolica >= 80 && exam.diastolica <= 89 ? (
                                <img src={mildDiastolic} />
                            ) : exam.diastolica >= 90 ? (
                                <img src={highDiastolic} />
                            ) : null}
                        </td>
                    </tr>
                </tbody>
            ) : null}

            {exam.exam === 'Orina Completa 1' ? (
                <tbody >
                    <tr>
                        <td className='text-start item-table-modal pt-3' >Eritrocitos</td>
                        <td className='pt-3'>{exam.Eritrocitos}</td>
                        <td>
                            {exam.Eritrocitos >= 3 ? (
                                <HighErythrocyte className='bar-table-modal' />
                            ) : (
                                <NormalErythrocyte className='bar-table-modal' />
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className='text-start item-table-modal pt-3' >Glucosuria</td>
                        <td className='pt-3'>{exam.Glucosuria}</td>
                        <td>
                            {exam.Glucosuria > 0 ? (
                                <HighGlycosuria className='bar-table-modal' />
                            ) : (
                                <NormalGlycosuria className='bar-table-modal' />
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className='text-start item-table-modal pt-3'>Proteinuria</td>
                        <td className='pt-3'>{exam.Proteinuria}</td>
                        <td>
                            {exam.Proteinuria > 0 ? (
                                <HighProteinuria className='bar-table-modal' />
                            ) : (
                                <NormalProteinuria className='bar-table-modal' />
                            )}
                        </td>
                    </tr>
                </tbody>
            ) : null}

            {exam.exam === 'Creatinina en Sangre 1' ? (
                <tbody >
                    <tr>
                        <td className='text-start item-table-modal pt-3' >Creatinina</td>
                        <td className='pt-3'>{exam.Creatinina}</td>
                        <td>
                            {gender === 'F' ?
                                <>
                                    {exam.Creatinina >= 1.11 ? (
                                        <HighCreatinineFemale className='bar-table-modal' />
                                    ) : exam.Creatinina <= 0.57 ? (
                                        <LowCreatinineFemale />
                                    ) :
                                        <NormalCreatinineFemale className='bar-table-modal' />
                                    }
                                </>
                                : null}
                            {gender === 'M' ?
                                <>
                                    {exam.Creatinina >= 1.25 ? (
                                        <HighCreatinineMan className='bar-table-modal' />
                                    ) : exam.Creatinina <= 0.72 ? (
                                        <LowCreatinineMan />
                                    ) :
                                        <NormalCreatinineMan className='bar-table-modal' />
                                    }
                                </>
                                : null}
                        </td>
                    </tr>
                </tbody>
            ) : null}

            {exam.exam === 'Rac 1' ? (
                <tbody >
                    <tr>
                        <td className='text-start item-table-modal pt-3' >RAC</td>
                        <td className='pt-3'>{exam.Rac1}</td>
                        <td>
                            {exam.Rac1 >= 30 ? (
                                <HighRac className='bar-table-modal' />
                            ) : (
                                <NormalRac className='bar-table-modal' />
                            )}
                        </td>
                    </tr>
                </tbody>
            ) : null}

            {exam.exam === 'ELP 1' ? (
                <tbody >
                    <tr>
                        <td className='text-start item-table-modal pt-3' >Potasio</td>
                        <td className='pt-3'>{exam.Potasio}</td>
                        <td>
                            {exam.Potasio >= 5.5 ? (
                                <HighPotasio className='bar-table-modal' />
                            ) : exam.Potasio <= 3.5 ? (
                                <LowPotasio />
                            ) :
                                <NormalPotasio className='bar-table-modal' />
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className='text-start item-table-modal pt-3' >Sodio</td>
                        <td className='pt-3'>{exam.Sodio}</td>
                        <td>
                            {exam.Sodio >= 145 ? (
                                <HighSodio className='bar-table-modal' />
                            ) : exam.Sodio <= 135 ? (
                                <LowSodio />
                            ) :
                                <NormalSodio className='bar-table-modal' />
                            }
                        </td>
                    </tr>
                </tbody>
            ) : null}

            {exam.exam === 'Perfil Lipidico 1' ? (
                <tbody >
                    <tr>
                        <td className='text-start item-table-modal pt-3' >Colesterol Total</td>
                        <td className='pt-3'>{exam.Colesterol}</td>
                        <td>
                            {exam.Colesterol >= 200 ? (
                                <CholesterolBarExamHight className='bar-table-modal' />
                            ) : (
                                <CholesterolBarExamNormal className='bar-table-modal' />
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className='text-start item-table-modal pt-3' >HDL(Colesterol bueno)</td>
                        <td className='pt-3'>{exam.HDL}</td>
                        <td>
                            {gender === 'F' ?
                                <>
                                    {exam.HDL <= 50 ? (
                                        <LowHdlFemale className='bar-table-modal' />
                                    ) : (
                                        <NormalHdlFemale className='bar-table-modal' />
                                    )}
                                </>
                                : null}
                            {gender === 'M' ?
                                <>
                                    {exam.HDL <= 40 ? (
                                        <LowHdlMan className='bar-table-modal' />
                                    ) : (
                                        <NormalHdlMan className='bar-table-modal' />
                                    )}
                                </>
                                : null}
                        </td>
                    </tr>
                    <tr>
                        <td className='text-start item-table-modal pt-3'>LDL(Colesterol malo)</td>
                        <td className='pt-3'>{exam.LDL}</td>
                        <td>
                            {rcvPatient !== null ? (
                                <>
                                    {rcvPatient.rcv === 3 ? (
                                        <>
                                            {exam.LDL >= 70 ? (
                                                <HighLdlRcvH className='bar-table-modal' />
                                            ) : (
                                                <NormalLdlRcvH className='bar-table-modal' />
                                            )}
                                        </>
                                    ) : null}
                                    {rcvPatient.rcv === 2 ? (
                                        <>
                                            {exam.LDL >= 100 ? (
                                                <HighLdlRcvM className='bar-table-modal' />
                                            ) : (
                                                <NormalLdlRcvM className='bar-table-modal' />
                                            )}
                                        </>
                                    ) : null}
                                    {rcvPatient.rcv === 1 ? (
                                        <>
                                            {exam.LDL >= 130 ? (
                                                <HighLdlRcvb className='bar-table-modal' />
                                            ) : (
                                                <NormalLdlRcvB className='bar-table-modal' />
                                            )}
                                        </>
                                    ) : null}
                                </>
                            ) : null}
                        </td>
                    </tr>
                    <tr>
                        <td className='text-start item-table-modal pt-3' >Triglicéridos</td>
                        <td className='pt-3'>{exam.Trigliceridos}</td>
                        <td>
                            {exam.Trigliceridos >= 150 ? (
                                <HighTriglycerides className='bar-table-modal' />
                            ) : (
                                <NormalTriglycerides className='bar-table-modal' />
                            )}
                        </td>
                    </tr>
                </tbody>
            ) : null}

            {exam.exam === 'hemoglucotest' ? (
                <tbody >
                    <tr>
                        <td className='text-start item-table-modal pt-3' >Glicemia</td>
                        <td className='pt-3'>{exam.glicemia}</td>
                        <td>
                            {exam.glicemia >= 130 ? (
                                <HighHemoglucotest className='bar-table-modal' />
                            ) : exam.glicemia >= 110 ? (
                                <MediumHemoglucotest className='bar-table-modal' />
                            ) :
                                <NormalHemoglucotest className='bar-table-modal' />
                            }
                        </td>
                    </tr>
                </tbody>
            ) : null}

            {exam.exam === 'Glicemia en Sangre 1' ? (
                <tbody >
                    <tr>
                        <td className='text-start item-table-modal pt-3' >Glicemia en ayunas</td>
                        <td className='pt-3'>{exam.glicemia}</td>
                        <td>
                            {exam.glicemia >= 126 ? (
                                < HighSugarBarExam />
                            ) : exam.glicemia >= 100 ? (
                                <MediumSugarBarExam className='bar-table-modal' />
                            ) :
                                <NormalSugarBarExam className='bar-table-modal' />
                            }
                        </td>
                    </tr>
                </tbody>
            ) : null}

            {exam.exam === 'Glicemia en Sangre 2' ? (
                <tbody >
                    <tr>
                        <td className='text-start item-table-modal pt-3' >Glicemia en ayunas</td>
                        <td className='pt-3'>{exam.glicemia}</td>
                        <td>
                            {exam.glicemia >= 126 ? (
                                < HighSugarBarExam />
                            ) : exam.glicemia >= 100 ? (
                                <MediumSugarBarExam className='bar-table-modal' />
                            ) :
                                <NormalSugarBarExam className='bar-table-modal' />
                            }
                        </td>
                    </tr>
                </tbody>
            ) : null}

            {exam.exam === 'Hemoglobina Glicosilada 1' ? (
                <tbody >
                    <tr>
                        <td className='text-start item-table-modal pt-3' >HbA1c</td>
                        <td className='pt-3'>{exam.HbA1C} %</td>
                        <td>
                            {exam.HbA1C >= 6.40 ? (
                                <HighHemoglobin
                                    fill="white"
                                    responsive="scale-mobile"
                                />
                            ) : exam.HbA1C >= 5.70 ? (
                                <MediumHemoglobin
                                    fill="white"
                                    responsive="scale-mobile"
                                />
                            ) :
                                <NormalHemoglobin
                                    fill="white"
                                    responsive="scale-mobile"
                                />
                            }
                        </td>
                    </tr>
                </tbody>
            ) : null}

            {exam.exam === 'imc' ? (
                <tbody >
                    <tr>
                        <td className='text-start item-table-modal' >Perímetro Cintura</td>
                        <td>{exam.circumference}</td>
                        <td>
                            {exam.imc < 25 ? (
                                <WaistNormalBarExam className='bar-table-modal' />
                            ) : (
                                <WaistLowBarExam className='bar-table-modal' />
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className='text-start item-table-modal' >Peso</td>
                        <td>{exam.weight}</td>
                        <td>
                            {exam.imc < 25 ? (
                                <NormalWeightBarExam className='bar-table-modal' />
                            ) : exam.imc < 18.5 ? (
                                <LowWeightBarExam className='bar-table-modal' />
                            ) :
                                <HighWeightBarExam />
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className='text-start item-table-modal pt-3' >IMC</td>
                        <td className='pt-3'>{exam.imc}</td>
                        <td>
                            {exam?.sportsman ? (
                                <GreenWeightBar
                                    fill="white"
                                    responsive="scale-mobile"
                                />
                            ) :

                                exam.imc < 18.5 ? (
                                    <LowWeightBar
                                        fill="white"
                                        responsive="scale-mobile"
                                    />
                                ) : exam.imc < 25 ? (
                                    <GreenWeightBar
                                        fill="white"
                                        responsive="scale-mobile"
                                    />
                                ) : exam.imc < 30 ? (
                                    <OverWeightBar
                                        fill="white"
                                        responsive="scale-mobile"
                                    />
                                ) : exam.imc < 35 ? (
                                    <ObesityIandII
                                        fill="white"
                                        responsive="scale-mobile"
                                    />
                                ) : (
                                    <HighWeighBar
                                        fill="white"
                                        responsive="scale-mobile"
                                    />
                                )
                            }
                        </td>
                    </tr>
                </tbody>
            ) : null}
        </Table>
    )
}

export default TableModal