import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, ProgressBar, Spinner } from 'react-bootstrap';
import { HappContainer } from 'happ-components/HappStyledComponents/HappContainer/HappContainer';
import SweetAlert2 from 'react-sweetalert2';
import MentalHealthStructure from 'happ-components/MentalHealthStructure/MentalHealthStructure';
import MentalHealthOk from 'happ-components/MentalHealthStructure/MentalHealthOk';
import MentalHealthPsychologist from 'happ-components/MentalHealthStructure/MentalHealthPsychologist';
import MentalHealthPsychiatrist from 'happ-components/MentalHealthStructure/MentalHealthPsychiatrist';
import LoaderView from 'happ-components/Loader/LoaderView';
import { 
    getPHQ9CalculationAction,
    sendMentalHealthSurveyAction 
} from 'happ-redux/happ-actions/mentalHealth/mentalHealthAction';

const MentalHealth = () => {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true);
    const { profile } = useSelector(state => state.auth);
    const { phq9Calculation, saveMentalHealthSurvey, loadingSave } = useSelector(state => state.mentalHealth);
    useEffect(() => {
        const getInfo = () => {
            if (profile || saveMentalHealthSurvey === true) {
                dispatch(getPHQ9CalculationAction(profile._id));
                setTimeout(() => {
                    setLoading(false);
                }, 200);
            }
        }
        getInfo();
    }, [profile, saveMentalHealthSurvey]);
    const [survey, setSurvey] = useState({
        step: 1,
        progress: Math.round((1 * 100) / 9),
        questionOne: -1,
        questionTwo: -1,
        questionThree: -1,
        questionFour: -1,
        questionFive: -1,
        questionSix: -1,
        questionSeven: -1,
        questionEight: -1,
        questionNine: -1,
    });
    const [surveyError, setSurveyError] = useState(false);
    const [okSurvey, setOkSurvey] = useState({
        show: true,
        text: 'Encuesta registrada con exito',
        type: 'success',
        icon: 'success',
    });
    const [submit, setSubmit] = useState(false);
    const preview = () => {
        const { step } = survey;
        const newStep = step - 1;
        const newProgress = Math.round((newStep * 100) / 9);
        setSurvey({
            ...survey,
            step: newStep,
            progress: newProgress,
        })
    }
    const getQuestionByStep = currentStep => {
        const questions = {
            1: survey.questionOne,
            2: survey.questionTwo,
            3: survey.questionThree,
            4: survey.questionFour,
            5: survey.questionFive,
            6: survey.questionSix,
            7: survey.questionSeven,
            8: survey.questionEight,
            9: survey.questionNine,
        }
        return questions[currentStep];
    }
    const next = () => {
        const { step } = survey;
        const question = getQuestionByStep(step);
        if (question === -1) {
            setSurveyError(true);
            return;
        }
        const newStep = step + 1;
        const newProgress = Math.round((newStep * 100) / 9);
        setSurvey({
            ...survey,
            step: newStep,
            progress: newProgress,
        })
    }
    const handlerChange = e => {
        setSurvey({
            ...survey,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        });
        setSurveyError(false);
    }
    const submitSurvey = () => {
        const { step } = survey;
        const question = getQuestionByStep(step);
        if (question === -1) {
            setSurveyError(true);
            return;
        }
        dispatch(sendMentalHealthSurveyAction(survey, profile?._id, 'mental-survey', 'Encuesta Mental'));
    }
    const { progress } = survey;
    const renderSurvey = (
        <>
            {phq9Calculation && !isLoading && !loadingSave? (
                phq9Calculation.type.name === 'tipo-1' ? (
                    <MentalHealthOk />
                ) :  phq9Calculation.type.name === 'tipo-2' ? (
                    <MentalHealthPsychologist 
                        phq9Calculation={phq9Calculation}
                        profile={profile}
                    />
                ) : (
                    <MentalHealthPsychiatrist />
                )
            ): null}
            {!phq9Calculation && !isLoading && !loadingSave? (
                <>
                    <Col className='pt-2'>
                        <div className='d-flex justify-content-center'>
                            <div className='text-center px-5 py-2 text-white primary-bg-color  rounded-1 indicators-content'>
                                <strong className='title-stages' >Encuesta Salud Mental</strong>
                            </div>
                        </div>
                    </Col>
                    <h5 className='mt-4 text-center'>Durante las ultimas dos semanas ¿Con que frecuencia le han molestado los siguentes problemas?</h5>
                    <Col md={9} sm={12} lg={9} className='progress-bar-content'>
                        <ProgressBar now={progress} />
                    </Col>
                    <MentalHealthStructure 
                        survey={survey}
                        surveyError={surveyError}
                        preview={preview}
                        next={next}
                        handlerChange={handlerChange}
                        submitSurvey={submitSurvey}
                    />
                </>
            ) : null}
        </>
    )
    return ( 
        <Row className="mt-content">
            {saveMentalHealthSurvey ? (
                <SweetAlert2
                    {...okSurvey}
                />
            ) : null}
            <HappContainer>
                {loadingSave ? (
                    <LoaderView 
                        show={true}
                    />
                ) : null}
                {isLoading ? (
                    <LoaderView 
                        show={true}
                    />
                ) : renderSurvey}
            </HappContainer>
        </Row>    
     );
}
 
export default MentalHealth;