import { width } from '@mui/system'
import IndicatorModal from 'happ-components/Indicators/IndicatorModal'
import { useModal } from 'happ-custom-hooks/useModal/useModal'
import { agendaModal } from 'happ-data/data2'
import React, { useEffect } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'


const arrayOpen = [];
const test = [
    {
        title: 'Chequeo Diagnóstico',
        description:
            [
                { result: 'En esta etapa detectaremos si tienes sospecha de alguna enfermedad cardiovascular.' },
                { result: 'Se realizarán mediciones y exámenes no invasivos.' },
                { result: 'Si detectamos una sospecha, te realizaremos exámenes de sangre para confirmar.' }
            ]

    }
]
const test2 = [
    {
        title: 'Exámenes Físicos',
        description:
            [
                { result: 'En esta etapa realizaremos exámenes no invasivos' },
                { result: 'El objetivo es descartar otras enfermedades asociadas a las que ya hemos diagnosticado' },
                { result: 'Realizaremos un Electrocardiograma, te auscultaremos y evaluaremos si cuentas con edemas' }
            ]

    }
]

const HomeMessageAgenda3 = ({ healthIndicators }) => {

    // const [isOpen, openModal, setIsOpen] = useModal(false)

    // aqui podria haber un useEffect escuchando el cambio de estado de la agenda y que haga dispatch cuando cambie... 
    // si el estado de mi agenda es true osea ya tome la hora entonces muestro otro mensaje en este componente junto con una barra de color azul abajo. 

    // useSelector

    // test.map((posi, i) => {

    //     console.log(posi.description)

    // })

    const { openModal, ShowModal } = useModal(arrayOpen, 'homeButton');

    useEffect(() => {

    }, [])

    const statusExamenes = false;
    const horaAgendada = true;
    const status = 'stage3'


    return (

        <div className='blue-stripe'>
            {status === 'stage1' && !horaAgendada ? (
                <>
                    <Row>
                        <Col className=' text-center'>
                            <h1 className='primary-color-text title-message'>Comienza tu viaje</h1>
                            <p className='primary-color-text subtitle-message'>
                                Programa tu próxima etapa <strong>chequeo y diagnóstico</strong>
                            </p>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col className=' text-center'>
                            <Button onClick={openModal(test)} className='btn-home-message'>
                                Iniciar
                            </Button>
                        </Col>
                    </Row>
                    <ShowModal />
                </>
            ) : null}
            {status === 'stage1' && horaAgendada ? (
                <>
                    <Row>
                        <Col className=' text-center my-4 primary-color-text'>
                            <h1 className='title-message' ><strong>¡Tu hora está agendada!</strong></h1>
                            <p className='p-success-schedule'> Asiste en ayunas a tu consulta el día</p>
                            <p className='p-success-schedule'> <strong>viernes 6 de enero a las 11:00horas.</strong></p>
                        </Col>
                    </Row>
                    <Row className='pt-2'>
                        <Col className='d-flex justify-content-center'>
                            <div className='py-2 primary-bg-color rounded-1 indicators-content canvas-message'>
                                Conoce la <strong>importancia</strong> de cada examen
                            </div>
                        </Col>
                    </Row>
                </>
            ) : null}
            {status === 'stage2' ? (
                <>
                    <Row>
                        <Col className=' text-center'>
                            <h1 className='fw-bold primary-color-text title-message'>¡Revisa tus resultados!</h1>
                            <p className='primary-color-text lh-1 subtitle-message'>
                                Los resultados de laboratorio aún están siendo procesados, por favor espera.
                            </p>
                        </Col>
                    </Row>
                    <Row className='primary-bg-color text-white my-4'>
                        <Col className=' text-center'>
                            <p className='mt-2'> Conoce la <b>importancia</b> de cada examen</p>
                            {/* <p className='mt-2 '> <b>Serás notificado cuando estén disponibles </b> </p> */}
                        </Col>
                    </Row>
                </>
            ) : null}
            {status === 'stage3' && !horaAgendada ? (
                <>
                    <Row>
                        <Col className=' text-center primary-color-text'>
                            <h1 className='fw-bold  title-message'>Elige tu hora</h1>
                            <p className='lh-1 subtitle-message'>
                                Revisa tus resultados y agenda tu hora para la siguiente etapa.
                            </p>
                        </Col>
                    </Row>
                    <Row className='py-3'>
                        <Col className=' text-center'>
                            <Button onClick={openModal(test2)} className='shadow btn-home-message'>
                                {/* <Button  className='shadow btn-home-message'> */}
                                Agendar aquí
                            </Button>
                        </Col>
                    </Row>
                    <ShowModal />
                </>
            ) : null}
                        {status === 'stage3' && horaAgendada ? (
                <>
                    <Row>
                        <Col className=' text-center primary-color-text'>
                            <h1 className='fw-bold  title-message'>Exámenes Físicos</h1>
                            <p className='p-success-schedule'> Recuerda llegar el día</p>
                            <p className='p-success-schedule'> <strong>viernes 27 de enero a las 11:00horas.</strong></p>
                        </Col>
                    </Row>
                    <Row className='mb-3'>
                        <Col className=' text-center'>
                            <Link to="/agendarhora3" className='d-flex justify-content-center link'>
                                <Button className='btn-home-message'>
                                    Modificar hora
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </>
            ) : null}



        </div>
    )
}

export default HomeMessageAgenda3;