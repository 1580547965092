import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Container, Button, ButtonGroup } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import bajo from 'assets/imgs/15.png';
import medio from 'assets/imgs/50.png';
import alto from 'assets/imgs/75.png';
// components
import { HappContentIndicator, SubContentIndicator } from 'happ-components/HappStyledComponents/HappContentIndicator/HappContentIndicator';
import { Message } from 'happ-components/HappStyledComponents/HappElementsStyled/HappElementsStyled';
import { Dyslipidemiaicon, OverweightIcon, HypertensionIcon, DiabetesIcon, ScoreIcon, SmallScoreIcon, PressureIndicator, ColesterolIndicator, DiabetesIndicator } from 'happ-icons/icons';
import Params from 'happ-components/Indicators/Params';
import { Loader } from 'happ-components/Loader/Loader';
//actions
import { getHealthIndicatorsAction } from 'happ-redux/happ-actions/healthIndicatos/healthIndicatorsActions';
import IndicatorModal from './IndicatorModal';
import { indicadoresModal } from 'happ-data/data2'
import { useModal } from 'happ-custom-hooks/useModal/useModal';
import { agendaModal } from 'happ-data/data2';
import { HighRisk } from 'happ-icons/icons';
import { ModerateRisk } from 'happ-icons/icons';
import { LowRisk } from 'happ-icons/icons';
import HomeMessage from 'happ-components/HomeMessage/HomeMessage';
import HomeMessage2 from 'happ-components/HomeMessage/HomeMessage2';
import HomeMessage3 from 'happ-components/HomeMessage/HomeMessage3';
import ModalColesterol2 from 'happ-components/Modal/ModalChequeo2/ModalColesterol2';
import { useModal2 } from 'happ-custom-hooks/useModal/useModal2';
import ModalPeso2 from 'happ-components/Modal/ModalChequeo2/ModalPeso2';
import ModalAzucar2 from 'happ-components/Modal/ModalChequeo2/ModalAzucar2';
import ModalPresion2 from 'happ-components/Modal/ModalChequeo2/ModalPresion2';
import { DiabetesIndicatorBoxExam } from 'happ-icons/icons';

const arrayOpen = [];
const test = [
    {
        title: 'Chequeo Azúcar',
        description: [{ result: 'Tener niveles altos de azúcar en la sangre es dañino para tu cuerpo y puede causar graves problemas de salud. Como infartos cardiacos y cerebrales. pérdida de la visión y daño en los riñones' }]
    },
    {
        title: 'Control de Peso',
        description: [{ result: 'Controlar tu peso contribuye a la buena salud. Por el contrario, las personas que tienen obesidad, tienen un mayor riesgo de sufrir muchas enfermedades y afecciones graves' }]
    },
    {
        title: 'Chequeo Colesterol',
        description: [{ result: 'Un nivel alto de colesterol en la sangre aumenta el riesgo de enfermedad de las arterias coronarias, es importante que se encuentre dentro de rangos normales' }]
    },
    {
        title: 'Presión Arterial',
        description: [{ result: 'La presión arterial alta (hipertensión) puede dañar de manera discreta el organismo durante años antes de que se manifiesten los síntomas' }]
    }
];

const Indicators4 = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getHealthIndicatorsAction(navigate));
    }, []);

    const { healthIndicators, rcvPatient, errorServer, errorMsg, loading } = useSelector(state => state.indicators);
    const { openModal, ShowModal3, ShowModal3Test } = useModal(arrayOpen, 'indicators');

    const [show, handleShow, handleClose] = useModal2(false);
    const [show2, handleShow2, handleClose2] = useModal2(false);
    const [show3, handleShow3, handleClose3] = useModal2(false);
    const [show4, handleShow4, handleClose4] = useModal2(false);

    const statusExamenes = false;
    // console.log(healthIndicators)

    const status = 'stage3'

    return (
        loading ? (
            <Loader />
        ) : (
            errorServer ? (
                <Col sm={12} lg={12} md={12} className="mb-5 mt-5">
                    <Message>
                        <p>{errorMsg}</p>
                    </Message>
                </Col>
            ) : (
                <>
                    <HomeMessage3 healthIndicators={healthIndicators} />
                    <div className="indicators-content">
                        <Row className='px-2'>
                            <Col md={6} xs={6} lg={6} className="px-0 pt-4  col-indicator-mobile">

                                <HappContentIndicator
                                    // bgColor={ `indicator-${item.determination.status}`}
                                    className={status === 'stage3' ? 'box-content indicator-danger mb-4' : null} // en estatus podria enviarme el success el danger el normal para cambiar el color(agregar mas status)
                                    // onClick={openModal(test)} // una vez editada la api le envio item.modalinfo(que debiese ser un objeto) y el indice
                                    onClick={handleShow}
                                    role="button"
                                >
                                    <Row>
                                        {/* <Col className='d-flex justify-content-center' style={{border: '1px solid red'}}> */}
                                        <div className='my-2'>
                                            <ColesterolIndicator
                                                fill='white'
                                                responsive='scale-mobile'
                                            />
                                        </div>
                                    </Row>
                                    <Row className={status === 'stage3' ? `dark-opacity border-bottom-container` : 'border-bottom-container blue-border'}>
                                        <Col >
                                            <p> Colesterol</p>
                                        </Col>
                                    </Row>
                                </HappContentIndicator>

                                <HappContentIndicator
                                    // bgColor={ `indicator-${item.determination.status}`}
                                    className={status === 'stage3' ? 'box-content indicator-danger mb-4' : null}// en estatus podria enviarme el success el danger el normal para cambiar el color(agregar mas status)
                                    // onClick={openModal(test, i)} // una vez editada la api le envio item.modalinfo(que debiese ser un objeto) y el indice
                                    onClick={handleShow3}
                                    role="button"
                                >
                                    <Row>
                                        <div className='my-2'>
                                            <PressureIndicator
                                                fill='white'
                                                responsive='scale-mobile'
                                            />
                                        </div>
                                    </Row>
                                    <Row className={status === 'stage3' ? `dark-opacity border-bottom-container` : 'border-bottom-container blue-border'}>
                                        <Col >
                                            <p> Presión Arterial</p>
                                        </Col>
                                    </Row>
                                </HappContentIndicator>

                            </Col>

                            <Col md={6} xs={6} lg={6} className="px-0 pt-4  col-indicator-mobile">

                                <HappContentIndicator
                                    // bgColor={ `indicator-${item.determination.status}`}
                                    className={status === 'stage3' ? 'box-content indicator-success mb-4' : null} // en estatus podria enviarme el success el danger el normal para cambiar el color(agregar mas status)
                                    // onClick={openModal(test, i)} // una vez editada la api le envio item.modalinfo(que debiese ser un objeto) y el indice
                                    onClick={handleShow2}
                                    role="button"
                                >
                                    <Row>
                                        <div className='my-2'>
                                            <OverweightIcon
                                                fill='white'
                                                responsive='scale-mobile'
                                            />
                                        </div>
                                    </Row>
                                    <Row className={status === 'stage3' ? `dark-opacity border-bottom-container` : 'border-bottom-container blue-border'}>
                                        <Col >
                                            <p> Control de peso </p>
                                        </Col>
                                    </Row>
                                </HappContentIndicator>

                                <HappContentIndicator
                                    // bgColor={ `indicator-${item.determination.status}`}
                                    className={status === 'stage3' ? 'box-content indicator-warning-modal mb-4' : null} // en estatus podria enviarme el success el danger el normal para cambiar el color(agregar mas status)
                                    // onClick={openModal(test, i)} // una vez editada la api le envio item.modalinfo(que debiese ser un objeto) y el indice
                                    onClick={handleShow4}
                                    role="button"
                                >
                                    <Row>
                                        <div className='my-2'>
                                            {/* <DiabetesIndicator
                                                fill='white'
                                                responsive='scale-mobile'
                                            /> */}
                                            <DiabetesIndicatorBoxExam
                                                style={{ height: '53px' }}
                                                fill='white'
                                                responsive='scale-mobile'
                                            />
                                        </div>
                                    </Row>
                                    <Row className={status === 'stage3' ? `dark-opacity border-bottom-container` : 'border-bottom-container blue-border'}>
                                        <Col >
                                            <p>Azúcar</p>
                                        </Col>
                                    </Row>
                                </HappContentIndicator>

                            </Col>
                            {/* <ModalColesterol2 show={show} onHide={handleClose} />
                            <ModalPeso2 show={show2} onHide={handleClose2} />
                            <ModalPresion2 show={show3} onHide={handleClose3} />
                            <ModalAzucar2 show={show4} onHide={handleClose4} /> */}
                            <ModalColesterol2 show={show} onHide={handleClose} />
                            <ModalPeso2 show={show2} onHide={handleClose2} />
                            <ModalPresion2 show={show3} onHide={handleClose3} />
                            <ModalAzucar2 show={show4} onHide={handleClose4} />
                            {/* <ShowModal3Test/> */}
                            {/* <ShowModal /> */}
                            {/* <ShowModal2 /> para stage2 */}
                            {/* <ShowModal3 /> para stage3  */}
                        </Row>
                        {/* {status === 'stage3' ? ( */}
                        <>
                            {/* <h4 className='primary-color-text'>Tu riesgo cardiovascular</h4> */}
                            {/* {rcvPatient !== null ? ( */}
                            <div className='indicators-content d-flex justify-content-center w-100 mt-3' >
                                <ModerateRisk />
                                {/* {rcvPatient === 'Alto' ? (
                                                <HighRisk  />
                                                ) : null} */}
                                {/* {rcvPatient === 'Moderado' ? (
                                                <ModerateRisk  />
                                                ) : null} */}
                                {/* {rcvPatient === 'Bajo' ? (
                                                <LowRisk  />
                                            ) : null} */}
                            </div>
                            {/* ) : null} */}
                        </>
                        {/* ) : null} */}

                    </div>
                </>

            )
        )
    );
}

export default Indicators4;