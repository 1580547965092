import TableModal from 'happ-components/TableModal/TableModal'
import React, { useState } from 'react'
import { Accordion, Col, Row } from 'react-bootstrap'

const AccordionModalEcg = ({title}) => {
  const [activeKey, setActiveKey] = useState("0");

  return (
    <Accordion activeKey={activeKey} className="accordion-modal my-3">
      <Accordion.Item eventKey="0">
        <Accordion.Header onClick={() => setActiveKey("0")}>
          <Row>
            <Col>
            <h6 className='fw-bold'>Electrocardiograma</h6>
            <div className='accordion-description'>Aquí encontrarás la interpretación de tu médico, una vez completes la etapa de telemedicina</div>
            </Col>
          </Row>
        </Accordion.Header>

        <Accordion.Body>
          <Row>
            <div>La interpretación de tu examen estará disponible luego de la hora de telemedicina</div>
          {/* <TableModal title={title} /> */}
          </Row>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export default AccordionModalEcg
