import React from 'react'
import happLogo from 'assets/imgs/logodemo.png'

function OauthLoader() {
  return (
    <div className='oauth-loading'>
      <img className="oauth-loading__logo" src={happLogo} alt='loading-screen'/>
      <div className="oauth-loading__spinner rotate-center" />
    </div>
  )
}

export default OauthLoader