import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Container, Row, Col, Card, Form, Button, Modal} from 'react-bootstrap';
import { useNavigate, Link } from 'react-router-dom';
//components
import {HappContainer} from 'happ-components/HappStyledComponents/HappContainer/HappContainer';
import {FormError} from 'happ-components/HappStyledComponents/HappFormStyles/HappFormStyles';
import logo from 'assets/imgs/logo-happ-heart.svg';
import LoginLoader from 'happ-components/Loader/LoginLoader';
//custom-hooks
import useValidatorFrom from 'happ-custom-hooks/useValidatorForm/useValidatorForms';
//actions
import {authProcessAction} from 'happ-redux/happ-actions/auth/authActions';
import TermsAndConditionsModalText from './TermsAndConditionsModalText';

const initState = {
    password: ''
}
const TermsAndConditions = (props) => {

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {
        state,
        errors,
        handlerSubmit,
        handlerChange,
        handlerChangeDni
    } = useValidatorFrom(initState, authProcess);
    const {dni, password} = state;
    const {errorServer, errorMsg, session} = useSelector(state => state.auth);
    function authProcess(){
        const userParams = {
            password
        }
        dispatch(authProcessAction(userParams, navigate));
    }    
    return ( 
        <Container>
            <LoginLoader show={session}/> 
                <Row className="vh-100 d-flex justify-content-center align-items-center">
                    <Col md={8} lg={6} xs={12}>
                        <HappContainer>
                            <Card className="shadow-box border-box">
                                <Card.Body className='card-login'>
                                    <div className="mb-3 mt-md-4">
                                        <div className="content-logo">
                                            <img src={logo} alt="description"/>
                                        </div>
                                    </div>
                                        <Form 
                                            className="form-action"
                                            onSubmit={handlerSubmit}
                                        >
                                            <div className="text-center">
                                                Bienvenido!
                                                <br></br><br></br>
                                            </div>
                                            <div className="text-center">
                                                Tu viaje de salud preventiva digital comienza ahora
                                                <br></br><br></br>
                                            </div>
                                            {/* checkbox */}
                                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                <div className='text-center'>
                                                    <Form.Check 
                                                        type="checkbox" 
                                                        label="Acepto los "
                                                    />
                                                    <TermsAndConditionsModalText></TermsAndConditionsModalText>
                                                    {/* <Link className="primary-color" to="/terms-and-conditions">Terminos y condiciones</Link> */}
                                                </div>    
                                            </Form.Group>

                                            <div className="d-grid">
                                                <Button className="primary-bg-color" type="submit">
                                                    Continuar
                                                </Button>
                                            </div>
                                        </Form>    
                                </Card.Body>
                            </Card>
                        </HappContainer>
                    </Col>
                </Row>
            </Container>
    );
}
 
export default TermsAndConditions;