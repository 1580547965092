import {
    LOGOUT,
    INIT_SCHEDULE,
} from 'happ-redux/happ-types';
import { getScheduleByPatient } from 'happ-redux/happ-services/schedule/schedule';
import { SCHEDULE_MEDICAL } from 'happ-redux/happ-types';
import { SCHEDULE_MEDICAL_ERROR } from 'happ-redux/happ-types';
import { createScheduleByPatient } from 'happ-redux/happ-services/schedule/schedule';
import { INIT_ADD_SCHEDULE } from 'happ-redux/happ-types';
import { ADD_SCHEDULE } from 'happ-redux/happ-types';
import { ERROR_ADD_SCHEDULE } from 'happ-redux/happ-types';
import { INIT_DELETE_SCHEDULE } from 'happ-redux/happ-types';
import { deleteScheduleByPatient } from 'happ-redux/happ-services/schedule/schedule';
import { DELETED_SCHEDULE } from 'happ-redux/happ-types';
import { DELETE_ERROR } from 'happ-redux/happ-types';
import { getCurrentScheduleByPatient } from 'happ-redux/happ-services/schedule/schedule';
import { ERROR_SCHEDULING } from 'happ-redux/happ-types';
import { GET_SCHEDULING } from 'happ-redux/happ-types';
import { INIT_GET_SCHEDULING } from 'happ-redux/happ-types';
import { GET_SCHEDULING404 } from 'happ-redux/happ-types';
import { updateScheduleByPatient } from 'happ-redux/happ-services/schedule/schedule';
import { PROCCESS_UPDATE } from 'happ-redux/happ-types';
import { ERROR_UPDATE } from 'happ-redux/happ-types';
import { INIT_UPDATE } from 'happ-redux/happ-types';
import { INIT_SCHEDULE_FORM } from 'happ-redux/happ-types';

export function proccessScheduleAction(id, navigate) {

    const errorServer = {
        server: 'Error en el servidor, no se pudo acceder a la plataforma'
    }
    return async (dispatch) => {
        dispatch(initSchedule());
        try {
            const token = localStorage.getItem('token');
            if (token === null) {
                dispatch(logout());
                navigate('/login');
            }
            const response = await getScheduleByPatient(id);
            
            if (response.status === 401) {
                dispatch(logout());
                navigate('/login');
            }
            if (response.status === 200) {
                dispatch(scheduleMedical(response.data));
            } else {
                dispatch(scheduleMedicalError(response.data.message));
            }
        } catch (error) {
            dispatch(scheduleMedicalError(errorServer.server));
        }
    }
}

export function schedulingAction(id, navigate) {
    const errorServer = {
        server: 'Error en el servidor, no se pudo acceder a la plataforma'
    }
    return async (dispatch) => {
        dispatch(initGetScheduling());
        try {
            const token = localStorage.getItem('token');
            if (token === null) {
                dispatch(logout());
                navigate('/login');
            }
            const response = await getCurrentScheduleByPatient(id);
            // console.log(response.data)
            if (response.status === 401){
                dispatch(logout());
                navigate('/login');
            }
            if (response.data.status === 200) {
                dispatch(getScheduling(response.data)); // objeto donde saco el dia y hora
                // console.log(' es 200 porq ya agendé')
            }
            if (response.data.status === 404) {
                dispatch(getScheduling404(response.data)); // true o false
                // console.log(' es 404 porq no eh agendado')
            }
            if (response.data.status !== 404 && response.data.status !== 200) {
                dispatch(errorScheduling(response.data.msg));
            }
        } catch (error) {
            dispatch(errorScheduling(errorServer.server));
        }
    }
}

export function createPatientScheduleAction(id, phone, schedule, day, hour, hourId, navigate) {

    const errorServer = {
        server: 'Error en el servidor, no se pudo acceder a la plataforma'
    }

    return async (dispatch) => {
        dispatch(initAddScheduleTime());
        try {
            const token = localStorage.getItem('token');
            if (token === null) {
                dispatch(logout());
                navigate('/login');
            }
            const response = await createScheduleByPatient(id, phone, schedule, day, hour, hourId);

            console.log(response)
            if (response.status === 401) {
                dispatch(logout());
                navigate('/login');
            }
            if (response.status === 201) {
                dispatch(sendScheduleTime(response.data));
                console.log(response.data)
                console.log('cree agenda')
            } else {
                dispatch(errorScheduleTime(response.data.message));
            }
        } catch (error) {
            dispatch(errorScheduleTime(errorServer.server));
        }
    }
}


export function deleteScheduledTime(scheduleId, navigate) {

    const errorServer = {
        server: 'Error en el servidor, no se pudo acceder a la plataforma'
    }

    return async (dispatch) => {
        dispatch(initDeleteScheduleTime());
        try {
            const token = localStorage.getItem('token');
            if (token === null) {
                dispatch(logout());
                navigate('/login');
            }
            const response = await deleteScheduleByPatient(scheduleId);

            if (response.status === 401) {
                dispatch(logout());
                navigate('/login');
            }
            if (response.status === 200) {
                dispatch(scheduleTimeDeleted(response));
                navigate('/')
            } else {
                dispatch(errorDeleteSchedule(response.data.message));
            }
        } catch (error) {
            dispatch(errorScheduleTime(errorServer.server));
        }
    }
}
// cambia estado de formulario
export function initUpdateScheduleForm() {

    return async (dispatch) => {
        dispatch(initScheduleForm());
    }
}

export function updateSchedule(scheduleId, schedule, day, hour, hourId, phone, navigate) {

    const errorServer = {
        server: 'Error en el servidor, no se pudo acceder a la plataforma'
    }
    return async (dispatch) => {
        dispatch(initUpdateSchedule());
        try {
            const token = localStorage.getItem('token');
            if (token === null) {
                dispatch(logout());
                navigate('/login');
            }
            const response = await updateScheduleByPatient(scheduleId, schedule, day, hour, hourId, phone);

            if (response.status === 401) {
                dispatch(logout());
                navigate('/login');
            }
            if (response.status === 200) {
                dispatch(sendScheduleTime(response.data));
            } else {
                dispatch(errorScheduleTime(response.data.message));
            }

        } catch (error) {
            dispatch(errorScheduleTime(errorServer.server));
        }
    }
}


const initSchedule = () => ({
    type: INIT_SCHEDULE
});

const scheduleMedical = response => ({
    type: SCHEDULE_MEDICAL,
    payload: response
});

const scheduleMedicalError = error => ({
    type: SCHEDULE_MEDICAL_ERROR,
    payload: error
});


// getScheduling
const initGetScheduling = () => ({
    type: INIT_GET_SCHEDULING
});
const getScheduling = response => ({
    type: GET_SCHEDULING,
    payload: response
});
const getScheduling404 = response => ({
    type: GET_SCHEDULING404,
    payload: response,
});
const errorScheduling = error => ({
    type: ERROR_SCHEDULING,
    payload: error
});


const initScheduleForm = () => ({
    type: INIT_SCHEDULE_FORM
})

const initUpdateSchedule = () => ({
    type: INIT_UPDATE
});

const proccessUpdate = response => ({
    type: PROCCESS_UPDATE,
    payload: response
})

const errorUpdate = error => ({
    type: ERROR_UPDATE,
    payload: error
});





const logout = () => ({
    type: LOGOUT
});

// post
const initAddScheduleTime = () => ({
    type: INIT_ADD_SCHEDULE
});

const sendScheduleTime = response => ({
    type: ADD_SCHEDULE,
    payload: response
});

const errorScheduleTime = error => ({
    type: ERROR_ADD_SCHEDULE,
    payload: error
});

// delete
const initDeleteScheduleTime = () => ({
    type: INIT_DELETE_SCHEDULE
});
const scheduleTimeDeleted = response => ({
    type: DELETED_SCHEDULE,
    payload: response,
})
const errorDeleteSchedule = response => ({
    type: DELETE_ERROR,
    payload: response,
})

