import axiosClient from 'happ-config/axios';

const sendCancerSurvey = async (body) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await axiosClient.post('/cancer-journey', body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const lastCancerPrescription = async (type, patient) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await axiosClient.get(`/cancer-journey/${patient}/${type}`,  {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const getCancerCalculation = async (type, patient) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await axiosClient.get(`/cancer-journey/calculation-cancer-by-type/${type}/${patient}`,  {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

export {
    sendCancerSurvey,
    lastCancerPrescription,
    getCancerCalculation
}